import axios from 'axios';
import { customer_Movements } from '../Models/Customer_Movements';
import { KitRequest, Product } from '../Models/Product';
import utils from '../utils';
import { Utils } from '../utils/utils';

const productsAPI = {
    getProductsByCustomerId(customerId:number) : Promise<Product[]> {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.get(`${config.data[0].ulrBackend}/Products/${customerId}`)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                            utils.messageError();
                        } else {
                            console.log('Error', error.message);
                            utils.messageError();
                        }
                        console.log(error.config);
                    });
            });
        });
    },

    createProduct(data: Product){
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].ulrBackend}/Products/createProduct`,data)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                            utils.messageError();
                        } else {
                            console.log('Error', error.message);
                            utils.messageError();
                        }
                        console.log(error.config);
                    });
            });
        });
    },

    postProductIn(data: customer_Movements[]) {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].ulrBackend}/Products/ProductIn`,data)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                        console.log(error.config);
                    });
            });
        });
    },
    UpdateInventoryWarehouse(billId:number,warehouseId:number,data: any) {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].ulrBackend}/Bill/UpdateInventoryWarehouse?billId=${billId}&warehouseId=${warehouseId}`,data)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                        console.log(error.config);
                    });
            });
        });
    },
    CreateKit(data: KitRequest) {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].ulrBackend}/Products/Create_Kit`,data)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                        console.log(error.config);
                    });
            });
        });
    }
}

export default productsAPI;