import { useContext, useState } from "react"
import { Product } from "../Models/Product"
import productsAPI from '../Api/api-products';
import customerAPI from "../Api/api-customers";
import { Customer } from "../Models/Customer";
import Swal from "sweetalert2";
import utils from "../utils";
import InventoryAPI from "../Api/api-inventory";
import { GetInventory } from "../Models/Inventory";
import filterContext from "./filterContex";

export const useAddProduct = () => {
    const filters = useContext(filterContext);
    const [values, setValues] = useState<Product>({
        id: 0,
        name: '',
        palomaName: '',
        quantity: 0,
        code: '',
        selling_price: 0,
        customerId: filters.customerId
    });

    const [validated, setValidated] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [inventories, setInventories] = useState<GetInventory[]>([]);


    const handleInputChange = (e: any) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // setLoading(true);
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setLoading(false);
            return setValidated(true);
        }

        setValidated(false);

        productsAPI.createProduct(values).then(res => {
            Swal.fire(
                'Completado',
                'Producto creado correctamente.',
                'success'
            ).then((result) => {

            })
            setLoading(true);
        })
            .catch(err => {
                console.log(err);
                utils.messageError();
                setLoading(false);
            });
    }

    const getCustomers = () => {
        customerAPI.getCustomers().then(res => {
            setCustomers(res);
        })
    }

    const getInventories = () => {
        setLoading(true);
        InventoryAPI.GetInventoryByCustomerId(filters.customerId, filters.warehouseId).then(res => {
            setInventories(res);
            setLoading(false);
        })
    }

    return {
        values,
        handleInputChange,
        handleSubmit,
        validated,
        loading,
        getCustomers,
        customers,
        inventories,
        getInventories,
        filters
    }
}
