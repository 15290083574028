import React, { useContext, useEffect, useState } from 'react';
import { message } from 'antd';
import filterContext from '../../../Hooks/filterContex';
import billsAPI from '../../../Api/api-bills';
import { BillGet, BillStatus, getBillsStatus } from '../../../Models/Bill';
import moment from 'moment';
import customerAPI from '../../../Api/api-customers';
import { Customer } from '../../../Models/Customer';

export const useNewsCourier = () => {

    const filters = useContext(filterContext);
    const [newsCouriers, setNewsCouriers] = useState<BillStatus[]>([]);
    const [newsCouriersLoading, setNewsCouriersLoading] = useState(false);
    const getNewsCourier = async() => {
        setNewsCouriersLoading(true);
        try {
            var from: any = "";
            var to: any = "";
            from = moment(new Date()).add(-60, "d");
            to = moment(new Date()).add(1, "d");
            let payload : getBillsStatus = {
                warehouseId: filters.warehouseId,
                from: moment(new Date()).add(-60, "d").format('YYYY-MM-DD'),
                to: moment(new Date()).add(1, "d").format('YYYY-MM-DD'),
                status: "Novedad Mensajero"
            }
            let bills = await billsAPI.GetBillByDepartmentandStatus(payload);
            console.log(bills)
            setNewsCouriers(bills.reverse());
        } catch (error) {
            message.error(`No se obtuvieron las novedades de mensajero. ${error}`)
        }
        setNewsCouriersLoading(false);
    }

    const [customers, setCustomers] = useState<Customer[]>([])
    const getCustomers = async() => {
        try {
            const customers = await customerAPI.getCustomers();
            setCustomers(customers);
        } catch (error) {
            message.error(`No se obtuvieron los clientes. ${error}`)
        }
    }

    useEffect(() => {
        let mounted = true;
        if ( mounted ) {
            getNewsCourier();
            getCustomers();
        }
        return () => {
            mounted = false;
        }
    }, [filters.warehouseId])

    return {
        newsCouriers,
        newsCouriersLoading,
        customers,
        getNewsCourier
    }
}
