import React, { useState } from "react"
import usersAPI from "../Api/api-users";
import utils from "../utils";

export const useLoginForm = () => {

  // Hooks
  const [values, setValues] = useState({
    email: '',
    password: '',
  });

  const [validated, setValidated] = useState<boolean>(false);
  const [credentials, setCredentials] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);


  // Events   
  const handleChange = (e: any) => {

    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value
    })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    // debugger
    e.preventDefault();

    setLoading(true);

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setLoading(false);
      return setValidated(true);
    }


    setValidated(false);


    usersAPI.login(values).then((res:any)=>{
      let userData = utils.parseJwt(res);
      localStorage.userName = userData.nombre;
      localStorage.email = userData.email;
      localStorage.customerId = userData.Customer;
      localStorage.userID = userData.nameid;
      localStorage.role = userData["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
      localStorage.token = res;
      utils.crearCookie("token", res, userData.exp);
      window.location.href="/"

      setCredentials(true);
      setLoading(false);
    })
    .catch(err=>{
      console.log(err);
      setCredentials(false);
      setLoading(false);
    })
  }

  return { handleChange, handleSubmit, values, validated, credentials, loading }
}

export default useLoginForm;
