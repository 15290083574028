import React, { useRef } from 'react';

// DevExtreme
import DataGrid, {
    Column,
    Grouping,
    Pager,
    Paging,
    Sorting,
    ColumnFixing,
    Lookup,
} from 'devextreme-react/data-grid';
import { Modal, Spin } from 'antd';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';


type ModalPdfProps = {
    bills: any,
    modalPdfVisible: any,
    setModalPdfVisible: any,
    billsLoading: any,
    messager: any,
    customers : any
}

export const ModalPdf = ({ bills, modalPdfVisible, setModalPdfVisible, billsLoading, messager, customers }: ModalPdfProps) => {

    const componentRef = useRef<any>();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    const imprimirRuta = () => {
        printDiv('element-to-print');
      };
    
      function printDiv(elemento:any) {
    
        var divElements = document.getElementById(elemento)?.innerHTML;
        //Get the HTML of whole page
        var oldPage = document.body.innerHTML;
        //Reset the page's HTML with div's HTML only
        document.body.innerHTML ='' +
        '<style type="text/css">' +
        'table {' +
          'border-spacing: 0;' +
          'border-collapse: collapse;' +
          '}'+
        'table tr {' +
        'border-top: 1px solid gray !important;' +
        '}' +
        '</style>'+
          "<html><head><title></title></head><body>" +
          divElements +
          "</body>";
        //Print Page
        window.print();
        //Restore orignal HTML
        document.body.innerHTML = oldPage;
      }
    
      window.onafterprint = function () {
        window.location.reload();
      }

    const detailCell = ({ data }: any) => {
        return (
            <ul>
                <li><b>Nombre: </b>{`${data.name}`}</li>
                <li><b>Cel: </b>{`${data.phone}`}</li>
                <li><b>Dir: </b>{`${data.address}`}</li>
                <li><b>Ciu: </b>{`${data.city}`}</li>
            </ul>
        )
    }

    const day = new Date();

    return (
        <Modal
            title="Imprimir Rutas"
            visible={modalPdfVisible}
            onOk={imprimirRuta}
            onCancel={() => setModalPdfVisible(false)}
            width={1100}
            okText="Imprimir"
            cancelText="Cancelar"
        >
            <div id="element-to-print">
          <table width="100%">
            <tr>
              <td></td>
              <td>Guia</td>
              <td>Cliente</td>
              <td>Descripción del servicios</td>
              <td>Detalle</td>
              <td>observaciones Entrega</td>
              <td>Valor a cobrar</td>
            </tr>
            {bills.map((x:any, i:any) =>
              <>
                <tr>
                  <td>{x.delivery_Order}</td>
                  <td style={{ fontSize: 9 }}>{x.guia}</td>
                  <td>{x.customer}</td>
                  <td>{x.service_Description}</td>
                  <td>{x.name}-{x.contacto_destino}-{x.city}-{x.address}-TEL:{x.phone}</td>
                  <td>{x.aditionalInformation}</td>
                  <td>{x.collect_Value}</td>
                </tr>
                {i == 8 || i == 20 ?
                  <div className="html2pdf__page-break" style={{ marginTop: 20 }}></div>
                  : null}
              </>
            )}

          </table>
        </div>
            {/* <Spin tip="Cargando..." spinning={billsLoading}>
                <div id="data-grid-demo" ref={componentRef}>
                    <h4 className="mb-3"> Mensajero: {messager}</h4>
                    <h5>Fecha: { moment(day).format('DD-MM-YYYY, h:mm:ss a')}</h5>
                    <DataGrid
                        id="gridContainer"
                        dataSource={bills}
                        wordWrapEnabled={true}
                        showBorders={true}
                    >
                        <Sorting mode="multiple" />
                        <ColumnFixing enabled={true} />
                        <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                        <Grouping autoExpandAll={false} />

                        <Column dataField="delivery_Order" dataType="number" caption="Orden" alignment="center" />
                        <Column dataField="guia" dataType="string" caption="Guia" />
                        <Column dataField="customerId" dataType="number" caption="Cliente" allowEditing={false}>
                            <Lookup dataSource={customers} displayExpr="name" valueExpr="id" />
                        </Column>
                        <Column dataField="service_Description" dataType="string" caption="Descripción" />
                        <Column cellRender={detailCell} caption="Detalle" />
                        <Column dataField="aditionalInformation" dataType="string" caption="Información Adicional" />
                        <Column dataField="collect_Value" caption="Valor a cobrar" dataType="number" format="currency" />
                        <Column dataField="aditional_Value" caption="Valor adicional" dataType="number" format="currency" />

                        <Pager allowedPageSizes={[5, 10, 50, 100]} showPageSizeSelector={true} />
                        <Paging defaultPageSize={5} />
                    </DataGrid>
                </div>
            </Spin> */}
        </Modal>
    )
}


