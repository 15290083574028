import React, { useContext } from 'react';

// Ant Desing
import { Col, Row, Tabs, Alert, Badge} from 'antd';
import { ScheduledServices } from './ScheduledServices';
import { DeferredServices } from './DeferredServices';
import { CourierServices } from './CourierServices';
import PendingAnulations  from './PendingAnulations';
import { useAdminService } from './Hooks/useAdminService';
import { useCustomer } from '../../Hooks/useCustomer';
import { useMessager } from '../../Hooks/useMessager';
import { useStatus } from '../../Hooks/useStatus';
import filterContext from '../../Hooks/filterContex';
import { useHistoryServices } from '../../Hooks/useHistoryServices';
import PendingServices from '../PendingServices/PendingServices';


// Ant Desing Controlls
const { TabPane } = Tabs;

export const TabServices = () => {

    const { statusChange, services, servicesLoading, statusSelected, getServices, selectedRows, selectedRowKeys, setSelectedRowKeys, handleUpdate } = useAdminService();
    // const { handleUpdate } = useHistoryServices();
    const { statuses } = useStatus();
    const { customers } = useCustomer();
    const { messagersByWarehouse } = useMessager();
    const filters = useContext(filterContext);

    return (
        <>
            <h3 className="title mt-2">Servicios Programados</h3>
            <Row>
                <Col span={24}>
                    <Tabs defaultActiveKey="1" onChange={statusChange}>
                        <TabPane tab="Programados" key="Programado">
                            {services.length !== 0 ?
                                (
                                    <ScheduledServices
                                        services={services}
                                        servicesLoading={servicesLoading}
                                        customers={customers}
                                        messagers={messagersByWarehouse}
                                        statuses={statuses}
                                        statusSelected={statusSelected}
                                        getServices={getServices}
                                        selectedRows={selectedRows}
                                        selectedRowKeys={selectedRowKeys}
                                        setSelectedRowKeys={setSelectedRowKeys}
                                        handleUpdate={handleUpdate}
                                    />
                                )
                                :
                                (
                                    <Alert
                                        message="No hay servicios programados"
                                        description="Seleccione una bodega"
                                        type="warning"
                                        showIcon
                                    />
                                )
                            }
                        </TabPane>
                        <TabPane tab="Programados Aplazados" key="Programado Aplazado">
                            {services.length !== 0 ?
                                (
                                    <DeferredServices
                                        services={services}
                                        servicesLoading={servicesLoading}
                                        customers={customers}
                                        messagers={messagersByWarehouse}
                                        statuses={statuses}
                                        statusSelected={statusSelected}
                                        getServices={getServices}
                                        selectedRows={selectedRows}
                                        selectedRowKeys={selectedRowKeys}
                                        setSelectedRowKeys={setSelectedRowKeys}
                                        handleUpdate={handleUpdate}
                                    />
                                )
                                :
                                (
                                    <Alert
                                        message="No hay servicios programados"
                                        description="Seleccione una bodega"
                                        type="warning"
                                        showIcon
                                    />
                                )
                            }

                        </TabPane>
                        
                        <TabPane tab="Novedades Mensajero" key="Novedad Mensajero">
                            {services.length !== 0 ?
                                (
                                    <CourierServices
                                        services={services}
                                        servicesLoading={servicesLoading}
                                        customers={customers}
                                        messagers={messagersByWarehouse}
                                        statuses={statuses}
                                        statusSelected={statusSelected}
                                        getServices={getServices}
                                        selectedRows={selectedRows}
                                        selectedRowKeys={selectedRowKeys}
                                        setSelectedRowKeys={setSelectedRowKeys}
                                        handleUpdate={handleUpdate}
                                    />
                                )
                                :
                                (
                                    <Alert
                                        message="No hay servicios programados"
                                        description="Seleccione una bodega"
                                        type="warning"
                                        showIcon
                                    />
                                )
                            }

                        </TabPane>

                        <TabPane tab={<Badge count={services.filter(x => x.statusId === 11).length}>Solicitudes de Anulación</Badge>} key="Solicitud Anulación">
                        {/* <TabPane tab="Solicitudes de Anulación" key="Solicitud Anulación"> */}
                            {services.length !== 0 ?
                                (
                                    <PendingAnulations
                                        services={services}
                                        servicesLoading={servicesLoading}
                                        customers={customers}
                                        messagers={messagersByWarehouse}
                                        statuses={statuses}
                                        statusSelected={statusSelected}
                                        getServices={getServices}
                                        selectedRows={selectedRows}
                                        selectedRowKeys={selectedRowKeys}
                                        setSelectedRowKeys={setSelectedRowKeys}
                                        handleUpdate={handleUpdate}
                                    />
                                )
                                :
                                (
                                    <Alert
                                        message="No hay servicios programados"
                                        description="Seleccione una bodega"
                                        type="warning"
                                        showIcon
                                    />
                                )
                            }

                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </>
    )
}
