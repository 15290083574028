import { message } from "antd";
import moment from "moment";
import { useContext, useRef, useState } from "react";
import billsAPI from "../Api/api-bills";
import citiesAPI from "../Api/api-cities";
import WarehouseAPI from "../Api/api-warehouse";
import { BillGet, getBillsById, getBillsRequest } from "../Models/Bill";
import { cityModel } from "../Models/City";
import filterContext from "./filterContex";

export const usePendingServices = () => {

    const filters = useContext(filterContext);
    const [pendingServices, setPendingServices] = useState<BillGet[]>([]);
    const [pendingServicesFiltered, setPendingServicesFiltered] = useState<BillGet[]>([]);
    const [CitiesList, setCitiesList] = useState<cityModel[]>([]);
    const [selectedData, setSelectedData] = useState<BillGet>({
        id: 0,
        name: '',
        address: '',
        phone: '',
        document: '',
        email: '',
        company: '',
        consigned_Value: 0,
        typeConsignedValue: 'Con Recaudo',
        created_at: '',
        updated_by: 0,
        confirmed: true,
        observations: '',
        statusId: 0,
        status: '',
        sellerId: 0,
        seller: '',
        cityId: 0,
        city: '',
        provinceId: 0,
        province: '',
        company_TransportId: 0,
        company_Transport: '',
        customerId: 0,
        customer: '',
        collect_Value: 0,
        number_account: '',
        guia: '',
        packing: '',
        packingQuantity: 0,
        bill_Products: [],
        billNews: [],
        totalPendingBillNews: 0,
        totalSolvedBillNews: 0,
        products: "",
        delivered_at: "",
        warehouseId: 0,
        bill_News:"",
        createdBy:"",
        plate:"",
        zone:"",
        service_Description:"",
        delivery_Order:0
    });
    const [loading, setLoading] = useState<boolean>(false)
    const [Province, setProvince] = useState<string>("");
    const [selectedBillid, setSelectedBillid] = useState<number>(0);
    const [newsShow, setNewsShow] = useState(false);
    const [packOffShow, setPackOffShow] = useState(false);
    const [filteredSelected, setFilteredSelected] = useState<number>(0);
    const [search, setSearch] = useState({
        type: 0,
        input: ''
    });
    const [select, setselect] = useState<number>(0)
    const formRef = useRef<any>();



    const getPendingServices = () => {
        getWarehouse();
        setLoading(true);
        var from: any = "";
        var to: any = "";
        if (filters.date) {
            from = moment(filters.date[0]);
            to = moment(filters.date[1]);
        }
        else {
            from = moment(new Date()).add(-60, "d");
            to = moment(new Date()).add(1, "d");
        }

        let el: getBillsRequest = {
            customerId: filters.customerId, 
            warehouseId: filters.warehouseId,
            from: from,
            to: to,
            isClosed: false
        }

        billsAPI.GetBillsByDepartmentandDate(el).then(res => {
            setPendingServices(res);
            setLoading(false);
        });
    }

    const getWarehouse = () => {
        WarehouseAPI.getWarehouse().then(res => {
            let province = res.filter(x => x.id == filters.warehouseId);
            if (province.length > 0) {
                setProvince(province[0].name);
            }
        })
    }

    const GetBillById = (billId: number, modal: string) => {
        let el: getBillsById = {
            id: billId
        }
        billsAPI.getBillsById(el).then(res => {
            setSelectedData(res);
            if (modal == 'News') {
                setNewsShow(true)
            }
            if (modal == 'Dispatch') {
                setPackOffShow(true)
            }

        })
    }

    const getCities = () => {
        citiesAPI.getAllCities().then(res => {
            console.log('cities', res)
            setCitiesList(res);
        })
    }


    // Filtro de la columna detalle
    const handleSearch = (values : any) => {

        const { type, input } = values;


        switch (type) {
            case 1:
                
                if (input === '' || input === undefined) {
                    
                    message.warn('Digite un nombre a buscar.')
                    
                } else {
                    setLoading(true);
                    const arrayNames = pendingServices.filter(x => x.name.toLocaleLowerCase().includes(input.toLocaleLowerCase()));
                    setPendingServices(arrayNames);
                }
                break;
            case 2:
                if (input === '' || input === undefined) {
                    message.warn('Digite un teléfono a buscar.')
                } else {
                    setLoading(true);
                    const arrayPhones = pendingServices.filter(x => x.phone.includes(input));
                    setPendingServices(arrayPhones);
                }
                break;
            default:
                message.warn('Selecciona un filtro a buscar.');
                break;
        }

        setLoading(false);

    }


    const resetFilter = () => {
        formRef.current.resetFields();
        setSearch({ 
            type: 0,
            input: ''
        });
        setselect(0);

        getPendingServices();
    }


    return {
        pendingServices,
        getPendingServices,
        loading,
        Province,
        filters,
        selectedBillid,
        setSelectedBillid,
        selectedData,
        setSelectedData,
        GetBillById,
        setNewsShow,
        setPackOffShow,
        packOffShow,
        newsShow,
        CitiesList,
        getCities,
        filteredSelected,
        setFilteredSelected,
        handleSearch,
        search,
        setSearch,
        pendingServicesFiltered,
        resetFilter,
        formRef,
        select, 
        setselect
    }
}