import React, { useState } from 'react';

// DevExtreme
import DataGrid, {
    Column,
    FilterRow,
    Grouping,
    Selection,
    GroupPanel,
    Pager,
    Paging,
    SearchPanel,
    Export,
    HeaderFilter,
    Sorting,
    ColumnFixing,
    Editing,
    Lookup
} from 'devextreme-react/data-grid';
import { BillStatus } from '../../Models/Bill';
import { message, Spin, Badge, Row, Col, Button } from 'antd';
import { Customer } from '../../Models/Customer';
import { Statuses } from '../../Models/Status';
import { EditOutlined, EnvironmentOutlined, PrinterOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import { ModalGuia } from './ModalGuia';
import { DrawerEdit } from './DrawerEdit';
import { DrawerNews } from './DrawerNews';
import { DrawerRouting } from './DrawerRouting';
import { ModalGuias } from './ModalGuias';


type DeferredServicesProps = {
    services: BillStatus[],
    servicesLoading: boolean,
    customers: Customer[]
    messagers: any,
    statuses: Statuses[],
    statusSelected: string,
    getServices: Function,
    selectedRows: any
    selectedRowKeys: any,
    setSelectedRowKeys: any
    handleUpdate: any
}

export const DeferredServices = ({ services, servicesLoading, customers, messagers, statuses, statusSelected, getServices, selectedRows, selectedRowKeys, setSelectedRowKeys, handleUpdate }: DeferredServicesProps) => {
    // Modal Guia
    const [modalGuiaVisible, setModalGuiaVisible] = useState(false);
    const [guiaSelected, setGuiaSelected] = useState<BillStatus>({});

    const showModalGuia = (data: BillStatus) => {
        setGuiaSelected(data);
        setModalGuiaVisible(true);
    }

    // Drawer News
    const [visibleDrawerNews, setVisibleDrawerNews] = useState(false);
    const showDrawerNews = async (data: BillStatus) => {
        setBillSelected(data);
        setVisibleDrawerNews(true);
    }

    // Drawer Edit
    const [drawerEditVisible, setDrawerEditVisible] = useState(false);
    const [billSelected, setBillSelected] = useState<any>({});
    const showModalEdit = (data: any) => {
        setBillSelected(data);
        setDrawerEditVisible(true);
    }

    // Drawer Routing
    const [drawerRoutingVisible, setDrawerRoutingVisible] = useState(false);
    const routingService = () => {
        if (selectedRowKeys.length !== 0) {
            setDrawerRoutingVisible(true);
        } else {
            message.warn('Seleccione servicios para enrutar');
        }
    }

    // Modal Guias
    const [modalGuiasVisible, setModalGuiasVisible] = useState(false);

    const showModalGuias = () => {
        if (selectedRowKeys.length !== 0) {
            setModalGuiasVisible(true);
        } else {
            message.warn('Seleccione servicios para imprimir.');
        }

    }

    // Custom Cells
    const guiaCell = ({ data }: any) => {
        if (data.observations !== '') {
            return (
                <Badge color="green">
                    <a onClick={() => showModalGuia(data)}>{data.guia}</a>
                </Badge>
            )
        } else {
            return (
                <a onClick={() => showModalGuia(data)}>{data.guia}</a>
            )
        }
    }

    const detailCell = ({ data }: any) => {
        return (
            <ul>
                <li><b>Nombre: </b>{`${data.name}`}</li>
                <li><b>Cel: </b>{`${data.phone}`}</li>
                <li><b>Dir: </b>{`${data.address}`}</li>
                <li><b>Ciu: </b>{`${data.city}`}</li>
            </ul>
        )
    }

    const newsCell = ({ data }: any) => {
        return (
            <SearchOutlined onClick={() => showDrawerNews(data)} />
        )
    }

    const modalCell = ({ data }: any) => {
        return <EditOutlined onClick={(e) => showModalEdit(data)} />
    }

    return (
        <div>
            <Row gutter={[16, 8]} className="mb-2">
                <Col span={8}>
                    <Button
                        type="primary"
                        title="Enrutar Servicios Seleccionados"
                        icon={<UserOutlined />}
                        onClick={routingService}
                    >
                        Enrutar Servicios Seleccionados
                    </Button>
                </Col>
                <Col span={8}>
                    <Button
                        type="primary"
                        title="Ver Mapa"
                        icon={<EnvironmentOutlined />}
                        onClick={()=>window.location.href='/AdminCenter'}

                    >
                        Ver Mapa
                    </Button>
                </Col>
                <Col span={8}>
                    <Button
                        type="primary"
                        title="Imprimir"
                        icon={<PrinterOutlined />}
                        onClick={showModalGuias}
                    >
                        Imprimir
                    </Button>
                </Col>
            </Row>
            <Spin tip="Cargando..." spinning={servicesLoading}>
                <div id="data-grid-demo">
                    <DataGrid
                        id="gridContainer"
                        dataSource={services}
                        onRowUpdated={handleUpdate}
                        onSelectionChanged={selectedRows}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        showBorders={true}
                        rowAlternationEnabled={true}
                    >
                        <SearchPanel visible={true} highlightCaseSensitive={true} />
                        <Grouping autoExpandAll={true} />
                        <FilterRow visible={true} />
                        <GroupPanel visible={true} />
                        <Grouping autoExpandAll={true} />
                        <Export enabled={true} allowExportSelectedData={false} />
                        <HeaderFilter visible={true} />
                        <Sorting mode="multiple" />
                        <ColumnFixing enabled={true} />
                        <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                        <Grouping autoExpandAll={false} />

                        <Selection
                            mode="multiple"
                            selectAllMode={'allPages'}
                            showCheckBoxesMode={'onClick'}
                        />
                        <Column caption="Guias" allowEditing={false} cellRender={guiaCell} />
                        <Column dataField="service_Description" dataType="string" caption="Descripción" allowEditing={false} />
                        <Column dataField="customerId" dataType="number" caption="Cliente" allowEditing={false}>
                            <Lookup dataSource={customers} displayExpr="name" valueExpr="id" />
                        </Column>
                        <Column cellRender={detailCell} caption="Detalle" />
                        <Column dataField="aditionalInformation" dataType="string" caption="Información Adicional" />
                        <Column dataField="service_Value" caption="Valor Servicio" dataType="number" format="currency" />
                        <Column dataField="zone" dataType="string" caption="Ruta / Mensajero" >
                            <Lookup dataSource={messagers} displayExpr="name" valueExpr="name" />
                        </Column>
                        <Column cellRender={newsCell} caption="Novedades" alignment="center" />
                        <Column cellRender={modalCell} caption="Editar" alignment="center" />

                        <Pager allowedPageSizes={[5, 10, 50, 100,200]} showPageSizeSelector={true} />
                        <Paging defaultPageSize={200} />
                        <Editing
                            mode="cell"
                            allowUpdating={true}
                        />
                    </DataGrid>
                </div>
            </Spin>

            <ModalGuia
                guiaSelected={guiaSelected}
                modalGuiaVisible={modalGuiaVisible}
                setModalGuiaVisible={setModalGuiaVisible}
            />

            <DrawerEdit
                drawerEditVisible={drawerEditVisible}
                setDrawerEditVisible={setDrawerEditVisible}
                billSelected={billSelected}
                messagers={messagers}
                statuses={statuses}
                setBillSelected={setBillSelected}
                statusSelected={statusSelected}
                getServices={getServices}
            />

            <DrawerNews
                visibleDrawerNews={visibleDrawerNews}
                setVisibleDrawerNews={setVisibleDrawerNews}
                billSelected={billSelected}
            />

            <DrawerRouting
                drawerRoutingVisible={drawerRoutingVisible}
                setDrawerRoutingVisible={setDrawerRoutingVisible}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                messagers={messagers}
                statusSelected={statusSelected}
                getServices={getServices}
            />

            <ModalGuias
                modalGuiasVisible={modalGuiasVisible}
                setModalGuiasVisible={setModalGuiasVisible}
                selectedRowsKeys={selectedRowKeys}
            />

        </div>
    )
}
