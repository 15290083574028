import { useContext, useRef, useState } from "react"
import { Product } from "../Models/Product"
import productsAPI from '../Api/api-products';
import customerAPI from "../Api/api-customers";
import { Customer } from "../Models/Customer";
import Swal from "sweetalert2";
import utils from "../utils";
import { customer_Movements } from "../Models/Customer_Movements";
import filterContext from "./filterContex";
import zIndex from "@material-ui/core/styles/zIndex";
import { message } from "antd";

export const useInventoryEntries = () => {

    const filters = useContext(filterContext);
    const ref = useRef<any>();
    const [values, setValues] = useState<customer_Movements>({
        id: 0,
        movement_type: 'Entrada',
        quantity: 0,
        productId: 0,
        customerId: filters.customerId,
        billId: 0,
        warehouseId:filters.warehouseId,
        observations:""
    });

    const [ProductsList, setProductsList] = useState<Product[]>([]);
    const [ProductsListFiltered, setProductsListFiltered] = useState<Product[]>([]);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [arrayProducts, setArrayProducts] = useState<customer_Movements[]>([]);

    const addProduct = (e: customer_Movements) => {
        setArrayProducts([ ...arrayProducts, e]);
    }

    const deleteProduct = (data : any) => {
        let newArray = arrayProducts.filter(x => x.productId !== data.productId);
        setArrayProducts(newArray)
    }
    

    const saveData = async() => {
        if(arrayProducts.length !== 0){
            let newArray = arrayProducts.map( x => {
                return {
                    ...x,
                    customerId: filters.customerId,
                    warehouseId: filters.warehouseId
                }
            })
            try {
                await productsAPI.postProductIn(newArray);
                message.success('Se crearon correctamente los pedidos');
            } catch (error) {
                message.error(`No se pudo crear las entradas. ${error}`);
            }
            setModalVisible(false);
            ref.current.resetFields();
            setArrayProducts([]);
        }
        // e.customerId = filters.customerId;
        // e.warehouseId = filters.warehouseId;
        // productsAPI.postProductIn(e).then(res => {
        //     console.log(filters)
        //     Swal.fire(
        //         'Completado',
        //         'Entrada creada correctamente.',
        //         'success'
        //     ).then((result) => {
                
        //     })
        //     setLoading(true);
        // })
        //     .catch(err => {
        //         console.log(err);
        //         utils.messageError();
        //         setLoading(false);
        //     });
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
      };

    const onSearchSelect = (value: any) => {
        if (value) {
          let array = ProductsList.filter((x: Product) => x.palomaName.toLowerCase().includes(value.toLowerCase()));
          setProductsListFiltered(array);
        } else {
            setProductsListFiltered(ProductsList);
        }
      };


    const getProductsList = () => {
        productsAPI.getProductsByCustomerId(filters.customerId).then(res => {
            setProductsList(res);
            setProductsListFiltered(res);
        });
    }

    return {
        values,
        getProductsList,
        ProductsListFiltered,
        saveData,
        loading,
        setModalVisible,
        modalVisible,
        onSearchSelect,
        onFinishFailed,
        filters,
        addProduct,
        arrayProducts,
        deleteProduct,
        ref,
        setArrayProducts
    }
}
