import React, { useState } from "react";
import billsAPI from "../Api/api-bills";

// Models
import { BillGet, BillHistory, BillResponseHistory, getBillsRequest, getBillsRequestPaging } from "../Models/Bill";
import moment from "moment";
import { useContext } from "react";
import filterContext from "./filterContex";
import WarehouseAPI from "../Api/api-warehouse";
import { Movement } from "../Models/Movement";
import Swal from "sweetalert2";
import utils from "../utils";
import ModalDetailService from "../Components/ModalDetailService/ModalDetailService";
import { message } from "antd";


export const useHistoryServices = () => {
    
    const filters = useContext(filterContext);

    const dateFormat = 'YYYY/MM/DD';
    const [filterDates, setFilterDates] = useState<any>([moment(new Date(), dateFormat), moment(new Date(), dateFormat)]);
    const [filterDatesMovement, setFilterDatesMovement] = useState<any>([moment(new Date(), dateFormat), moment(new Date(), dateFormat)]);
  

    const [historyServices, setHistoryServices] = useState<BillGet[]>([]);
    const [novedades, setNovedades] = useState<BillGet[]>([]);
    const [dailyResume, setDailyResume] = useState<BillGet[]>([]);
    const [loading, setLoading] = useState<boolean>(false)
    const [Province, setProvince] = useState<string>("");
    const [selectedBillid, setSelectedBillid] = useState<number>(0);
    const [detailServiceId, setDetailServiceId] = useState<number>(0);
    const [movementList, setMovementList] = useState<Movement[]>([]);
    const [statusList, setStatusList] = useState<any>([]);
    const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [PageSize, setPageSize] = useState(800);
    const [totalpages, setTotalpages] = useState<number>(0);
    const [StatusFilter, setStatusFilter] = useState<number[]>([1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11]);

    const handleChangePage = (page:number) => {
        setPageNumber(page);
        getHistoryServices(page);
    }

    const getHistoryServices = (page:number) => {
        setLoading(true);
        var from:any = "";
        var to:any = "";
        if(filters.date)
        {
            from = moment(filters.date[0]);
            to = moment(filters.date[1]);
        }
        else{
            from = moment(new Date()).add(-7,"d");
            to = moment(new Date()).add(1,"d");
        }
        let el:getBillsRequestPaging = {
            customerId:filters.customerId,
            warehouseId:filters.warehouseId,
            isClosed: false,
            pageNumber: page,
            pageSize: PageSize,
            from: from,
            to:to,
            stateId:StatusFilter
        }
            billsAPI.GetBillHistory(el).then(res=>{
            //setTotalpages(res.totalPages);
            //setPageSize(res.pageSize);
            debugger
            setHistoryServices(res);
            setLoading(false);
        });
    }

    const getNovedades = () => {
        setLoading(true);
        var from:any = moment(new Date()).add(-60,"d");
        var to:any =  moment(new Date()).add(1,"d");
        let el:getBillsRequestPaging = {
            customerId:filters.customerId,
            warehouseId:filters.warehouseId,
            isClosed: false,
            pageNumber: 1,
            pageSize: PageSize,
            from: from,
            to:to,
            stateId:[1,4,8]
        }
            billsAPI.GetBillHistory(el).then(res=>{
            //setTotalpages(res.totalPages);
            //setPageSize(res.pageSize);
            setNovedades(res);
            setLoading(false);
        });
    }

    const getDailyResume = (fecha : any) => {
        setLoading(true);
        getWarehouse();
        let objFilter:any = {
            customerId: filters.customerId,
            warehouseId: filters.warehouseId,
            from: fecha[0],
            to : fecha[1],
        }

        billsAPI.GetDailyResume(objFilter).then(res=>{
            setDailyResume(res.reverse());
            setLoading(false);
        });

    }

    const getStatuses = async() => {
        try {
            const statuses = await billsAPI.getStatusList();
            setStatusList(statuses)
        } catch (error) {
            message.error(`No se obtuvieron los estados. ${error}`);
        }
    }

    const getMovement = (fecha : any) => {
        // debugger
        setLoading(true);
        // var from:any = "";
        // var to:any = "";
        // if(filters.date)
        // {
        //     from = moment(filters.date[0]);
        //     to = moment(filters.date[1]);
        // }
        // else{
        //     from = moment(new Date()).add(-60,"d");
        //     to = moment(new Date()).add(1,"d");
        // }
        
        let el:getBillsRequest = {
            customerId:filters.customerId,
            warehouseId:filters.warehouseId,
            from: fecha[0],
            to : fecha[1],
        }

        // console.log(el)
        billsAPI.GetMovement(el).then(res=>{
            setLoading(false);
            setMovementList(res);
            // console.log(res)
        })
    }

    const getWarehouse = () => {
        WarehouseAPI.getWarehouse().then(res=>{
            let province =  res.filter(x=>x.id == filters.warehouseId);
            if(province.length > 0)
            {
                setProvince(province[0].name);
            }
        })
    }

    const saveEditing = (row:any) => {
        setLoading(true);
        billsAPI.UpdateBills(row.data).then(resp => { 
            Swal.fire(
              'Completado',
              'Pedido actualizado correctamente.',
              'success',
            )
            setLoading(false)
          })
          .catch(err => {
            console.log(err);
            utils.messageError();
            setLoading(false);
          });
    }

    const DetailService = (idService:number) => {
        setDetailServiceId(idService);
        setShowDetailModal(true);
    }

    const handleUpdate = async({ data } : any) => {
        setLoading(true);
        try {
            if(data.statusId == 1){
                data.zone = null;
            }
            await billsAPI.UpdateBillHeader(data);
            message.success(`Se actualizó correctamente el servicio.`);
            getHistoryServices(pageNumber);
        } catch (error) {
            message.error(`No se pudo actualizar el servicio. ${error}`);
        }
        setLoading(false);
    }

    return {
        historyServices,
        getHistoryServices,
        loading,
        Province,
        filters,
        selectedBillid,
        setSelectedBillid,
        getMovement,
        movementList,
        statusList,
        saveEditing,
        DetailService,
        detailServiceId,
        dailyResume,
        filterDates,
        getDailyResume,
        setFilterDates,
        filterDatesMovement, 
        setFilterDatesMovement,
        getStatuses, 
        handleUpdate,
        showDetailModal, 
        setShowDetailModal,
        PageSize,
        totalpages,
        pageNumber,
        handleChangePage,
        novedades,
        getNovedades
    }
    
}