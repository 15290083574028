import React, { useEffect, useState, memo } from "react";
import { IOfficesComponentProps } from "./IOfficesComponentProps";
import "./OfficesComponent.css";

import MaterialTable from "material-table";
import moment from 'moment';

// Router Dom
import { Link } from "react-router-dom";


// Components
import HeaderComponent from "../HeaderComponent/HeaderComponent";
import FooterComponent from "../FooterComponent/FooterComponent";
import FormModalComponent from "../SheduledComponent/components/FormModalComponent/FormModalComponent";
//import NewsDispatchedModalComponent from "./components/NewsDispatchedModalComponent/NewsDispatchedModalComponent";
import NewsModalComponent from "../SheduledComponent/components/NewsModalComponent/NewsModalComponent";


// Icons Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBinoculars, faEye, faLongArrowAltLeft, faPenNib } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

// React Bootstrap
import {  Col, Row, Form, Modal, Tabs, Tab } from "react-bootstrap";


// Models
import { BillGet } from "../../Models/Bill";
import { useSheduledComponent } from "../../Hooks/useSheduledComponent";




const OfficesComponent: React.SFC<IOfficesComponentProps> = memo((props) => {

 

  
  const [scheduledShow, setScheduledShow] = React.useState(false);
  const [newsDispatchModal, setNewsDispatchModal] = React.useState(false);
  const [newsShow, setNewsShow] = React.useState(false);
  const [adminAccess, setAdminAccess] = React.useState();

  

  

  const handleClose3 = () => {
    setScheduledShow(false);
    setToDisable(false);
  };

  const handleClose = () => {
    setNewsDispatchModal(false)
  };

  const handleClose2 = () =>{
    setNewsShow(false);
    setValidated(false);
  };



  const [selectedData, setSelectedData] = React.useState<any>({
    id                   : 0,
    name                 : '',
    address              : '',
    phone                : '',
    document             : '',
    email                : '',
    company              : '',
    consigned_Value      : 0,
    typeConsigned_value  : 'Con Recaudo',
    created_at           : '',
    updated_by           : 0,
    confirmed            : true,
    observations         : '',
    statusId             : 0,
    status               : '',
    sellerId             : 0,
    seller               : '',
    cityId               : 0,
    city                 : '',
    provinceId           : 0,
    province             : '',
    company_TransportId  : 0,
    company_Transport    : '',
    customerId           : 0,  
    customer             : '',  
    collect_Value        : 0,  
    number_account       : '',  
    guia                 : '',
    packing              : '',
    packingQuantity      : 0,
    bill_Products        : [],
    billNews             : [],
    totalPendingBillNews : 0,
    TotalSolvedBillNews : 0,
    products:"",
    delivered_at:""
  });

  const seeScheduled = (data: any) => {
    setSelectedData(data);
    setScheduledShow(true);
  }

  const seeNewsModalDispatch = (data: any) => {
    setSelectedData(data);
    setNewsDispatchModal(true);
  }

  const seeNews = (data : any) => {
    setSelectedData(data);
    setNewsShow(true);
  }
  
  const {
    billDispatch,
    getBill,
    billNews,
    getSellersList,
    sellersList,
    getCities,
    CitiesList,
    getProductsList,
    ProductsList,
    quantity,
    setQuantity,
    handleInputChange,
    handleSubmit,
    validated,
    setSelectedProduct,
    pushProduct,
    toDisable,
    setToDisable,
    loading,
    customers,
    getCustomers,
    deleteProduct,
    setValidated,
    key,
    setKey,
    news,
    handleNews,
    // newsSolved
  } = useSheduledComponent(selectedData, setSelectedData, handleClose3);

  const [columns, setColumns] = useState([
    {title: 'ID', field: 'id'},
    {title: 'Guia', field: 'guia'},
    {title: 'Transportadora', field: 'company_Transport'},
    { title: 'Datos Cliente', field: 'id', render: (rowData : BillGet) =>   
      `${rowData.name} - ${rowData.address} - ${rowData.phone}`
    },
    { title: "Productos", field: "", render: (rowData : BillGet) =>  
    {
      let prods = rowData.bill_Products.map(x=>{return  ` ${x.quantity}x ${x.palomaName}`});
      return `${prods.join()}`
    }
    },
    { title: 'Fecha', field: 'id', render: (rowData : BillGet) =>
       
      `${ moment(rowData.created_at).format('YYYY-MM-DD HH:mm')}`
    },
    { title: 'Detalle', field: 'id', render: (rowData : BillGet) =>   
      <FontAwesomeIcon className="cursor" onClick = { () => seeScheduled(rowData)} icon={faBinoculars} />
    },
    
    {  title: 'Crear Novedad', field: 'id',  render: (rowData : any) =>   
      localStorage.role == '1' ? <FontAwesomeIcon className="cursor" onClick = { () => seeNews(rowData)} icon={faPenNib} /> : '-'
      // <FontAwesomeIcon className="cursor" onClick = { () => seeNews(rowData)} icon={faPenNib} />
    },
    { title: 'Novedades', field: 'id', render: (rowData : BillGet) =>
      rowData.billNews.length > 0 ? <FontAwesomeIcon className="cursor" onClick = { () => seeNewsModalDispatch(rowData)} icon={faEye} /> :  '-'
    },

    { title: 'Whatsapp', field: 'id', render: (rowData : any) =>   
      <FontAwesomeIcon className="cursor"  icon={faWhatsapp} />
    },

  ]);

  useEffect(() => {
    if (localStorage.role !== "1") {
      getBill(localStorage.customerId);
    } 
    getCustomers();
    getSellersList(localStorage.customerId);
    getCities();
    getProductsList(localStorage.customerId);
  }, [])

  return (
    <React.Fragment>
     {/*  <HeaderComponent />

      <Link to="/ManagementComponent" className="btn btn-outline-danger m-5">
        Regresar
        <FontAwesomeIcon className="ml-2" icon={faLongArrowAltLeft} />
      </Link>

      <div className="m-5">
        <Row>
          <Col>
            { localStorage.role == "1" &&
              <Form.Group as={Col} md="2">
                <Form.Label> Cliente </Form.Label>
                <Form.Control
                  as="select"
                  name="customerId"
                  onChange={(e : any) => getBill(e.target.value)}
                  required
                  custom
                >
                  <option>Seleccionar...</option>
                  {customers.map(customer => 
                    <option key={customer.id} value={customer.id}>{customer.name}</option>  
                  )}
                </Form.Control>
              </Form.Group>
            }
          </Col>
        </Row>
        <Row>
          <Col>
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(key : any) => setKey(key)}
            >
              <Tab eventKey="general" title="General">
                <MaterialTable
                  title="Resumen de despachos"
                  columns={columns}
                  data={billDispatch}
                  isLoading={loading}
                />
              </Tab>
              <Tab eventKey="novedades" title="Novedades">
                <MaterialTable
                  title="Resumen de despachos"
                  columns={columns}
                  data={billNews}
                  isLoading={loading}
                /> 
              </Tab>
              {/* <Tab eventKey="solucionadas" title="Novedades Solucionadas">
                <MaterialTable
                  title="Resumen de despachos"
                  columns={columns}
                  data={newsSolved}
                  isLoading={loading}
                /> 
              </Tab> 
            </Tabs>
          </Col>

          <Modal
            size="lg"
            centered
            show={scheduledShow} 
            onHide={handleClose3}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {localStorage.role == '1' ? 'Editar Pedido' : 'Detalle'}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormModalComponent
                deleteProduct={ deleteProduct }
                selectedData={ selectedData }
                validated={ validated }
                handleInputChange={ handleInputChange }
                handleSubmit={ handleSubmit } 
                setSelectedProduct={ setSelectedProduct }
                handleClose3={ handleClose3 }
                sellersList={ sellersList }
                CitiesList={ CitiesList }
                ProductsList={ ProductsList }
                setQuantity={ setQuantity }
                quantity={ quantity }
                pushProduct={ pushProduct }
                toDisable={ toDisable }
                setToDisable={ setToDisable }
                loading={ loading }
              />
            </Modal.Body>
          </Modal>

          <Modal show={newsShow} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Añadir Novedad</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            
            <NewsModalComponent 
              news={ news }
              handleClose={ handleClose } 
              selectedData={ selectedData}
              handleInputChange={ handleInputChange }
              loading={ loading }
              handleNews={ handleNews }
              validated={ validated }
            />

          </Modal.Body>
        </Modal>

          <Modal
            size="lg"
            centered
            show={newsDispatchModal} 
            onHide={handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Novedades Despachadas
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <NewsDispatchedModalComponent 
                handleClose={ handleClose }
                selectedData={selectedData}
              />
            </Modal.Body>
          </Modal>
        </Row>
      </div> */}

      {/* <FooterComponent /> */}
    </React.Fragment>
  );
});

export default OfficesComponent;
