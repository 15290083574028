import React, { useState } from 'react'
import { Button, Col, Drawer, Form, message, Row, Select } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useRef } from 'react';
import billsAPI from '../../Api/api-bills';

const { Option } = Select;
type DrawerRoutingProps = {
    drawerRoutingVisible: any,
    setDrawerRoutingVisible: any,
    selectedRowKeys: any,
    messagers: any,
    setSelectedRowKeys: any
    getSheduledInvoice: any
}
export const DrawerRouting = ({ drawerRoutingVisible, setDrawerRoutingVisible, selectedRowKeys, messagers, setSelectedRowKeys, getSheduledInvoice }: DrawerRoutingProps) => {

    const selectMessagerRef = useRef<any>();
    const [drawerRoutingLoading, setDrawerRoutingLoading] = useState(false);
    const [messager, setMessager] = useState('');

    const removeSelectedKey = (id: any) => {
        let newArr = selectedRowKeys.filter((x: any) => x.id !== id);
        if (newArr.length === 0) {
            message.warn('Seleccione servicios para enrutar.');
            setDrawerRoutingVisible(false)
            return;
        }
        setSelectedRowKeys(newArr);
    }

    const routingServices = async () => {
        if (messager !== '') {
            setDrawerRoutingLoading(true);
            try {
                selectedRowKeys.forEach(async (bill: any) => {
                    let newBill = bill;
                    newBill.zone = messager;
                    newBill.statusId = 8;
                    await billsAPI.UpdateBillHeader(newBill);
                    getSheduledInvoice();
                })
                message.success('Se enrutaron los servicios correctamente.');
            } catch (error) {
                message.error(`No se pudo enrutar masivamente. ${error}`);
            }
            setDrawerRoutingLoading(false);
            setDrawerRoutingVisible(false);
            selectMessagerRef.current.resetFields();
        } else {
            message.warn('Seleccione un mensajero');
        }
    }

    return (
        <Drawer
            title="Enrutado Masivo"
            placement="left"
            closable={true}
            onClose={() => setDrawerRoutingVisible(false)}
            visible={drawerRoutingVisible}
            width={450}
        >
            <Form layout="vertical" ref={selectMessagerRef}>
                <Form.Item name="messager">
                    <Select placeholder="Seleccione un mensajero" onChange={(e: any) => setMessager(e)}>
                        {messagers.map((m: any) =>
                            <Option key={m.id} value={m.name}>{m.name}</Option>
                        )}
                    </Select>
                </Form.Item>
            </Form>

            {selectedRowKeys.map(({ id, name, address, phone }: any) =>
                <>
                    <Row key={id} gutter={[48, 48]} className="mt-3">
                        <Col span={12}>
                            <p>NOMBRE: {name}</p>
                            <p>DIRECCIÓN: {address}</p>
                            <p>TEl: {phone}</p>
                        </Col>
                        <Col span={6}>
                            <DeleteOutlined style={{ color: 'red' }} onClick={() => removeSelectedKey(id)} />
                        </Col>
                    </Row>
                    <div style={{ border: '1px solid black ' }} className="mt-2"></div>
                </>
            )}

            <Button type="primary" loading={drawerRoutingLoading} className="mt-5" onClick={routingServices}>Enrutar Servicios</Button>
        </Drawer>
    )
}
