import * as React from 'react';

// React Router Dom
import { Link } from 'react-router-dom';

// React Bootstrap 
import { Button, Col, Modal, Row, Form } from 'react-bootstrap';

// Icons Font Awesome
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// React Material Table
import MaterialTable from 'material-table';


// Components
import FooterComponent from '../FooterComponent/FooterComponent';
import HeaderComponent from '../HeaderComponent/HeaderComponent';
import InventoryEntriesComponent from './components/TicketsComponent/InventoryEntriesComponent';
import AddProductsComponent from './components/AddProductsComponent/AddProductsComponent';
import DataGrid, {
  Column,
  FilterRow,
  Grouping,
  Selection,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  Export,
  HeaderFilter,
  Sorting,
  ColumnFixing
} from 'devextreme-react/data-grid';


// React Props 
import { IInventoryComponentProps } from './IInventoryComponentProps';
import './InventoryComponent.css';

import { useAddProduct } from '../../Hooks/useAddProduct';
import { Result } from 'antd';
import Modal_Add_Kit from '../2.0/Modal_Add_Kit/Modal_Add_Kit';




const InventoryComponent: React.SFC<IInventoryComponentProps> = (props) => {

  const [lgShow2, setLgShow2] = React.useState(false);
  const handleClose2 = () => setLgShow2(false);


  const { loading, inventories, getInventories, filters } = useAddProduct();


  const [columns /*setColumns*/] = React.useState([
    { title: 'Nombre Paloma', field: 'palomaName' },
    { title: 'Nombre Cliente', field: 'name' },
    { title: 'Código', field: 'code' },
    { title: 'Total Inventario', field: 'totalInventory' },
    { title: 'Total Programado', field: 'totalProgrammed' },
    { title: 'Total Enrutado', field: 'totalDispatched' },
    { title: 'Saldo', field: 'balance' },
  ]);



  React.useEffect(() => {
    getInventories();
  }, [filters.customerId, filters.warehouseId])

  return (
    <React.Fragment>
      {filters.customerId != 0 && filters.warehouseId != 0 ?
        <div className="m-5">
          {localStorage.role == "1" &&

            <Row>
              <Col>
                <InventoryEntriesComponent />

                <Button
                  variant="secondary"
                  className="float-right"
                  onClick={() => setLgShow2(true)}
                >
                  Agregar productos
            </Button>

                <Modal
                  size="lg"
                  show={lgShow2}
                  onHide={() => setLgShow2(false)}
                  aria-labelledby="example-modal-sizes-title-lg"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                      Crear Producto
                </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>

                    <AddProductsComponent handleClose={handleClose2} />
                  </Modal.Body>
                </Modal>
              </Col>
              <Col>
                <Modal_Add_Kit customerId={filters.customerId}/>
               </Col>
            </Row>
          }
          <Row className="mt-3">
            <Col>
{/*               <MaterialTable
                title='Inventario'
                columns={columns}
                data={inventories}
                isLoading={loading}
              /> */}
               <DataGrid
                  dataSource={inventories}
                  allowColumnReordering={true}
                  showBorders={true}
                  allowColumnResizing={true}
                  rowAlternationEnabled={true}
                >
                  <SearchPanel visible={true} highlightCaseSensitive={true} />
                  <Grouping autoExpandAll={true} />
                  <FilterRow visible={true} />
                  <GroupPanel visible={true} />
                  <Grouping autoExpandAll={true} />
                  <Export enabled={true} allowExportSelectedData={false} />
                  <HeaderFilter visible={true} />
                  <Sorting mode="multiple" />
                  <ColumnFixing enabled={true} />
                  <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                  <Grouping autoExpandAll={false} />
                  <Column dataField="name" dataType="string" caption="Nombre Cliente" alignment="center" />
                  <Column dataField="palomaName" dataType="string" caption="Nombre Paloma" alignment="center" />
                  <Column dataField="code" dataType="string" caption="Código" alignment="center" />
                  <Column dataField="totalInventory" dataType="number" caption="Total Inventario" alignment="center" />
                  <Column dataField="totalProgrammed" dataType="number" caption="Total Programado" alignment="center" />
                  <Column dataField="totalDispatched" dataType="number" caption="Total Despachado" alignment="center" />
                  <Column dataField="balance" dataType="number" caption="Saldo" alignment="center" />
                </DataGrid>
            </Col>
          </Row>
        </div>
        :
        <Result status="warning" title="Selecciona un cliente y una bodega" />
      }
    </React.Fragment>
  );
};

export default InventoryComponent;
