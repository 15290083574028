import React from 'react'

// Ant Desing
import { Col, Row, Form, Select, Input, InputNumber, Button } from 'antd';

// Hooks
import { useFormService } from './Hooks/useFormService';

// Styles
import './FormService.css';
import { CompanyTrasnport } from '../../Models/CompanyTransport';

// Ant Desing Controllers
const { Option } = Select;
const { TextArea } = Input;

type FormDataProps = {
    sellers : any,
    sellersLoading : any,
    handleInputChange : any,
    handleSelectChange : any,
    onFinishFailed : any,
    provinces : any,
    provinceLoading : any,
    cities : any,
    citiesLoading : any,
    companyLoading : any,
    companyTransports : any,
    visibleCities : any,
    handleSubmit : any,
    billLoading : any
}

export const FormData = ({ sellers, sellersLoading, handleInputChange, handleSelectChange, onFinishFailed, provinces, provinceLoading, cities, citiesLoading, companyLoading, companyTransports, visibleCities, handleSubmit, billLoading} : FormDataProps) => {
    return (
        <Form
            layout="vertical"
            initialValues={{
                collect_Value: 0
            }}
            onFinishFailed={onFinishFailed}
            onFinish={handleSubmit}
        >
            <Row gutter={[24, 24]}>
                <Col span={8}>
                    <Form.Item
                        label="Vendedor"
                    >
                        <Select
                            placeholder="Seleccionar"
                            loading={sellersLoading}
                            onChange={(value) => handleSelectChange(value, 'sellerId')}
                        >
                            {sellers.map(({ id, name } : any) =>
                                <Option key={id} value={id}>{name}</Option>
                            )}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Nombre Completo"
                        name="name"
                        rules={[{ required: true, message: 'Campo requerido' }]}
                    >
                        <Input
                            name="name"
                            onChange={handleInputChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Cedula"
                    >
                        <Input
                            name="document"
                            onChange={handleInputChange}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col span={8}>
                    <Form.Item
                        label="Dirección"
                        name="address"
                        rules={[{ required: true, message: 'Campo requerido' }]}
                    >
                        <Input
                            name="address"
                            onChange={handleInputChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Correo"
                    >
                        <Input
                            name="email"
                            onChange={handleInputChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Teléfono"
                        name="phone"
                        rules={[{ required: true, message: 'Campo requerido' }]}
                    >
                        <Input
                            name="phone"
                            onChange={handleInputChange}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col span={8}>
                    <Form.Item
                        label="Departamento"
                        name="provinceId"
                        rules={[{ required: true, message: 'Campo requerido' }]}
                    >
                        <Select
                            placeholder="Seleccionar"
                            onChange={(value) => handleSelectChange(value, 'provinceId')}
                            loading={provinceLoading}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option : any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {provinces.map(({ id, name } : any) =>
                                <Option key={id} value={id}>{name}</Option>
                            )}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Ciudad"
                        name="cityId"
                        rules={[{ required: true, message: 'Campo requerido' }]}
                    >
                        <Select
                            placeholder="Seleccionar"
                            onChange={(value) => handleSelectChange(value, 'cityId')}
                            loading={citiesLoading}
                            disabled={visibleCities}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option : any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {cities.map(({ id, name } : CompanyTrasnport) =>
                                <Option key={id} value={id}>{name}</Option>
                            )}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Transportadora"
                        name="company_TransportId"
                        rules={[{ required: true, message: 'Campo requerido' }]}
                    >
                        <Select
                            placeholder="Seleccionar"
                            onChange={(value) => handleSelectChange(value, 'company_TransportId')}
                            loading={companyLoading}
                        >
                            {companyTransports.map(({ id, name } : any) =>
                                <Option key={id} value={id}>{name}</Option>
                            )}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col span={12}>
                    <Form.Item
                        label="Recaudo"
                    >
                        <Select
                            placeholder="Seleccionar"
                            onChange={(value) => handleSelectChange(value, 'typeConsigned_value')}
                        >
                            <Option value={"Con Recaudo"} key={"Con Recaudo"}>Con Recaudo</Option>
                            <Option value={"Sin Recaudo"} key={"Sin Recaudo"}>Sin Recaudo</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Valor a recaudar"
                        name="collect_Value"
                    >
                        <InputNumber
                            formatter={(value : any) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(value : any) => handleSelectChange(value, 'collect_Value')}
                            min={0}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item
                        label="Observaciones"
                    >
                        <TextArea
                            rows={4}
                            name="observations"
                            onChange={handleInputChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Button type="primary" loading={billLoading} htmlType="submit" /* onClick={handleSubmit} */ block>Guardar Pedido</Button>
                </Col>
            </Row>

        </Form>
    )
}
