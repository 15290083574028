import React, { useRef } from 'react';

// Moment js
import moment from 'moment';

// Ant Desing
import { Modal } from 'antd';

import { useReactToPrint } from 'react-to-print';

// Logo
import logo from '../../Assets/Logo.png';
import './ModalGuia.css';
import DataGrid, {
    Column,
    FilterRow,
    Grouping,
    Selection,
    GroupPanel,
    Pager,
    Paging,
    SearchPanel,
    Export,
    HeaderFilter,
    Sorting,
    ColumnFixing,
    Editing,
    Lookup
} from 'devextreme-react/data-grid';


// Barcode
var Barcode = require('react-barcode');

export const ModalGuias = ({ modalGuiasVisible, setModalGuiasVisible, selectedRowsKeys }) => {

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => {window.location.reload()}
    })

    // console.log(selectedRowsKeys)

    return (
        <Modal
            title="Guias"
            visible={modalGuiasVisible}
            onOk={handlePrint}
            onCancel={() => setModalGuiasVisible(false)}
            width={1000}
        >

            <div ref={componentRef} id="item-container">
                {selectedRowsKeys.map(bill =>
                    <div className="item">
                        {/* {console.log(bill)} */}
                        <h6 className="mt-4">Orden: {bill.delivery_Order}</h6>
                        <table border="1" width="100%" className="mt-3">
                            <tr>
                                <td>
                                    <Barcode
                                        value={bill.guia}
                                        height={50}
                                        textPosition="top"

                                    />
                                </td>
                                <td style={{ minWidth: 100 }}>
                                    <div>
                                        <div className="titulo-campo-guia">Fecha:</div>
                                        <div>{moment(bill.created_at).add(-5, 'hours').format("YYYY-MM-DD HH:mm")}</div>
                                        
                                    </div>
                                </td>
                                <td>
                                    <div className="titulo-campo-guia datos-contacto">
                                        Contacto ( +57) 300 733 21 87<br />
                                        contacto@palomamensajeria.com<br />
                                        {
                                            bill.city
                                        }
                                    </div>
                                </td>
                                <td>
                                    <div className="titulo-campo-imagen">
                                        <img src={logo} alt="logo" className="img-responsive" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1">
                                    <div className="titulo-campo-guia">Nombre</div>
                                    <div className="lable-guia">{bill.customer}</div>
                                </td>
                                <td colspan="1">
                                    <div className="titulo-campo-guia enlinea">DESCRIPCIÓN SERVICIO</div>
                                    <div className="lable-guia enlinea">{bill.service_Description}</div>
                                </td>
                                <td colspan="2">
                                    <div className="titulo-campo-guia">ID</div>
                                    <div className="lable-guia">{bill.id}</div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <div className="titulo-campo-guia fondogris"></div>
                                </td>
                                <td colspan="2">
                                    <div className="titulo-campo-guia fondogris"></div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="titulo-campo-guia">Nombre</div>
                                    <div className="lable-guia">{bill.name}</div>
                                </td>
                                <td>
                                    <div className="titulo-campo-guia">Teléfono</div>
                                    <div className="lable-guia">{bill.phone} - {bill.plate}</div>
                                </td>

                                <td colspan="2">
                                    <div className="titulo-campo-guia">Direccion</div>
                                    <div className="lable-guia">{bill.address}</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="titulo-campo-guia">Ciudad</div>
                                    <div clsassName="lable-guia">{bill.city}</div>
                                </td>
                                <td>
                                    <div className="titulo-campo-guia">Valor a recoger</div>
                                    <div className="lable-guia">{bill.collect_Value}</div>
                                </td>
                                <td colspan="2">
                                    <div className="titulo-campo-guia">Productos</div>
                                    <div className="lable-guia">{bill.products}</div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <div className="titulo-campo-guia">Observaciones del servicio</div>
                                    <div className="lable-guia">{bill.observations}</div>
                                </td>
                                <td colSpan="2">
                                    <div className="titulo-campo-guia">Observaciones de entrega</div>
                                    <div className="lable-guia">observaciones de entrega</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="titulo-campo-guia">NOMBRE</div>
                                    <div className="lable-guia"><br/></div>
                                </td>
                                <td>
                                    <div className="titulo-campo-guia">FIRMA</div>
                                    <div className="lable-guia"><br/></div>
                                </td>

                                <td >
                                    <div className="titulo-campo-guia">CÉDULA</div>
                                    <div className="lable-guia"><br/></div>
                                </td>
                                <td >
                                    <div className="titulo-campo-guia">FECHA</div>
                                    <div className="lable-guia"><br/></div>
                                </td>
                            </tr>
                        </table>
                    </div>
                )}
            </div>
        </Modal>
    )
}
