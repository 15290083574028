import React, { useEffect } from 'react';
import { IModalDetailServiceProps } from './IModalDetailServiceProps';
import './style.css';
import { PlusOutlined, SaveOutlined, MinusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, Modal, Popconfirm, Row, Select } from 'antd';
import { useModalDetailService } from '../../Hooks/useModalDetailService';
import { useServiceForm } from '../../Hooks/useServiceForm';
import { BillPost } from '../../Models/Bill';
import moment from 'moment';
const { Option } = Select;
const { TextArea } = Input;

const ModalDetailService: React.SFC<IModalDetailServiceProps> = (props) => {

  const {closeModal, getServiceData, billDetailData, loading,
    onFinish, form, onCancelOrder, updateSolution, saveModalBillNews, billNews,guia,setGuia,PalomaNews } = useModalDetailService(props.setShowDetailModal,props.getHistoryServices);
    
  const {
    ProductsListFiltered,
    onSearchProducts,
    sellersList,
    CitiesList,
    getProductsList,
    getSellersList,
    getCities,
    onFinishFailed,
    onChangeProvince,
    onChangeCity,
  } = useServiceForm();

  useEffect(() => {
    getServiceData(props.idService,"");
    getProductsList();
    getSellersList();
    getCities();
  }, [props.idService]);

  return (
    <div>
      <Modal
        title={
          <Row gutter={[16,16]}>
            <Col span={2}>{`Guia:`}</Col>
            <Col span={6}><Input value={guia} onChange={(e)=>setGuia(e.target.value)}/></Col>
            <Col span={2}><Button onClick={()=>getServiceData(props.idService,guia)} type='primary'>Buscar</Button></Col>
            <Col span={2}></Col>
            <Col span={6}>
              {(billDetailData && localStorage.role == "1") &&
                <Popconfirm title='Seguro de cancelar este pedido?' onConfirm={() => onCancelOrder(billDetailData.id)}>
                  <Button><MinusCircleOutlined style={{ color: 'red' }} /> Cancelar</Button>
                </Popconfirm>
              }
            </Col>
            <Col span={6}>
              Pedido: <b># {billDetailData?.id}</b>
            </Col>
          </Row>
        }
        visible={props.showDetailModal}
        width={1000}
        onOk={closeModal}
        onCancel={closeModal}
        footer={[]}
      >

        {billDetailData &&
          <>
            {billDetailData.billNews.length > 0 &&
              <div>
                <hr />
                <h1 style={{ textAlign: 'center', textDecoration: 'underline' }}>NOVEDADES</h1>
                {billDetailData.billNews.map((x : any, i : any)   =>
                  <Row gutter={[16, 16]} key={x.id}>
                    <Col span={12}>
                      <b>Tipo de Novedad:</b> &nbsp;&nbsp; <br />
                      {x.newsBillingType}
                    </Col>
                    <Col span={12}>
                      <div style={{ color: 'blue' }}>({moment(x.created_at).format('YYYY-DD-MM HH:mm')})</div>
                      {x.description}
                    </Col>
                    <Col span={24}>
                      <TextArea rows={4} value={x.solution} onChange={(e) => updateSolution(i, e.target.value)} />
                    </Col>
                    <Button style={{ float: 'right' }} type='dashed' onClick={() => saveModalBillNews(i)}><SaveOutlined /> Guardar</Button>
                  </Row>
                )}
                <hr />
              </div>
            }
            <Form
              name="Formulario"
              initialValues={billDetailData}
              onFinish={onFinish}
              form={form}
              onFinishFailed={onFinishFailed}
            >
              <Row gutter={[16, 16]} style={{ marginTop: 20 }}>


                <Form.Item
                  label='Guia'
                  name="guia"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="id"
                >
                </Form.Item>
                <Form.Item
                  name="customerId"
                >
                </Form.Item>
                <Form.Item
                  name="statusId"
                >
                </Form.Item>
                <Form.Item
                  name="guia"
                >
                </Form.Item>

                <Col span={8}>
                  <Form.Item
                    label="Vendedor"
                    name="sellerId"
                  >
                    <Select style={{ width: '100%' }}>
                      <Option value={0} key={0}> Selecciona un vendedor...</Option>
                      {sellersList.map(x =>
                        <Option key={x.id} value={x.id}>{x.name}</Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Nombre Completo"
                    name="name"
                    rules={[{ required: true, message: 'El nombre es obligatorio!' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Cédula"
                    name="document"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
                <Col span={8}>
                  <Form.Item
                    label="Dirección"
                    name="address"
                    rules={[{ required: true, message: 'La dirección es obligatoria!' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Correo"
                    name="email"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Teléfono"
                    name="phone"
                    rules={[{ required: true, message: 'La teléfono es obligatorio!' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
                <Col span={8}>
                  <Form.Item
                    name="provinceId"
                    label="Departamento"
                    rules={[
                      {
                        validator: (_, value) =>
                          value != 0 ? Promise.resolve() : Promise.reject('El departamento es obligatorio!'),
                      },
                    ]}>
                    <Select
                      style={{ width: '100%' }}
                      onChange={onChangeProvince}
                    >
                      <Option value={0} key={0}> Selecciona un departamento...</Option>
                      {CitiesList.map(x => <Option value={x.provinceId} key={x.provinceName}>{x.provinceName}</Option>)}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues: BillPost, currentValues: BillPost) => prevValues.provinceId !== currentValues.provinceId}
                  >
                    {({ getFieldValue }) =>
                      <Form.Item
                        name="cityId"
                        label="Ciudad"
                        rules={[
                          {
                            validator: (_, value) =>
                              value != 0 ? Promise.resolve() : Promise.reject('La ciudad es obligatoria!'),
                          },
                        ]}>
                        <Select
                          style={{ width: '100%' }}
                          onChange={onChangeCity}
                        >
                          <Option value={0} key={0}> Selecciona una ciudad...</Option>
                          {CitiesList.filter(x => x.provinceId == getFieldValue('provinceId'))
                            .map(x => x.cities_CompanyTransports
                              .map(ct => <Option value={ct.cityId} key={ct.cityName}>{ct.cityName}</Option>))}
                        </Select>
                      </Form.Item>
                    }
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues: BillPost, currentValues: BillPost) => prevValues.cityId !== currentValues.cityId}
                  >
                    {({ getFieldValue }) =>
                      <Form.Item
                        name="company_TransportId"
                        label="Transportadora"
                        rules={[
                          {
                            validator: (_, value) =>
                              value != 0 ? Promise.resolve() : Promise.reject('La transportadora es obligatoria!'),
                          },
                        ]}>
                        <Select style={{ width: '100%' }}>
                          <Option value={0} key={0}> Selecciona una transportadora...</Option>
                          {CitiesList.filter(x => x.provinceId == getFieldValue('provinceId'))
                            .map(x => x.cities_CompanyTransports.filter(city => city.cityId == getFieldValue('cityId'))
                              .map(ct => ct.company_Transports.map(company => <Option value={company.id} key={company.name}>{company.name}</Option>)))}
                        </Select>
                      </Form.Item>
                    }
                  </Form.Item>
                </Col>
              </Row>

              <hr />
              <h1 style={{ textAlign: 'center', textDecoration: 'underline' }}>PRODUCTOS</h1>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.List name="bill_Products">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(field => (
                          <Row gutter={[16, 16]}>
                            <Col span={12}>
                              <Form.Item
                                {...field}
                                name={[field.name, 'productId']}
                                label="Producto"
                                fieldKey={[field.fieldKey, 'productId']}
                                rules={[
                                  {
                                    validator: (_, value) =>
                                      value != 0 ? Promise.resolve() : Promise.reject('La ciudad es obligatoria!'),
                                  },
                                ]}>
                                <Select
                                  style={{ width: '100%' }}
                                  showSearch
                                  defaultActiveFirstOption={false}
                                  showArrow={true}
                                  filterOption={false}
                                  onSearch={onSearchProducts}
                                  notFoundContent="No hay datos..."
                                  allowClear
                                >
                                  <Option value={0} key={0}> Selecciona un producto...</Option>
                                  {ProductsListFiltered.map(x =>
                                    <Option key={x.id} value={x.code}>{x.palomaName}</Option>
                                  )}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                {...field}
                                name={[field.name, 'quantity']}
                                fieldKey={[field.fieldKey, 'quantity']}
                                rules={[{ required: true, message: 'La cantidad es obligatoria' }]}
                              >
                                <InputNumber />
                              </Form.Item>
                            </Col>
                            {(billDetailData && localStorage.role == "1") &&
                              <Col span={2}>
                                <MinusCircleOutlined onClick={() => remove(field.name)} style={{ color: 'red' }} />
                              </Col>
                            }
                          </Row>
                        ))}
                        <Form.Item>
                          {(billDetailData && localStorage.role == "1") &&
                            <Button type="primary" onClick={() => add()} block icon={<PlusOutlined />}>
                              Agregar Producto
                            </Button>
                          }
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Col>
              </Row>

              <hr />

              <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
                <Col span={8}>
                  <Form.Item
                    label="Recaudo"
                    name="typeConsignedValue"
                  >
                    <Select style={{ width: '100%' }}>
                      <Option value={"Con Recaudo"} key={"Con Recaudo"}>Con Recaudo</Option>
                      <Option value={"Sin Recaudo"} key={"Sin Recaudo"}>Sin Recaudo</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Valor a Recaudar"
                    name="collect_Value"
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                 Mensajero: <b>{billDetailData.zone}</b>
                 Estado: <b>{billDetailData.status}</b>
                </Col>
              </Row>
              {localStorage.role === "1" &&
               <Row>
                  <Col>
                  {PalomaNews.map((x : any, i : any)   =>
                  <Row gutter={[16, 16]} key={x.id}>
                    <Col span={12}>
                      <b>Tipo de Novedad:</b> &nbsp;&nbsp; <br />
                      {x.newsBillingType}
                    </Col>
                    <Col span={12}>
                      <div style={{ color: 'blue' }}>({moment(x.created_at).format('YYYY-DD-MM HH:mm')})</div>
                      {x.description}
                    </Col>
                    <Col span={12}>
                      {x.solution}
                    </Col>
                  </Row>
                )}
                  </Col>
              </Row>    
              }        
              <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
                <Col span={24}>
                  <Form.Item
                    label="Observaciones"
                    name="observations"
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                {(billDetailData && localStorage.role == "1") &&
                  <Button type="primary" htmlType="submit" style={{ float: 'right' }} loading={loading}>
                    <SaveOutlined /> Guardar
                  </Button>
                }
              </Form.Item>
            </Form>
          </>
        }
      </Modal>
    </div>
  );
};

export default ModalDetailService;
