import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import billsAPI from '../Api/api-bills'
import { Statuses } from '../Models/Status';

export const useStatus = () => {

    const [statuses, setStatuses] = useState<Statuses[]>([]);
    const getStatuses = async() => {
        try {
            const statuses = await billsAPI.getStatusList();
            setStatuses(statuses)
        } catch (error) {
            message.error(`No se obtuvieron los estados. ${error}`);
        }
    }

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            getStatuses();
        }
        return () => {
            mounted = false;
        }
    }, [])

    return {
        statuses,
        getStatuses
    }

}