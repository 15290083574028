import { notification } from "antd";
import { useEffect, useState } from "react";
import productsAPI from "../../../Api/api-products";
import { KitRequest, Product } from "../../../Models/Product";

export const useAddKit = (customerId:number) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [productList, setProductList] = useState<Product[]>([]);
    const [productListFiltered, setProductListFiltered] = useState<Product[]>([]);
    const [kit, setKit] = useState<KitRequest>({
        productId: 0,
        product_List: []
      });

    const showModal = () => {
        setIsModalVisible(true);
      };
    
      const handleCancel = () => {
        setIsModalVisible(false);
      };

      const saveKit = () => {
        setLoading(true);
        productsAPI.CreateKit(kit).then(res=>{
            setLoading(false);
            handleCancel();
            notification.success({message:'El kit fue creado exitosamente'});
            setKit({
                productId: 0,
                product_List: []
              })
        })
      }

      const updateKitData = (e:any) => {

        setKit({...kit, productId: e})
      }

      const addProduct = () => {
          let kitTemp = {...kit}
          kitTemp.product_List.push(0);
          setKit(kitTemp);
      }

      const updateProductId = (e:any, index:number) => {
        let kitTemp = {...kit};
        kitTemp.product_List[index] = e;
        setKit(kitTemp);
      }

      const deleteProductFromKit = (index:number) => {
        let kitTemp = {...kit};
        kitTemp.product_List.splice(index, 1);
        setKit(kitTemp);
      }

      const getProductList = (customerId: number) => {
        productsAPI.getProductsByCustomerId(customerId).then(res => {
            setProductList(res.filter(x => x.is_Kit === false));
            setProductListFiltered(res.filter(x => x.is_Kit === false));
        })
    }

    const onSearchProducts = (value: any) => {
        if (value) {
            let array = productList.filter((x: Product) => x.palomaName.toLowerCase().includes(value.toLowerCase()));
            setProductListFiltered(array);
        } else {
            setProductListFiltered(productList);
        }
    };

    return {
        isModalVisible,
        showModal,
        handleCancel,
        loading,
        saveKit,
        productListFiltered,
        getProductList,
        onSearchProducts,
        updateKitData,
        kit,
        addProduct,
        updateProductId,
        deleteProductFromKit,
        productList
    }
}