import React, { useState } from 'react';
import moment from 'moment';
import { Button, Drawer, message, Timeline } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import BillNewsAPI from '../../Api/api-billNews';

type DrawerNewsProps = {
    visibleDrawerNews: any,
    setVisibleDrawerNews: any,
    billNews: any
    billData: any
}

export const DrawerNews = ({ visibleDrawerNews, setVisibleDrawerNews, billNews, billData }: DrawerNewsProps) => {

    const [solution, setSolution] = useState('');
    const [solutionNewsLoading, setSolutionNewsLoading] = useState(false);
    const solutionNews = async (news: any) => {
        setSolutionNewsLoading(true);
        try {
            let newNews = news;
            news.solution = solution;
            await BillNewsAPI.updateBillNews(newNews);
            message.success('Se solucionó la novedad correctamente');
        } catch (error) {
            message.error(`No se pudo solucionar la novedad. ${error}`)
        }
        setSolutionNewsLoading(false);
        // setVisibleDrawerNews(false);
    }
    return (
        <Drawer
            title="Novedades"
            placement="right"
            closable={true}
            visible={visibleDrawerNews}
            onClose={() => setVisibleDrawerNews(false)}
            width={450}
        >
            <h6>Fecha creación guia: {moment(billData.created_at).format('DD-MM-YYYY, h:mm:ss a')}</h6>

            {billData.status === 'Entregado' &&
                <>
                    <h6>Estado: {billData.status}</h6>
                    <h6>Fecha entrega: {moment(billData.delivered_at).format('DD-MM-YYYY, h:mm:ss a')}</h6>
                </>
            }
            <Timeline className="mt-5">
                {billNews?.map((news: any) =>
                    <Timeline.Item key={news.id} color={news.solution !== null ? 'green' : 'red'}>
                        {localStorage.role === "1" &&
                            <>
                                <h6>{news.newsBillingType}</h6>
                                <p>Fecha: {moment(news.created_at).format('DD-MM-YYYY, h:mm:ss a')}</p>
                                <p>Descripción: {news.description}</p>
                                {news.solution !== null &&
                                    <p>Solución: {news.solution}</p>
                                }
                                {/* {news.solution === null &&
                                    <>
                                        <TextArea rows={4} onChange={(event: any) => setSolution(event.target.value)} />
                                        <Button loading={solutionNewsLoading} type="primary" className="mt-1" onClick={() => solutionNews(news)}>Solucionar</Button>
                                    </>
                                } */}
                            </>
                        }
                        {/* {news.solution === null &&
                            <>
                                <TextArea rows={4} onChange={(event: any) => setSolution(event.target.value)} />
                                <Button loading={solutionNewsLoading} type="primary" className="mt-1" onClick={() => solutionNews(news)}>Solucionar</Button>
                            </>
                        } */}
                    </Timeline.Item>
                )}
            </Timeline>
        </Drawer>
    )
}
