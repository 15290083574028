import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form, Select, Spin, Badge, message, Alert, Tabs } from 'antd'
import { EditOutlined, FilePdfOutlined, PrinterOutlined, SearchOutlined, RocketOutlined } from '@ant-design/icons';

// DevExtreme
import DataGrid, {
    Column,
    FilterRow,
    Grouping,
    Selection,
    GroupPanel,
    Pager,
    Paging,
    SearchPanel,
    Export,
    HeaderFilter,
    Sorting,
    ColumnFixing,
    Editing,
    Lookup
} from 'devextreme-react/data-grid';
import { useStatus } from '../../Hooks/useStatus';
import { useCustomer } from '../../Hooks/useCustomer';
import { useMessager } from '../../Hooks/useMessager';
import { useAdminRouting } from './Hooks/useAdminRouting';
import { BillStatus } from '../../Models/Bill';
import { ModalGuia } from './ModalGuia';
import { DrawerNews } from './DrawerNews';
import { DrawerEditRouting } from './DrawerEditRouting';
import { ModalGuias } from './ModalGuias';
import { ModalPdf } from './ModalPdf';
import UploadExcel from './UploadExcel';
// import { ModalInventory } from './ModalInventory';
import { ModalInventory } from './ModalInventory'

const { Option } = Select;
const { TabPane } = Tabs;

export const RoutingServices = () => {

    const {
        servicesRouting,
        loadingRouting,
        filters,
        handleMessager,
        selectRef,
        messagerSelected,
        getRoutingServices,
        selectedRowKeys,
        selectedRows,
        handleUpdate,
        dispatchBills,
        billData,
        loadingBillProducts
    } = useAdminRouting()

    const { statuses } = useStatus();
    const { customers } = useCustomer();
    const { messagersByWarehouse } = useMessager();

    // Modal Guia
    const [modalGuiaVisible, setModalGuiaVisible] = useState(false);
    const [guiaSelected, setGuiaSelected] = useState<BillStatus>({});

    const showModalGuia = (data: BillStatus) => {
        setGuiaSelected(data);
        setModalGuiaVisible(true);
    }

    // Drawer News
    const [visibleDrawerNews, setVisibleDrawerNews] = useState(false);
    const showDrawerNews = async (data: BillStatus) => {
        setBillSelected(data);
        setVisibleDrawerNews(true);
    }

    // Drawer Edit
    const [drawerEditVisible, setDrawerEditVisible] = useState(false);
    const [billSelected, setBillSelected] = useState<any>({});
    const showModalEdit = (data: any) => {
        setBillSelected(data);
        setDrawerEditVisible(true);
    }

    // Modal Guias
    const [modalGuiasVisible, setModalGuiasVisible] = useState(false);

    const showModalGuias = () => {
        if (selectedRowKeys.length !== 0) {
            setModalGuiasVisible(true);
        } else {
            message.warn('Seleccione servicios para imprimir.');
        }
    }

    const [modalInventoryVisible, setModalInventoryVisible] = useState(false);
    const showModalInventory = () => {
        if (selectedRowKeys.length !== 0) {
            setModalInventoryVisible(true);
        } else {
            message.warn('Seleccione servicios para imprimir.');
        }
    }

    // Export PDF
    const [modalPdfVisible, setModalPdfVisible] = useState(false);
    const exportGrid = () => {
        setModalPdfVisible(true);
    }


    // Custom Cells
    const guiaCell = ({ data }: any) => {
        if (data.totalPendingBillNews > 0) {
            return (
                <Badge color="red" text={<a onClick={() => showModalGuia(data)}>{data.guia}</a>} />
            )
        } else {
            return (
                <a onClick={() => showModalGuia(data)}>{data.guia}</a>
            )
        }
    }

    const detailCell = ({ data }: any) => {
        return (
            <ul>
                <li><b>Nombre: </b>{`${data.name}`}</li>
                <li><b>Cel: </b>{`${data.phone}`}</li>
                <li><b>Dir: </b>{`${data.address}`}</li>
                <li><b>Ciu: </b>{`${data.city}`}</li>
            </ul>
        )
    }

    const newsCell = ({ data }: any) => {
        return (
            <SearchOutlined onClick={() => showDrawerNews(data)} />
        )
    }

    const modalCell = ({ data }: any) => {
        return <EditOutlined onClick={(e) => showModalEdit(data)} />
    }

    return (
        <React.Fragment>
            <Form layout="vertical" ref={selectRef}>
                <Row>
                    <Col span={8}>
                        <Form.Item label="Mensajero">
                            <Select placeholder="Seleccione un mensajero" onChange={handleMessager} disabled={filters.warehouseId === 0}>
                                {messagersByWarehouse.map(({ id, name }: any) =>
                                    <Option key={id} value={name}>{name}</Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Row gutter={[16, 8]} className="mb-2">
                {servicesRouting.length !== 0 ?
                    (
                        <>
                            <Col span={6}>
                                <Button
                                    type="primary"
                                    title="Ver Mapa"
                                    icon={<FilePdfOutlined />}
                                    onClick={() => setModalPdfVisible(true)}
                                >
                                    Imprimir Ruta
                                </Button>
                            </Col>
                            <Col span={6}>
                                <Button
                                    type="primary"
                                    title="Imprimir"
                                    icon={<PrinterOutlined />}
                                    onClick={showModalGuias}
                                >
                                    Imprimir Guias
                                </Button>
                            </Col>

                            <Col span={6}>
                                <Button
                                    type="primary"
                                    title="Despachar"
                                    icon={<RocketOutlined />}
                                    onClick={dispatchBills}
                                >
                                    Despachar
                                </Button>
                            </Col>

                            <Col span={6}>
                                <Button
                                    type="primary"
                                    title="Inventario"
                                    // icon={<RocketOutlined />}
                                    onClick={showModalInventory}
                                >
                                    Inventario
                                </Button>
                            </Col>
                            
                            <Tabs defaultActiveKey="1" >
                                <TabPane tab="Enrutados" key="1">
                                    <Col span={24}>
                                        <Spin tip="Cargando..." spinning={loadingRouting}>
                                            <div id="data-grid-demo">
                                                <DataGrid
                                                    id="gridContainer"
                                                    dataSource={servicesRouting}
                                                    onRowUpdated={handleUpdate}
                                                    onSelectionChanged={selectedRows}
                                                    allowColumnReordering={true}
                                                    allowColumnResizing={true}
                                                    columnAutoWidth={true}
                                                    showBorders={true}
                                                    rowAlternationEnabled={true}
                                                >
                                                    <SearchPanel visible={true} highlightCaseSensitive={true} />
                                                    <Grouping autoExpandAll={true} />
                                                    <FilterRow visible={true} />
                                                    <GroupPanel visible={true} />
                                                    <Grouping autoExpandAll={true} />
                                                    <Export enabled={true} allowExportSelectedData={false} />
                                                    <HeaderFilter visible={true} />
                                                    <Sorting mode="multiple" />
                                                    <ColumnFixing enabled={true} />
                                                    <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                                                    <Grouping autoExpandAll={false} />
                                                    <Selection
                                                        mode="multiple"
                                                        selectAllMode={'allPages'}
                                                        showCheckBoxesMode={'onClick'}
                                                    />
                                                    {/* <Column caption="Orden" dataField="delivery_Order" dataType="number" alignment="center" />
                                                    <Column caption="Guias" allowEditing={false} cellRender={guiaCell} />
                                                    <Column dataField="customerId" dataType="number" caption="Cliente" allowEditing={false}>
                                                        <Lookup dataSource={customers} displayExpr="name" valueExpr="id" />
                                                    </Column>
                                                    <Column dataField="service_Description" dataType="string" caption="Descripción" allowEditing={false} />
                                                    <Column cellRender={detailCell} caption="Detalle" />
                                                    <Column dataField="aditionalInformation" dataType="string" caption="Información Adicional" />
                                                    <Column dataField="collect_Value" caption="Valor a cobrar" dataType="number" format="currency" />
                                                    <Column dataField="aditional_Value" caption="Valor adicional" dataType="number" format="currency" /> */}
                                                    {/* <Column dataField="service_Value" caption="Valor Servicio" dataType="number" format="currency" /> */}
                                                    {/* <Column dataField="statusId" dataType="string" caption="Estado" >
                                                        <Lookup dataSource={statuses} displayExpr="name" valueExpr="id" />
                                                    </Column> */}
                                                    <Column caption="Orden" dataField="delivery_Order" dataType="number" alignment="center" visible={filters.warehouseId != 1} />
                                                    <Column caption="Guias" allowEditing={false} cellRender={guiaCell} visible={filters.warehouseId != 1} />


                                                    <Column dataField="service_Description" dataType="string" caption="Descripción" allowEditing={false} visible={filters.warehouseId != 1} />
                                                    <Column dataField="customerId" dataType="number" caption="Cliente" allowEditing={false}>
                                                        <Lookup dataSource={customers} displayExpr="name" valueExpr="id" />
                                                    </Column>
                                                    <Column cellRender={detailCell} caption="Detalle" visible={filters.warehouseId != 1} />
                                                    <Column dataField="aditionalInformation" dataType="string" caption="Información Adicional" />
                                                    
                                                    <Column dataField="statusId" dataType="string" caption="Estado" visible={filters.warehouseId != 1} >
                                                        <Lookup dataSource={statuses} displayExpr="name" valueExpr="id" />
                                                    </Column> 
                                                    <Column dataField="document" caption="Cédula" visible={true} />
                                                    <Column dataField="collect_Value" caption="Valor a cobrar" dataType="number" format="currency" visible={true} />
                                                    <Column dataField="id" dataType="string" caption="ID" alignment="center" visible={filters.warehouseId == 1} />
                                                    <Column dataField="guia" dataType="string" caption="Guia" alignment="center" visible={filters.warehouseId == 1} />
                                                    <Column dataField="company_Transport" dataType="string" caption="Transportadora" alignment="center" visible={filters.warehouseId == 1} />
                                                    <Column dataField="cityCode" dataType="string" caption="Código Ciudad" width={150} visible={filters.warehouseId == 1} />
                                                    <Column caption="Detalle" dataType="string" /* width={300} */ cellRender={detailCell} visible={filters.warehouseId == 1} />
                                                    <Column dataField="name" caption="Nombre" dataType="string" visible={filters.warehouseId == 1} />
                                                    <Column dataField="address" caption="Detalle" dataType="string" visible={filters.warehouseId == 1} />
                                                    <Column dataField="phone" caption="Teléfono" dataType="string" visible={filters.warehouseId == 1} />
                                                    <Column dataField="city" dataType="string" caption="Ciudad" width={150} visible={filters.warehouseId == 1} />
                                                    <Column dataField="updatedBy" caption="Usuario" dataType="string" visible={filters.warehouseId == 1} />
                                                    <Column dataField="seller" dataType="string" caption="Vendedor" visible={filters.warehouseId == 1} />
                                                    <Column dataField="created_at" dataType="date" caption="Fecha" format="dd-MM-yyyy HH:mm" visible={filters.warehouseId == 1} />
                                                    <Column dataField="observations" dataType="string" caption="Observaciones" visible={filters.warehouseId == 1} />
                                                    <Column dataField="products" dataType="string" caption="Productos" visible={filters.warehouseId == 1} />
                                                    <Column cellRender={newsCell} caption="Novedades" alignment="center" />
                                                    <Column cellRender={modalCell} caption="Editar" alignment="center" />

                                                    <Pager allowedPageSizes={[5, 10, 50, 100]} showPageSizeSelector={true} />
                                                    <Paging defaultPageSize={100} />
                                                    <Editing
                                                        mode="cell"
                                                        allowUpdating={true}
                                                    />
                                                </DataGrid>
                                            </div>
                                        </Spin>
                                    </Col>
                                </TabPane>
                                <TabPane tab="Carga Masiva" key="2">
                                    <UploadExcel />
                                </TabPane>
                            </Tabs>
                        </>
                    )
                    :
                    (
                        <Col span={24}>
                            <Alert
                                message="No hay servicios enrutados"
                                description="Seleccione una bodega y un mensajero"
                                type="warning"
                                showIcon
                            />
                        </Col>
                    )
                }
            </Row>

            <ModalGuia
                guiaSelected={guiaSelected}
                modalGuiaVisible={modalGuiaVisible}
                setModalGuiaVisible={setModalGuiaVisible}
            />


            <DrawerNews
                visibleDrawerNews={visibleDrawerNews}
                setVisibleDrawerNews={setVisibleDrawerNews}
                billSelected={billSelected}
            />

            <DrawerEditRouting
                drawerEditVisible={drawerEditVisible}
                setDrawerEditVisible={setDrawerEditVisible}
                billSelected={billSelected}
                messagers={messagersByWarehouse}
                statuses={statuses}
                setBillSelected={setBillSelected}
                messagerSelected={messagerSelected}
                getRoutingServices={getRoutingServices}
            />

            <ModalGuias
                modalGuiasVisible={modalGuiasVisible}
                setModalGuiasVisible={setModalGuiasVisible}
                selectedRowsKeys={selectedRowKeys}
            />

            <ModalPdf
                bills={servicesRouting}
                modalPdfVisible={modalPdfVisible}
                setModalPdfVisible={setModalPdfVisible}
                billsLoading={loadingRouting}
                messager={messagerSelected}
                customers={customers}
            />

            <ModalInventory
                modalInventoryVisible={modalInventoryVisible}
                setModalInventoryVisible={setModalInventoryVisible}
                billData={billData}
                loadingBillProducts={loadingBillProducts}
                selectedRowsKeys={selectedRowKeys}
            />
        </React.Fragment>
    )
}