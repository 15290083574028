import { useEffect, useState } from "react";
import { message } from "antd";
import moment from "moment";
import usersAPI from "../../../Api/api-users";

type UserPasswords = {
    password?: string,
    confirmPassword?: string
}

type QueryParams = {
    token?: any
    sinceDate?: any
    idUser?: any
    email?: any
}

export const useRecoverPassword = () => {

    const [userPasswords, setUserPasswords] = useState<UserPasswords>({})
    const [queryParams, setQueryParams] = useState<QueryParams>({})
    const [isValid, setIsValid] = useState(true);
    const [loading, setLoading] = useState(false);

    const handleChange = ({ target }: any) => {
        const { name, value } = target;
        setUserPasswords({
            ...userPasswords,
            [name]: value
        })
    }

    const recoveryPassword = async () => {
        if (userPasswords.password === userPasswords.confirmPassword) {
            setLoading(true);
            try {
                let userRecover = {
                    loginName: queryParams.email,
                    token: queryParams.token,
                    password: userPasswords.password
                }
               const resp = await usersAPI.resetPass(userRecover);
               if( resp ) {
                   message.success('Se actualizó la contraseña correctamente');
                   setTimeout(() => {
                        window.location.href = "/LoginComponent"
                   }, 2000);
               } else {
                   setIsValid(false);
               }
               setLoading(false);

            } catch (error) {
                message.error(`${error}`)
            }
        } else {
            message.warn('Las contraseñas no son iguales');
        }
    }

    const getParams = () => {
        let today: any = new Date();
        today = moment(today).format('DD-MM-YYYY h:mm:ss')
        let token: any = getQueryVariable('token');
        let sinceDate: any = getQueryVariable('SinceDate');
        let email: any = getQueryVariable('email')
        sinceDate = new Date(sinceDate.replaceAll("%20", " "))
        let idUser: any = getQueryVariable('IdUser');
        let queryParams = {
            token,
            sinceDate,
            idUser,
            email
        }
        queryParams.idUser = Number(idUser);
        queryParams.sinceDate = moment(sinceDate).format('DD-MM-YYYY h:mm:ss')
        if (queryParams.sinceDate > today) {
            setQueryParams({ token, sinceDate, idUser, email })
        } else {
            setIsValid(false);
            setTimeout(() => {
                window.location.href = "/LoginComponent"
            }, 3000);
        }
    }

    const getQueryVariable = (variable: string) => {
        let query = window.location.search.substring(1);
        let vars = query.split("&");
        for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split("=");
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    }


    useEffect(() => {
        let mounted = true;
        if( mounted ) {
            getParams();
        }
        return () => {
            mounted = false;
        }
    }, [])


    return {
        isValid,
        handleChange,
        loading,
        recoveryPassword
    }
}
