import React from 'react'

// Ant Desing
import { Col, Row, Form, Select, InputNumber, Button, Alert } from 'antd';

// DevExtreme
import DataGrid, { Column, Editing, Lookup } from 'devextreme-react/data-grid';

// Hooks
import { useFormService } from './Hooks/useFormService';

// Styles
import './FormService.css';



// Ant Desing Controllers
const { Option } = Select;

type ProductsDataProps = {
    products: any,
    productsLoading: any,
    handleProductsChange: any,
    addProduct: any,
    arrayProducts: any,
    formProductsRef: any,
    removeProduct: any,
}

export const ProductsData = ({ products, productsLoading, handleProductsChange, addProduct, arrayProducts, formProductsRef, removeProduct }: ProductsDataProps) => {

    return (
        <>
            <h4 className="title-form">Productos</h4>
            <Form
                layout="vertical"
                initialValues={{
                    quantity: 1
                }}
                ref={formProductsRef}
            >
                <Row gutter={[24, 24]}>
                    <Col span={8}>
                        <Form.Item
                            label="Producto"
                            name="productId"
                        >
                            <Select
                                placeholder="Seleccionar"
                                onChange={(value) => handleProductsChange(value, 'productId')}
                                loading={productsLoading}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {products.map(({ id, name }: any) =>
                                    <Option key={id} value={name}>{name}</Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Cantidad"
                            name="quantity"
                        >
                            <InputNumber
                                onChange={(value: any) => handleProductsChange(value, 'quantity')}
                                min={1}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Button
                            type="primary"
                            className="btn-add"
                            onClick={addProduct}
                        >
                            Agregar Producto
                        </Button>
                    </Col>
                    <Col span={24}>
                        {arrayProducts.length !== 0
                            ?
                            (
                                <DataGrid
                                    dataSource={arrayProducts}
                                    showBorders={true}
                                    rowAlternationEnabled={true}
                                    width={500}
                                    onRowRemoved={removeProduct}
                                >
                                    <Column dataField="uniqid" dataType="number" caption="ID" visible={false} />
                                    <Column dataField="productId" dataType="string" caption="Producto" >
                                        <Lookup dataSource={products} displayExpr="name" valueExpr="name" />
                                    </Column>
                                    <Column dataField="quantity" dataType="number" caption="Cantidad" />

                                    <Editing
                                        mode="row"
                                        useIcons={true}
                                        allowDeleting={true}
                                    />
                                </DataGrid>
                            )
                            :
                            (
                                <Alert
                                    message="No hay productos agregado"
                                    description="Agrege un productos"
                                    type="warning"
                                    showIcon
                                />
                            )
                        }
                    </Col>
                </Row>
            </Form>
        </>
    )
}
