import React from 'react'

import { Button, Col, Form, Input, Row, Select, Spin, Modal } from 'antd';
import { Customer } from '../../../Models/Customer';
import { useState } from 'react';
import { useCreateUsers } from '../Hooks/useCreateUsers';

type UserEditModalProps = {
    visible: boolean,
    setVisible: Function,
    userSelected: any,
    customers: Customer[]
    setUserSelected : Function
}

export const UserEditModal = ({ visible, setVisible, userSelected, customers, setUserSelected }: UserEditModalProps) => {

    const { handleUserUpdate, loading } = useCreateUsers();

    const handleChange = (e : any) => {
        setUserSelected({
            ...userSelected,
            [e.target.name] : e.target.value
        })
    }

    const handleChangeRol = (e : any ) => {
        setUserSelected({
            ...userSelected,
            rolId : e
        })
    }
    
    const handleChangeCustomer = (e : any ) => {
        setUserSelected({
            ...userSelected,
            customerId : e
        })
    }

    const { Option } = Select;

    return (
        <Modal
            title="Editar Usuario"
            centered
            visible={visible}
            onOk={() => handleUserUpdate(userSelected)}
            onCancel={() => setVisible(false)}
            // width={1000}
            okText="Actualizar"
            cancelText="Cancelar"
            footer={[
                <Button key="back" onClick={()=>setVisible(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={ () => handleUserUpdate(userSelected) }>
                    Enviar
                </Button>,      
            ]}

        >

            <Row>
                <Col span={12} style={{margin: '10px'}}>
                    <div>Nombre</div>
                    <Input value={userSelected.name} name="name"  onChange={ handleChange } />
                </Col>
                <Col span={12} style={{margin: '10px'}}>
                    <div>Email</div>
                    <Input value={userSelected.email} name="email"  onChange={ handleChange } />
                </Col>
                <Col span={12} style={{margin: '10px'}}>
                    <div>Rol</div>
                    <Select value={userSelected.rolId}  onChange={ handleChangeRol }>
                        <Option value={1}> Administrador </Option>
                        <Option value={2}> Vendedor </Option>
                        <Option value={3}> Cliente </Option>
                        <Option value={4}> Mensajero </Option>
                    </Select>
                </Col>
                <Col span={12} style={{margin: '10px'}}>
                    <div>Cliente</div>
                    <Select value={userSelected.customerId}  onChange={ handleChangeCustomer }>
                        {customers.map(({ id, name }) =>
                            <Option key={id} value={id}>{name}</Option>
                        )}
                    </Select>
                </Col>
            </Row>

        </Modal>
    )
}

