import React, { useState, useEffect } from 'react';
import { IDrawerMensajerosDisponiblesProps } from './IDrawerMensajerosDisponiblesProps';
import  './DrawerMensajerosDisponibles.css';
import { Drawer, Row, Col, Checkbox, Input, Button } from 'antd';

export interface asignatioList {
  zona: string,
  departamento: string,
  cantidad: number
}

const DrawerMensajerosDisponibles: React.SFC<IDrawerMensajerosDisponiblesProps> = (props) => {


  const [asignationList, setAsignationList] = useState<asignatioList[]>([]);
  const [vehiculos, setVehiculos] = useState([]);

  const [bodega, setBodega] = useState('');
  const [serviciosMensajeros, setServiciosMensajeros] = useState([])

  useEffect(() => {
    obtenerServiciosEnrutados();
    obtenerServiciosMensajeros();
    obtenerBodega(props.bodegaSeleccionada);
  }, [props.drawerMensajerosVisible]);

  const obtenerServiciosEnrutados = () => {
    // servicesAPI.getServicesByState("Enrutado").then(json => {
    //   let asignationList:any = json.filter((x: service) => x.departamento_destino === 'Cundinamarca').reduce(countEm, {});
    //   configurarMensajeros(asignationList);
    // });
  }

  const obtenerBodega = (bodega : number) => {
    switch (bodega) {
      case 2:
        setBodega('Cundinamarca') 
        break;
      case 3:
        setBodega('Valle')
        break;
      case 4:
        setBodega('Antioquia')
        break;
      case 5:
        setBodega('Atlantico')
        break
    }
  }

  const obtenerServiciosMensajeros = () => {
    let serviciosMensajeros = props.mensajeros.map((x: any) => {
      return {
        mensajero : x.name,
        total : props.listadoServicios.filter((s:any) => s.zone == x.name).length
      }
    })

    setServiciosMensajeros(serviciosMensajeros)
  }

  const countEm = (accum: any, each: any) => {
    if (!accum[each.zona])
      accum[each.zona] = 0
    accum[each.zona] += 1
    return accum
  }


  const configurarMensajeros = (asignationList:any) => { 
    let arrayVehiculos:any = [...props.mensajeros] as any;
    //let totalMensajeros = arrayVehiculos.filter((x:any)=>x.coords !== null).length;
    arrayVehiculos.map((x:any, i:number)=>{
      arrayVehiculos[i].checked = false;
      arrayVehiculos[i].cantidad = asignationList[x.name];
      /* arrayVehiculos[i].cantidad = (props.numServiciosProgramados/totalMensajeros).toFixed(0);
      if(x.coords)
      {
        arrayVehiculos[i].start_address_lat = parseFloat(x.coords.split("/")[0].split(",")[0]);
        arrayVehiculos[i].start_address_lon = parseFloat(x.coords.split("/")[0].split(",")[1]);
        arrayVehiculos[i].end_address_lat = parseFloat(x.coords.split("/")[1].split(",")[0]);
        arrayVehiculos[i].end_address_lon = parseFloat(x.coords.split("/")[1].split(",")[1]);  
      }
      else{
        arrayVehiculos[i].cantidad = 0;
      } */
    });

    setVehiculos(arrayVehiculos);
  }

  const checkedHandler = (index: number) => {
    let arrayVehiculos: any = [...props.mensajeros] as any;
    arrayVehiculos[index].checked = !arrayVehiculos[index].checked;
    setVehiculos(arrayVehiculos)
  }

  /* const seleccionarTodos = (checked:boolean) => {
    let arrayVehiculos:any = [...props.mensajeros] as any;
    arrayVehiculos.map((x:any, i:number)=>{
      arrayVehiculos[i].checked = checked;
    });
    setVehiculos(arrayVehiculos);
  }

  

  /* const actualizarNumeroServicios = (value:any, index:number) => {
    let arrayVehiculos:any = [...props.mensajeros] as any;
    arrayVehiculos[index].cantidad = value ;
    setVehiculos(arrayVehiculos)
  } */

  /* const obtenerVehiculosCheckeados = () => {
    console.log(vehiculos.filter((x:any)=>x.checked))
  } */

  return (
    <div>
      <Drawer className="drawerMensajerosDisponibles"
        title={`Total servicios por ruta - ${bodega}`}
        width={350}
        placement="left"
        closable={false}
        onClose={() => props.setDrawerMensajerosVisible(false)}
        visible={props.drawerMensajerosVisible}
      >
        <Row gutter={[16, 16]}>
          <Col span={16}><b>Mensajero</b></Col>
          <Col span={6}><b>Cantidad</b></Col>
        </Row>
        {serviciosMensajeros.map((x: any) =>
          // mensajero.cantidad > 0 &&
          <Row gutter={[16, 16]}>
            {/* <Col span={2}>
                <Checkbox 
                  checked={mensajero.checked} 
                  onChange={()=>checkedHandler(index)} 
                  />
                </Col> */}
            <Col span={16}>{x.mensajero}</Col>
            <Col span={6}>
                {x.total} 
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24}>
          </Col>
        </Row>
      </Drawer>
    </div>
  );
};

export default DrawerMensajerosDisponibles;
