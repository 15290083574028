import { Button, Form, Input, message, Modal,  Row, Col } from 'antd'
import React, { useRef, useState } from 'react'
import billsAPI from '../../Api/api-bills';
import { BillStatus } from '../../Models/Bill';

type CreateNewsModalProps = {
    isModalVisible: any,
    setIsModalVisible: any,
    newsModal: BillStatus,
    // getNewsCourier : any
    statusSelected: string,
    getServices: Function
}

const { TextArea } = Input;

export const CreateNewsModal = ({ isModalVisible, setIsModalVisible, newsModal, statusSelected, getServices }: CreateNewsModalProps) => {


    const [news, setNews] = useState<News>({
        id: 0,
        billId: newsModal.id,
        description: '',
        solution: '',
        newsBillingType: 'Novedad',
        changeStatus: true,
        userID: Number(localStorage.userID)
    });

    const formNewsRef = useRef<any>();
    const [loading, setloading] = useState(false);
    const handleSubmit = async () => {
        if(news.description !== ''){
            setloading(true);
            try {
               news.billId = newsModal.id;
               await billsAPI.createBillsNews(news);
               message.success(`Se creo correctamente la novedad`);
               getServices(statusSelected);
            } catch (error) {
                message.error(`No se creo la novedad. ${error}`);
            }
            setIsModalVisible(false);
            setloading(false);
            formNewsRef.current.resetFields();
        } else {
            message.warn(`Escriba la novedad.`);
        }
        // if (Object.keys(news).length === 0) {
        //     message.error(`Complete todos los campos`);
        //     return;
        // }

        // if (news.description !== '') {
        //     setloading(true);
        //     try {
        //         let payload = {
        //             billId: newsModal.id,
        //             changeStatus: true,
        //             ...news
        //         }
        //         await billsAPI.createBillsNews(payload);
        //         message.success('Se creo correctamente la novedad');
        //         // getNewsCourier();
        //     } catch (error) {
        //         message.error(`No se pudo crear la novedad`);
        //     }
        //     setloading(false);
        //     setIsModalVisible(false);
        //     formNewsRef.current.resetFields();
        // } else {
        //     message.error(`Complete todos los campos.`)
        // }
    }

    const handleInput = ({ target }: any) => {
        const { name, value } = target;
        setNews({
            ...news,
            [name]: value
        })
    }


    return (
        <>
            <Modal
                title="Añadir Novedad"
                visible={isModalVisible}
                onOk={handleSubmit}
                onCancel={() => setIsModalVisible(false)}
                footer={[
                    <Button type="ghost" onClick={() => setIsModalVisible(false)}>Cancelar</Button>,
                    <Button type="primary" onClick={handleSubmit} loading={loading}>Guardar</Button>
                ]}
            >
                <Form
                    ref={formNewsRef}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="description"
                                rules={[{ required: true, message: 'Por favor escriba la descripción de la novedad' }]}
                            >
                                <TextArea rows={4} placeholder="Escriba su novedad..." onChange={handleInput} name="description" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}



type News = {
    id?: number,
    billId?: number,
    newsBillingType?: string,
    description?: string,
    solution?: string,
    created_at?: string,
    update_at?: string,
    userID?: number,
    changeStatus?: boolean
}