import { useContext, useEffect, useRef, useState } from "react";

// Ant Desing
import { message } from "antd";

// Swal
import Swal from "sweetalert2";

// API
import usersAPI from "../../../Api/api-users";
import productsAPI from "../../../Api/api-products";

// Hooks
import filterContext from "../../../Hooks/filterContex";

// Models
import { User } from "../../../Models/User";
import { CityByProvince, cityModel, Provinces } from "../../../Models/City";
import citiesAPI from "../../../Api/api-cities";
import { CompanyTrasnport } from "../../../Models/CompanyTransport";
import billsAPI from "../../../Api/api-bills";
import utils from "../../../utils";

// uniqid
var uniqid = require('uniqid');

export const useFormService = () => {

    const filters = useContext(filterContext);

    // Form Controllers
    const [sellersLoading, setSellersLoading] = useState(false);
    const [sellers, setSellers] = useState<User[]>([]);
    const getSellers = async (customerId: number) => {
        setSellersLoading(true);
        try {
            const sellers = await usersAPI.getSellers(customerId);
            setSellers(sellers);
        } catch (error) {
            message.error(`No se pudo obtener los vendores. ${error}`)
        }
        setSellersLoading(false);
    }

    const [provinces, setProvinces] = useState<Provinces[]>([]);
    const [provinceLoading, setProvinceLoading] = useState(false);
    const getProvinces = async() => {
        setProvinceLoading(true);
        try {
            const arrayProvinces = await citiesAPI.getProvinces();
            const arrayProvinceFilter = arrayProvinces.map(p => {
                return {
                    ...p,
                    warehouse: p.id == 1 ? true : p.id == 11 ? true : p.id == 14 ? true : p.id == 23 ? true : p.id == 33 ? true :p.id == 34 ? true :p.id == 35 ? true : false
                }
            })
            setProvinces(arrayProvinceFilter.filter(p => p.warehouse === true))
        } catch (error) {
            message.error(`No se obtuvieron los departamentos. ${error}`);
        }
        setProvinceLoading(false);
    }

    const [cities, setCities] = useState<CompanyTrasnport[]>([]);
    const [citiesLoading, setCitiesLoading] = useState(false);
    const getCities = async( provinceId : number ) => {
        setCitiesLoading(true);
        try {
            const cities = await citiesAPI.getCitiesByProvince(provinceId, 1);
            setCities(cities);
        } catch (error) {
            message.error(`No se pudieron cargar las ciudades. ${error}`);
        }
        setVisibleCities(false);
        setCitiesLoading(false);
    }

    const [companyTransports, setCompanyTransports] = useState<CompanyTrasnport[]>([]);
    const [companyLoading, setCompanyLoading] = useState(false);
    const getCompanyTransports = async() => {
        setCompanyLoading(true);
        try {
            const transports = await citiesAPI.getCompanyTrasnports();
            setCompanyTransports(transports);
        } catch (error) {
            message.error(`No se obtuvieron las trasnportadoras. ${error}`)
        }
        setCompanyLoading(false);
    }

    // Products Service
    const formProductsRef = useRef<any>()
    const [arrayProducts, setArrayProducts] = useState<BillProduct[]>([]);
    const [billProduct, setBillProduct] = useState<BillProduct>({});
    const [products, setProducts] = useState<Product[]>([])
    const [productsLoading, setProductsLoading] = useState(false);

    const handleProductsChange = (value: any, name: string) => {
        setBillProduct({
            ...billProduct,
            [name]: value
        })
    }

    const addProduct = () => {
        if (Object.entries(billProduct).length !== 0) {
            if (!billProduct.quantity) {
                billProduct.quantity = 1;
            }
            let prod = products.filter(x=>x.name == billProduct.productId);
            debugger
            if(prod.length > 0)
            {
                billProduct.code = prod[0].code;
            }
            
            billProduct.uniqid = uniqid();
            setArrayProducts( prevState => [...prevState, billProduct])
            setBillProduct({});
            formProductsRef.current.resetFields();
        } else {
            message.warn('Seleccione un producto');
        }
    }

    const removeProduct = ({ data }: any) => {
        let newArr = arrayProducts.filter(bill => bill.uniqid !== data.uniqid);
        setArrayProducts(newArr);
    }

    const getProducts = async (customerId: number) => {
        setProductsLoading(true);
        try {
            const products = await productsAPI.getProductsByCustomerId(customerId);
            setProducts(products);
        } catch (error) {
            message.error(`No se pudo obtener los vendores. ${error}`)
        }
        setProductsLoading(false);
    }

    // Form Service
    const [billData, setBillData] = useState<any>({});
    const [billLoading, setBillLoading] = useState(false);
    const [visibleCities, setVisibleCities] = useState(true);
    const handleInputChange = (event: any) => {
        const { name, value } = event.target;
        setBillData({
            ...billData,
            [name]: value
        });
    }

    const handleSelectChange = (value: any, name: string) => {
        if(name === 'provinceId'){
           setVisibleCities(true);
           getCities(value);
        }
        setBillData({
            ...billData,
            [name]: value
        });
    }

    const handleSubmit = async() => {
        let newArrProd = arrayProducts.map( prod => {
            return {
                productId : prod.code,
                quantity : prod.quantity
            }
        })
        billData.bill_Products = newArrProd;
        billData.updated_by = localStorage.userID;
        billData.confirmed = false;
        billData.statusId = 1;
        billData.customerId = filters.customerId;
        billData.service_Description = 'ENTREGAR PEDIDO'
        if(billData.bill_Products.length !== 0){
            setBillLoading(true);
            try {
                    let arrayBill = [ billData ];
                    // console.log(arrayBill)
                await billsAPI.PostBills(arrayBill);
                console.log('entró')
                Swal.fire(
                    'Completado',
                    'Los datos fueron guardados',
                    'success',
                ).then((result) => {
                    window.location.href = "/"
                });
            } catch (error) {
                utils.messageError(error);
            }
            setBillLoading(false);
        } else {
            message.warn('Agrege productos al pedido.')
        }
      
    };

    const onFinishFailed = () => {
        message.error(`Campos sin completar`);
    }


    useEffect(() => {
        getSellers(filters.customerId);
        getProducts(filters.customerId);
        getProvinces();
        getCompanyTransports();
    }, [filters.customerId])


    return {
        sellers,
        sellersLoading,
        handleInputChange,
        handleSelectChange,
        products,
        productsLoading,
        handleProductsChange,
        addProduct,
        arrayProducts,
        formProductsRef,
        removeProduct,
        onFinishFailed,
        provinces,
        provinceLoading,
        cities,
        citiesLoading,
        companyLoading,
        companyTransports,
        visibleCities,
        handleSubmit,
        billLoading
    }
}

// Models Component

type BillData = {
    id?: number,
    name?: string,
    address?: string,
    phone?: string,
    document?: string,
    email?: string,
    company?: string,
    typeConsignedValue?: string,
    consigned_Value?: number,
    created_at?: string,
    updated_by?: number,
    confirmed?: boolean,
    observations?: string,
    statusId?: number,
    sellerId?: number,
    provinceId?: number,
    cityId?: number,
    company_TransportId?: number,
    customerId?: number,
    collect_Value?: number,
    number_account?: string,
    bill_Products?: Product[]
}

type Product = {
    id?: number,
    name?: string,
    palomaName?: string,
    code?: string,
    quantity?: number
    selling_price?: number,
    customerId?: number,
}

type BillProduct = {
    uniqid?: string,
    productId?: string,
    quantity?: number,
    code?:string
}

