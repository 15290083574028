import { Form } from "antd";
import { useState } from "react";
import BillNewsAPI from "../Api/api-billNews";
import billsAPI from "../Api/api-bills";
import { BillGet, BillPost, getBillsById } from "../Models/Bill";
import { BillNews } from "../Models/BillNews";
import utils from "../utils";
import { useAlertMessage } from "./useAlertMessage";
import { useManageErrors } from "./useManageAPIErrors";

export const useModalDetailService = (setShowDetailModal:Function,getHistoryServices? : any) => {

    const {onError} = useManageErrors();
    const {Confirm} = useAlertMessage();
    const {ShowLoading,CloseSwal} = useAlertMessage();

    const [visible, setVisible] = useState<boolean>(false);
    const [billDetailData, setBillDetailData] = useState<BillGet>();
    const [billNews, setBillNews] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [guia, setGuia] = useState<string>("");
    const [PalomaNews, setPalomaNews]:any = useState([]);
    const [form] = Form.useForm<BillGet>();


    const closeModal = () => {
        setShowDetailModal(false);
        form.setFieldsValue({})
        setGuia("");
    }

    const getServiceData = (idService:number,guia:string) => {
        ShowLoading('Cargando Datos...');
        let data:any = {}
        if(guia != "")
        {
           let el: getBillsById = {
                guia: guia
            }
            data = el;
        }
        else{
            let el: getBillsById = {
                id: idService
            }
            data = el;
        }
        
        billsAPI.getBillsById(data).then(res=>{
            let tipo_Pago:string = res.typeConsignedValue;  
            res.typeConsignedValue = tipo_Pago == 'Pago contra entrega' ? 'Con Recaudo' : 'Sin Recaudo'
            form.setFieldsValue(res);
            setGuia(res.guia);
            setPalomaNews(res.billNews.filter((x : any) => x.newsBillingType != 'Novedad'))
            let resFilter = res;
            resFilter.billNews = res.billNews.filter((x : any) => x.newsBillingType === 'Novedad')
            setBillDetailData(resFilter);
            CloseSwal();
            setLoading(false);
        })
        .catch(error => {onError(error);CloseSwal();});
    }

    const validations = (data: BillPost) => {
        if(data.bill_Products.length == 0)
        {
            utils.messageError('Debe agregar al menos un producto');
            setLoading(false);
            return false;
        }
        if(data.typeConsignedValue == 'Con Recaudo' && data.collect_Value == 0)
        {
            utils.messageError('Indique cual es el valor a recaudar');
            setLoading(false);
            return false;
        }
        return true;
    }

    const onFinish = (values: BillPost) => {
        setLoading(true)
        console.log('Success:', values);
        values.updated_by = localStorage.userID;
        if (validations(values)) {
            ShowLoading('Actualizando...');
             billsAPI.UpdateBills(values).then(res => {
                setLoading(false);
                Confirm(200,'El pedido fue actualizado');
            })
            .catch(error => {onError(error);setLoading(false)});
        }
    };

    const saveModalBillNews = (index:number) => {
        console.log(billDetailData);
        let data = {...billDetailData as any}
        BillNewsAPI.updateBillNews(data.billNews[index]).then(res=>{
            Confirm(200,'Los datos fueron guardados');
            getHistoryServices()
            
        })
    }

    const updateSolution = (index:number,solution:string) => {
        let data = {...billDetailData as any}
        let newState = [...billDetailData?.billNews as any];
        newState[index].solution = solution;
        data.billNews = newState;
        setBillDetailData({
            ...data,
        });
        // Comentario
    }


    const onCancelOrder = (billId:number) => {
        console.log(billId)
        billsAPI.cancelBill(billId,"Cancelado").then(res=>{
            Confirm(200,'El pedido fue cancelado');
        })
        .catch(error => onError(error));
    }

    return{
        closeModal,
        getServiceData,
        billDetailData,
        billNews,
        loading,
        onFinish,
        form,
        onCancelOrder,
        updateSolution,
        saveModalBillNews,
        guia, 
        setGuia,
        PalomaNews
    }
}