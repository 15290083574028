import { message } from "antd";
import moment from "moment";
import { useState } from "react"
import billsAPI from "../../Api/api-bills";
import customerAPI from "../../Api/api-customers";
import WarehouseAPI from "../../Api/api-warehouse";
import { Customer } from "../../Models/Customer";

export interface warehouse {
    id:number,
    name:string,
}

export const useLayout = () => {

    const [collapsed, setCollapsed] = useState<boolean>(true);
    const [selectedKey, setSelectedKey] = useState<number>();
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<number>(localStorage.customerId);
    const [selectedWareHouse, setSelectedWareHouse] = useState<number>(0);
    const [selectedDate, setSelectedDate] = useState([moment(new Date(), "YYYY-MM-DD").add(-15,"d"), moment(new Date(), "YYYY-MM-DD").add(1,"d")]);
    //const [selectedDate, setSelectedDate] = useState([moment(new Date(), "YYYY-MM-DD"), moment(new Date(), "YYYY-MM-DD")]);
    const [WarehousesList, setWarehousesList] = useState<warehouse[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);


    const  onCollapse = (collapsed:boolean) => {
        setCollapsed(collapsed );
      };

    const ChangeTab = (selected:any) => {
        setSelectedKey(selected.key);
    }

    const getCustomers = () => {
        customerAPI.getCustomers().then(res => {
            setCustomers(res);
        })
    }

    const getWarehouse = () => {
        WarehouseAPI.getWarehouse().then(res=>{
            setWarehousesList(res);
        })
    }

    const UpdateBillStatus = () => {
        setLoadingStatus(true);
        message.warn('Actualizando estados... Este proceso puede tardar varios minutos.',8);
        billsAPI.UpdateBillStatus().then(res=>{
            message.warn('Los estados fueron actualizados');
            setLoadingStatus(false);
        })
    }

    return{
        collapsed,
        onCollapse,
        ChangeTab,
        selectedKey,
        getCustomers,
        customers,
        setSelectedCustomer,
        selectedCustomer,
        setSelectedWareHouse,
        selectedWareHouse,
        setSelectedDate,
        selectedDate,
        WarehousesList,
        getWarehouse,
        UpdateBillStatus,
        loadingStatus
    }
}