import React, { useEffect, useState } from 'react';

// Moment js
import moment from 'moment';

// Ant Desing
import { message, Modal, Spin } from 'antd';

// Logo
import logo from '../../Assets/Logo.png';
import './ModalGuia.css';
import billsAPI from '../../Api/api-bills';

// Barcode
var Barcode = require('react-barcode');

export const ModalGuia = ({ guiaSelected, modalGuiaVisible, setModalGuiaVisible }) => {


    const [guia, setGuia] = useState({});
    const [products, setProducts] = useState('')
    const [billNewsGuia, setBillNewsGuia] = useState([]);
    const [loadingGuia, setLoadingGuia] = useState(false);
    const getDateGuia = async ({ id, guia, products }) => {
        debugger
        let payload = {
            id,
            guia
        }
        setLoadingGuia(true);
        try {
            const bill = await billsAPI.getBillsById(payload);
            let billNews = bill.billNews?.filter((bill) => bill.newsBillingType === 'Novedad Cliente');
            
            setGuia(bill);
            setBillNewsGuia(billNews);
            setProducts(products)
        } catch (error) {
            message.error(`No se pudo cargar la información de la guia. ${error}`);
        }
        setLoadingGuia(false);
    }


    useEffect(() => {
        let mounted = true;
        if (mounted) {
            getDateGuia(guiaSelected);
        }
        return () => {
            mounted = false;
        }
    }, [guiaSelected])

    return (
        <Modal
            title={`Guia: ${guia.guia}`}
            visible={modalGuiaVisible}
            onOk={() => setModalGuiaVisible(false)}
            onCancel={() => setModalGuiaVisible(false)}
            width={1000}
        >
            <Spin tip="Cargando Guia..." spinning={loadingGuia}>
                <h6>Orden {guia.delivery_Order}</h6>
                <table border="1" width="100%" className="mt-3">
                    <tr>
                        <td>
                            <Barcode
                                value={guia.guia}
                                height={50}
                                textPosition="top"

                            />
                        </td>
                        <td style={{ minWidth: 100 }}>
                            <div>
                                <div className="titulo-campo-guia">Fecha:</div>
                                <div>{moment(guia.created_at).add(-5, 'hours').format("YYYY-MM-DD HH:mm")}</div>
                            </div>
                        </td>
                        <td>
                            <div className="titulo-campo-guia datos-contacto">
                                Contacto ( +57) 300 733 21 87<br />
                                contacto@palomamensajeria.com<br />
                                {
                                    guia.city
                                }
                            </div>
                        </td>
                        <td>
                            <div className="titulo-campo-imagen">
                                <img src={logo} alt="logo" className="img-responsive" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                    <td colspan="1">
                            <div className="titulo-campo-guia">Nombre</div>
                            <div className="lable-guia">{guia.customer}</div>
                        </td>
                        <td colspan="1">
                            <div className="titulo-campo-guia enlinea">DESCRIPCIÓN SERVICIO</div>
                            <div className="lable-guia enlinea">{guia.service_Description}</div>
                        </td>
                        <td colspan="2">
                            <div ></div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <div className="titulo-campo-guia fondogris"></div>
                        </td>
                        <td colspan="2">
                            <div className="titulo-campo-guia fondogris"></div>
                        </td>
                    </tr>
                    <tr>
                    <td>
                            <div className="titulo-campo-guia">Nombre</div>
                            <div className="lable-guia">{guia.name}</div>
                        </td>
                        <td>
                            <div className="titulo-campo-guia">Teléfono</div>
                            <div className="lable-guia">{guia.phone} - {guia.plate}</div>
                        </td>
                      
                        <td colspan="2">
                            <div className="titulo-campo-guia">Direccion</div>
                            <div className="lable-guia">{guia.address}</div>
                        </td>
                    </tr>
                    <tr>
                    <td>
                            <div className="titulo-campo-guia">Ciudad</div>
                            <div clsassName="lable-guia">{guia.city}</div>
                        </td>
                        <td>
                            <div className="titulo-campo-guia">Valor a recoger</div>
                            <div className="lable-guia">{guia.collect_Value}</div>
                        </td>
                        <td colspan="2">
                            <div className="titulo-campo-guia">Productos</div>
                            <div className="lable-guia">{guia.products}</div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <div className="titulo-campo-guia">Observaciones del servicio</div>
                            <div className="lable-guia">{guia.observations}</div>
                        </td>
                        <td colSpan="2">
                            <div className="titulo-campo-guia">Observaciones de entrega</div>
                            <ul className="mr-5">
                                {billNewsGuia?.map(bill =>
                                    <li key={bill.id}> {bill.description} ({moment(bill.created_at).format("YYYY-MM-DD")})</li>
                                )}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="titulo-campo-guia">NOMBRE</div>
                            <div className="lable-guia"><br/></div>
                        </td>
                        <td>
                            <div className="titulo-campo-guia">FIRMA</div>
                            <div className="lable-guia"><br/></div>
                        </td>
                      
                        <td >
                            <div className="titulo-campo-guia">CÉDULA</div>
                            <div className="lable-guia"><br/></div>
                        </td>
                        <td >
                            <div className="titulo-campo-guia">FECHA</div>
                            <div className="lable-guia"><br/></div>
                        </td>
                    </tr>
                </table>
            </Spin>

        </Modal>
    )
}
