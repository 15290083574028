import * as React from 'react';
import { IHomeComponentProps } from './IHomeComponentProps';
import './HomeComponent.css';

// React-Bootstrap
import {  Col, Container, Row } from 'react-bootstrap';

// Icons Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faTruckLoading, faWarehouse } from '@fortawesome/free-solid-svg-icons';

// Components
import HeaderComponent from '../HeaderComponent/HeaderComponent';
import FooterComponent from '../FooterComponent/FooterComponent';
import { Link } from 'react-router-dom';



const HomeComponent: React.SFC<IHomeComponentProps> = (props) => {
  return (
    <React.Fragment>
     {/*  <HeaderComponent />

      <Container style={{marginTop: '150px'}}>
        <Row>
          <Col lg={6}>
            <Link to="/ManagementComponent" className="btn btn-secondary btn-lg btn-block">
              Bodegas Principales
              <FontAwesomeIcon className="ml-2" icon={faWarehouse} />
            </Link>
          </Col>
          <Col lg={6}>
            <Link to="/DepartamentComponent" className="btn btn-secondary btn-lg btn-block">
              Domicilios
              <FontAwesomeIcon className="ml-2" icon={faTruckLoading} />
            </Link>
          </Col>
        </Row>
      </Container>
 */}


      {/* <FooterComponent /> */}
    </React.Fragment>
  );
};

export default HomeComponent;