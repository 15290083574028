import React, { useContext } from 'react';
import { LiquidationAdmin } from './LiquidationAdmin';


export const LiquidationService = () => {

    return (
        <React.Fragment>
            {localStorage.role == "1" && 
                <LiquidationAdmin />
            }

            {localStorage.role == "3" &&
                <div>Liquidación Cliente</div>
            }
        </React.Fragment>
    )
}
