import { useState } from "react";
import billsAPI from "../../../Api/api-bills";
import { BillGet } from "../../../Models/Bill";

export const useSearch = () => {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedSearchType, setSelectedSearchType] = useState<string>("Guia");
    const [results, setResults] = useState<BillGet[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [HasResults, setHasResults] = useState<boolean>(true);

    const handleChangeSelect = (e:any) => {
        setSelectedSearchType(e);
    }

    const closeModal = () =>{
        setIsModalVisible(false);
    }
    
    const showModal = () =>{
        setIsModalVisible(true);
    }

    const gotoUrl = (data:any) => {
        window.open(`/DetailBill?id=${data.data.id}`);
    }

    const SearchData = () =>{
        setLoading(true);
        setHasResults(true);
        billsAPI.SearchBill(selectedSearchType,searchText).then(res=>{
            setLoading(false);
            setResults(res);
            if(res.length == 0)
            {
                setHasResults(false);
            }
        })
    }


    return{
        handleChangeSelect,
        loading,
        closeModal,
        isModalVisible,
        showModal,
        selectedSearchType,
        setSearchText,
        SearchData,
        results,
        HasResults,
        gotoUrl
    }
}