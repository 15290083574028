import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Drawer, Form, message, Row, Select } from 'antd'
import React, { useRef, useState } from 'react'
import billsAPI from '../../Api/api-bills'
import { BillStatus } from '../../Models/Bill'

type DrawerRoutingProps = {
    drawerRoutingVisible: boolean,
    setDrawerRoutingVisible: Function
    selectedRowKeys: BillStatus[],
    setSelectedRowKeys: any,
    messagers: any,
    statusSelected: string,
    getServices : Function
}

// Ant Controls
const { Option } = Select;

export const DrawerRouting = ({ drawerRoutingVisible, setDrawerRoutingVisible, selectedRowKeys, setSelectedRowKeys, messagers, statusSelected, getServices }: DrawerRoutingProps) => {

    const formSelectRef = useRef<any>();

    const onClose = () => {
        setDrawerRoutingVisible(false);
    }

    const removeSelectedKey = (id: any) => {
        let newArr = selectedRowKeys.filter((x: any) => x.id !== id);
        if (newArr.length === 0) {
            message.warn('Seleccione servicios para enrutar.');
            setDrawerRoutingVisible(false)
            return;
        }
        setSelectedRowKeys(newArr);
    }

    const [messager, setMessager] = useState('');
    const [routingLoading, setRoutingLoading] = useState(false);
    const routingServices = async () => {
        if (messager !== '') {
            setRoutingLoading(true);
            try {
                selectedRowKeys.forEach(async(bill) => {
                    let newBill = bill;
                    newBill.zone = messager;
                    newBill.statusId = 8;
                    await billsAPI.UpdateBillHeader(newBill);
                })
                getServices(statusSelected);
                message.success('Se enrutaron los servicios correctamente.');
            } catch (error) {
                message.error(`No se pudo enrutar masivamente. ${error}`);
            }
            formSelectRef.current.resetFields();
            setRoutingLoading(false);
            setDrawerRoutingVisible(false);
        } else {
            message.error('Seleccione un mensajero para poder enrutar');
        }
    }

    const selectChange = (value: string) => {
        setMessager(value);
    }

    return (
        <Drawer
            title="Enrutado Masivo"
            placement="left"
            closable={true}
            onClose={onClose}
            width={450}
            visible={drawerRoutingVisible}
        >
            <Form layout="vertical" ref={formSelectRef}>
                <Row>
                    <Col span={24}>
                        <Form.Item label="Mensajero" name="messager">
                            <Select placeholder="Seleccione un mensajero" onChange={selectChange}>
                                {messagers.map(({ id, name }: any) =>
                                    <Option key={id} value={name}>{name}</Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            {selectedRowKeys.map(({ id, name, address, phone }: any) =>
                <>
                    <Row key={id} gutter={[48, 48]} className="mt-3">
                        <Col span={12}>
                            <p>NOMBRE: {name}</p>
                            <p>DIRECCIÓN: {address}</p>
                            <p>TEl: {phone}</p>
                        </Col>
                        <Col span={6}>
                            <DeleteOutlined style={{ color: 'red' }} onClick={() => removeSelectedKey(id)} />
                        </Col>
                    </Row>
                    <Divider></Divider>
                </>
            )}

            <Button type="primary" icon={<SaveOutlined />} loading={routingLoading} className="mt-5" onClick={routingServices}>Enrutar Servicios</Button>
        </Drawer>
    )
}
