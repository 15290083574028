import React, { useContext, useState } from 'react';
import { Row, Col, Select, Card, Typography, Spin, Badge, DatePicker, Alert, Form, Statistic } from 'antd';
import DataGrid, {
    Column,
    FilterRow,
    Grouping,
    Selection,
    GroupPanel,
    Pager,
    Paging,
    SearchPanel,
    Export,
    HeaderFilter,
    Sorting,
    ColumnFixing,
    Editing,
    Lookup
} from 'devextreme-react/data-grid';
import { BillGet, BillStatus } from '../../Models/Bill'
import { Statuses } from '../../Models/Status'
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import filterContext from '../../Hooks/filterContex';
import { DrawerNews } from '../ScheduledServicesNew/DrawerNews';
import { ModalGuia } from '../ScheduledServicesNew/ModalGuia';
import moment from 'moment';
import { DrawerEdit } from './DrawerEdit';

const { Title, Paragraph, Text } = Typography;
const { RangePicker } = DatePicker;

type LiquidationCustomersProps = {
    services: BillStatus[],
    loadingServices: boolean,
    statuses: Statuses[],
    valorRecaudo: number,
    valorDomicilio: number,
    handleUpdate: any,
    handleChangeDate : any,
    getServices : any,
}

export const LiquidationCustomers = ({ getServices, services, loadingServices, statuses, valorDomicilio, valorRecaudo, handleUpdate, handleChangeDate }: LiquidationCustomersProps) => {

    const { warehouseId } = useContext(filterContext);
    const [filterDates, setFilterDates] = useState<any>([moment(new Date(), 'YYYY-MM-DD'), moment(new Date(), 'YYYY-MM-DD')]);

    // Modal Guia
    const [modalGuiaVisible, setModalGuiaVisible] = useState(false);
    const [guiaSelected, setGuiaSelected] = useState<BillStatus>({});

    const showModalGuia = (data: BillStatus) => {
        setGuiaSelected(data);
        setModalGuiaVisible(true);
    }

    // Drawer News
    const [visibleDrawerNews, setVisibleDrawerNews] = useState(false);
    const [billSelected, setBillSelected] = useState<any>({});
    const showDrawerNews = async (data: BillStatus) => {
        setBillSelected(data);
        setVisibleDrawerNews(true);
    }

    // Drawer Edit 
    const [visibleDrawerEdit, setVisibleDrawerEdit] = useState(false);
    const showDrawerEdit = (data : any) => {
        setBillSelected(data);
        setVisibleDrawerEdit(true);
    }

    // Custom Cells
    const guiaCell = ({ data }: any) => {
        if (data.totalPendingBillNews > 0) {
            return (
                <Badge color="red" text={<a onClick={() => showModalGuia(data)}>{data.guia}</a>}/>
            )
        } else {
            return (
                <a onClick={() => showModalGuia(data)}>{data.guia}</a>
            )
        }
    }

    const detailCell = ({ data }: any) => {
        return (
            <ul>
                <li><b>Nombre: </b>{`${data.name}`}</li>
                <li><b>Cel: </b>{`${data.phone}`}</li>
                <li><b>Dir: </b>{`${data.address}`}</li>
                <li><b>Ciu: </b>{`${data.city}`}</li>
            </ul>
        )
    }

    const newsCell = ({ data }: any) => {
        if (data.totalPendingBillNews > 0) {
            return (
                <Badge color="red" text={<SearchOutlined onClick={() => showDrawerNews(data)} />}/>
            )
        } else {
            return (
                null
            )
        }
    }

    const editDrawer = ({ data } : any) => {
        return (
            <EditOutlined  onClick={() => showDrawerEdit(data)} />
        )
    }

    const colorValue = ({ data }: any) => {
        if (data.statusId == 5) {
            return (
                <Text type="danger">${data.collect_Value}</Text>
            )
        } else {
            return (
                <Text>${data.collect_Value}</Text>
            )
        }   
    }

    const onExporting = (e:any) => {  
        e.component.beginUpdate();  
        e.component.columnOption("Novedades", "visible", false);  
        e.component.columnOption("Editar", "visible", false);  
        e.component.columnOption("Novedad", "visible", true);  
        e.component.columnOption("Guia", "visible", true);
        e.component.columnOption("Guias", "visible", false);
        e.component.columnOption("Detalle", "visible", false);
        e.component.columnOption("Nombre", "visible", true);
    } 
    const onExported = (e:any) =>{  
        e.component.columnOption("Novedades", "visible", true);  
        e.component.columnOption("Editar", "visible", true); 
        e.component.columnOption("Novedad", "visible", false);  
        e.component.columnOption("Guia", "visible", false);
        e.component.columnOption("Guias", "visible", true);
        e.component.columnOption("Detalle", "visible", true);
        e.component.columnOption("Nombre", "visible", false);
        e.component.endUpdate();  
    }

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    })

    return (
        <React.Fragment>
            {warehouseId !== 0
                ?
                (
                    <>
                        <Form layout="horizontal">
                           
                        </Form>
                        <Row justify="space-between" style={{ marginBottom: '12px' }}>
                            <Col>
                                <Card style={{ width: 300 }} /* loading={loading} */>
                                    <Spin tip="Cargando Valor..." spinning={loadingServices}>
                                        <Statistic
                                            title="Valor recaudo"
                                            value={valorRecaudo}
                                            valueStyle={{ color: '#3f8600' }}
                                            suffix="$"
                                        />
                                    </Spin>
                                </Card>
                            </Col>
                            <Col>
                                <Card style={{ width: 300 }} /* loading={loading} */>
                                    <Spin tip="Cargando Valor..." spinning={loadingServices}>
                                        <Statistic
                                            title="Valor Domicilio"
                                            value={valorDomicilio}
                                            valueStyle={{ color: '#3f8600' }}
                                            suffix="$"
                                        />
                                    </Spin>
                                </Card>
                            </Col>
                            <Col>
                                <Card style={{ width: 300 }} /* loading={loading} */>
                                    <Spin tip="Cargando Valor..." spinning={loadingServices}>
                                        <Statistic
                                            title="Valor Liquidar"
                                            value={valorRecaudo - valorDomicilio}
                                            valueStyle={{ color: '#3f8600' }}
                                            suffix="$"
                                        />
                                    </Spin>
                                </Card>
                            </Col>
                        </Row>
                        <Spin tip="Cargando..." spinning={loadingServices}>
                            {services.length !== 0
                                ?
                                (
                                    <div id="data-grid-demo">
                                        <DataGrid
                                            id="gridContainer"
                                            dataSource={services}
                                            onRowUpdated={handleUpdate}
                                            onExporting={onExporting}
                                            onExported={onExported}
                                            showBorders={true}
                                            wordWrapEnabled={true}
                                        // rowAlternationEnabled={true}
                                        >
                                            <SearchPanel visible={true} highlightCaseSensitive={true} />
                                            <Grouping autoExpandAll={true} />
                                            <FilterRow visible={true} />
                                            <GroupPanel visible={true} />
                                            <Grouping autoExpandAll={true} />
                                            <Export enabled={true} allowExportSelectedData={false} />
                                            <HeaderFilter visible={true} />
                                            <Sorting mode="multiple" />
                                            <ColumnFixing enabled={true} />
                                            <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                                            <Grouping autoExpandAll={false} />

                                            
                                            <Column caption="Guia" dataField="guia" visible={false} />
                                            <Column dataField="businessName" caption="R. social" dataType="string" />
                                            <Column caption="Guias" allowEditing={false} cellRender={guiaCell} />
                                            <Column cellRender={detailCell} caption="Detalle" />
                                            <Column dataField="statusId" dataType="number" caption="Estado" allowEditing={false}>
                                                <Lookup dataSource={statuses} displayExpr="name" valueExpr="id" />
                                            </Column>
                                            <Column dataField="plate" caption="Placa" dataType="string" />
                                            <Column dataField="name" caption="cliente" dataType="string" />
                                            <Column dataField="createdBy" caption="Creado por" dataType="string" />
                                            <Column dataField="delivered_at" caption="Fecha" dataType="date" allowEditing={false} />
                                            <Column cellRender={newsCell} caption="Novedades" alignment="center" />
                                            <Column dataField="products" caption="Productos" dataType="string" allowEditing={false} />
                                            <Column dataField="service_Value" caption="Valor servicio" dataType="number" allowEditing={true}                                          />
                                            <Column dataField="collect_Value" caption="Valor a cobrar" dataType="number" allowEditing={true}  />
                                            <Column caption="Editar" cellRender={ editDrawer } alignment="center"/>
                                            <Column caption="Novedad" dataField="bill_News" visible={false}/>
                                            
                                           

                                            <Pager allowedPageSizes={[5, 10, 50, 100]} showPageSizeSelector={true} />
                                            <Paging defaultPageSize={5} />
                                            <Editing
                                                mode="cell"
                                                allowUpdating={true}
                                            />
                                        </DataGrid>
                                    </div>
                                )
                                :
                                (
                                    <Alert
                                        message="No hay servicios que mostrar"
                                        description="Seleccione una bodega diferente a Nacional y un cliente"
                                        type="warning"
                                        showIcon
                                    />
                                )
                            }
                        </Spin>

                    </>
                )
                :
                (
                    <Alert
                        message="No hay servicios con esta bodega"
                        description="Seleccione una bodega diferente a Nacional"
                        type="warning"
                        showIcon
                    />
                )
            }

            <DrawerNews

                visibleDrawerNews={visibleDrawerNews}
                setVisibleDrawerNews={setVisibleDrawerNews}
                billSelected={billSelected}
            />

            <ModalGuia
                guiaSelected={guiaSelected}
                modalGuiaVisible={modalGuiaVisible}
                setModalGuiaVisible={setModalGuiaVisible}
            />
            
            <DrawerEdit 
                visibleDrawerEdit={visibleDrawerEdit}
                setVisibleDrawerEdit={setVisibleDrawerEdit}
                billSelected={billSelected}
                setBillSelected={setBillSelected}
                getServices={getServices}
            />
        </React.Fragment>
    )
}
