import { message } from "antd";
import { useContext, useEffect, useRef, useState } from "react"
import billsAPI from "../../../Api/api-bills";
import citiesAPI from "../../../Api/api-cities";
import customerAPI from "../../../Api/api-customers";
import noteBookApi from "../../../Api/api-notebook";
import filterContext from "../../../Hooks/filterContex";
import { cityModel, Provinces } from "../../../Models/City";
import { CompanyTrasnport } from "../../../Models/CompanyTransport";
import { Customer } from "../../../Models/Customer";
import { NoteBook } from "../../../Models/NoteBook";

export const useRequestForm = () => {

    const filters = useContext(filterContext);

    /* Libretas */

    // Origen
    const [dateOrigin, setDateOrigin] = useState<NoteBook>({
        ubication: 'Origen',
    });
    const [dateDestination, setDateDestination] = useState<NoteBook>({
        ubication: 'Destino',
    });


    const handleDateOriginChange = (event: any) => {
        const { name, value } = event.target;
        setDateOrigin({
            ...dateOrigin,
            [name]: value
        })
    }
    const handleDateDestinationChange = (event: any) => {
        const { name, value } = event.target;
        setDateDestination({
            ...dateDestination,
            [name]: value
        })
    }

    const handleOriginSelectChange = (value: any, name: string) => {
        if (name === 'province') {
            let province = provinces.find(p => p.name === value);
            if (province !== undefined) {
                getCities(province.id)
            }
            // setDisabledCities(true);
        }
        setDateOrigin({
            ...dateOrigin,
            [name]: value
        })
    }
    const handleDestinationSelectChange = (value: any, name: string) => {
        if (name === 'province') {
            let province = provinces.find(p => p.name === value);
            if (province !== undefined) {
                getCities(province.id)
            }
            setDisabledCities(true);
        }
        setDateDestination({
            ...dateDestination,
            [name]: value
        })
    }

    const [originNoteBook, setOriginNoteBook] = useState<NoteBook[]>([]);
    const getOriginNoteBooks = async (customerId: number) => {
        try {
            const arrayCustomers = await customerAPI.getCustomers();
            const customer = arrayCustomers.find(c => c.id === Number(customerId))?.name;
            if (customer !== undefined) {
                const noteBook: any = await noteBookApi.getNoteBook(customer, 'Origen');
                setOriginNoteBook(noteBook);
            }
        } catch (error) {
            message.error(`No se obtuvo la libreta de origen. ${error}`);
        }
    }
    const [destinationNoteBook, setDestinationNoteBook] = useState<NoteBook[]>([]);
    const getDestinationNoteBooks = async (customerId: number) => {
        try {
            const arrayCustomers = await customerAPI.getCustomers();
            const customer = arrayCustomers.find(c => c.id === Number(customerId))?.name;
            if (customer !== undefined) {
                const noteBook: any = await noteBookApi.getNoteBook(customer, 'Destino');
                setDestinationNoteBook(noteBook);
            }
        } catch (error) {
            message.error(`No se obtuvo la libreta de origen. ${error}`);
        }
    }

    const [contactOrigin, setContactOrigin] = useState<NoteBook>({})
    const [contactDestination, setContactDestination] = useState<NoteBook>({})
    const handleNoteBook = (value: any, type: any) => {
        if( type === 'origin'){
            let contact: any = originNoteBook.find(c => c.id === value);
            setContactOrigin(contact);
            setDateOrigin({
                ...contact
            })
        }

        if(type === 'destination'){
            let contact: any = destinationNoteBook.find(c => c.id === value);
            console.log(contact)
            setContactDestination(contact);
            setDateDestination({
                ...contact
            })
        }
    
    }

    const confirm = async (type : string) => {
        if( type === 'origin'){
            try {
                let contactId: any = contactOrigin.id;
                await noteBookApi.deleteNoteBook(contactId);
                message.success(`Se eliminó correctamente el contacto de la libreta`);
                getOriginNoteBooks(localStorage.customerId);
            } catch (error) {
                message.error(`No se eliminó el contacto. ${error}`)
            }
            setDateOrigin({});
            setContactOrigin({});
        }

        if( type === 'destination'){
            try {
                let contactId: any = contactDestination.id;
                await noteBookApi.deleteNoteBook(contactId);
                message.success(`Se eliminó correctamente el contacto de la libreta`);
                getDestinationNoteBooks(localStorage.customerId);
            } catch (error) {
                message.error(`No se eliminó el contacto. ${error}`)
            }
            setDateDestination({});
            setContactDestination({});
        }
    }

    const [loadingNoteBook, setLoadingNoteBook] = useState(false);
    const [loadingNoteBookDestination, setLoadingNoteBookDestination] = useState(false);
    const addBook = async (type: string) => {
        switch (type) {
            case 'origin':
                setLoadingNoteBook(true);
                try {
                    delete dateOrigin.id;
                    await noteBookApi.postNoteBook(dateOrigin);
                    getOriginNoteBooks(localStorage.customerId)
                    message.success(`Se agrego a la libreta`);
                    setDateOrigin({});
                    setContactOrigin({});
                    setContactDestination({});
                } catch (error) {
                    message.error(`No se pudo agregar a la libreta. ${error}`);
                }
                setLoadingNoteBook(false);
                break;
            case 'destination':
                setLoadingNoteBookDestination(true);
                try {
                    delete dateDestination.id;
                    await noteBookApi.postNoteBook(dateDestination);
                    getDestinationNoteBooks(localStorage.customerId)
                    message.success(`Se agrego a la libreta`);
                    setDateDestination({});
                    setContactOrigin({});
                    setContactDestination({});
                } catch (error) {
                    message.error(`No se pudo agregar a la libreta. ${error}`);
                }
                setLoadingNoteBookDestination(false);
                break;
        }
    }



    // Servicios
    const [provinces, setProvinces] = useState<Provinces[]>([]);
    const [provinceLoading, setProvinceLoading] = useState(false);
    const [disabledCities, setDisabledCities] = useState(true);

    const getProvinces = async () => {
        setProvinceLoading(true);
        try {
            let arrayProvince = await citiesAPI.getProvinces();
            let arrayProvinceFilter = arrayProvince.map(p => {
                return {
                    ...p,
                    warehouse: p.id == 1 ? true : p.id == 11 ? true : p.id == 14 ? true : p.id == 23 ? true : false
                }
            });
            setProvinces(arrayProvinceFilter.filter(p => p.warehouse === true));
        } catch (error) {
            message.error(`No se obtuvieron los departamentos. ${error}`);
        }
        setProvinceLoading(false);
    }

    const [cities, setCities] = useState<CompanyTrasnport[]>([]);
    const [citiesLoading, setCitiesLoading] = useState(false);
    const getCities = async (provinceId: number) => {
        setCitiesLoading(true);
        try {
            const cities = await citiesAPI.getCitiesByProvince(provinceId, 1);
            setCities(cities);
        } catch (error) {
            message.error(`No se obtuvieron las ciudades. ${error}`);
        }
        setCitiesLoading(false);
        setDisabledCities(false);
    }

    const getCustomer = async (customerId: string) => {
        try {
            const arrayCustomers = await customerAPI.getCustomers();
            const customer = arrayCustomers.find(c => c.id === Number(customerId))?.name;
            if (customer !== undefined) {
                setDateOrigin({
                    ...dateOrigin,
                    customer
                })
                setDateDestination({
                    ...dateDestination,
                    customer
                })
            }
        } catch (error) {
            message.error(`No se pudo obtener el cliente. ${error}`);
        }
    }

    const [customers, setCustomers] = useState<Customer[]>([]);
    const [loadingCustomers, setLoadingCustomers] = useState(false);
    const getCustomers = async() => {
        setLoadingCustomers(true);
        try {
            const customers = await customerAPI.getCustomers();
            setCustomers(customers)
        } catch (error) {
            message.error(`No se obtuvieron los clientes. ${error}`)
        }
        setLoadingCustomers(false);
    }

    const [allCities, setAllCities] = useState<cityModel[]>([]);
    const getAllCities = async () => {
        setCitiesLoading(true);
        try {
            let arrayCities = await citiesAPI.getAllCities();
            setAllCities(arrayCities)
        } catch (error) {
            message.error(`No se obtuvieron las ciudades. ${error}`);
        }
        setCitiesLoading(false);
    } 

    const [loading, setLoading] = useState(false);
    const handleSubmit = async() => {
        setLoading(true);
        debugger
        try {
            let data : any = {
                name: dateDestination.contact,
                address: `${dateDestination.address} ${dateDestination.interior} ${dateDestination.neighborhood}`,
                phone : dateDestination.phone,
                collect_Value : dateDestination.collect_Value ? Number(dateDestination.collect_Value) : 0,
                document: "",
                email : "",
                observations : dateDestination.observations,
                service_Description : dateDestination.service_Description,
                businessName : dateDestination.business_name,
                plate : dateDestination.cellphone,
                updated_by : localStorage.userID,
                confirmed : false,
                statusId : 1,
                customerId : filters.customerId,
                cityId : allCities.find( c => c.name == dateDestination.city)?.id,
                company_TransportId : 1
            }; 
            let arrayBill = [ data ];
            await billsAPI.PostBills(arrayBill);
            message.success(`Se creo el servicio correctamente`);
            setTimeout(() => {
                window.location.href = "/"
            }, 2000);
        } catch (error) {
            message.error(`No se creo el servicio. ${error}`);
        }
        setLoading(false);

    }

    const handleFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    }
 

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            getCustomer(localStorage.customerId);
            getOriginNoteBooks(localStorage.customerId);
            getDestinationNoteBooks(localStorage.customerId);
            getProvinces();
            getCustomers();
            getAllCities();
        }
        return () => {
            mounted = false;
        }
    }, [])



    return {
        handleDateOriginChange,
        handleOriginSelectChange,
        addBook,
        provinces,
        provinceLoading,
        disabledCities,
        cities,
        citiesLoading,
        loadingNoteBook,
        originNoteBook,
        handleNoteBook,
        dateOrigin,
        contactOrigin,
        confirm,
        handleDateDestinationChange,
        handleDestinationSelectChange,
        loadingNoteBookDestination,
        destinationNoteBook,
        dateDestination,
        contactDestination,
        customers,
        loadingCustomers,
        handleSubmit,
        handleFailed,
        loading
    }
}


