import * as React from 'react';
import { IDetailBillProps } from './IDetailBillProps';
import './DetailBill.css';
import { Button, Col, DatePicker, Divider, Input, InputNumber, Popconfirm, Result, Row, Select, Timeline } from 'antd';
import { useDetailBill } from '../../../Hooks/useDetailBill';
import { spawn } from 'child_process';
import moment, { isDate } from 'moment';
import { DeleteOutlined, MinusCircleOutlined, SaveOutlined, SendOutlined } from '@ant-design/icons/lib/icons';
import TextArea from 'antd/lib/input/TextArea';
import { Footer } from 'antd/lib/layout/layout';
import { ModalGuia } from '../../ScheduledServicesNew/ModalGuia';
const { Option } = Select;
const logoEmpresa = require('../../../Assets/Logo.png');

const DetailBill: React.SFC<IDetailBillProps> = (props) => {

  const {
    guia,
    billData,
    statusList,
    messengers,
    updateSolution,
    productListFiltered,
    onSearchProducts,
    SaveBillNews,
    loading,
    UpdateBillHeader,
    isAdmin,
    handleChange,
    handleChangeSelect,
    handleChangeNewsState,
    handleSubmitNews,
    billProducts,
    updateProductsArray,
    saveProducts,
    modalGuiaVisible,
    setModalGuiaVisible,
    Anular
  } = useDetailBill();

  return (
    <div>
      {billData &&
        <>
          <Row className='Header' gutter={[16, 16]}>
            <Col span={1}></Col>
            <Col span={4}>
              <img src={logoEmpresa.default} alt='LogoEmpresa' width='100' />
            </Col>
            <Col span={6}>
              <h3>Guia: 
                <span className='linkGuia' onClick={()=>setModalGuiaVisible(true)}>{billData.guia}</span>
              </h3>
            </Col>
            <Col span={1}></Col>
            <Col span={4}>
              Estado:
              <Select value={billData.statusId} style={{ width: '100%' }} disabled={!isAdmin} onChange={(e) => handleChangeSelect('statusId', e)}>
                {statusList.map(p =>
                  <Option value={p.id} key={p.id}>{p.name}</Option>
                )}
              </Select>
            </Col>
            <Col span={2}>
            Orden:
            <Input value={billData?.delivery_Order} disabled={!isAdmin} onChange={handleChange} name="delivery_Order" />
            </Col>
            <Col span={1}></Col>
            <Col span={2}>
              <br />
              {isAdmin &&
                <Button type='primary' loading={loading} onClick={UpdateBillHeader}>Guardar</Button>
              }
            </Col>
            <Col span={2}>
              <br />
              {billData.status =='Programado' &&
                <Popconfirm title="¿Esta seguro que desea anular la guia?" onConfirm={Anular} okText="Si" cancelText="No">
                  <Button type='primary' loading={loading}>Solicitar anulación</Button>
                </Popconfirm>
              }
            </Col>
          </Row>
          <div className='text-title'>Datos del destinatario</div>
          <Row className='detailBill' gutter={[16, 16]}>
            <Col span={6}>
              Nombre:
              <Input value={billData?.name} disabled={!isAdmin} onChange={handleChange} name="name" />
            </Col>
            <Col span={4}>
              Teléfono:
              <Input value={billData?.phone} disabled={!isAdmin} onChange={handleChange} name="phone" />
            </Col>
            <Col span={7}>
              Dirección:
              <Input value={billData?.address} disabled={!isAdmin} onChange={handleChange} name="address" />
            </Col>
            <Col span={5}>
              Ciudad:
              <Input value={billData?.city} disabled={true} />
            </Col>

          </Row>
          <Row className='detailBill' gutter={[16, 16]}>
            <Col span={4}>
              Cédula:
              <Input value={billData?.document} disabled={!isAdmin} onChange={handleChange} name="document" />
            </Col>
            <Col span={6}>
              Correo:
              <Input value={billData?.email} disabled={!isAdmin} onChange={handleChange} name="email" />
            </Col>
            <Col span={4}>
              Transportadora:
              <Input value={billData?.company_Transport} disabled={true} />
            </Col>
            <Col span={4}>
              Fecha Entrega:
              <DatePicker value={moment(billData?.delivered_at)} disabled={true} />
            </Col>
          </Row>
          <Divider></Divider>
          <Row className='detailBill' gutter={[16, 16]}>
            <Col span={12}>
              <div className='text-title'>Productos</div>

              {billProducts.map((x,i) =>
                <Row className='detailBill' gutter={[16, 16]} key={x.id}>
                  <Col span={1}></Col>
                  <Col span={18}>
                    Producto:
                    <Select
                      style={{ width: '100%' }}
                      value={x.productId}
                      showSearch
                      defaultActiveFirstOption={false}
                      showArrow={true}
                      filterOption={false}
                      onSearch={onSearchProducts}
                      notFoundContent="No hay datos..."
                      allowClear
                      disabled={!isAdmin}
                      onChange={(e)=>updateProductsArray('Update',i,x.quantity,e)}
                    >
                      <Option value={0} key={0}> Selecciona un producto...</Option>
                      {productListFiltered.map(x =>
                        <Option key={x.id} value={x.code}>{x.palomaName}</Option>
                      )}
                    </Select>
                  </Col>
                  <Col span={4}>
                    Cantidad:
                    <InputNumber value={x.quantity} disabled={!isAdmin} onChange={(e)=>updateProductsArray('Update',i,e,x.productId)}/>
                  </Col>
                  <Col span={1}>
                    <br />
                    {isAdmin &&
                      <Popconfirm title="Eliminar este prodcuto?" onConfirm={() => updateProductsArray('Delete', i,x.quantity,0)}>
                        <DeleteOutlined style={{ color: 'red' }} />
                      </Popconfirm>
                    }
                  </Col>
                </Row>
              )}
              <Row>
                <Col span={2}></Col>
                <Col span={10}>
                  {isAdmin &&
                    <Button onClick={() => updateProductsArray('Add', 0,0,0)}>Agregar Producto</Button>
                  }
                </Col>
                <Col span={10}>
                  {isAdmin &&
                    <Button onClick={saveProducts}>Guardar</Button>
                  }
                </Col>
                <Col span={2}></Col>
              </Row>
            </Col>
            <Col span={12}>
              <div className='text-title'>Datos de envío</div>
              <Row className='detailBill' gutter={[16, 16]}>
                <Col span={12}>
                  Valor a recaudar:
                  <Input value={billData?.collect_Value} disabled={!isAdmin} onChange={handleChange} name="collect_Value" />
                </Col>
                <Col span={12}>
                  Placa:
                  <Input value={billData?.plate} disabled={!isAdmin} onChange={handleChange} name="plate" />
                </Col>
                <Col span={12}>
                  Mensajero:
                  <Select value={billData.zone} style={{ width: '100%' }} disabled={!isAdmin} onChange={(e: any) => handleChangeSelect('zone', e)}>
                    {messengers.filter(x => x.warehouseId == billData.warehouseId).map(p =>
                      <Option value={p.name} key={p.name}>{p.name}</Option>
                    )}
                  </Select>
                </Col>
                <Col span={12}>
                  Descripción del servicio:
                  <Input value={billData?.service_Description} disabled={!isAdmin} onChange={handleChange} name="service_Description" />
                </Col>
                <Col span={24}>
                  Observaciones:
                  <TextArea rows={4} value={billData.observations} disabled={!isAdmin} onChange={handleChange} name="observations" />
                </Col>
              </Row>
            </Col>
          </Row>


          <Divider />
        </>
      }
      <div>
        <hr />
        <Row className='detailBill' gutter={[16, 16]}>
          <Col span={7}>
            <div className='text-title'>Novedades</div>
            {billData?.billNews.filter(n => n.newsBillingType == 'Novedad').map((x: any, i: any) =>
              <>
                <Row gutter={[16, 16]} key={x.id}>
                  <Col span={24}>
                    <div style={{ color: 'blue' }}>({moment(x.created_at).format('YYYY-DD-MM HH:mm')})</div>
                    {x.description}
                    <TextArea value={x.solution} onChange={(e) => updateSolution(x.id, e.target.value)} />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col span={19}></Col>
                  <Col span={4}>
                    <Button type='primary' icon={<SendOutlined />} onClick={() => SaveBillNews(x.id)}>Enviar Solución</Button>
                  </Col>
                </Row>
              </>
            )}
          </Col>
          <Col span={2}></Col>
          <Col span={8}>
            <div className='text-title'>Historico Novedades</div>
            {billData?.billNews?.length !== 0 &&
              <>
                {billData?.status === 'Entregado' &&
                  <>
                    <h6>Estado: {billData?.status}</h6>
                    <h6>Fecha entrega: {moment(billData?.delivered_at).format('DD-MM-YYYY, h:mm:ss a')}</h6>
                  </>
                }
                <Timeline className="mt-5">
                  {billData?.billNews?.map((news: any) =>
                    <Timeline.Item key={news.id} color={news.solution !== null ? 'green' : 'red'}>
                      <>
                        <h6>{news.newsBillingType}</h6>
                        <p>Fecha: {moment(news.created_at).format('DD-MM-YYYY, h:mm:ss a')}</p>
                        <p>Descripción: {news.description}</p>
                        {news.solution !== null &&
                          <p>Solución: {news.solution}</p>
                        }
                      </>
                    </Timeline.Item>
                  )}
                </Timeline>
              </>
            }
            {billData?.billNews?.length == 0 &&
              <Result
                title="No hay historico de novedades para este pedido"
              />
            }
          </Col>
          {isAdmin &&
            <Col span={7}>
              <div className='text-title'>Nueva Novedad</div>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Select placeholder="Seleccione un tipo" onChange={(e: any) => handleChangeNewsState('newsBillingType', e)}>
                    <Option value="Novedad Paloma">Novedad Paloma</Option>
                    <Option value="Novedad Cliente">Novedad Cliente</Option>
                  </Select>
                </Col>
                <Col span={24}>
                  <TextArea rows={4} placeholder="Digite la novedad..." name="description" onChange={(e: any) => handleChangeNewsState('description', e.target.value)} />
                </Col>
                <Col span={24}>

                  <Button
                    type="primary"
                    icon={<SaveOutlined />}
                    onClick={handleSubmitNews}
                    block
                  >
                    Crear Novedad
                  </Button>
                </Col>
              </Row>
            </Col>
          }
        </Row>
        {billData &&
          <ModalGuia guiaSelected={billData} setModalGuiaVisible={setModalGuiaVisible} modalGuiaVisible={modalGuiaVisible}/>
        }
        <Footer title='Paloma Mensajería' />
      </div>
    </div>
  );
};

export default DetailBill;
