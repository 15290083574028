import axios from 'axios';
import { BillPost, BillResponseHistory, BillStatus, DispatchOrder, getBillsById, getBillsRequest, getBillsRequestPaging, getBillsStatus, SettleCourier } from '../Models/Bill';
import { Utils } from '../utils/utils';
import { BillGet } from '../Models/Bill';
import utils from '../utils';
import { Movement } from '../Models/Movement';
import { Statuses } from '../Models/Status';
import { Product } from '../Models/Product'



const billsAPI = {
    getBills(customerId: number): Promise<BillGet[]> {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.get(`${config.data[0].ulrBackend}/Bill/${customerId}`)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        reject(error)
                        utils.messageError(error);
                    });
            });
        });
    },
    UpdateBillStatus() {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.get(`${config.data[0].ulrBackend}/Bill/JobUpdateBillsStatus`)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        reject(error)
                        console.log(error);
                    });
            });
        });
    },
    getBillsById(data: getBillsById): Promise<BillGet> {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].ulrBackend}/Bill/GetBillById`,data)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        reject(error)
                    });
            });
        });
    },
    cancelBill(billId: number,status:string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].ulrBackend}/Bill/cancelBill?billId=${billId}&status=${status}`)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        reject(error)
                    });
            });
        });
    },
    PostBills(data: BillPost[]) {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].ulrBackend}/Bill/PostBill`, data)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                            utils.messageError();
                        } else {
                            console.log('Error', error.message);
                            utils.messageError();
                        }
                        console.log(error.config);
                    });
            });
        });
    },
    UpdateBills(data: BillPost) {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].ulrBackend}/Bill/updateBill`, data)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        reject(error)
                    });
            });
        });
    },
    UpdateBillHeader(data: any) {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].ulrBackend}/Bill/UpdateBillHeader`, data)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        reject(error)
                    });
            });
        });
    },
    DispatchBills(data: DispatchOrder) {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].ulrBackend}/Bill/dispatchBill`, data)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                        console.log(error.config);
                    });
            });
        });
    },
    GetBillsByDepartmentandDate(data: getBillsRequest): Promise<BillGet[]> {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].ulrBackend}/Bill/GetBillByDepartmentandDate`, data)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                        console.log(error.config);
                    });
            });
        });
    },
    GetBillHistory(data: getBillsRequestPaging): Promise<BillGet[]> {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].ulrBackend}/Bill/GetBillHistory`, data)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        console.log(error);
                        console.log(error.config);
                    });
            });
        });
    },
    GetBillByDepartmentandStatus(data: getBillsStatus): Promise<BillStatus[]> {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].ulrBackend}/Bill/GetBillByDepartmentandStatus`, data)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                        console.log(error.config);
                    });
            });
        });
    },
    GetSettleCourierInvoice(data: SettleCourier): Promise<BillStatus[]> {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].ulrBackend}/Bill/GetSettleCourierInvoice`, data)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                        console.log(error.config);
                    });
            });
        });
    },
    GetDailyResume(data: getBillsRequest): Promise<BillGet[]> {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].ulrBackend}/Bill/GetDailyResume`, data)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                        console.log(error.config);
                    });
            });
        });
    },
    GetMovement(data: getBillsRequest): Promise<Movement[]> {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].ulrBackend}/Bill/GetMovement`, data)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                        console.log(error.config);
                    });
            });
        });
    },
    PrintLabels(data: any) {
        return new Promise((resolve, reject) => {
            axios.post(`https://images-ms-dev.bycrecel.com/api/Document/multiple`, data, {
                responseType: 'blob',
            })
                .then(response => resolve(response.data))
                .catch((error) => {
                    if (error.response) {
                        reject(error.response.data)
                        console.log(error);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                })
        });
    },
    
    getStatusList(): Promise<Statuses[]> {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.get(`${config.data[0].ulrBackend}/Status/GetStatusList`)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                        console.log(error.config);
                    });
            });
        });
    },

    createBillsNews( billNews : any) {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].ulrBackend}/Bill/createBillNews`, billNews)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                        console.log(error.config);
                    });
            });
        });
    }, 
    GetBillProductsById(data: any): Promise<Product[]>{
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].ulrBackend}/Bill/GetBillProductsById`, data)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                        console.log(error.config);
                    });
            });
        });
    }, 
    SearchBill(Type: string,value:string): Promise<BillGet[]>{
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.get(`${config.data[0].ulrBackend}/Bill/SearchBill?Type=${Type}&value=${value}`)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                        console.log(error.config);
                    });
            });
        });
    }
}

export default billsAPI;