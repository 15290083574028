import React from 'react';

// Custom Hooks
import { useCreateUsers } from '../Hooks/useCreateUsers';

// Props
import { IFormUserProps } from './IFormUserProps';

// Ant Desing
import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import { EditOutlined } from '@ant-design/icons';

// Devextreme
import DataGrid, { Column, Editing, Export, FilterRow, Grouping, GroupPanel, Lookup, Pager, Paging, SearchPanel } from 'devextreme-react/data-grid';


// Style
import './FormUser.css';
import { useState } from 'react';
import { UserEditModal } from './UserEditModal';

const FormUser: React.SFC<IFormUserProps> = (props) => {

  // Ant Desing
  const { Option } = Select;

  const { customers, onFinish, setButton, onFinishFailed, provinces, handleSelectChange, isRole, formRef, rols, users } = useCreateUsers();

  const [userSelected, setUserSelected] = useState({});
  const [visible, setVisible] = useState(false);


  const handleModal = ({ data }: any) => {
    return <EditOutlined onClick={() => handleVisible(data)} />
  }


  const handleVisible = (data: any) => {
    setUserSelected(data);
    setVisible(true);
  }
  return (
    <div>
      <Form
        ref={formRef}
        initialValues={{}}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row
          gutter={[16, 16]}
          className="rowForm"
        >
          <Col span={12}>
            <Form.Item
              label="Nombre"
              name="name"
              rules={[{ required: true, message: 'Por favor digite su nombre!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Por favor digite su correo!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Bodega"
              name="warehouseId"
              rules={[{ required: true, message: 'Por favor seleccione una bodega!' }]}
            >
              <Select placeholder="Seleccionar...">
                {provinces.map(pro => (
                  <Option
                    key={pro.id}
                    value={pro.id}
                  >
                    {pro.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Rol"
              name="rolId"
              rules={[{ required: true, message: 'Seleccione un rol!' }]}

            >
              <Select placeholder="Seleccionar..." onChange={handleSelectChange}>
                {rols.map(({ id, name }: any) =>
                  <Option key={id} value={id}>{name}</Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Cliente"
              name="customerId"
              rules={[{ required: !isRole, message: 'Seleccione un cliente!' }]}
            >
              <Select placeholder="Seleccionar..." disabled={isRole}>
                {customers.map(c => (
                  <Option
                    key={c.id}
                    value={c.id}
                  >
                    {c.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Codigo"
              name="code"
              rules={[{ required: !isRole, message: 'Digite un codigo!' }]}
            >
              <Input disabled={isRole} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              onClick={() => setButton('formUser')}
              className="btn-unico"
              htmlType="submit"
              block
            >
              Crear
            </Button>
          </Col>
        </Row>
      </Form>

      <Row>
        <Col>
          {/* <Spin tip="Cargando..." spinning={loading}> */}
          <DataGrid
            dataSource={users}
            allowColumnReordering={true}
            showBorders={true}
            rowAlternationEnabled={true}
          >

            <GroupPanel visible={true} />
            <SearchPanel visible={true} highlightCaseSensitive={true} />
            <Grouping autoExpandAll={true} />
            <FilterRow visible={true} />

            <Column dataField="name" caption="Nombre" dataType="string" />
            <Column dataField="email" caption="Correo" dataType="string" />
            <Column dataField="warehouseId" caption="Bodega" dataType="string">
              <Lookup dataSource={provinces} valueExpr="id" displayExpr="name" />
            </Column>
            <Column dataField="rolId" caption="Rol" dataType="string">
              <Lookup dataSource={rols} valueExpr="id" displayExpr="name" />
            </Column>
            <Column dataField="customerId" caption="Cliente" dataType="string" >
              <Lookup dataSource={customers} valueExpr="id" displayExpr="name" />
            </Column>
            <Column cellRender={handleModal} alignment="center" />

            <Paging defaultPageSize={5} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[5, 10, 20]}
              showInfo={true} />
          </DataGrid>
          {/* </Spin> */}
        </Col>
      </Row>

      {userSelected &&
        <UserEditModal visible={visible} setVisible={setVisible} userSelected={userSelected} customers={customers} setUserSelected={setUserSelected} />
      }
    </div>
  );
};

export default FormUser;
