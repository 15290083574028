import { useCurrierLiquidation } from "../hooks/useCurrierLiquidation";
import DataGrid, {
  Column,
  FilterRow,
  Grouping,
  Selection,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  Export,
  HeaderFilter,
  Sorting,
  ColumnFixing,
  Editing,
  Lookup,
} from "devextreme-react/data-grid";
import { Button, Col, DatePicker, Popconfirm, Row, Select, Spin } from "antd";
const { RangePicker } = DatePicker;
const { Option } = Select;

const CurrierLiquidation = () => {
  const {
    LiquidationList,
    handleUpdate,
    getLiquidationList,
    loading,
    WarehousesList,
    setSelectedWareHouse,
    DeleteLiquidation,
    setSelectedDate,
    selectedDate
  } = useCurrierLiquidation();

  return (
    <div>
      <Row>
        <Col span={6}>Liquidación de mensajeros</Col>
        <Col span={1}></Col>
        <Col span={4}>
          Bodega:
          <Select
            style={{ width: 200, marginLeft: 10 }}
            onChange={(e: any) => setSelectedWareHouse(e)}
          >
            {WarehousesList.map((x) => (
              <Option key={x.id} value={x.id}>
                {x.name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={2}></Col>
        <Col span={6}>
          Fecha:
          <RangePicker onChange={(e) => setSelectedDate(e)} />
        </Col>
        <Col span={2}>
          <br />
          {selectedDate.length > 1 && (
            <Button type="primary" onClick={getLiquidationList}>
              Generar
            </Button>
          )}
        </Col>
        <Col span={1}></Col>
        <Col span={2}>
          <br />
          {LiquidationList.length > 0 && (
            <Popconfirm
              title="Eliminar los datos de esta liquidación"
              onConfirm={DeleteLiquidation}
            >
              <Button type="primary">Eliminar</Button>
            </Popconfirm>
          )}
        </Col>
      </Row>
      <Spin spinning={loading} tip="Cargando...">
        {LiquidationList.length > 0 && (
          <DataGrid
            id="LiquidationList"
            dataSource={LiquidationList}
            onRowUpdated={handleUpdate}
            showBorders={true}
            wordWrapEnabled={true}
            rowAlternationEnabled={true}
          >
            <SearchPanel visible={true} highlightCaseSensitive={true} />
            <Grouping autoExpandAll={true} />
            <FilterRow visible={true} />
            <GroupPanel visible={true} />
            <Grouping autoExpandAll={true} />
            <Export enabled={true} allowExportSelectedData={false} />
            <HeaderFilter visible={true} />
            <Sorting mode="multiple" />
            <ColumnFixing enabled={true} />
            <Grouping contextMenuEnabled={true} expandMode="rowClick" />
            <Grouping autoExpandAll={false} />

            <Column caption="id" dataField="id" visible={false} />
            <Column
              dataField="courrier"
              caption="Mensajero"
              dataType="string"
              allowEditing={false}
            />
            <Column
              dataField="date"
              caption="Fecha"
              dataType="date"
              format={"dd/MM/yyyy"}
              allowEditing={false}
            />
            <Column
              dataField="warehouse"
              caption="Bodega"
              allowEditing={false}
            />
            <Column
              dataField="system_Value"
              caption="Sistema"
              dataType="number"
              format="$ #,##0.00"
              allowEditing={false}
            />
            <Column
              dataField="paloma_Consignation_Value"
              caption="Consignación Paloma"
              dataType="number"
              format="$ #,##0.00"
            />
            <Column
              dataField="client_Consignation_Value"
              caption="Consignación Cliente"
              dataType="number"
              format="$ #,##0.00"
            />
            <Column
              dataField="expenses"
              caption="Gastos"
              dataType="number"
              format="$ #,##0.00"
            />
            <Column
              dataField="total"
              caption="Efectivo"
              dataType="number"
              format="$ #,##0.00"
              allowEditing={false}
            />
            <Column
              dataField="total_Collected"
              caption="Recogido"
              dataType="number"
              allowEditing={false}
            />
            <Column
              dataField="total_Delivered"
              caption="Entregados"
              dataType="number"
              allowEditing={false}
            />
            <Column
              dataField="total_Failed"
              caption="Fallidos"
              dataType="number"
              allowEditing={false}
            />
            <Column
              dataField="total_Canceled"
              caption="Cancelados"
              dataType="number"
              allowEditing={false}
            />

            <Pager
              allowedPageSizes={[5, 10, 50, 100]}
              showPageSizeSelector={true}
            />
            <Paging defaultPageSize={100} />
            <Editing mode="cell" allowUpdating={true} />
          </DataGrid>
        )}
      </Spin>
    </div>
  );
};

export default CurrierLiquidation;
