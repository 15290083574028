import Swal from "sweetalert2";
import * as qs from 'query-string';

const utils = {
    sortArray(array, property, direction) {
        direction = direction || 1;
        array.sort(function compare(a, b) {
            let comparison = 0;
            if (a[property] > b[property]) {
                comparison = 1 * direction;
            } else if (a[property] < b[property]) {
                comparison = -1 * direction;
            }
            return comparison;
        });
        return array;
    },
    parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    },
    crearCookie(clave, valor, exp) {
        var d = new Date(exp * 1000)
        var expires = "expires=" + d.toUTCString();
        let cookiepath = "/";
        document.cookie = clave + "=" + valor + "; " + expires + "; path=" + cookiepath;
    },
    obtenerCookie(clave) {
        var name = clave + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1);
            if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
        }
        return "";
    },
    comprobarCookie(clave) {
        var clave = utils.obtenerCookie(clave);
        if (clave != "") {
            return true;
        } else {
            return false;
        }
    },
    delete_cookie(name) {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        window.location.href = '/LoginComponent'
    },

    messageError(err) {
        return Swal.fire(
            'Oops...',
            err,
            'error',
        );
    },
    getQueryStirng(){
        const parsed = qs.parse(window.location.search);
        return parsed;
    }
}

export default utils;