import React, { useEffect, useRef, useState } from 'react';
import { message, Typography, Card, Row, Col, Button, Select, Input, Form } from 'antd';
import { useParams } from 'react-router';
import './ConfirmedGuide.css'
import { SearchOutlined } from '@ant-design/icons';
import billsAPI from '../../Api/api-bills';
import { ModalGuia } from './ModalGuia';
import Swal from 'sweetalert2';


const { Title, Paragraph } = Typography;
const { Option } = Select;
const { TextArea } = Input;

export const ConfirmedGuide = () => {

    const { guia, id } = useParams<Params>();
    const textRef = useRef<any>();
    const [bill, setBill] = useState<any>({});
    const [news, setNews] = useState<News>({
        id: 0,
        billId: bill.id,
        newsBillingType: 'Novedad Paloma',
        description: `${bill.confirmed == true ? 'Confirmo Entrega' : 'No Confirmo Entrega'}`,
        solution: '',
        changeStatus: false,
        userID: bill.userId
    });
    const [loadingBill, setLoadingBill] = useState(false);

    const getBill = async () => {
        setLoadingBill(true);
        try {
            const payload = {
                id: Number(id),
                guia
            }
            const bill = await billsAPI.getBillsById(payload);
            setBill(bill);
            setNews({
                ...news,
                description: bill.confirmed ? 'Confirmo Entrega' : 'No Confirmo Entrega'
            })
        } catch (error) {
            message.error(`No se obtuvo los datos de la guia.`);
        }
        setLoadingBill(false);
    }

    const handleSelect = (value: any, name: string) => {

        setNews(news);
        textRef.current.resetFields();
        if (value === 1) {
            setBill({
                ...bill,
                [name]: true
            })
            setNews({
                ...news,
                description: 'Confirmo Entrega'
            })
        } else {
            setBill({
                ...bill,
                [name]: false
            })
            setNews({
                ...news,
                description: 'No Confirmo Entrega'
            })
        }
    }

    const handleInput = ({ target }: any) => {
        const { name, value } = target;
        if (bill.confirmed) {
            setNews({
                ...news,
                [name]: 'Confirmo Entrega, ' + value
            })
        } else {
            setNews({
                ...news,
                [name]: 'No Confirmo Entrega, ' + value
            })
        }
    }

    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const handleSubmit = async () => {
        setLoadingSubmit(true);
        console.log(bill);
        debugger
        try {
            news.billId = bill.id;
            news.changeStatus = false;
            await billsAPI.createBillsNews(news);
            await billsAPI.UpdateBillHeader(bill);
            Swal.fire(
                'Completado',
                'Pedido Actualizado',
                'success',
            ).then((result) => {
                window.location.href = "https://web.palomamensajeria.com/"
            });
        } catch (error) {
            message.error(`No se pudo actualizar la guia. ${error}`);
        }
        setLoadingSubmit(false);
    }

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            getBill();
        }
        return () => {
            mounted = false;
        }
    }, [])

    return (
        <>
            <Title level={2} className="title">Confirmación de Entrega</Title>
            <Row justify="center">
                <Col xs={18} sm={18} md={18} lg={12} xl={12}>
                    <Card
                        loading={loadingBill}
                        title="Datos Guia"
                        hoverable
                    >
                        <Row gutter={[8, 8]}>
                            <Col lg={12} sm={24} xs={24}>
                                <Title level={5}>Guia</Title>
                                <Paragraph>{bill.guia}</Paragraph>
                            </Col>
                            <Col lg={12} sm={24} xs={24}>
                                <Title level={5}>Contacto</Title>
                                <Paragraph>{bill.name}</Paragraph>
                            </Col>
                            <Col lg={12} sm={24} xs={24}>
                                <Title level={5}>Teléfono</Title>
                                <Paragraph>{bill.phone}</Paragraph>
                            </Col>
                            <Col lg={12} sm={24} xs={24}>
                                <Title level={5}>Dirección</Title>
                                <Paragraph>{bill.address}</Paragraph>
                            </Col>
                            <Col lg={12} sm={24} xs={24}>
                                <Title level={5}>Departamento</Title>
                                <Paragraph>{bill.province}</Paragraph>
                            </Col>
                            <Col lg={12} sm={24} xs={24}>
                                <Title level={5}>Ciudad</Title>
                                <Paragraph>{bill.city}</Paragraph>
                            </Col>
                            <Col lg={12} sm={24} xs={24}>
                                <Title level={5}>Productos</Title>
                                <Paragraph>{bill.products}</Paragraph>
                            </Col>
                            <Col lg={12} sm={24} xs={24}>
                                <Title level={5}>Valor</Title>
                                <Paragraph>{bill.collect_Value}</Paragraph>
                            </Col>
                            <Col lg={8} sm={24} xs={24}>
                                <Title level={5}>Confirmo Entrega</Title>
                                {console.log(bill)}
                                <Select placeholder="Seleccione una opción" value={bill.confirmed ? 1 : 0} onChange={(value) => handleSelect(value, 'confirmed')}>
                                    <Option value={1}>Sí</Option>
                                    <Option value={0}>No</Option>
                                </Select>
                            </Col>
                            <Form ref={textRef}>
                                <Col lg={24} sm={24} xs={24}>
                                    <Title level={5}>Comentarios</Title>
                                    <Form.Item name="description">
                                        <TextArea rows={4} name="description" onChange={handleInput} />
                                    </Form.Item>
                                </Col>
                            </Form>
                            <Col lg={24} sm={24} xs={24}>
                                <Button type="primary" loading={loadingSubmit} onClick={handleSubmit} block>Enviar</Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </>
    )


}

type Params = {
    guia: string,
    id: string
}

type News = {
    id?: number,
    billId?: number,
    newsBillingType?: string,
    description?: string,
    solution?: string,
    created_at?: string,
    update_at?: string,
    userID?: number,
    changeStatus?: boolean
}