import { Form } from "antd";
import { useContext, useState } from "react";
import Swal from "sweetalert2";
import billsAPI from "../Api/api-bills";
import citiesAPI from "../Api/api-cities";
import productsAPI from "../Api/api-products";
import usersAPI from "../Api/api-users";
import { BillPost } from "../Models/Bill";
import { GetFormCities } from "../Models/City";
import { Product } from "../Models/Product";
import { User } from "../Models/User";
import utils from "../utils";
import filterContext from "./filterContex";
import { useAlertMessage } from "./useAlertMessage";
import { useManageErrors } from "./useManageAPIErrors";

export const useServiceForm = () => {

    const {onError} = useManageErrors();
    const {Confirm} = useAlertMessage();
    const filters = useContext(filterContext);
    const [billData, setBillData] = useState<BillPost>({
        id: 0,
        name: '',
        address: '',
        phone: '',
        document: '',
        email: '',
        company: '',
        typeConsignedValue: 'Con Recaudo',
        consigned_Value: 0,
        created_at: '',
        updated_by: localStorage.userID,
        confirmed: true,
        observations: '',
        statusId: 1,
        sellerId: 0,
        provinceId: 0,
        cityId: 0,
        company_TransportId: 0,
        customerId: filters.customerId,
        collect_Value: 0,
        number_account: '',
        bill_Products: []
    });
    const [loading, setLoading] = useState<boolean>(false)
    const [ProductsList, setProductsList] = useState<Product[]>([]);
    const [ProductsListFiltered, setProductsListFiltered] = useState<Product[]>([]);
    const [sellersList, setSellersList] = useState<User[]>([]);
    const [CitiesList, setCitiesList] = useState<GetFormCities[]>([]);
    const [form] = Form.useForm<BillPost>();

    const validations = (data: BillPost) => {
        if(data.bill_Products.length == 0)
        {
            utils.messageError('Debe agregar al menos un producto');
            return false;
        }
        if(data.typeConsignedValue == 'Con Recaudo' && data.collect_Value == 0)
        {
            utils.messageError('Indique cual es el valor a recaudar');
            return false;
        }
        return true;
    }

    const onFinish = (values: BillPost) => {
        console.log('Success:', values);
        values.updated_by = localStorage.userID;
        values.confirmed = false;
        values.statusId = 1;
        values.customerId = filters.customerId;
        values.service_Description = 'ENTREGAR PEDIDO';
        let el:BillPost[] = [values];
        setLoading(true);
        if (validations(values)) {
            billsAPI.PostBills(el).then(res => {
                Swal.fire(
                    'Completado',
                    'Los datos fueron guardados',
                    'success',
                ).then((result) => {
                    window.location.href = "/"
                });
                setLoading(false);
            })
                .catch(err => {
                    utils.messageError(err);
                    setLoading(false);
                });
        }
    };

    const SavaMasiveUPload = (values: BillPost[]) => {
        console.log('Success:', values);
        values.map(x=>{
            x.updated_by = localStorage.userID;
            x.confirmed = false;
            x.statusId = 1;
            x.customerId = filters.customerId;
            x.service_Description = 'ENTREGAR PEDIDO'
        })
        setLoading(true);
            billsAPI.PostBills(values).then(res => {
                Swal.fire(
                    'Completado',
                    'Los datos fueron guardados',
                    'success',
                ).then((result) => {
                    window.location.href = "/"
                });
                setLoading(false);
            })
                .catch(err => {
                    utils.messageError(err);
                    setLoading(false);
                });
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onSearchProducts = (value: any) => {
        if (value) {
            let array = ProductsList.filter((x: Product) => x.palomaName.toLowerCase().includes(value.toLowerCase()));
            setProductsListFiltered(array);
        } else {
            setProductsListFiltered(ProductsList);
        }
    };


    const getProductsList = () => {
        productsAPI.getProductsByCustomerId(filters.customerId).then(res => {
            setProductsList(res);
            setProductsListFiltered(res);
        });
    }

    const getSellersList = () => {
        usersAPI.getSellers(filters.customerId).then(res => {
            setSellersList(res);
        })
    }
    

    const getCities = () => {
        citiesAPI.getCities().then(res => {
            setCitiesList(res);
        })
    }

    const onChangeProvince = (value: number) => {
        form.setFieldsValue({ cityId: 0, company_TransportId: 0 })
    }

    const onChangeCity = (value: number) => {
        form.setFieldsValue({ company_TransportId: 0 })
    }

    return {
        filters,
        onFinishFailed,
        onFinish,
        loading,
        onSearchProducts,
        ProductsListFiltered,
        getProductsList,
        getSellersList,
        getCities,
        billData,
        sellersList,
        CitiesList,
        form,
        onChangeProvince,
        onChangeCity,
        ProductsList,
        SavaMasiveUPload
    }
}