import React, {useEffect} from 'react';
import { useAddProduct } from '../../../../Hooks/useAddProduct';
import { IAddProductsComponentProps } from './IAddProductsComponentProps';
import './AddProductsComponent.css';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';

const AddProductsComponent: React.SFC<IAddProductsComponentProps> = (props) => {


  const {
    values,
    handleInputChange,
    handleSubmit,
    validated,
    loading,
    getCustomers,
    customers
    // error
  } = useAddProduct();
  

  useEffect(() => {
    getCustomers()
  }, [])

  return (
    <Form noValidate onSubmit={ handleSubmit } validated={ validated }>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={ values.name }
              onChange={ handleInputChange }
              required
              
            />
            <Form.Control.Feedback type="invalid">
              Este campo es requerido
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Nombre Paloma</Form.Label>
            <Form.Control
              type="text"
              name="palomaName"
              value={ values.palomaName }
              onChange={ handleInputChange }
              required
            />
            <Form.Control.Feedback type="invalid">
              Este campo es requerido
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Código</Form.Label>
            <Form.Control
              type="text"
              name="code"
              value={ values.code }
              onChange={ handleInputChange }
              required
            />
            <Form.Control.Feedback type="invalid">
              Este campo es requerido
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Precio de Venta</Form.Label>
            <Form.Control
              min={1}
              type="number"
              name="selling_price"
              value={ values.selling_price}
              onChange={ handleInputChange }
              required
            />
            <Form.Control.Feedback type="invalid">
              Digite un precio de venta
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group controlId="exampleForm.SelectCustomSizeSm">
            <Form.Label>Cliente</Form.Label>
            <Form.Control 
              as="select"  
              name="customerId"
              value={ values.customerId }
              onChange={ handleInputChange }
              required
              custom
            >
              <option value="">Seleccionar...</option>
              {customers.map( customer => 

                <option key={customer.id} value={customer.id}>{customer.name}</option>
              )}
            </Form.Control>
          </Form.Group>
        </Col>

        <Col>
        
          <Button
            type="submit"
            variant="primary"
            style={{marginTop:30}}
            block
          >
            Guardar
            {loading &&

              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="ml-3"
              />
            }
          </Button>
        </Col>
      </Row>



    </Form>
  );
};

export default AddProductsComponent;
