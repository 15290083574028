import * as React from "react";
import { useSheduledComponent } from "../../Hooks/useSheduledComponent";

// React Router Dom
import { Link } from "react-router-dom";

// Material Data Table
import MaterialTable from "material-table";

// Components
import HeaderComponent from "../HeaderComponent/HeaderComponent";
import FooterComponent from "../FooterComponent/FooterComponent";
import FormModalComponent from "./components/FormModalComponent/FormModalComponent";
import NewsModalComponent from "./components/NewsModalComponent/NewsModalComponent";

// React Bootstrap
import { Button, Form, Modal, Tab, Tabs } from "react-bootstrap";

// Icons FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBinoculars, faEye, faLongArrowAltLeft, faPenNib, faTruck } from "@fortawesome/free-solid-svg-icons";


import "./SheduledComponent.css";

import { ISheduledComponentProps } from "./ISheduledComponentProps";
import { BillGet } from "../../Models/Bill";
import OfficesModalComponent from "./components/OfficesModalComponent/OfficesModalComponent";
import { Product } from "../../Models/Product";
import { isDebuggerStatement } from "typescript";
//import NewsDispatchedModalComponent from "../OfficesComponent/components/NewsDispatchedModalComponent/NewsDispatchedModalComponent";
const SheduledComponent: React.SFC<ISheduledComponentProps> = (props) => {
  const [newsShow, setNewsShow] = React.useState(false);
  const [packOffShow, setPackOffShow] = React.useState(false);
  const [scheduledShow, setScheduledShow] = React.useState(false);
  const [newsDispatchModal, setNewsDispatchModal] = React.useState(false);



  const handleClose = () => {
    setNewsShow(false);
    setValidated(false);
  };
  const handleClose2 = () => {
    setPackOffShow(false);
    setValidated(false);
  };
  const handleClose3 = () => {
    setScheduledShow(false);
    setToDisable(false);
    setValidated(false);
  }

  const handleClose4 = () => {
    setNewsDispatchModal(false)
  };


  const [selectedData, setSelectedData] = React.useState<any>({
    id: 0,
    name: '',
    address: '',
    phone: '',
    document: '',
    email: '',
    company: '',
    consigned_Value: 0,
    typeConsigned_value: 'Con Recaudo',
    created_at: '',
    updated_by: 0,
    confirmed: true,
    observations: '',
    statusId: 0,
    status: '',
    sellerId: 0,
    seller: '',
    cityId: 0,
    city: '',
    provinceId: 0,
    province: '',
    company_TransportId: 0,
    company_Transport: '',
    customerId: 0,
    customer: '',
    collect_Value: 0,
    number_account: '',
    guia: '',
    packing: '',
    packingQuantity: 0,
    bill_Products: [],
    billNews: [],
    totalPendingBillNews: 0,
    TotalSolvedBillNews: 0,
    products: "",
    delivered_at:""
  });

  const {
    getBill,
    bill,
    billNews,
    getSellersList,
    sellersList,
    getCities,
    CitiesList,
    getProductsList,
    ProductsList,
    quantity,
    setQuantity,
    handleInputChange,
    handleSubmit,
    validated,
    setValidated,
    setSelectedProduct,
    pushProduct,
    toDisable,
    setToDisable,
    loading,
    handleDispatch,
    deleteProduct,
    key,
    setKey,
    handleNews,
    news
  } = useSheduledComponent(selectedData, setSelectedData, handleClose3, handleClose2, handleClose);




  React.useEffect(() => {
    getBill(localStorage.customerId);
    getSellersList(localStorage.customerId);
    getCities();
    getProductsList(localStorage.customerId);
  }, [])




  const [columns, setColumns] = React.useState([
    { title: "ID", field: "id" },
    {
      title: 'Datos Cliente', field: 'id', render: (rowData: BillGet) =>
        `${rowData.name} - ${rowData.address} - ${rowData.phone}`
    },
    {
      title: 'Detalle', field: 'id', render: (rowData: any) =>
        <FontAwesomeIcon className="cursor" onClick={() => seeScheduled(rowData)} icon={faBinoculars} />
    },
    { title: "Vendedora", field: "seller" },
    { title: "Estado", field: "status" },
    {
      title: 'Crear Novedad', field: 'id', render: (rowData: any) =>
        <FontAwesomeIcon className="cursor" onClick={() => seeNews(rowData)} icon={faPenNib} />
    },
    {
      title: 'Novedades', field: 'id', render: (rowData: BillGet) =>
        rowData.billNews.length > 0 ? <FontAwesomeIcon className="cursor" onClick={() => seeNewsModalDispatch(rowData)} icon={faEye} /> : '-'
    },
    {
      title: 'Despachar', field: 'id', render: (rowData: any) =>
        <FontAwesomeIcon className="cursor" onClick={() => packOff(rowData)} icon={faTruck} />
    },
  ]);



  const seeNews = (data: any) => {
    // debugger
    setSelectedData(data);
    setNewsShow(true);
  }


  const packOff = (data: any) => {
    setSelectedData(data);
    setPackOffShow(true);
  }

  const seeScheduled = (data: any) => {
    setSelectedData(data);
    setScheduledShow(true);
  }

  const seeNewsModalDispatch = (data: any) => {
    setSelectedData(data);
    setNewsDispatchModal(true);
  }


  return (
    <>
     
      <div className="m-5">

        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(key: any) => setKey(key)}
        >
          <Tab eventKey="general" title="General">
            <MaterialTable
              title="Programados"
              columns={columns}
              data={bill}
              isLoading={loading}
            />
          </Tab>
          <Tab eventKey="novedades" title="Novedades">
            <MaterialTable
              title="Programados"
              columns={columns}
              data={billNews}
              isLoading={loading}
            />
          </Tab>
        </Tabs>



        <Modal show={newsShow} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Añadir Novedad</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <NewsModalComponent
              news={news}
              handleClose={handleClose}
              selectedData={selectedData}
              handleInputChange={handleInputChange}
              loading={loading}
              handleNews={handleNews}
              validated={validated}
            />

          </Modal.Body>
        </Modal>

        <Modal show={packOffShow} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Despacho</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <OfficesModalComponent
              handleDispatch={handleDispatch}
              selectedData={selectedData}
              handleInputChange={handleInputChange}
              validated={validated}
              handleClose2={handleClose2}
              CitiesList={CitiesList}
            />

          </Modal.Body>

        </Modal>

        <Modal
          size="lg"
          centered
          show={scheduledShow}
          onHide={handleClose3}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Editar Programado
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormModalComponent
              selectedData={selectedData}
              validated={validated}
              handleInputChange={handleInputChange}
              handleSubmit={handleSubmit}
              setSelectedProduct={setSelectedProduct}
              handleClose3={handleClose3}
              sellersList={sellersList}
              CitiesList={CitiesList}
              ProductsList={ProductsList}
              setQuantity={setQuantity}
              quantity={quantity}
              pushProduct={pushProduct}
              toDisable={toDisable}
              setToDisable={setToDisable}
              loading={loading}
              deleteProduct={deleteProduct}
            />
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          centered
          show={newsDispatchModal}
          onHide={handleClose4}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Novedades Despachadas
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <NewsDispatchedModalComponent
              selectedData={selectedData}
              handleClose={handleClose4}
            /> */}
          </Modal.Body>
        </Modal>
      </div>

      {/* <FooterComponent /> */}
    </>
  );
};

export default SheduledComponent;
