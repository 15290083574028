import { notification } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import LiquidationAPI from "../../../Api/api-liquidation";
import WarehouseAPI, { warehouse } from "../../../Api/api-warehouse";
import { Liquidation } from "../../../Models/Liquidation";

export const useCurrierLiquidation = () => {

    const [LiquidationList, setLiquidationList] = useState<Liquidation[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedWareHouse, setSelectedWareHouse] = useState<number>(0);
    const [WarehousesList, setWarehousesList] = useState<warehouse[]>([]);
    const [selectedDate, setSelectedDate] = useState<any>([]);

    useEffect(() => {
        getWarehouse();
    }, []);

    const getLiquidationList = async (e:any) => {
        setLoading(true);
        LiquidationAPI.GetLiquidations(moment(selectedDate[0]).format('YYYY-MM-DD'),moment(selectedDate[1]).format('YYYY-MM-DD'),selectedWareHouse).then(res => {
            setLiquidationList(res);  
            setLoading(false);
        })
    }

    const DeleteLiquidation = async () => {
        setLoading(true);
        LiquidationAPI.DeleteLiquidation(moment(selectedDate[0]).format('YYYY-MM-DD'),moment(selectedDate[1]).format('YYYY-MM-DD'),selectedWareHouse).then(res => {
            setLiquidationList(res);  
            setLoading(false);
        })
    }

    const getWarehouse = () => {
        WarehouseAPI.getWarehouse().then(res=>{
            setWarehousesList(res);
        })
    }

    const postLiquidation = async (liqui:Liquidation) => {
        setLoading(true);
        LiquidationAPI.postLiquidation(liqui).then(res => {
            notification.success({message: 'Los datos fueron guardados'})  
            setLoading(false);
        })
    }

    const handleUpdate = (e: any) => {
        console.log(e.data)
        let collect = LiquidationList.filter(x => x.id === e.data.id)[0].system_Value;
        let paloma = LiquidationList.filter(x => x.id === e.data.id)[0].paloma_Consignation_Value;
        let client = LiquidationList.filter(x => x.id === e.data.id)[0].client_Consignation_Value;
        let expenses = LiquidationList.filter(x => x.id === e.data.id)[0].expenses;
        LiquidationList.filter(x => x.id === e.data.id)[0].total = collect - paloma - client - expenses;
        setLiquidationList(LiquidationList);
        let liqui = LiquidationList.filter(x => x.id === e.data.id)[0];
        postLiquidation(liqui)
    }

    return{
        LiquidationList,
        handleUpdate,
        getLiquidationList,
        loading,
        WarehousesList,
        setSelectedWareHouse,
        DeleteLiquidation,
        setSelectedDate,
        selectedDate
    }
}