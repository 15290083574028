import { Drawer, message, Spin, Timeline, Alert } from 'antd'
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import billsAPI from '../../Api/api-bills';
import { BillStatus } from '../../Models/Bill';
import { BillNews } from '../../Models/BillNews';
import { Product } from '../../Models/Product';

type DrawerNewsProps = {
    visibleDrawerNews: boolean,
    setVisibleDrawerNews: Function,
    billSelected: BillStatus
}

export const DrawerNews = ({ visibleDrawerNews, setVisibleDrawerNews, billSelected }: DrawerNewsProps) => {

    const onClose = () => {
        setVisibleDrawerNews(false);
    }

    const [bill, setBill] = useState<BillGet>({});
    const [loadingBill, setLoadingBill] = useState(false);
    const getBillDate = async ({ id, guia }: BillStatus) => {
        
        let payload = {
            id,
            guia
        }
        setLoadingBill(true);
        try {
            const bill = await billsAPI.getBillsById(payload);
            setBill(bill)

        } catch (error) {
            message.error(`No se pudo obtener el historico de novedades. ${error}`);
        }
        setLoadingBill(false);
    }

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            getBillDate(billSelected);
        }
        return () => {
            mounted = false;
        }
    }, [billSelected])

    return (
        <Drawer
            title="Historico Novedades"
            placement="right"
            visible={visibleDrawerNews}
            onClose={onClose}
            closable={true}
            width={450}
        >
            <Spin tip="Cargando Historico..." spinning={loadingBill}>
                {bill.billNews?.length !== 0 &&
                    <>
                        <h6>Fecha creación guia: {moment(bill.created_at).format('DD-MM-YYYY, h:mm:ss a')}</h6>
                        {bill.status === 'Entregado' &&
                            <>
                                <h6>Estado: {bill.status}</h6>
                                <h6>Fecha entrega: {moment(bill.delivered_at).format('DD-MM-YYYY, h:mm:ss a')}</h6>
                            </>
                        }
                        <Timeline className="mt-5">
                            {bill.billNews?.map((news: any) =>
                                <Timeline.Item key={news.id} color={news.solution !== null ? 'green' : 'red'}>
                                    <>
                                        <h6>{news.newsBillingType}</h6>
                                        <p>Fecha: {moment(news.created_at).format('DD-MM-YYYY, h:mm:ss a')}</p>
                                        <p>Descripción: {news.description}</p>
                                        {news.solution !== null &&
                                            <p>Solución: {news.solution}</p>
                                        }
                                    </>
                                </Timeline.Item>
                            )}
                        </Timeline>
                    </>
                }
                {bill.billNews?.length == 0 &&
                    <Alert
                        message="No hay historico de novedades para este pedido"
                        description="Seleccione otro pedido bodega"
                        type="warning"
                        showIcon
                    />
                }
            </Spin>
        </Drawer>
    )
}


type BillGet = {
    id?: number,
    name?: string,
    address?: string,
    phone?: string,
    document?: string,
    email?: string,
    company?: string,
    consigned_Value?: number,
    typeConsignedValue?: 'Con Recaudo' | 'Sin Recaudo',
    created_at?: string,
    updated_by?: number,
    confirmed?: boolean,
    observations?: string,
    statusId?: number,
    status?: string,
    sellerId?: number,
    seller?: string,
    cityId?: number,
    city?: string,
    provinceId?: number,
    province?: string,
    company_TransportId?: number,
    company_Transport?: string,
    customerId?: number,
    customer?: string,
    collect_Value?: number,
    number_account?: string,
    guia?: string,
    packing?: string,
    packingQuantity?: number,
    bill_Products?: Product[],
    billNews?: BillNews[],
    totalPendingBillNews?: number,
    totalSolvedBillNews?: number,
    products?: string,
    delivered_at?: string,
    warehoseId?: number,
}