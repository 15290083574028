import { Button, Form, Input, message, Modal, Select } from 'antd'
import React, { useRef, useState } from 'react'
import billsAPI from '../../Api/api-bills';

type CreateNewsModalProps = {
    isModalVisible: any,
    setIsModalVisible: any,
    newsModal: any,
    getNewsCourier : any
}

const { TextArea } = Input;
const { Option } = Select;
export const CreateNewsModal = ({ isModalVisible, setIsModalVisible, newsModal, getNewsCourier}: CreateNewsModalProps) => {


    const [news, setNews] = useState({
        description: '',
        newsBillingType: 'Novedad'
    });
    const formNewsRef = useRef<any>();



    const [loading, setloading] = useState(false);
    const handleSubmit = async () => {
        if(Object.keys(news).length === 0){
            message.error(`Complete todos los campos`);
            return;
        }

        if (news.description !== '') {
            setloading(true);
            try {
                let payload = {
                    billId: newsModal.id,
                    changeStatus: true,
                    ...news
                }
                await billsAPI.createBillsNews(payload);
                message.success('Se creo correctamente la novedad');
                getNewsCourier();
            } catch (error) {
                message.error(`No se pudo crear la novedad`);
            }
            setloading(false);
            setIsModalVisible(false);
            formNewsRef.current.resetFields();
        } else {
            message.error(`Complete todos los campos.`)
        }
    }

    const handleInput = ({ target }: any) => {
        const { name, value } = target;
        setNews({
            ...news,
            [name]: value
        })
    }

    const handleSelect = (value: any, name: string) => {
        setNews({
            ...news,
            [name]: value
        })
    }

    /* const handleError = ( errors : any ) => {
        console.log(object)
    } */


    return (
        <>
            <Modal
                title="Añadir Novedad"
                visible={isModalVisible}
                onOk={handleSubmit}
                onCancel={() => setIsModalVisible(false)}
                footer={[
                    <Button type="ghost" onClick={() => setIsModalVisible(false)}>Cancelar</Button>,
                    <Button type="primary" onClick={handleSubmit} loading={loading}>Guardar</Button>
                ]}
            >
                <Form
                    ref={formNewsRef}
                >
                    <Form.Item
                        name="description"
                        rules={[{ required: true, message: 'Por favor escriba la descripción de la novedad' }]}
                    >
                        <TextArea rows={4} placeholder="Escriba su novedad..." onChange={handleInput} name="description" />
                    </Form.Item>
                    {/* <Form.Item
                        name="newsBillingType"
                        rules={[{ required: true, message: 'Seleccione un tipo de novedad' }]}
                    >
                        <Select placeholder="Tipo de novedad" onChange={(v) => handleSelect(v, 'newsBillingType')}>
                            <Option value={'Novedad Cliente'}>Novedad Cliente</Option>
                            <Option value={'Novedad Paloma'}>Novedad Paloma</Option>
                        </Select>
                    </Form.Item> */}
                </Form>
            </Modal>
        </>
    )
}
