import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import { useAlertMessage } from './useAlertMessage';


export const useManageErrors = () => {
    
    const {Confirm} = useAlertMessage();
    const onError = (error:any) => {
        if (error.response) {
            let text = error.response.data ? error.response.data : error.response.statusText;
            text = text.title ? text.title : text;
            Confirm(error.response.status,text,undefined,error.message);
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            console.log('request',error.request);
            Confirm(0,error.message,undefined,undefined);
        } else {
            console.log('Error', error.message);
            Confirm(0,error.message,undefined,undefined);
        }
        
        console.log('config',error.config);
    }

    return {
        onError
    }
}