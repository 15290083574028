import * as React from 'react';
import { IAdminFooterProps } from './IAdminFooterProps';
import './AdminFooter.css';
import { Row, Col, Input, Select, Menu, Form, Button } from 'antd';
import Layout, { Header } from 'antd/lib/layout/layout';
import { ciudadBolivar, Sohacha, Kenedy, Zona1, SurBajo, SurAlto, fontibon, chapineroCentro, engativa, funzaMosqueraMadrid, subaBaja, Bosa, subaMedia, subaAlta, usme } from '../Mapa/CoordenadasZonas';
const { Search } = Input;
const { Option } = Select;

const AdminFooter: React.SFC<IAdminFooterProps> = (props) => {

  const handleChangeBodega = (e: number) => {
    props.setBodegaSeleccionada(e);
    props.setCentrarMapa(true);
    let arrayMensajeros = props.mensajeros.filter((x: any) => x.department === e);
    props.setMensajerosFiltrados(arrayMensajeros);
  }

  const handleChangeZonaSeleccionada = (e: string) => {
    props.setzonaSeleccionada(e);
    switch (e) {
      case 'ciudadBolivar': props.setCenterMap(ciudadBolivar[0]);
        break;
      case 'usme': props.setCenterMap(usme[0]);
        break;
      case 'Sohacha': props.setCenterMap(Sohacha[0]);
        break;
      case 'Kenedy': props.setCenterMap(Kenedy[0]);
        break;
      case 'Zona1': props.setCenterMap(Zona1[0]);
        break;
      case 'SurBajo': props.setCenterMap(SurBajo[0]);
        break;
      case 'SurAlto': props.setCenterMap(SurAlto[0]);
        break;
      case 'fontibon': props.setCenterMap(fontibon[0]);
        break;
      case 'chapineroCentro': props.setCenterMap(chapineroCentro[0]);
        break;
      case 'engativa': props.setCenterMap(engativa[0]);
        break;
      case 'funzaMosqueraMadrid': props.setCenterMap(funzaMosqueraMadrid[0]);
        break;
      case 'subaBaja': props.setCenterMap(subaBaja[0]);
        break;
      case 'subaMedia': props.setCenterMap(subaMedia[0]);
        break;
      case 'subaAlta': props.setCenterMap(subaAlta[0]);
        break;
    }
  }

  return (
    <Row className="adminFooter">
      <Col >
        <label className="color-select">Bodega:&nbsp;</label>
        <Select value={props.bodegaSeleccionada} style={{ width: 180 }} onChange={(e) => handleChangeBodega(e)}>
          <Option value={2}>Cundinamarca</Option>
          <Option value={4}>Antioquia</Option>
          <Option value={3}>Valle</Option>
          <Option value={5}>Atlantico</Option>
          <Option value={6}>Quito</Option>
          <Option value={7}>Guayaquil</Option>
          <Option value={8}>Cuenca</Option>
        </Select>
      </Col>
      {props.estadoSeleccionado === "Enrutado" &&
        <Col >
          <label className="color-select">&nbsp;Mensajero:&nbsp;</label>

          <Select defaultValue="Todos" style={{ width: 180 }} onChange={(e) => props.setMensajeroSeleccionado(e)}>
            <Option value="Todos">Todos</Option>
            {props.mensajerosFiltrados.map((x: any) =>
              <Option value={x.name} key={x.name}>{x.name}</Option>
            )}
          </Select>
        </Col>
      }
      {props.estadoSeleccionado === "Programado" &&
        <Col>
          <label className="color-select">&nbsp; Sector:&nbsp;</label>
          <Select
            value={props.zonaSeleccionada}
            style={{ width: 180 }}
            onChange={(e) => handleChangeZonaSeleccionada(e)}>
            <Option value="Todas">Todas</Option>
            <Option value="ciudadBolivar">Ciudad Bolivar</Option>
            <Option value="usme">Usme</Option>
            <Option value="Sohacha">Sohacha</Option>
            <Option value="Bosa">Bosa</Option>
            <Option value="Kenedy">Kenedy</Option>
            <Option value="Zona1">Zona 1</Option>
            <Option value="SurBajo">Sur Bajo</Option>
            <Option value="SurAlto">Sur Alto</Option>
            {/* <Option value="chapineroCentro">Chapinero Centro</Option> */}
            <Option value="fontibon">Fontibon</Option>
            <Option value="engativa">engativa</Option>
            {/* <Option value="funzaMosqueraMadrid">Funza Mosquera Madrid</Option> */}
            <Option value="subaBaja">Suba Baja</Option>
            {/* <Option value="subaMedia">Suba Media</Option> */}
            {/* <Option value="subaAlta">Suba Alta</Option> */}
            <Option value="subaChia">Suba Chia</Option>
            <Option value="chico">chico</Option>
            <Option value="norteArriba">Norte Arriba</Option>
            <Option value="norteAbajo">Norte Abajo</Option>
            <Option value="norOccidente">Noroccidente</Option>
            <Option value="sabana">Sabana</Option>
          </Select>
        </Col>
      }
      {/* <Col xs={0} sm={0} md={10} lg={10} xl={10}></Col> */}
      &nbsp;&nbsp;
      <Col>
        <Search placeholder="Buscar Guia" onSearch={props.searchGuia} enterButton />
      </Col>
      &nbsp;&nbsp;
      <Col>
        <Button type="primary" danger ghost onClick={props.quitarFiltro}>Quitar</Button>
      </Col>
    </Row>
  );
};

export default AdminFooter;
