import React, { useRef, useState } from 'react';
import { Divider, Drawer, Form, Row, Col, Select, Input, message, Button } from 'antd';
import { BillStatus } from '../../Models/Bill';
import { Statuses } from '../../Models/Status';
import { SaveOutlined } from '@ant-design/icons';
import billsAPI from '../../Api/api-bills';

type DrawerMessagerProps = {
    billSelected: BillStatus,
    visible: boolean,
    setVisible: Function,
    statuses: Statuses[],
    getRoutes: Function
}

const { Option } = Select;
const { TextArea } = Input;

export const DrawerMessager = ({ billSelected, visible, setVisible, statuses, getRoutes }: DrawerMessagerProps) => {

    const formRef = useRef<any>();
    const [formData, setFormData] = useState<FormData>({
        description: '',
        statusId: 0
    })
    const [news, setNews] = useState<News>({
        id: 0,
        billId: 0,
        newsBillingType: 'Novedad Cliente',
        description: '',
        solution: '',
        changeStatus: false,
        userID: Number(localStorage.userID)
    })
    const onClose = () => {
        setVisible(false);
        formRef.current.resetFields();
    }

    const handleInputChange = ({ target } : any) => {
        const { name, value } = target;
        setFormData({
            ...formData,
            [ name ] : value
        })  
    }

    const handleSelectChange = ( name : string, value : any) => {
        setFormData({
            ...formData,
            [ name ] : value
        })
    }

    const [loadingForm, setLoadingForm] = useState(false);
    const handleSubmit = async( values : FormData ) => {
        setLoadingForm(true);
        try {
            if( values.description !== undefined) {
                news.billId = billSelected.id;
                news.description = formData.description;
                await billsAPI.createBillsNews(news);
                message.success('Se creo la novedad correctamente.');
            }
            
            billSelected.statusId = formData.statusId;
            await billsAPI.UpdateBillHeader(billSelected);
            message.success(`Se actualizó correctamente el pedido`);
            getRoutes();
        } catch (error) {
            message.error(`No se actualizó el pedido. ${error}`);
        }
        setLoadingForm(false);
        onClose();
    }

    return (
        <Drawer
            title={`${billSelected.guia}`}
            placement="right"
            visible={visible}
            onClose={onClose}
            closable={true}
            width='95%'
        >
            <Divider orientation="left">Datos Pedido</Divider>
            <div className="datos">
                <h6>Destinatario</h6>
                <p>Razón Social: {billSelected.businessName}</p>
                <p>Contácto: {billSelected.name} </p>
                <p>Teléfono: {billSelected.phone} </p>
            </div>
            <Divider></Divider>
            <div className="observaciones">
                <h6>Observaciones</h6>
                <ul>
                    <li>
                        {billSelected.observations}
                    </li>
                    <li>
                        {billSelected.aditionalInformation}
                    </li>
                </ul>
            </div>
            <Divider></Divider>
            <h6>Valor a cobrar</h6>
            <p>{billSelected.collect_Value}</p>


            <Divider orientation="left">Novedades Mensajero</Divider>
            <Form
                layout="vertical"
                ref={formRef}
                onFinish={ handleSubmit }
                initialValues={{ statusId: billSelected.statusId}}
            >
                <Row>
                    <Col span={12}>
                        <Form.Item 
                            label="Novedades Predeterminadas" 
                            name="description"
                        >
                            <Select placeholder="Selecciona una novedad" onChange={(value) => handleSelectChange('description', value )}>
                                <Option value="Consignación a cliente">Consignación a cliente</Option>
                                <Option value="Consignación a paloma">Consignación a paloma</Option>
                                <Option value="Consignación a diomer">Consignación a diomer</Option>
                                <Option value="Consignacion a carolina">Consignacion a carolina</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item 
                            label="Observaciones" 
                            name="description"
                        >
                            <TextArea rows={4} name="description" onChange={ handleInputChange } />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Estado" name="statusId">
                            <Select placeholder="Seleccione un estado" onChange={(value) => handleSelectChange('statusId', value )}>
                                {statuses.map(({id, name}) =>
                                    <Option key={id} value={id}>{name}</Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Button 
                            type="primary"
                            icon={<SaveOutlined />}
                            loading={loadingForm}
                            htmlType="submit"
                        >
                            Guardar
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )
}

type FormData = {
    description: string,
    statusId: number
}

type News = {
    id?: number,
    billId?: number,
    newsBillingType?: string,
    description?: string,
    solution?: string,
    created_at?: string,
    update_at?: string,
    userID?: number,
    changeStatus?: boolean
}