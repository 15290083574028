import React, { useState, useEffect } from 'react';
import { IMapaProps } from './IMapaProps';
import './Mapa.css';
import { GoogleMap, LoadScript, Polygon, Marker } from '@react-google-maps/api'
import { Modal, Row, Col, Button, message, Select, InputNumber, Divider, Input, Drawer } from 'antd';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { ciudadBolivar, Sohacha, Kenedy, Zona1, SurBajo, SurAlto, fontibon, chapineroCentro, engativa, funzaMosqueraMadrid, subaBaja, Bosa, subaMedia, subaAlta, usme, sabana, norOccidente, norteAbajo, norteArriba, chico, subaChia } from '../Mapa/CoordenadasZonas';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import utils from '../../../utils';
import billsAPI from '../../../Api/api-bills';

import Paloma from '../../../Assets/Paloma.png'
import { ModalVerGuia } from '../ModalVerGuia';
const { confirm } = Modal;
const { Option } = Select;

const Mapa: React.SFC<IMapaProps> = (props) => {

  const [mapIsReady, setMapIsReady] = useState(false);
  const [ModalVisible, setModalVisible] = useState(false);
  const [guiaSeleccionada, setGuiaSeleccionada]: any = useState({});
  const [billNewsGuia, setBillNewsGuia] = useState<any>([]);
  const [arrayGuiasEnrutar, setArrayGuiasEnrutar]: any = useState([]);
  const [messagerSelect, setMessagerSelect] = useState('');
  const [mensajeroSeleccionado, setMensajeroSeleccionado] = useState(props.mensajeroSeleccionado !== "Todos" ? props.mensajeroSeleccionado : "");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const [drawerSize, setDrawerSize] = useState(24)

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const closeDrawer = () => {
    setArrayGuiasEnrutar([]);
    setDrawerSize(24)
    setMensajeroSeleccionado("-")
    props.setMensajeroSeleccionado("-")
  }

  useEffect(() => {
    if (props.centrarMapa) {
      centerMapByCity();
    }
    if (!mapIsReady) {
      configureGoogleMaps();
    }
  }, [props.bodegaSeleccionada, props.listadoServicios]);

  const configureGoogleMaps = () => {
    const ApiKey = 'AIzaSyB4UgoZSKxV-408XVK4SjZhCvlZBFGJg0M';
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${ApiKey}&libraries=geometry`;
    script.async = true;
    script.defer = true;
    script.addEventListener('load', () => {
      setMapIsReady(true);
    });
    document.body.appendChild(script);
  }

  const mapContainerStyle = {
    height: '85vh',
    width: "100%"
  }

  const centerMapByCity = () => {
    switch (props.bodegaSeleccionada) {
      case 2: props.setCenterMap({ lng: -74.12715911865234, lat: 4.64897361172607 })
        break;
      case 4: props.setCenterMap({ lng: -75.5888933, lat: 6.2058238 })
        break;
      case 3: props.setCenterMap({ lng: -76.5957045, lat: 3.3952332 })
        break;
      case 5: props.setCenterMap({ lng: -74.7889, lat: 10.9878 })
        break;
      case 6: props.setCenterMap({ lng: -78.5706253, lat: -0.1862504 })
    }
    if (props.listadoServicios.length === 1) {
      props.setCenterMap({ lng: props.listadoServicios[0].lng, lat: props.listadoServicios[0].lat })
    }
    props.setCentrarMapa(false);
  }

  function showDetail(service: any) {
    Modal.info({
      title: <div onClick={() => verGuia(service)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>{service.guia}</div>,
      content: (
        <div>
          <b>Descripción:&nbsp;</b>{service.service_Description}<br />
          <b>Razón social:&nbsp;</b>{service.businessName}<br />
          <b>Contacto:&nbsp;</b>{service.name}<br />
          <b>Dirección:&nbsp;</b>{service.address}<br />
          <b>Barrio:&nbsp;</b>{service.barrio_destino}<br />
          <b>Ciudad:&nbsp;</b>{service.city}<br />
          <b>Novedades:&nbsp;
            <Input onBlur={(e) => actualizarNovedad(service, e)}
              defaultValue={service.aditionalInformation}
            />
          </b><br />
          <Row>
            <Button type="primary" onClick={() => {
              service.colorChanged = true;
              actualizarDatosServicio(service);
            }}>Cambiar Color</Button>
          </Row>
          <Row>
            <Col xs={19} sm={19} md={19} lg={19} xl={19}>
              <b>Mensajero:&nbsp;</b>
              <Select
                defaultValue={service.zone} style={{ width: '100%' }}
                onChange={(e) => actualizarMensajero(service, e)}>
                {props.mensajerosFiltrados.map((x: any) =>
                  <Option value={x.name} key={x.name}>{x.name}</Option>
                )}
              </Select>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
              <b>Orden:&nbsp;</b>
              <InputNumber defaultValue={service.delivery_Order} onChange={(e) => actualizarOrdenEntrega(service, e)} />
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
              <Button type="primary" onClick={(e) => enrutarServicio(service)}>Enrutar</Button>
            </Col>
          </Row>
        </div>
      ),
      onOk() {
        console.log(service)

      },
    });
  };

  const crearNovedad = async (service: any, novedad: any) => {
    try {
      let payload = {
        id: 0,
        billId: service.id,
        newsBillingType: 'Novedad Paloma',
        description: novedad,
        solution: '',
        userID: Number(localStorage.userID),
        changeStatus: false
      }

      await billsAPI.createBillsNews(payload);
      message.success(`Se creo correctamente la novedad`);
    } catch (error) {
      message.error(`No se pudo crear la novedad ${error}`);
    }

    props.obtenerServicios(props.estadoSeleccionado);
  }

  const actualizarNovedad = async (service: any, novedad: any) => {
    if (service.novedades_entrega !== novedad.currentTarget.value) {
      let textoNovedad = novedad.currentTarget.value;
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: "Actualizar las novedades de este servicio?",
        onOk() {
          crearNovedad(service, novedad.target.value);
          // service.novedades_entrega = textoNovedad;
          // actualizarDatosServicio(service);
        },
        onCancel() {
          props.obtenerServicios(props.estadoSeleccionado)
        }
      });
    }
  }

  const actualizarMensajero = (service: any, mensajero: string) => {
    service.zone = mensajero;
    service.statusId = 8;
    // actualizarDatosServicio(service);
  }

  const actualizarOrdenEntrega = (service: any, orden: any) => {
    service.delivery_Order = orden;
    // actualizarDatosServicio(service);
  }

  const enrutarServicio = (service: any) => {
    actualizarDatosServicio(service);
  }

  const verGuia = async (service: any) => {
    try {
      let payload = {
        id: service.id,
        guia: service.guia
      }
      let bill = await billsAPI.getBillsById(payload);
      let billNews = bill.billNews.filter((bill: any) => bill.newsBillingType === 'Novedad Cliente');
      setGuiaSeleccionada(bill);
      setBillNewsGuia(billNews)
      setModalVisible(true);
    } catch (error) {
      message.error(`No se pudo obtener la información del servicio. ${error}`);
    }
    // let arrayItems: any = [];
    // arrayItems.push(service);
    // let billNews : any = service.billNews

    // console.log(billNews)
    // setGuiaSeleccionada(service);
    // setBillNewsGuia(billNews)
    // setModalVisible(true);
  }

  const getNewCoordinates = (coord: any, service: any) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    confirm({
      title: 'Quieres cambiar la ubicación de este servicio?',
      icon: <ExclamationCircleOutlined />,
      content: <div>
        <b>Descripción:&nbsp;</b>{service.service_Description}<br />
        <b>Razón social:&nbsp;</b>{service.businessName}<br />
        <b>Contacto:&nbsp;</b>{service.name}<br />
        <b>Dirección:&nbsp;</b>{service.address}<br />
        <b>Sector:&nbsp;</b>{service.sector}<br />
      </div>,
      onOk() {
        service.lat = lat;
        service.lng = lng;
        actualizarDatosServicio(service);
        // utils.checkServiceInSector(service).then(res => {
        //   debugger
        //   let data = [...props.listadoServicios as any]
        //   service.sector = res;
        //   let index = data.map(function (e) { return e.guia; }).indexOf(service.guia);
        //   data[index].numero_cuenta_cobro = 1;
        //   props.setListadoServicios(data);
        //   actualizarDatosServicio(service);
        // });
        props.setCenterMap({ lng: lng, lat: lat })
      },
      onCancel() {
        props.obtenerServicios(props.estadoSeleccionado)
      },
    });
  };

  const actualizarDatosServicio = async (service: any, novedad?: any) => {
    try {


      // if(novedad !== '') {
      //   await billsAPI.createBillsNews(payload);
      //   message.success(`Novegad Creada`);
      // }

      service.colorChanged = true;
      await billsAPI.UpdateBillHeader(service);
      message.success('Servicio Actualizado');
      console.log(service)
    } catch (error) {
      message.error(`No se creó la novedad. ${error}`)
    }
    props.obtenerServicios(props.estadoSeleccionado);
    // try {
    //   await billsAPI.UpdateBillHeader(service);
    //   message.success('Servicio Actualizado');
    // } catch (error) {
    //   message.error(`El servicio no se pudo actualizar. ${error}`);
    // }
    // servicesApi.getServicesByGuia(service.guia).then((res:service)=>{
    //   res.numero_cuenta_cobro = service.numero_cuenta_cobro;
    //   res.lat = service.lat;
    //   res.lng = service.lng;
    //   res.orden_entrega = service.orden_entrega;
    //   res.novedades_entrega = service.novedades_entrega;
    //   res.zona = service.zona;
    //   servicesApi.update(res).then(json => {
    //     message.success("servicio actualizado");
    //   })
    //     .catch(err => message.error('se presentó un error, intentelo nuevamente'))
    // });
  }

  const icons = [
    'http://maps.google.com/mapfiles/ms/micons/blue.png',
    'http://maps.google.com/mapfiles/ms/micons/red.png',
    'http://maps.google.com/mapfiles/ms/micons/green.png',
    'http://maps.google.com/mapfiles/ms/micons/lightblue.png',
    'http://maps.google.com/mapfiles/ms/micons/orange.png',
    'http://maps.google.com/mapfiles/ms/micons/pink.png',
    'http://maps.google.com/mapfiles/ms/micons/purple.png',
    'http://maps.google.com/mapfiles/ms/micons/blue.png',
    'http://maps.google.com/mapfiles/ms/micons/red.png',
    'http://maps.google.com/mapfiles/ms/micons/green.png',
    'http://maps.google.com/mapfiles/ms/micons/lightblue.png',
    'http://maps.google.com/mapfiles/ms/micons/orange.png',
    'http://maps.google.com/mapfiles/ms/micons/pink.png',
    'http://maps.google.com/mapfiles/ms/micons/purple.png',
  ]

  const options = {
    fillOpacity: 0.4,
    strokeColor: "red",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
  }

  //--Ruta mensajeros
  const actualizarOrden = (i: any, value: any) => {
    let arrayAux = [...arrayGuiasEnrutar]
    arrayAux[i].delivery_Order = value;
    setArrayGuiasEnrutar(arrayAux);
  }

  const eliminarServicio = (item: any) => {
    let arrayItems = arrayGuiasEnrutar.filter((x: any) => x.guia !== item.guia);
    setArrayGuiasEnrutar(arrayItems);
  }

  const selecteService = (item: any) => {
    let itemExist = arrayGuiasEnrutar.find((x: any) => x.guia == item.guia);
    if (itemExist) {
      setVisible(true);
      return;
    }
    let arrayItems = [...arrayGuiasEnrutar as any];
    arrayItems.push(item);
    let contador = 0;
    let arraySum = arrayItems.map(x => {
      contador += 5;
      return {
        ...x,
        delivery_Order: contador
      }
    })
    setArrayGuiasEnrutar(arraySum);
    setVisible(true);
    setDrawerSize(16)

  }

  const guardarRuta = () => {
    if (props.mensajeroSeleccionado !== 'Todos') {
      setLoading(true);
      try {
        arrayGuiasEnrutar.forEach(async (service: any) => {
          let bill = service;
          bill.zone = props.mensajeroSeleccionado;
          bill.statusId = 8
          await billsAPI.UpdateBillHeader(bill);
          message.success('Se enrutaron los servicios correctamente')
        });
      } catch (error) {
        message.error(`No se enrutaron los servicios. ${error}`);
      }
      setVisible(false);
      setLoading(false);
      props.obtenerServicios(props.estadoSeleccionado)
    } else {
      message.error('Seleccione un mensajero para enrutar.')
    }
    // setLoading(true);
    // if (props.mensajeroSeleccionado != "") {
    //   arrayGuiasEnrutar.map((item: service, i: number) => {
    //     item.zona = props.mensajeroSeleccionado;
    //     item.estado = "Enrutado";
    //     servicesApi.update(item).then((json) => {
    //       if (arrayGuiasEnrutar.length === i + 1) {
    //         message.success("servicio actualizado");
    //         window.location.reload(false);
    //       }
    //     });
    //   })
    // }
    // else {
    //   message.warn("Debes seleccionar un mensajero");
    //   setLoading(false);
    // }
  }

  const cargarServiciosSector = () => {
    setArrayGuiasEnrutar([])
    let arrayItems: any = [];
    props.listadoServicios.map((item: any, index: number) => {
      if (props.mensajeroSeleccionado !== "Todos") {
        if (item.zone == props.mensajeroSeleccionado) {
          arrayItems.push(item);
        }
        if (index + 1 == props.listadoServicios.length) {
          setArrayGuiasEnrutar(arrayItems);
        }
      }
      else {
        if (item.sector == props.zonaSeleccionada) {
          arrayItems.push(item);
        }
        if (index + 1 == props.listadoServicios.length) {
          setArrayGuiasEnrutar(arrayItems);
        }
      }
    })
  }

  return (
    <div className="mapa">
      <Row>
        <Col span={drawerSize}>
          <LoadScript
            id="script-loader"
            googleMapsApiKey="AIzaSyB4UgoZSKxV-408XVK4SjZhCvlZBFGJg0M"
          >
            <GoogleMap
              id="marker-example"
              mapContainerStyle={mapContainerStyle}
              zoom={12}
              center={props.centerMap}
            >
              {props.zonaSeleccionada == "ciudadBolivar" &&
                <Polygon
                  paths={ciudadBolivar}
                  options={options}
                />
              }
              {props.zonaSeleccionada == "usme" &&
                <Polygon
                  paths={usme}
                  options={options}
                />
              }
              {props.zonaSeleccionada == "Sohacha" &&
                <Polygon
                  paths={Sohacha}
                  options={options}
                />
              }
              {props.zonaSeleccionada == "Bosa" &&
                <Polygon
                  paths={Bosa}
                  options={options}
                />
              }
              {props.zonaSeleccionada == "SurAlto" &&
                <Polygon
                  paths={SurAlto}
                  options={options}
                />
              }
              {props.zonaSeleccionada == "Kenedy" &&
                <Polygon
                  paths={Kenedy}
                  options={options}
                />
              }
              {props.zonaSeleccionada == "Zona1" &&
                <Polygon
                  paths={Zona1}
                  options={options}
                />
              }
              {props.zonaSeleccionada == "SurBajo" &&
                <Polygon
                  paths={SurBajo}
                  options={options}
                />
              }
              {props.zonaSeleccionada == "chapineroCentro" &&
                <Polygon
                  paths={chapineroCentro}
                  options={options}
                />
              }
              {props.zonaSeleccionada == "fontibon" &&
                <Polygon
                  paths={fontibon}
                  options={options}
                />
              }
              {props.zonaSeleccionada == "engativa" &&
                <Polygon
                  paths={engativa}
                  options={options}
                />
              }
              {props.zonaSeleccionada == "funzaMosqueraMadrid" &&
                <Polygon
                  paths={funzaMosqueraMadrid}
                  options={options}
                />
              }
              {props.zonaSeleccionada == "subaBaja" &&
                <Polygon
                  paths={subaBaja}
                  options={options}
                />
              }
              {props.zonaSeleccionada == "subaMedia" &&
                <Polygon
                  paths={subaMedia}
                  options={options}
                />
              }
              {props.zonaSeleccionada == "subaAlta" &&
                <Polygon
                  paths={subaAlta}
                  options={options}
                />
              }

              {props.zonaSeleccionada == "subaChia" &&
                <Polygon
                  paths={subaChia}
                  options={options}
                />
              }
              {props.zonaSeleccionada == "chico" &&
                <Polygon
                  paths={chico}
                  options={options}
                />
              }
              {props.zonaSeleccionada == "norteArriba" &&
                <Polygon
                  paths={norteArriba}
                  options={options}
                />
              }
              {props.zonaSeleccionada == "norteAbajo" &&
                <Polygon
                  paths={norteAbajo}
                  options={options}
                />
              }
              {props.zonaSeleccionada == "norOccidente" &&
                <Polygon
                  paths={norOccidente}
                  options={options}
                />
              }
              {props.zonaSeleccionada == "sabana" &&
                <Polygon
                  paths={sabana}
                  options={options}
                />
              }
              {props.listadoServicios.map((x: any) =>
                <>
                  <Marker
                    label={{
                      text: x.delivery_Order.toString(),
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                    animation={2}
                    position={{ lat: x.lat, lng: x.lng }}
                    onRightClick={(e) => showDetail(x)}
                    onClick={(e) => selecteService(x)}
                    icon={props.zonaSeleccionada == x.sector ? Paloma : x.colorChanged === true ? icons[2] : icons[1]}
                    //icon={icons[props.mensajerosFiltrados.map(function(e:any) { return e.name; }).indexOf(x.zona)]}
                    key={x.id}
                    draggable={true}
                    onDragEnd={(coord: any) => getNewCoordinates(coord, x)}
                  />
                </>
              )}

            </GoogleMap>
          </LoadScript>
        </Col>


        <Col span={arrayGuiasEnrutar.length !== 0 ? 8 : 0} style={{ maxHeight: '90vh', overflowY: 'auto' }}>
          <Row justify="end">
            <Col>
              &nbsp;
              <CloseOutlined style={{ fontSize: '20px' }} onClick={closeDrawer} />
            </Col>
          </Row>
          <Row style={{ padding: 50 }}>
            <Col span={16}>
              Mensajero:
              <Select
                style={{ width: '95%' }}
                value={props.mensajeroSeleccionado !== "Todos" ? props.mensajeroSeleccionado : mensajeroSeleccionado}
                onChange={(e) => {
                  setMensajeroSeleccionado(e);
                  props.setMensajeroSeleccionado(e)
                }}
              >
                <Option value={"-"}>-</Option>
                {props.users.map((x: any) => (
                  <Option value={x.name}>{x.name}</Option>
                ))}
              </Select>
            </Col>
            {props.zonaSeleccionada !== "Todas" &&
              <Col span={4} style={{ marginTop: '21px' }}>
                &nbsp;
                <Button type="primary" onClick={cargarServiciosSector}>Cargar</Button>
              </Col>
            }
            <Col span={4} style={{ marginTop: '21px' }}>
              &nbsp;
              <Button type="ghost" danger onClick={closeDrawer}>Vaciar</Button>
            </Col>
          </Row>
          {arrayGuiasEnrutar.map((value: any, i: number) =>
            <div style={{ padding: 5 }}>
              <>
                <Row justify="space-between">
                  <Col >
                    <InputNumber
                      value={value.delivery_Order}
                      onChange={(e) => actualizarOrden(i, e)}
                    />
                  </Col>
                  <Col >
                    <div onClick={() => verGuia(value)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>{value.guia}</div>
                    {value.address}
                  </Col>
                  <Col >
                    <DeleteOutlined onClick={() => eliminarServicio(value)} style={{ color: 'red' }} />
                  </Col>
                </Row>
                <Divider />
              </>
            </div>
          )}
          {arrayGuiasEnrutar.length > 0 &&
            <Row>
              <Col span={24}>
                <Button type="primary" loading={loading} onClick={guardarRuta} disabled={props.mensajeroSeleccionado == "-"}>Guardar</Button>
              </Col>
            </Row>
          }
        </Col>
      </Row>

      {/* {guiaSeleccionada.length > 0 && */}
      <ModalVerGuia
        setModalGuiaVisible={setModalVisible}
        modalGuiaVisible={ModalVisible}
        guiaSelected={guiaSeleccionada}
        billNewsGuia={billNewsGuia}
      />
      {/* } */}
    </div>
  );
};

export default Mapa;
