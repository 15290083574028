import React, { useState, useEffect } from 'react';
// import GoogleMapReact from 'google-map-react';
import Geocode from "react-geocode"; 
import { message, } from 'antd';
// import servicesAPI from '../../api/api-service';
import { 
  ciudadBolivar, 
  Sohacha, 
  Kenedy, 
  Zona1, 
  SurBajo, 
  SurAlto, 
  fontibon, 
  chapineroCentro, 
  engativa, 
  funzaMosqueraMadrid, 
  subaBaja, 
  Bosa, 
  subaMedia, 
  subaAlta,
  subaChia,
  chico,
  sabana,
  norteArriba,
  norteAbajo,
  norOccidente
} from './Mapa/CoordenadasZonas';
import billsAPI from '../../Api/api-bills';
Geocode.setRegion("co");
Geocode.setApiKey("AIzaSyB4UgoZSKxV-408XVK4SjZhCvlZBFGJg0M");

const GeoCoder = (props) => {

    useEffect(() => {
        getCoordinates(props.listadoServicios);
    }, []);

    const key = 'updatable';

    const getCoordinates = async(listadoServicios) => {
        message.loading({ content: 'Calculando coordenadas...', key });
        let array = [];
        let index = 0;
        listadoServicios.map((x, i) => {
          if(x.lat !== null || x.lat !== 0){
            checkServiceInSector(x).then( async(item) => {
              x.sector = item;
              await billsAPI.UpdateBillHeader(x);
            })
          } else {
            index++;
            let el = {
              lat: x.lat,
              lng: x.lng,
              servicio: x
            }
            array.push(x)
            if (index + 1 === listadoServicios.length) {
              message.success({ content: 'Coordenadas calculadas!', key, duration: 3 });
              console.log(array)
              props.setArrayCoordinates(array);
              props.setGettingCoordinates(false);
            }
          }
          // if (x.lat === null || x.lat === 0) {
          //   Geocode.fromAddress(`${x.address} ${x.city} ${x.province}`).then(
          //     response => {
          //       index++;
          //       const { lat, lng } = response.results[0].geometry.location;
          //       let el = {
          //         lat: lat,
          //         lng: lng,
          //         servicio: x
          //       }
          //       x.lat = lat;
          //       x.lng = lng;
          //       checkServiceInSector(x).then(async(item) => {
          //         // debugger
          //         x.sector = item;
          //         await billsAPI.UpdateBillHeader(x);
          //         // billsAPI.UpdateBillHeader(x).then(json => {console.log(`${json} servicio actualizado`)})
          //       })
          //       array.push(x)
          //       if (index + 1 === listadoServicios.length) {
          //         message.success({ content: 'Coordenadas calculadas!', key, duration: 3 });
          //         props.setArrayCoordinates(array)
          //         props.setGettingCoordinates(false);
          //       }
          //     },
          //     error => {
          //       index++;
          //       x.lat = 4.733832386205059;
          //       x.lng = -73.8720703125;
          //       checkServiceInSector(x).then(async(item) => {
          //         // debugger
          //         x.sector = item;
          //         await billsAPI.UpdateBillHeader(x);
          //         // billsAPI.UpdateBillHeader(x).then(json => {console.log(`${json} servicio actualizado`)})
          //       })
          //       if (index + 1 === listadoServicios.length) {
          //           message.success({ content: 'Coordenadas calculadas!', key, duration: 3 });
          //           props.setArrayCoordinates(array)
          //           props.setGettingCoordinates(false);
          //         }
          //       console.error(error);
          //     }
          //   );
          
        })
      }

      const checkServiceInSector = async (service) => {
        const latLng = await new window.google.maps.LatLng({ lat: service.lat , lng: service.lng });
        let draw;
        let containsPlace;
        console.log(service.guia)
        //ciudadBolivar
       /* draw = await new window.google.maps.Polygon({ paths: ciudadBolivar });
        //containsPlace = await window.google.maps.geometry.poly.containsLocation(latLng, draw);
        //if (containsPlace) return "ciudadBolivar";
    
        //Sohacha
        draw = await new window.google.maps.Polygon({ paths: Sohacha });
        //containsPlace = await window.google.maps.geometry.poly.containsLocation(latLng, draw);
        //if (containsPlace) return "Sohacha";
    
        //Kenedy
        draw = await new window.google.maps.Polygon({ paths: Kenedy });
        containsPlace = await window.google.maps.geometry.poly.containsLocation(latLng, draw);
        if (containsPlace) return "Kenedy";
    
        //Zona1
        draw = await new window.google.maps.Polygon({ paths: Zona1 });
        containsPlace = await window.google.maps.geometry.poly.containsLocation(latLng, draw);
        if (containsPlace) return "Zona1";
    
        //SurBajo
        draw = await new window.google.maps.Polygon({ paths: SurBajo });
        containsPlace = await window.google.maps.geometry.poly.containsLocation(latLng, draw);
        if (containsPlace) return "SurBajo";
    
        //SurAlto
        draw = await new window.google.maps.Polygon({ paths: SurAlto });
        containsPlace = await window.google.maps.geometry.poly.containsLocation(latLng, draw);
        if (containsPlace) return "SurAlto";
    
        //fontibon
        draw = await new window.google.maps.Polygon({ paths: fontibon });
        containsPlace = await window.google.maps.geometry.poly.containsLocation(latLng, draw);
        if (containsPlace) return "fontibon";
    
        //chapineroCentro
        draw = await new window.google.maps.Polygon({ paths: chapineroCentro });
        containsPlace = await window.google.maps.geometry.poly.containsLocation(latLng, draw);
        if (containsPlace) return "chapineroCentro";
    
        //engativa
        draw = await new window.google.maps.Polygon({ paths: engativa });
        containsPlace = await window.google.maps.geometry.poly.containsLocation(latLng, draw);
        if (containsPlace) return "engativa";
    
        //funzaMosqueraMadrid
        draw = await new window.google.maps.Polygon({ paths: funzaMosqueraMadrid });
        containsPlace = await window.google.maps.geometry.poly.containsLocation(latLng, draw);
        if (containsPlace) return "funzaMosqueraMadrid";
    
        //SurBajo
        draw = await new window.google.maps.Polygon({ paths: SurBajo });
        containsPlace = await window.google.maps.geometry.poly.containsLocation(latLng, draw);
        if (containsPlace) return "SurBajo";
    
        //Bosa
        draw = await new window.google.maps.Polygon({ paths: Bosa });
        containsPlace = await window.google.maps.geometry.poly.containsLocation(latLng, draw);
        if (containsPlace) return "Bosa";
    
        //subaBaja
        draw = await new window.google.maps.Polygon({ paths: subaBaja });
        containsPlace = await window.google.maps.geometry.poly.containsLocation(latLng, draw);
        if (containsPlace) return "subaBaja";
    
         //subaMedia
         draw = await new window.google.maps.Polygon({ paths: subaMedia });
         containsPlace = await window.google.maps.geometry.poly.containsLocation(latLng, draw);
         if (containsPlace) return "subaMedia";
    
          //subaAlta
          draw = await new window.google.maps.Polygon({ paths: subaAlta });
          containsPlace = await window.google.maps.geometry.poly.containsLocation(latLng, draw);
          if (containsPlace) return "subaAlta";

          //subaChia
          draw = await new window.google.maps.Polygon({ paths: subaChia });
          containsPlace = await window.google.maps.geometry.poly.containsLocation(latLng, draw);
          if (containsPlace) return "subaChia";

          //chico
          draw = await new window.google.maps.Polygon({ paths: chico });
          containsPlace = await window.google.maps.geometry.poly.containsLocation(latLng, draw);
          if (containsPlace) return "chico";

          //sabana
          draw = await new window.google.maps.Polygon({ paths: sabana });
          containsPlace = await window.google.maps.geometry.poly.containsLocation(latLng, draw);
          if (containsPlace) return "sabana";

          //norteArriba
          draw = await new window.google.maps.Polygon({ paths: norteArriba });
          containsPlace = await window.google.maps.geometry.poly.containsLocation(latLng, draw);
          if (containsPlace) return "norteArriba";

          //norteAbajo
          draw = await new window.google.maps.Polygon({ paths: norteAbajo });
          containsPlace = await window.google.maps.geometry.poly.containsLocation(latLng, draw);
          if (containsPlace) return "norteAbajo";

          //norOccidente
          draw = await new window.google.maps.Polygon({ paths: norOccidente });
          containsPlace = await window.google.maps.geometry.poly.containsLocation(latLng, draw);
          if (containsPlace) return "norOccidente";*/
  
      return "OtrosSectores";
      }
      
      return(
          <div></div>
      );
}

export default GeoCoder;