import axios from 'axios';
import { City, cityModel, GetFormCities, Provinces } from '../Models/City';
import { CompanyTrasnport } from '../Models/CompanyTransport';
import utils from '../utils';
import { Utils } from '../utils/utils';

const citiesAPI = {
    getCities() : Promise<GetFormCities[]> {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.get(`${config.data[0].ulrBackend}/cities`)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                            utils.messageError();
                        } else {
                            console.log('Error', error.message);
                            utils.messageError();
                        }
                        console.log(error.config);
                    });
            });
        });
    },
    getAllCities() : Promise<cityModel[]> {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.get(`${config.data[0].ulrBackend}/Cities/GetAllCities`)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                            utils.messageError();
                        } else {
                            console.log('Error', error.message);
                            utils.messageError();
                        }
                        console.log(error.config);
                    });
            });
        });
    },

    getAllCitiesByProvinceId( provinceId : number ) : Promise<cityModel[]> {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.get(`${config.data[0].ulrBackend}/Cities/GetAllCitiesByProvinceId?ProvinceId=${provinceId}`)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                            utils.messageError();
                        } else {
                            console.log('Error', error.message);
                            utils.messageError();
                        }
                        console.log(error.config);
                    });
            });
        });
    },

    getProvinces() : Promise<Provinces[]> {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.get(`${config.data[0].ulrBackend}/Province/GetProvinces`)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                            utils.messageError();
                        } else {
                            console.log('Error', error.message);
                            utils.messageError();
                        }
                        console.log(error.config);
                    });
            });
        });
    },

    getCompanyTrasnports() : Promise<CompanyTrasnport[]> {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.get(`${config.data[0].ulrBackend}/CompanyTransports`)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                            utils.messageError();
                        } else {
                            console.log('Error', error.message);
                            utils.messageError();
                        }
                        console.log(error.config);
                    });
            });
        });
    },

    getCitiesByProvince(provinceId : number, companyTransportId : number) : Promise<CompanyTrasnport[]> {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.get(`${config.data[0].ulrBackend}/Cities/GetCitiesByProvinceAndCompanyTransport?ProvinceId=${provinceId}&companyTransportId=${companyTransportId}`)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                            utils.messageError();
                        } else {
                            console.log('Error', error.message);
                            utils.messageError();
                        }
                        console.log(error.config);
                    });
            });
        });
    },
}

export default citiesAPI;