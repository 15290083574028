import * as React from 'react';
import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { INewsModalComponentProps } from './INewsModalComponentProps';
import './NewsModalComponent.css';

const NewsModalComponent: React.SFC<INewsModalComponentProps> = ( props ) => {

  
  return (
    <>
      <Form noValidate onSubmit={ props.handleNews } validated={ props.validated }>
        <Row>
          <Col>
            <Form.Group controlId="validationDescription">
              <Form.Label>Novedad</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={props.news.description}
                onChange={props.handleInputChange}
                required
              />
            </Form.Group>
            <Form.Control.Feedback type="invalid">
              Este campo es obligatorio.
            </Form.Control.Feedback>
          </Col>
        </Row>

        {/* <Row>
          <Col>
            <Form.Group controlId="validationTypeNews">
              <Form.Label>Tipo Novedad</Form.Label>
              <Form.Control
                as="select"
                name="newsBillingType"
                value={props.news.newsBillingType}
                onChange={props.handleInputChange}
                required
                custom
              >
                <option value="">Seleccionar...</option>
                <option value={'Novedad Cliente'}>Novedad Cliente</option>
                <option value={'Novedad Paloma'}>Novedad Paloma</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Seleccione un tipo de novedad
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row> */}

        <Row>
          <Col>
            <Button variant="primary" type="submit"   disabled={ props.loading }>
              Guardar
              {props.loading &&

                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="ml-3"
                />
              }
            </Button>
          </Col>
          <Col>
            <Button variant="secondary" onClick={props.handleClose} >
              Cancelar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default NewsModalComponent;
