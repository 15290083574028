import { useRef } from 'react'

import { useReactToPrint } from 'react-to-print';

import { Modal, Spin, Table } from 'antd';

import './ModalGuia.css';

export const ModalInventory = ({ modalInventoryVisible, setModalInventoryVisible, loadingBillProducts, billData, selectedRowsKeys }) => {

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    })

    const columns = [
        {
            title: "Producto",
            dataIndex: "productName",
            key: "productName"
        },
        {
            title: "Cantidad",
            dataIndex: "quantity",
            key: "quantity"
        },
        {
            title: "Caja1"
        },
        {
            title: "Caja2"
        },
        {
            title: "Caja3"
        },
        {
            title: "Caja4"
        },
        {
            title: "Caja5"
        },
        {
            title: "Caja6"
        },
        {
            title: "Caja7"
        },
        {
            title: "Caja8"
        },
        {
            title: "Caja9"
        },
        {
            title: "Caja10"
        }
    ]

    return (
        <Modal
            title="Inventario Guias"
            visible={modalInventoryVisible}
            onOk={handlePrint}
            onCancel={() => setModalInventoryVisible(false)}
            width={1000}
        >
            <Spin tip="Cargando..." spinning={loadingBillProducts}>
                <div ref={componentRef} id="item-container" >
                    {
                        billData.map(bill =>
                            <div className="item" >
                                <b>ID:</b>{bill.billId}
                                <Table columns={columns} dataSource={bill.bill_Products} pagination={false} />
                            </div>
                        )
                    }
                </div>
            </Spin>
        </Modal>
    )
}

