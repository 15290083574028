import { message } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import billsAPI from '../../../Api/api-bills';
import filterContext from '../../../Hooks/filterContex';
import { BillStatus } from '../../../Models/Bill';

export const useAdminService = () => {

    const filters = useContext(filterContext);
    const [statusSelected, setStatusSelected] = useState("Programado");

    const statusChange = (status: string) => {
        setStatusSelected(status);
    }

    const [services, setServices] = useState<BillStatus[]>([]);
    const [servicesLoading, setServicesLoading] = useState(false);
    const getServices = async (status: string) => {
        debugger
            var from: any = "";
            var to: any = "";
            from = moment(new Date()).add(-60, "d");
            to = moment(new Date()).add(1, "d");
            let payload = {
                warehouseId: filters.warehouseId,
                from: from,
                to: to,
                status: status
            }
            setServicesLoading(true);
            try {
                const services = await billsAPI.GetBillByDepartmentandStatus(payload);
                // console.log(services)
                setServices(services);
            } catch (error) {
                message.error(`No se obtuvieron los servicios ${status}. ${error}`);
            }
            setServicesLoading(false);
    }

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const selectedRows = ({ selectedRowKeys }: any) => {
        setSelectedRowKeys(selectedRowKeys);
    }

    const handleUpdate = async ({ data }: any) => {
        let payload = {
            id: data.id,
            guia: data.guia
        }
        setServicesLoading(true);
        try {
            // debugger
            let bill = await billsAPI.getBillsById(payload);
            let billNews = bill.billNews.filter(b => b.newsBillingType === 'Novedad Paloma');
            let lastBillNews = billNews.pop();
            let compare = lastBillNews?.description === data.aditionalInformation;
            
            if (data.aditionalInformation !== null) {
                if(!compare) {
                    let registerNews = {
                        id: 0,
                        billId: data.id,
                        newsBillingType: 'Novedad Paloma',
                        description: data.aditionalInformation,
                        solution: '',
                        changeStatus: false,
                        userID: Number(localStorage.userID)
                    };
                    
                    if (data.zone !== null) {
                        data.statusId = 8;
                    }
                    
                    await billsAPI.createBillsNews(registerNews);
                    await billsAPI.UpdateBillHeader(data);
                    message.success('Se creo correctamente la novedad y se actualizó el servicio.');
                } else {
                    if (data.zone !== null) {
                        data.statusId = 8;
                    }
                    await billsAPI.UpdateBillHeader(data);
                    message.success(`Se actualizó correctamente el servicio`);
                }
            } else {
                if (data.zone !== null) {
                    data.statusId = 8;
                }
                await billsAPI.UpdateBillHeader(data);
                message.success('Se actualizó correctamente el servicio.');
            }
            
        } catch (error) {
            message.error(`No se pudo actualizar el servicio. ${error}`);
        }
        setServicesLoading(false);
        getServices(statusSelected);
    }

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            getServices(statusSelected)
        }
        return () => {
            mounted = false;
        }
    }, [statusSelected, filters.warehouseId])

    

    return {
        statusChange,
        services,
        servicesLoading,
        statusSelected,
        getServices,
        selectedRows,
        selectedRowKeys,
        setSelectedRowKeys,
        handleUpdate,
    }
}
