import React, { useEffect } from 'react';
import { ILayoutProps } from './ILayoutProps';
import './styles.css';
import { Layout, Menu } from 'antd';
import {
  ContainerOutlined,
  GlobalOutlined,
  DingdingOutlined,
  DropboxOutlined,
  UserOutlined,
  BranchesOutlined,
  CarryOutOutlined,
  CheckOutlined,
  DollarCircleOutlined,
  FileTextOutlined
} from '@ant-design/icons';
import { useLayout } from './useLayout';
import HeaderComponent from '../HeaderComponent/HeaderComponent';
import FilterHeader from '../FilterHeader/FilterHeader';
import InventoryComponent from '../InventoryComponent/InventoryComponent';
import HistoryServices from '../HistoryServices/HistoryServices';
import filterContext from '../../Hooks/filterContex';
import PendingServices from '../PendingServices/PendingServices';
import CreateUsers from '../CreateUsers/CreateUsers';
import { RoutingServices } from '../ScheduledServicesNew/RoutingServices';
import { FormService } from '../FormService/FormService';
import { TabServices } from '../ScheduledServicesNew/TabServices';
import { CourierRoutes } from '../CourierRoutesNew/CourierRoutes';
import { TabService } from '../ServiceConfirmed/TabService';
import { LiquidationService } from '../LiquidationService/LiquidationService';
import { CustomerPage } from '../LiquidationService/CustomerPage';
import Account from '../Account/Account';


const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const logoEmpresa = require('../../Assets/Logo.png');
const LayoutComponent: React.SFC<ILayoutProps> = (props) => {

  const {
    collapsed,
    onCollapse,
    ChangeTab,
    selectedKey,
    getCustomers,
    customers,
    setSelectedCustomer,
    selectedCustomer,
    setSelectedWareHouse,
    selectedWareHouse,
    selectedDate,
    setSelectedDate,
    WarehousesList,
    UpdateBillStatus,
    loadingStatus,
    getWarehouse
  } = useLayout();

  useEffect(() => {
    getCustomers()
    getWarehouse();
  }, []);

  return (
    <>
      <HeaderComponent />

      {localStorage.role !== "4" ?
        (
          <>
            <Layout style={{ minHeight: '85vh' }}>
              <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
                <Menu theme="dark" mode="inline" onClick={ChangeTab}>
                  <Menu.Item key="1" icon={<ContainerOutlined />}>
                    Formulario
                  </Menu.Item>
                  <Menu.Item key="2" icon={<DropboxOutlined />}>
                    Productos
                  </Menu.Item>
                  <Menu.Item key="3" icon={<DingdingOutlined />}>
                    Gestión
                  </Menu.Item>
                  {localStorage.role === "1" && <Menu.Item key="4" icon={<GlobalOutlined />}>
                    Programados
                  </Menu.Item>
                  }
                  {localStorage.role === "1" && <Menu.Item key="5" icon={<UserOutlined />}>
                    Crear Usuario
                  </Menu.Item>
                  }
                  {localStorage.role === "1" && <Menu.Item key="6" icon={<CarryOutOutlined />}>
                    Servicio Programados
                  </Menu.Item>
                  }
                  {localStorage.role === "1" && <Menu.Item key="7" icon={<BranchesOutlined />}>
                    Servicio Enrutados
                  </Menu.Item>
                  }
                  {localStorage.role === "1" && <Menu.Item key="8" icon={<CheckOutlined />}>
                    Servicio Confirmados
                  </Menu.Item>
                  }
                  {localStorage.role == "1" &&
                    <Menu.Item key="9" icon={<DollarCircleOutlined />}>
                      Liquidación
                    </Menu.Item>
                  }
                   {localStorage.role == "1" &&
                    <Menu.Item key="10" icon={<FileTextOutlined />}>
                      Cuentas de cobro
                    </Menu.Item>
                  }
                </Menu>
              </Sider>
              <Layout>
                <FilterHeader
                  customers={customers}
                  setSelectedCustomer={setSelectedCustomer}
                  setSelectedWareHouse={setSelectedWareHouse}
                  setSelectedDate={setSelectedDate}
                  WarehousesList={WarehousesList}
                  UpdateBillStatus={UpdateBillStatus}
                  loadingStatus={loadingStatus}
                />
                <filterContext.Provider value={{ date: selectedDate, warehouseId: selectedWareHouse, customerId: selectedCustomer }}>
                  <Content style={{ margin: '0 16px' }}>
                    {selectedKey == 1 && <FormService />}
                    {/* {selectedKey == 1 && <ServiceForm />} */}
                    {selectedKey == 2 && <InventoryComponent />}
                    {selectedKey == 3 && <HistoryServices />}
                    {selectedKey == 4 && <PendingServices />}
                    {selectedKey == 5 && <CreateUsers />}
                    {/* {selectedKey == 6 && <ScheduledServices/>} */}
                    {selectedKey == 6 && <TabServices />}
                    {selectedKey == 7 && <RoutingServices />}
                    {selectedKey == 8 && <TabService />}
                    {selectedKey == 9 && <LiquidationService />}
                    {selectedKey == 10 && <Account />}
                    {(selectedKey != 1 && selectedKey != 2 && selectedKey != 3 && selectedKey != 4 && selectedKey != 5 && selectedKey != 6 && selectedKey != 7 && selectedKey != 8 && selectedKey != 9 && selectedKey != 10) &&
                      <div style={{ textAlign: 'center', marginTop: '50px' }}>
                        <img src='https://palomamensajeriaapp.azurewebsites.net/static/media/BannerPaloma4.5c668d4b.png' style={{ width: '80%', borderRadius: 50 }} alt='LogoEmpresa' width='100' />
                      </div>
                    }
                    {/*  {selectedKey == 4 && <PendingServices/>} */}
                  </Content>
                </filterContext.Provider>
                <Footer style={{ textAlign: 'center', color: 'lightgray' }}>Powered by Zarus Soft</Footer>
              </Layout>
            </Layout>
          </>
        )
        :
        (
          <CourierRoutes />

        )
      }
    </>
  );
};

export default LayoutComponent;
