import * as React from "react";
import { Link } from "react-router-dom";

import "./ManagementComponent.css";

// React Bootstrap
import { Col, Container, Row } from "react-bootstrap";

// Icons Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoxes,
  faDollyFlatbed,
  faList,
  faLongArrowAltLeft,
  faScroll,
  faSort,
} from "@fortawesome/free-solid-svg-icons";

// Components
import FooterComponent from "../FooterComponent/FooterComponent";
import HeaderComponent from "../HeaderComponent/HeaderComponent";

// React Props
import { IManagementComponentProps } from "./IManagementComponentProps";

const ManagementComponent: React.SFC<IManagementComponentProps> = (props) => {
  return (
    <React.Fragment>
     {/*  <HeaderComponent />

      <Link to="/HomeComponent" className="btn btn-outline-danger m-5">
        Regresar
        <FontAwesomeIcon className="ml-2" icon={faLongArrowAltLeft} />
      </Link>

      <Container>
        <Row>
          <Col lg={3}>
            <Link
              to="/FormComponent"
              className="btn btn-secondary btn-lg btn-block"
            >
              Formulario
              <FontAwesomeIcon className="ml-2" icon={faSort} />
            </Link>
          </Col>

          {localStorage.role == "1" &&
            <Col lg={3}>
              <Link
                to="/SheduledComponent"
                className="btn btn-secondary btn-lg btn-block"
              >
                Programados
                <FontAwesomeIcon className="ml-2" icon={faScroll} />
              </Link>
            </Col>
          }
          
          <Col lg={3}>
            <Link
              to="/OfficesComponent"
              className="btn btn-secondary btn-lg btn-block"
            >
              Resumen Despachos
              <FontAwesomeIcon className="ml-2" icon={faDollyFlatbed} />
            </Link>
          </Col>
          <Col lg={3}>
            <Link
              to="/InventoryComponent"
              className="btn btn-secondary btn-lg btn-block"
            >
              Inventario
              <FontAwesomeIcon className="ml-2" icon={faBoxes} />
            </Link>
          </Col>
        </Row>

        <Row className="mt-4">
          
        </Row>
      </Container> */}
      {/* <FooterComponent /> */}
    </React.Fragment>
  );
};

export default ManagementComponent;
