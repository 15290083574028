import { SaveOutlined } from '@ant-design/icons';
import { Col, DatePicker, Row, Select, Spin, Button } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import ModalDetailService from '../ModalDetailService/ModalDetailService';
import ModalSearch from '../NewComponents/ModalSearch/ModalSearch';
import { IFilterHeaderProps } from './IFilterHeaderProps';
import './styles.css';
const { RangePicker } = DatePicker;
const { Option } = Select;

const FilterHeader: React.SFC<IFilterHeaderProps> = (props) => {

  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);

  return (
    <Row className="HeaderContainer">
      <Col span={1}></Col>
      <Col span={6}>
        {localStorage.role === "1" &&
          props.customers.length > 0 ?
          <>
            Cliente:
               <Select 
                style={{ width: 200, marginLeft: 10 }}
                onChange={(e)=>props.setSelectedCustomer(e)}
              >
              {props.customers.map(customer =>
                <option key={customer.id} value={customer.id}>{customer.name}</option>
              )}
            </Select>
          </>
          :
          localStorage.role === "1" &&
            <Spin tip="Cargando clientes..." />
        }
      </Col>
      <Col span={1}></Col>
      <Col span={6}>
        Bodega:
       <Select style={{ width: 200, marginLeft: 10 }} onChange={(e)=>props.setSelectedWareHouse(e)}>
        {props.WarehousesList.map(x =>
          <Option key={x.id} value={x.id}>{x.name}</Option>
        )}
        </Select>
      </Col>
      <Col span={7}>
        Fecha:
        <RangePicker format="YYYY-MM-DD" style={{ marginLeft: 10 }} onChange={(e)=>props.setSelectedDate(e)}/>
      </Col>
      <Col span={3}>
{/*       {localStorage.role === "1" &&
        <Button type="primary" onClick={()=>setShowDetailModal(true)}>Consultar Guia<SaveOutlined/></Button>
      } */}
        <ModalSearch/>
      </Col>
      
      <ModalDetailService idService={0} showDetailModal={showDetailModal} setShowDetailModal={setShowDetailModal}/>
    </Row>
  );
};

export default FilterHeader;
