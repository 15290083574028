import { message } from "antd";
import {  createRef, useEffect, useRef, useState } from "react"

// API
import customerAPI from "../../../Api/api-customers";
import usersAPI from "../../../Api/api-users";
import WarehouseAPI from "../../../Api/api-warehouse";

// Modelos
import { Customer } from "../../../Models/Customer";
import {  Rol, User } from "../../../Models/User";
import { Warehouse } from "../../../Models/Warehouse";

export const useCreateUsers = () => {

    // Estados
    const [loading, setLoading] = useState<boolean>(false);
    const [rols, setRols] = useState<any>([]);
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [provinces, setProvinces] = useState<Warehouse[]>([]);
    const [button, setButton] = useState<string>('');
    const [isRole, setIsRole] = useState<boolean>(false);
    const formRef = useRef<any>();
    const [users, setUsers] = useState<any>([])

    // Arreglo para pintar el tipo de persona en la tabla
    const personType = [
        {
            personType: 'Persona Juridica'
        },
        {
            personType: 'Persona Natural'
        },
    ]

    // Arreglo para pintar el inventario en la tabla
    const inventories = [
        {
            inventory: true,
            name: 'Si'
        },
        {
            inventory: false,
            name: 'No'
        }
    ]

    const getUsers = async() => {
        setLoading(true);
        try {
            const resp = await usersAPI.getUsers();
            setUsers(resp);
        } catch (error) {
            message.error(`No se cargaron los usuarios ${error}`);
            setLoading(false);
        }
        setLoading(false);
    }


    // Esta función obtiene todas las provincias de la base de datos
    const getProvinces = async () => {
        setLoading(true);
        try {
            const resp = await WarehouseAPI.getWarehouse();
            setProvinces(resp);
            setLoading(false);
        } catch (error) {
            message.error(`No se pudieron cargar las provincias. ${error}`);
            setLoading(false);
        }
    }

    // Esta función obtiene los clientes de la base de datos
    const getCustomers = async () => {
        setLoading(true);

        try {
            const resp = await customerAPI.getCustomersList();
            setCustomers(resp);
            setLoading(false);
        } catch (error) {
            message.error(`No se pudieron cargar los clientes. ${error}`);
            setLoading(false);
        }
    }


    const getRols = async() => {
        setLoading(true);
        try {
            const rols = await usersAPI.getRols();
            setRols( rols );
        } catch (error) {
            message.error(`${error}`)
            setLoading(false);
        }
        setLoading(false);
    }

    // Esta función dependiendo del formulario envia los datos al servicio
    const onFinish = async (values: any) => {
        setLoading(true);
        switch (button) {
            case 'formCustomer':

                try {
                    let value: Customer = {
                        id: 0,
                        ...values
                    };
                    value.inventory = values.inventory == 1 ? true : values.inventory == 0 ? false : false;

                    await customerAPI.postCustomer(value);
                    setLoading(false);
                    getCustomers();
                    message.success('Se creo correctamente el cliente.');

                } catch (error) {
                    setLoading(false);
                    message.error(`No se pudo crear el cliente ${error}`);
                }

                break;

            case 'formUser':

                try {
                    let user: User = {
                        id: 0,
                        customerId: 7,
                        ...values
                    };

                    user.password='1234';
      
                    if(user.rolId === 1 || user.rolId === 4) {
                        user.code = '';
                        user.customerId = 7;
                    }
                    
                    await usersAPI.postUsers(user)
                    formRef.current.resetFields();
                    setIsRole(false);
                    setLoading(false);
                    message.success('Se creo correctamente el usuario.');

                } catch (error) {
                    setLoading(false);
                    message.error(`No se pudo crear el usuario. ${error}`);
                }
                break;

        }
    }

    // Esta función valida los campos requeridos
    const onFinishFailed = (errorInfo: any) => {
        message.error('Faltan datos por completar.');
    };

    // Esta función actualiza los clientes
    const handleUpdate = async ({ data }: any) => {
        setLoading(true);

        try {
            await customerAPI.postCustomer(data);
            setLoading(false);
            message.success('Se actualizó el cliente correctamente.');
            getCustomers();
        } catch (error) {
            setLoading(false);
            message.error(`No se puedo actualizar el cliente. ${error}`);
        }
        setLoading(false);
    }

    // Esta función desabilita los campos codigo y cliente de acuerdo al rol
    const handleSelectChange = (e : any) => {
        if ( e === 1 || e === 4) {
            setIsRole(true);
            return;
        } 
            
        setIsRole(false);

    }

    const handleUserUpdate = async(data: any) => {
        setLoading(true);
        try {
            await usersAPI.UpdateUser(data);
            message.success('El usuario se actualizó correctamente');
            getUsers();
        } catch (error) {
            message.error(`El usuario no se actualizó. ${error}`)
            setLoading(false);
        }
        setLoading(false);
    }

    useEffect(() => {
        getCustomers();
        getProvinces();
        getRols();
        getUsers();
    }, [])

    return {
        customers,
        onFinish,
        setButton,
        onFinishFailed,
        provinces,
        loading,
        handleUpdate,
        personType,
        inventories,
        handleSelectChange,
        isRole,
        formRef,
        rols,
        users,
        handleUserUpdate
    }
}
