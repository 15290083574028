import React, { useState } from "react";
import { Card, Col, Drawer, Row, Spin, Alert, Popconfirm } from "antd";
import {
  EditOutlined,
  EnvironmentOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";

import { useCourierRoutes } from "./Hooks/useCourierRoutes";
import { BillStatus } from "../../Models/Bill";
import { DrawerMessager } from "./DrawerMessager";

// Ant Desing Controllers
const { Meta } = Card;

export const CourierRoutes = () => {
  const { routes, routesLoading, statuses, getRoutes } = useCourierRoutes();

  const showInMapClicked = (route: BillStatus) => {
    window.open(`https://maps.google.com?q=${route.lat},${route.lng}`);
  };

  const enviarWP = (route: BillStatus, message: string) => {
    if (route.warehouseId) {
      let url = `https://api.whatsapp.com/send?phone=${
        route.warehouseId <= 5 ? "+57" : "+593"
      }${route.phone}&text=%20${message}`;
      window.open(url, "_blank");
    }
  };

  const confirm = (route: BillStatus) => {
    let message = `Somos la empresa PALOMA MENSAJERÍA, reciba un coridal saludo, Estamos cerca de su domicilio, en los próximos minutos le estaremos entregando su pedido. Le agradecemos estar atento(a)      Productos: ${route.products}  valor: $${route.collect_Value}`;
    enviarWP(route, message);
  };

  const cancel = (route: BillStatus) => {
    let message = `Reciba un cordial saludo de la empresa PALOMA MENSAJERÍA. En estos momentos estamos en la puerta de su domicilio con el pedido; en vista de que no fue posible completar la entrega, le agradecemos reprogramar su entrega. Somos Confiabilidad y Respaldo Productos: ${route.products}  valor: $${route.collect_Value}`;
    enviarWP(route, message);
  };

  const [visible, setVisible] = useState(false);
  const [billSelected, setBillSelected] = useState<BillStatus>({});
  const showDrawer = (route: BillStatus) => {
    setBillSelected(route);
    setVisible(true);
  };

  return (
    <>
      <Row justify="center">
        <Col>
          <Spin tip="Cargando pedidos..." spinning={routesLoading}>
            {routes.length !== 0 ? (
              <>
                {routes.map((route) => (
                  <Card
                    style={{ width: 300, margin: 30 }}
                    actions={[
                      <EnvironmentOutlined
                        onClick={() => showInMapClicked(route)}
                      />,
                      <Popconfirm
                        title="¿Qué mensaje desea enviar?"
                        onConfirm={() => confirm(route)}
                        onCancel={() => cancel(route)}
                        okText="Próximo a entregar"
                        cancelText="No encontró"
                      >
                        <WhatsAppOutlined />
                      </Popconfirm>,
                      <EditOutlined onClick={() => showDrawer(route)} />,
                    ]}
                  >
                    <Meta
                      avatar={
                        <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                          {route.delivery_Order}
                        </div>
                      }
                      title={route.guia}
                      description={route.service_Description}
                    />
                    <p>Direccion: {route.address}</p>
                    {route.warehouseId && (
                      <p>
                        TEL: <a href={`tel:${route.warehouseId <= 5 ? `+57${route.phone}` : `+593${route.phone}`}`}>{route.phone}</a>{" "}
                        CEL: <a href={`tel:${route.warehouseId <= 5 ? `+57${route.plate}` : `+593${route.plate}`}`}>{route.plate}</a>
                      </p>
                    )}
                  </Card>
                ))}
              </>
            ) : (
              <Alert
                message="No hay pedidos enrutados"
                description="Comunicarse con administración"
                type="warning"
                showIcon
              />
            )}
          </Spin>
        </Col>
      </Row>

      <DrawerMessager
        billSelected={billSelected}
        visible={visible}
        setVisible={setVisible}
        statuses={statuses}
        getRoutes={getRoutes}
      />
    </>
  );
};
