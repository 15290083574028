import { notification } from "antd";
import { useEffect, useState } from "react";
import BillNewsAPI from "../Api/api-billNews";
import billsAPI from "../Api/api-bills";
import customerAPI from "../Api/api-customers";
import productsAPI from "../Api/api-products";
import { BillGet } from "../Models/Bill";
import { Product } from "../Models/Product";
import { Statuses } from "../Models/Status";
import { User } from "../Models/User";
import utils from "../utils";

export interface News {
    id?: number,
    billId?: number,
    newsBillingType?: string,
    description?: string,
    solution?: string,
    created_at?: string,
    update_at?: string,
    userID?: number,
    changeStatus?: boolean
}

export const useDetailBill = () => {

    const [guia, setGuia] = useState<string>("");
    const [id, setId] = useState<string>("");
    const [billData, setBillData] = useState<BillGet>();
    const [productList, setProductList] = useState<Product[]>([]);
    const [productListFiltered, setProductListFiltered] = useState<Product[]>([]);
    const [statusList, setStatusList] = useState<Statuses[]>([]);
    const [messengers, setMessengers] = useState<User[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [billProducts, setbillProducts] = useState<Product[]>([]);
    const [modalGuiaVisible, setModalGuiaVisible] = useState(false);
    const [news, setNews] = useState<News>({
        id: 0,
        billId: 0,
        newsBillingType: '',
        description: '',
        solution: '',
        changeStatus: false,
        userID: Number(localStorage.userID)
    });


    useEffect(() => {
        sessionValidate();
        getStatusList();
        getQueryString();
        getMessengers();
    }, []);

    const getQueryString = () => {
        let qs: any = utils.getQueryStirng();
        let el:any={};
        if (qs.guia) {
            el = {
                guia: qs.guia
            }
        }
        else if(qs.id) {
            el = {
                id: qs.id
            }
        }
        billsAPI.getBillsById(el).then(res => {
            console.log(res);
            setBillData(res);
            getProductList(res.customerId);
            setbillProducts(res.bill_Products);
            setNews({
                ...news,
                billId: res.id
            })
        })
        setGuia(qs.guia)
    }

    const handleChangeNewsState = (field: any, value: string) => {
        setNews({
            ...news,
            [field]: value
        })
    }

    const Anular = () => {
        let data = {...billData, statusId:11};
        billsAPI.UpdateBillHeader(data).then(res => {
            notification.success({ message: 'Los datos fueron guardados' });
            setLoading(false);
            window.location.reload();
        })
    }

    const updateProductsArray = (action: 'Delete' | 'Add' | 'Update', index: number, quantity:number,code:any) => {
        let prods = [...billProducts];
        switch(action)
        {
            case 'Delete': prods.splice(index,1);
            break;
            case 'Add':  
            let el: Product = {
                id: 0,
                name: "",
                palomaName: "",
                code: "",
                quantity: 0,
                selling_price: 0,
                customerId: 0,
                productId: 0
            }
            prods.push(el);
            break;
            case 'Update':
            prods[index].quantity = quantity;
            prods[index].productId = code;
            break;
        }
        setbillProducts(prods);
    }

    const saveProducts = () => {
        let prods = billProducts.map(x=>{
            let el={
                quantity: x.quantity,
                productId: x.productId
              }
              return el;
        });
        if(billData)
        {
            productsAPI.UpdateInventoryWarehouse(billData.id,billData.warehouseId,prods).then(res=>{
                notification.success({ message: `Productos modificados` });
            })
        }
    }

    const handleSubmitNews = async () => {
        setLoading(true);
        try {
            news.changeStatus = false;
            await billsAPI.createBillsNews(news);
            notification.success({ message: `Novedad creada` });
        } catch (error) {
            console.error(`No se pudo crear la novedad. ${error}`);
        }
        setLoading(false);
    }

    const getProductList = (customerId: number) => {
        productsAPI.getProductsByCustomerId(customerId).then(res => {
            setProductList(res);
            setProductListFiltered(res);
        })
    }

    const onCancelOrder = (billId: number) => {
        console.log(billId)
        billsAPI.cancelBill(billId, "Cancelado").then(res => {
            notification.success({ message: "El pedido fue cancelado" })
        })
    }

    const getStatusList = () => {
        billsAPI.getStatusList().then(res => {
            setStatusList(res);
        })
    }

    const getMessengers = () => {
        customerAPI.getMessengers().then((res: any) => {
            setMessengers(res);
        })
    }

    const onSearchProducts = (value: any) => {
        if (value) {
            let array = productList.filter((x: Product) => x.palomaName.toLowerCase().includes(value.toLowerCase()));
            setProductListFiltered(array);
        } else {
            setProductListFiltered(productList);
        }
    };

    const updateSolution = (index: number, solution: string) => {
        let data = { ...billData as any }
        let newState = [...billData?.billNews as any];
        let i = newState.map(function (e) { return e.id; }).indexOf(index);
        newState[i].solution = solution;
        data.billNews = newState;
        setBillData({
            ...data,
        });
    }

    const SaveBillNews = (index: number) => {
        let data = { ...billData as any }
        let i = data.billNews.map(function (e: any) { return e.id; }).indexOf(index);
        BillNewsAPI.updateBillNews(data.billNews[i]).then(res => {
            notification.success({ message: 'Los datos fueron guardados' })
        })
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setBillData((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeSelect = (field: string, value: number) => {
        setBillData((prevState: any) => ({
            ...prevState,
            [field]: value
        }));
    };

    const UpdateBillHeader = () => {
        setLoading(true);
        billsAPI.UpdateBillHeader(billData).then(res => {
            notification.success({ message: 'Los datos fueron guardados' });
            setLoading(false);
        })
    }

    const sessionValidate = () => {
        let isLogedIn = utils.comprobarCookie('token');
        if (isLogedIn) {
            let userData = utils.parseJwt(localStorage.token);
            if (userData["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] == '1') {
                setIsAdmin(true);
            }
        }
    }

    return {
        guia,
        billData,
        productList,
        onCancelOrder,
        statusList,
        messengers,
        updateSolution,
        onSearchProducts,
        productListFiltered,
        SaveBillNews,
        loading,
        UpdateBillHeader,
        isAdmin,
        handleChange,
        handleChangeSelect,
        handleChangeNewsState,
        handleSubmitNews,
        billProducts,
        updateProductsArray,
        saveProducts,
        modalGuiaVisible,
        setModalGuiaVisible,
        Anular
    }
}