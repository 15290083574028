import React, { useState, useEffect } from 'react';
import { Button, Col, message, Row } from 'antd';
import * as XLSX from 'xlsx';
import './style.css';
import { saveAs } from 'file-saver';
import { useServiceForm } from '../../Hooks/useServiceForm';
import { usePendingServices } from '../../Hooks/usePendingServices';
import DataGrid, {
    Column,
    FilterRow,
    Grouping,
    Lookup,
    GroupPanel,
    Pager,
    Paging,
    SearchPanel,
    Export,
    HeaderFilter,
    Sorting,
    ColumnFixing,
    ColumnChooser
} from 'devextreme-react/data-grid';

const UploadExcelFile = (props) => {

    const [BillList, setBillList] = useState([]);
    const [errorList, setErrorList] = useState([]);

    const {
        CitiesList,
        getCities
    } = usePendingServices();

    const {
        sellersList,
        ProductsList,
        getProductsList,
        getSellersList,
        loading,
        SavaMasiveUPload
    } = useServiceForm();

    useEffect(() => {
        getProductsList();
        getSellersList();
        getCities();
    }, []);

    const downloadTemplate = () => {
        var wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "Pantilla Carga Pedidos",
            Subject: "Template",
            Author: "Zarus Soft",
            CreatedDate: new Date()
        };
        wb.SheetNames.push("Pedidos");
        var ws_data = [[
            'id',
            'name',
            'address',
            'phone',
            'document',
            'email',
            'observations',
            'sellerId',
            'cityId',
            'company_TransportId',
            'customerId',
            'collect_Value'
        ]];
        var ws = XLSX.utils.aoa_to_sheet(ws_data);
        wb.Sheets["Pedidos"] = ws;
        wb.SheetNames.push("Productos");
        var ws_data1 = [['id', 'Code', 'quantity']]
        var ws1 = XLSX.utils.aoa_to_sheet(ws_data1);
        wb.Sheets["Productos"] = ws1;
        var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), 'PlantillaCargaMasivaPedidos.xlsx');
    }


    function s2ab(s) {
        var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        var view = new Uint8Array(buf);  //create uint8array as viewer
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
        return buf;
    }

    const handleInputChange = (event) => {
        const target = event.target
        const name = target.name
        let hojas = []
        if (name === 'file') {

            let reader = new FileReader()
            reader.readAsArrayBuffer(target.files[0])
            reader.onloadend = (e) => {
                var data = new Uint8Array(e.target.result);
                var workbook = XLSX.read(data, { type: 'array' });
                workbook.SheetNames.forEach(function (sheetName) {
                    var XL_row_objet = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
                    hojas.push({
                        data: XL_row_objet,
                        sheetName
                    })
                })
                //Pedidos
                let arrayErrores = [];
                hojas[0].data.map((row, index) => {
                    row.bill_Products = [];
                    let products = hojas[1].data.filter(x => x.id == row.id);
                    let seller = sellersList.filter(x=>x.id == row.sellerId);
                    if(seller.length === 0)
                    {
                        row.sellerId = parseInt(row.sellerId);
                    }

                    let ciudades = CitiesList.filter(x => x.code == row.cityId);
                    if (ciudades.length > 0) {
                        row.cityId = ciudades[0].id;
                    }
                    else {
                        arrayErrores.push(`La ciudad con código ${row.cityId}, no existe`);
                    }
                    if (products.length > 0) {
                        products.map((x, i) => {
                            let item = ProductsList.filter(pro => pro.code == x.Code);
                            if (item.length > 0) {
                                item.map((bp, j) => {
                                    let el = {
                                        productId: bp.code,
                                        quantity: x.quantity
                                    }
                                    row.phone = row.phone.toString();
                                    row.document = row.document.toString();
                                    row.bill_Products.push(el);
                                    delete row.id;
                                    if (hojas[0].data.length == index + 1 && products.length == i + 1 && item.length == j + 1) {
                                        setBillList(hojas[0].data)
                                        console.log('Pedidos', hojas[0].data);
                                        setErrorList(arrayErrores)
                                    }
                                })
                            }
                            else {
                                arrayErrores.push(`El Producto con código: ${x.Code}, no esta registrado para este cliente`);
                                setErrorList(arrayErrores)
                            }
                        })
                    }
                    else {
                        arrayErrores.push(`El Pedido con Id ${row.id}, no tiene productos registrados`)
                        setErrorList(arrayErrores)
                    }
                })
            }
        }
    }


    const cellRender = (data) => {
        console.log(data)
        return <ul>
            {data.data.bill_Products.map(x => {
                let item = ProductsList.filter(pro => pro.code == x.productId);
                if (item.length > 0) {
                    return <li>{`${x.quantity}x ${item[0].palomaName}`}</li>
                }
            }
            )}

        </ul>;
    }

    return (
        <div>
            <Row>
                <Col span={1}></Col>
                <Col span={3}>
                    <Button onClick={downloadTemplate}>Descargar Plantilla</Button>
                </Col>
                <Col span={1}></Col>
                <Col span={3} style={{ textAlign: 'center' }}>
                    <label for="file" class="custom-file-upload" type="primary" >
                        Upload
                    </label>
                    <input
                        required
                        type="file"
                        name="file"
                        id="file"
                        onChange={handleInputChange}
                        placeholder="Archivo de excel"
                        onClick={e => (e.target.value = null)}
                    />
                </Col>
            </Row>
            <Row>
                <ul>
                    {errorList.map(x =>
                        <li style={{ color: 'red' }}>{x}</li>
                    )}
                </ul>
            </Row>
            {BillList.length > 0 &&
                <>
                    <Row>
                        <Col span={20}></Col>
                        <Col span={4}>
                            <Button type='primary' onClick={() => SavaMasiveUPload(BillList)} loading={loading} disabled={errorList.length != 0}>Guardar</Button>
                        </Col>
                    </Row>
                    <DataGrid
                        dataSource={BillList}
                        allowColumnReordering={true}
                        showBorders={true}
                        allowColumnResizing={true}
                        rowAlternationEnabled={true}
                    >
                        <SearchPanel visible={true} highlightCaseSensitive={true} />
                        <Grouping autoExpandAll={true} />
                        <FilterRow visible={true} />
                        <GroupPanel visible={true} />
                        <Grouping autoExpandAll={true} />
                        <Export enabled={true} allowExportSelectedData={false} />
                        <HeaderFilter visible={true} />
                        <Sorting mode="multiple" />
                        <ColumnFixing enabled={true} />
                        <ColumnChooser enabled={true} />
                        <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                        <Grouping autoExpandAll={false} />
                        <Column dataField="id" dataType="string" caption="ID" alignment="center" />
                        <Column dataField="name" dataType="string" caption="Nombre" alignment="center" />
                        <Column dataField="address" caption="Dirección" dataType="string"/>
                        <Column dataField="phone" dataType="string" caption="Teléfono" />
                        <Column dataField="document" dataType="string" caption="Identificación" />
                        <Column dataField="email" dataType="string" caption="Email" />
                        <Column dataField="observations" dataType="string" caption="Observaciones" />
                        <Column dataField="sellerId" dataType="string" caption="Vendedor">
                            <Lookup dataSource={sellersList} displayExpr="name" valueExpr="id" />
                        </Column>
                        <Column dataField="cityId" dataType="string" caption="Ciudad">
                            <Lookup dataSource={CitiesList} displayExpr="name" valueExpr="id" />
                        </Column>
                        <Column
                            dataField="collect_Value"
                            caption="Valor a cobrar"
                            dataType="number"
                            format="currency"
                            alignment="right"
                        />
                        <Column dataField="Productos"
                            allowSorting={false}
                            cellRender={cellRender}
                        />
                    </DataGrid>
                </>
            }
        </div>
    );
};

export default UploadExcelFile;