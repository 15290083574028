import { NoteBook } from "../Models/NoteBook";
import axios from 'axios';
import utils from '../utils';
import { Utils } from '../utils/utils';

const noteBookApi = {
    postNoteBook(data: NoteBook) {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].ulrBackend}/NoteBook/PostNoteBook`, data)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            // debugger
                            console.log(error.request);
                            utils.messageError();
                        } else {
                            // debugger
                            console.log('Error', error.message);
                            utils.messageError();
                        }
                        console.log(error.config);
                    });
            });
        });
    },
    getNoteBook(customer: string, ubication: string) {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.get(`${config.data[0].ulrBackend}/NoteBook/GetNoteBooks?customer=${customer}&ubication=${ubication}`)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            // debugger
                            console.log(error.request);
                            utils.messageError();
                        } else {
                            // debugger
                            console.log('Error', error.message);
                            utils.messageError();
                        }
                        console.log(error.config);
                    });
            });
        });
    },
    deleteNoteBook(id: number) {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.delete(`${config.data[0].ulrBackend}/NoteBook/DeleteNoteBook?IdNoteBook=${id}`)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            // debugger
                            console.log(error.request);
                            utils.messageError();
                        } else {
                            // debugger
                            console.log('Error', error.message);
                            utils.messageError();
                        }
                        console.log(error.config);
                    });
            });
        });
    },
}

export default noteBookApi;