import React from 'react';

// Componentes
import FormCustomer from './FormCustomer/FormCustomer';
import FormUser from './FormUser/FormUser';

// Props
import { ICreateUsersProps } from './ICreateUsersProps';

// Ant Desing
import { Tabs } from 'antd';

// Styles
import './CreateUsers.css';

const CreateUsers: React.SFC<ICreateUsersProps> = (props) => {

  // Ant Desing
  const { TabPane } = Tabs;

  return (
    <div  style={{ padding: 10, backgroundColor: 'white', marginTop: 15 }}>
      <h1 className="titulo">Crear Usuario</h1>

      <Tabs defaultActiveKey="1" /* onChange={callback} */>
        <TabPane tab="Crear Cliente" key="1">
          <FormCustomer />
        </TabPane>
        <TabPane tab="Crear Usuario" key="2">
          <FormUser />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default CreateUsers;
