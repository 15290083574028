import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { message } from "antd";
import { getBillsStatus } from "../../../Models/Bill";
import filterContext from "../../../Hooks/filterContex";
import billsAPI from "../../../Api/api-bills";
import customerAPI from "../../../Api/api-customers";

export const useSheduledServices = () => {

    const filters = useContext(filterContext);
    const [customers, setCustomers] = useState<any>([]);
    const [messagers, setMessagers] = useState<any>([]);
    const [statuses, setStatuses] = useState<any>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [sheduledInvoice, setSheduledInvoice] = useState<any>([]);
    const [deferredInovice, setDeferredInovice] = useState<any>([]);
    const [billLoading, setBillLoading] = useState(false);

    const getSheduledInvoice = async () => {
        if (filters.warehouseId !== 1) {
            setBillLoading(true);
            try {
                var from: any = "";
                var to: any = "";
                from = moment(new Date()).add(-60, "d");
                to = moment(new Date()).add(1, "d");
                let payload : getBillsStatus = {
                    warehouseId: filters.warehouseId,
                    from: moment(new Date()).add(-60, "d").format('YYYY-MM-DD'),
                    to: moment(new Date()).add(1, "d").format('YYYY-MM-DD'),
                    status: "Programado"
                }
                let bills = await billsAPI.GetBillByDepartmentandStatus(payload);
                setSheduledInvoice(bills);
            } catch (error) {
                message.error(`No se obtuvieron los servicios programados. ${error}`)
            }
            setBillLoading(false);
        } else {
            message.warn('Seleccione otra bodega que no sea nacional');
        }
    }

    const getDeferredInovice = async() => {
        if (filters.warehouseId !== 1) {
            setBillLoading(true);
            try {
                var from: any = "";
                var to: any = "";
                from = moment(new Date()).add(-60, "d");
                to = moment(new Date()).add(1, "d");
                let payload : getBillsStatus = {
                    warehouseId: filters.warehouseId,
                    from: moment(new Date()).add(-60, "d").format('YYYY-MM-DD'),
                    to: moment(new Date()).add(1, "d").format('YYYY-MM-DD'),
                    status: "Programado Aplazado"
                }
                let bills = await billsAPI.GetBillByDepartmentandStatus(payload);
                setDeferredInovice(bills.reverse());
            } catch (error) {
                message.error(`No se obtuvieron los servicios programados. ${error}`)
            }
            setBillLoading(false);
        } else {
            message.warn('Seleccione otra bodega que no sea nacional');
        }
    }


    const getMessengers = async () => {
        try {
            const resp : any = await customerAPI.getMessengers();
            const messagers = resp.filter( (m : any) => m.warehouseId === filters.warehouseId);
            setMessagers(messagers);
        } catch (error) {
            message.error(`No se pudo cargar los mensajeros. ${error}`);

        }
    }

    const getCustomers = async () => {

        try {
            const resp = await customerAPI.getCustomers();
            setCustomers(resp)
        } catch (error) {
            message.error(`No se pudo cargar los clientes. ${error}`);
        }
    }

    const getStatuses = async () => {
        try {
            const statuses : any = await billsAPI.getStatusList();  
            setStatuses(statuses.filter((status : any) => status.id !== 2 && status.id !== 4));
        } catch (error) {
            message.error(`No se cargaron los estados. ${error}`)
        }
    }


    const selectedRows = ({ selectedRowKeys }: any) => {
        setSelectedRowKeys(selectedRowKeys);
    }
    


    const handleUpdate = async ({ data }: any) => {
        setBillLoading(true);
        try {
            let payload = {
                id: data.id,
                guia: data.guia
            }
            let bill = await billsAPI.getBillsById(payload);
            let billNews = bill.billNews.filter( bill => bill.newsBillingType === 'Novedad Paloma');
            let lastBillNews = billNews.pop();
            let comparacion = lastBillNews?.description === data.aditionalInformation;
            if(!comparacion){
                if(data.aditionalInformation !== ''){
                    let registerNews = {
                        billId : data.id,
                        newsBillingType : 'Novedad Paloma',
                        description: data.aditionalInformation,
                        changeStatus : false
                    };
                    await billsAPI.createBillsNews(registerNews);
                    message.success('Se creo correctamente la novedad');
                } else {
                    message.warn('Escriba una novedad');
                    setBillLoading(false);
                    getSheduledInvoice();
                    getDeferredInovice();
                    return;
                }
            }
            if (data.zone !== null) {
                data.statusId = 8;
            }
            if (data.confirmed === 1) {
                data.confirmed = true;
            }
            if (data.confirmed === 0) {
                data.confirmed = false;
            }
            await billsAPI.UpdateBillHeader(data);
            getSheduledInvoice();
            getDeferredInovice();
            message.success('Se actualizó correctamente');
        } catch (error) {
            message.error(`No se pudo actualizar. ${error}`)
        }
        setBillLoading(false)
    }



    useEffect(() => {

        let mounted = true;
        if ( mounted ) {
            getSheduledInvoice();
            getDeferredInovice();
            getCustomers();
            getMessengers();
            getStatuses();
        }
        return () => {
            mounted = false;
        }
    }, [filters.warehouseId])

 

    return {
        sheduledInvoice,
        deferredInovice,
        messagers,
        customers,
        selectedRows,
        handleUpdate,
        statuses,
        getSheduledInvoice,
        selectedRowKeys,
        setSelectedRowKeys,
        getDeferredInovice,
        billLoading,
        setBillLoading,
    }
}
