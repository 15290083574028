import React from 'react'

// Ant Desing
import { Col, Row, Tabs } from 'antd';

// Styles
import './FormService.css';

// Components
import { FormData } from './FormData';
import { ProductsData } from './ProductsData';
import UploadExcelFile from './UploadExcel';
import { useFormService } from './Hooks/useFormService';
import { RequestForm } from './RequestForm';


const { TabPane } = Tabs;

export const FormService = () => {

    const {
        sellers,
        sellersLoading,
        handleInputChange,
        handleSelectChange,
        onFinishFailed,
        provinces,
        provinceLoading,
        cities,
        citiesLoading,
        companyLoading,
        companyTransports,
        visibleCities,
        handleSubmit,
        products,
        productsLoading,
        handleProductsChange,
        addProduct,
        arrayProducts,
        formProductsRef,
        removeProduct,
        billLoading
    } = useFormService();

    return (
        <div className="container-form">
            <Tabs defaultActiveKey="1" >
                <TabPane tab="Formulario" key="1">
                    <h3 className="title-form">Generar Pedido</h3>
                    <Row className="mt-5">
                        <Col className="content-grid" xs={4} sm={6} md={8} lg={10} xl={11}>
                            <FormData 
                                sellers={sellers}
                                sellersLoading={sellersLoading}
                                handleInputChange={handleInputChange}
                                handleSelectChange={handleSelectChange}
                                onFinishFailed={onFinishFailed}
                                provinces={provinces}
                                provinceLoading={provinceLoading}
                                cities={cities}
                                citiesLoading={citiesLoading}
                                companyLoading={companyLoading}
                                companyTransports={companyTransports}
                                visibleCities={visibleCities}
                                handleSubmit={handleSubmit}
                                billLoading={billLoading}
                            />
                        </Col>
                        <Col className="container-prod" xs={4} sm={6} md={8} lg={10} xl={11}>
                            <ProductsData 
                                products={products}
                                productsLoading={productsLoading}
                                handleProductsChange={handleProductsChange}
                                addProduct={addProduct}
                                arrayProducts={arrayProducts}
                                formProductsRef={formProductsRef}
                                removeProduct={removeProduct}
                            />
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab="Carga Masiva" key="2">
                    <UploadExcelFile />
                </TabPane>
                <TabPane tab="Solicitar Servicio" key="3">
                   <RequestForm />
                </TabPane>
            </Tabs>
        </div>
    )
}

