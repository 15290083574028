import React, { useContext } from 'react'
import { Row, Col, Form, Select, Tabs, Alert } from 'antd';
import './TabService.css';
import { useConfirmed } from './Hooks/useConfirmed';
import { UnconfirmedService } from './UnconfirmedService';
import { ConfirmedServices } from './ConfirmedServices';
import filterContext from '../../Hooks/filterContex';
import { useMessager } from '../../Hooks/useMessager';
import { useStatus } from '../../Hooks/useStatus';
import { useCustomer } from '../../Hooks/useCustomer';



// Ant Desing 
const { Option } = Select;
const { TabPane } = Tabs;


export const TabService = () => {

    const filters = useContext(filterContext);

    const {
        handleConfirmed,
        handleStatus,
        services,
        loadingServices,
        confirmedSelect,
        statusSelected,
        getServices,
        handleUpdate
    } = useConfirmed();
    const { messagersByWarehouse } = useMessager();
    const { statuses } = useStatus();
    const { customers } = useCustomer();

    return (
        <React.Fragment>
            <h3 className="title">Servicios Confirmados</h3>
            {filters.warehouseId !== 0 && filters.warehouseId !== 1 ?
                (
                    <>
                        <Form>
                            <Row>
                                <Col span={6}>
                                    <Form.Item>
                                        <Select placeholder="Seleccione un estado" defaultValue={'Programado'} onChange={handleStatus}>
                                            <Option value="Programado">Programados</Option>
                                            <Option value="Enrutado">Enrutados</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>

                        <Row>
                            <Col span={24}>
                                <Tabs defaultActiveKey="1" onChange={handleConfirmed}>
                                    <TabPane tab="Sin Confirmar" key="Sin Confirmar">
                                        <UnconfirmedService
                                            services={services}
                                            loadingServices={loadingServices}
                                            messagers={messagersByWarehouse}
                                            statuses={statuses}
                                            customers={customers}
                                            confirmedSelect={confirmedSelect}
                                            statusSelected={statusSelected}
                                            getServices={getServices}
                                            handleUpdate={handleUpdate}
                                        />
                                    </TabPane>
                                    <TabPane tab="Confirmados" key="Confirmado">
                                        <ConfirmedServices
                                            services={services}
                                            loadingServices={loadingServices}
                                            messagers={messagersByWarehouse}
                                            statuses={statuses}
                                            customers={customers}
                                            confirmedSelect={confirmedSelect}
                                            statusSelected={statusSelected}
                                            getServices={getServices}
                                            handleUpdate={handleUpdate}
                                        />
                                    </TabPane>
                                </Tabs>
                            </Col>
                        </Row>
                    </>
                )
                :
                (
                    <Alert
                        message="No está seleccionando una bodega."
                        description="Seleccione una bodega para buscar los servicios."
                        type="warning"
                        showIcon
                    />
                )
            }
        </React.Fragment>
    )
}
