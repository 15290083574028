import React from 'react';

// Styles
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';

// Componentes
import BodyComponent from './Components/BodyComponent/BodyComponent';

function App() {
  return (
    <>
      <BodyComponent />
    </>
  );
}

export default App;
