// Ant Desing
import { Col, Row, Input, Button,  Alert } from 'antd'

// Custom Hook
import { useRecoverPassword } from './Hooks/useRecoverPassword';

// Style
import './RecoverPassword.css'


// Images
const img = require('../../Assets/Login.jpg');
const logo = require('../../Assets/Logo.png');



export const RecoverPassword = () => {

    const {
        isValid,
        handleChange,
        loading,
        recoveryPassword
    } = useRecoverPassword();
    return (
        <>
            {isValid ?
                (
                    <Row className="container-row">
                        <Col md={10}>
                            <img src={img.default} className="img-fluid" />
                        </Col>
                        <Col md={14} className="px-5 pt-5 mt-5">
                            <img src={logo.default} width="200" className="py-3" />
                            <h4>Cambiar contreña</h4>
                            <Row>
                                <Col md={24} style={{ marginTop: '10px' }}>
                                    <Input.Password
                                        name="password"
                                        placeholder="Nueva Contraseña"
                                        onChange={handleChange}
                                    />
                                </Col>
                                <Col md={24} style={{ marginTop: '10px' }}>
                                    <Input.Password
                                        name="confirmPassword"
                                        placeholder="Confirma Contraseña"
                                        onChange={handleChange}
                                    />
                                </Col>
                                <Col md={24} style={{ marginTop: '10px' }}>
                                    <Button loading={ loading } type="primary" onClick={recoveryPassword}>
                                        Cambiar Contraseña
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )
                :
                (
                    <Row style={{ margin: "50px 200px" }}>
                        <Col md={24}>
                            <Alert
                                message="Token no válido"
                                description="El token no es válido o ya expiró"
                                type="error"
                                showIcon
                            />
                        </Col>
                    </Row>
                )
            }
        </>
    )
}
