import * as React from 'react';
import { IModalSearchProps } from './IModalSearchProps';
import { Button, Col, DatePicker, Divider, Input, InputNumber, Modal, Popconfirm, Result, Row, Select, Timeline } from 'antd';
import './ModalSearch.css';
import { useSearch } from '../../ScheduledServicesNew/Hooks/useSearch';
import { SearchOutlined } from '@ant-design/icons/lib/icons';
import DataGrid, {
  Column,
  FilterRow,
  Grouping,
  Selection,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  Export,
  HeaderFilter,
  Sorting,
  ColumnFixing,
  Editing,
  Lookup
} from 'devextreme-react/data-grid';

const { Option } = Select;
const logoEmpresa = require('../../../Assets/Logo.png');

const ModalSearch: React.SFC<IModalSearchProps> = (props) => {

  const {
    handleChangeSelect,
    loading,
    closeModal,
    isModalVisible,
    showModal,
    selectedSearchType,
    setSearchText,
    SearchData,
    HasResults,
    results,
    gotoUrl
  } = useSearch();

  return (
    <div>
      <Button type="primary" onClick={showModal} icon={<SearchOutlined />}>Buscar Guia</Button>
      <Modal
        title="Buscador"
        visible={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width={950}
        footer={[]}
      >
        <Row className='Header' gutter={[16, 16]}>
          <Col span={1}></Col>
          <Col span={4}>
            <img src={logoEmpresa.default} alt='LogoEmpresa' width='100' />
          </Col>
          <Col span={1}></Col>
          <Col span={4}>
            Busqueda por:
            <Select defaultValue={"Guia"} style={{ width: '100%' }} onChange={(e) => handleChangeSelect(e)}>
              <Option value={"Placa"} key={"Placa"}>{"Placa"}</Option>
              <Option value={"Telefono"} key={"Telefono"}>{"Teléfono"}</Option>
              <Option value={"Id"} key={"Id"}>{"Id"}</Option>
              <Option value={"Guia"} key={"Guia"}>{"Guia"}</Option>
            </Select>
          </Col>
          <Col span={1}></Col>
          <Col span={6}>
            {selectedSearchType}
            <Input onChange={(e) => setSearchText(e.target.value)} />
          </Col>

          <Col span={1}></Col>
          <Col span={2}>
            <br />
            <Button type='primary' loading={loading} icon={<SearchOutlined />} onClick={SearchData}> Buscar</Button>
          </Col>
        </Row>
        {results.length > 0 &&
          <Row>
            <Col span={24}>
              <DataGrid
                dataSource={results}
                allowColumnReordering={true}
                onRowClick={gotoUrl}
                allowColumnResizing={true}
                columnAutoWidth={true}
                showBorders={true}
                rowAlternationEnabled={true}
              >
                <SearchPanel visible={true} highlightCaseSensitive={false} />
                <FilterRow visible={true} />
                <GroupPanel visible={true} />
                <Grouping autoExpandAll={true} />
                <Export enabled={true} allowExportSelectedData={false} />
                <HeaderFilter visible={true} />
                <Sorting mode="multiple" />
                <ColumnFixing enabled={true} />
                <Column caption="Guia" dataField="guia" alignment="center" />
                <Column caption="Fecha" dataField="created_at" alignment="center" dataType="date" format={"dd-MM-yyyy hh:mm"}/>
                <Column caption="Name" dataField="name" />
                <Column dataField="service_Description" dataType="string" caption="Descripción" />
              </DataGrid>
            </Col>
          </Row>
        }
        {!HasResults &&
          <Result
            status="404"
            title="404"
            subTitle="No se encontraron resultados."
          />
        }
      </Modal>
    </div>
  );
};

export default ModalSearch;
