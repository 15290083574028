import React, { useState } from 'react'
import { Modal, Input, message, Button } from 'antd'
import usersAPI from '../../Api/api-users';

type RecoverModalProps = {
    isModalVisible: boolean,
    setIsModalVisible: Function
}

export const RecoverModal = ({ isModalVisible, setIsModalVisible }: RecoverModalProps) => {

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const expReg = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;


    const handleSubmit = async () => {
        if (!email) {
            message.warn('Debe escribir el correo');
            return;
        }
        if (expReg.test(email)) {
            setLoading(true);
            try {
                await usersAPI.postSaveToken(email);
                message.success('Se le envió al correo un link para recuperar la contraseña')
            } catch (error) {
                message.error(`${error}`)
                setLoading(false);
            }
            setLoading(false);
        } else {
            message.warn('El correo no es válido')
        }
    }

    const handleChange = ({ target }: any) => {
        setEmail(target.value)
    }
    return (
        <Modal
            title="Recuperar contraseña"
            visible={isModalVisible}
            onOk={handleSubmit}
            onCancel={() => setIsModalVisible(false)}
            okText="Enviar"
            cancelText="Cancelar"
            footer={[
                <Button key="back" onClick={() => setIsModalVisible(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
                    Enviar
                </Button>,
            ]}

        >
            <label>Correo: </label>
            <Input placeholder="example@example.com" onChange={handleChange} />
        </Modal>
    )
}
