export const ciudadBolivar= [
  {lng: -74.17741298675537, lat: 4.597086662955083},
  {lng: -74.1786789894104, lat: 4.597086662955083},
  {lng: -74.17861461639404, lat: 4.593429195819262},
  {lng: -74.18196201324463, lat: 4.591953370434504},
  {lng: -74.17917251586914, lat: 4.5792483126857615},
  {lng: -74.1763401031494, lat: 4.576852720938165},
  {lng: -74.17541742324829, lat: 4.575462418478801},
  {lng: -74.17569637298584, lat: 4.571719283055906},
  {lng: -74.1711688041687, lat: 4.566051069339544},
  {lng: -74.16286468505858, lat: 4.564446849777248},
  {lng: -74.15215730667114, lat: 4.559848067162749},
  {lng: -74.14938926696777, lat: 4.559356102627868},
  {lng: -74.14612770080566, lat: 4.561067282166679},
  {lng: -74.14132118225098, lat: 4.562393443505214},
  {lng: -74.1397762298584, lat: 4.561708973442748},
  {lng: -74.13898229598999, lat: 4.561708973442748},
  {lng: -74.13840293884277, lat: 4.567420000529572},
  {lng: -74.139883518219, lat: 4.573815441302768},
  {lng: -74.14239406585693, lat: 4.5802536032619035},
  {lng: -74.14475440979002, lat: 4.5871194862077065},
  {lng: -74.1452693939209, lat: 4.59054170851383},
  {lng: -74.14535522460938, lat: 4.595418346940075},
  {lng: -74.14535522460938, lat: 4.597214994794462},
  {lng: -74.14484024047852, lat: 4.5990544152855275},
  {lng: -74.14343476295471, lat: 4.603182399790094},
  {lng: -74.1461706161499, lat: 4.607139253651771},
  {lng: -74.14915323257446, lat: 4.6060377533012185},
  {lng: -74.1512668132782, lat: 4.606903981875549},
  {lng: -74.15232896804808, lat: 4.607342442850413},
  {lng: -74.15634155273438, lat: 4.607609796970687},
  {lng: -74.15818691253662, lat: 4.6063478846404475},
  {lng: -74.15921688079834, lat: 4.605214300468425},
  {lng: -74.16513919830321, lat: 4.605556514748467},
  {lng: -74.1681432723999, lat: 4.604315987199971},
  {lng: -74.16943073272704, lat: 4.6035032265987486},
  {lng: -74.17739152908325, lat: 4.598583866309664},
  {lng: -74.17741298675537, lat: 4.597086662955083}
]

  export const usme = [
    {lng: -74.11496579647064, lat: 4.576569313348251},
    {lng: -74.11928415298462, lat: 4.575890204138498},
    {lng: -74.12160158157349, lat: 4.575419639898771},
    {lng: -74.12336111068726, lat: 4.5744785104914865},
    {lng: -74.12400484085083, lat: 4.572895699153721},
    {lng: -74.1254210472107, lat: 4.56904560292406},
    {lng: -74.12651538848877, lat: 4.5697086765267265},
    {lng: -74.12885427474976, lat: 4.570671201632768},
    {lng: -74.13164377212524, lat: 4.569644508140365},
    {lng: -74.13797378540039, lat: 4.567227494738828},
    {lng: -74.13818836212157, lat: 4.566179406749588},
    {lng: -74.13868188858032, lat: 4.561858701324662},
    {lng: -74.1408061981201, lat: 4.562136767308239},
    {lng: -74.14921760559082, lat: 4.559570000293121},
    {lng: -74.15908813476562, lat: 4.558414952144282},
    {lng: -74.16715621948242, lat: 4.55161296429339},
    {lng: -74.17097568511963, lat: 4.547035489735528},
    {lng: -74.16616916656494, lat: 4.542586327713509},
    {lng: -74.15754318237305, lat: 4.536810932228202},
    {lng: -74.15213584899902, lat: 4.522521977594134},
    {lng: -74.15496826171874, lat: 4.505152389789714},
    {lng: -74.124755859375, lat: 4.505237955279861},
    {lng: -74.12286758422852, lat: 4.493558172842573},
    {lng: -74.13230895996094, lat: 4.4698985534652085},
    {lng: -74.12861824035645, lat: 4.465876769953988},
    {lng: -74.09591674804688, lat: 4.4786266034412465},
    {lng: -74.09359931945801, lat: 4.483589565819769},
    {lng: -74.09660339355469, lat: 4.496681356457466},
    {lng: -74.09909248352051, lat: 4.513794453416951},
    {lng: -74.09681797027588, lat: 4.53099270932765},
    {lng: -74.09666776657104, lat: 4.534736056642419},
    {lng: -74.09907102584837, lat: 4.538907192229355},
    {lng: -74.10115242004395, lat: 4.5429713524353454},
    {lng: -74.10304069519043, lat: 4.54784831444508},
    {lng: -74.10454273223877, lat: 4.560853384947251},
    {lng: -74.10647392272949, lat: 4.566286354573736},
    {lng: -74.10922050476074, lat: 4.571205937244265},
    {lng: -74.11292731761932, lat: 4.57454802577411},
    {lng: -74.11415576934814, lat: 4.575879509500126},
    {lng: -74.11483705043793, lat: 4.576136180777076},
    {lng: -74.11496579647064, lat: 4.576569313348251}
  ]

  export const Sohacha=[
  {lng: -74.17870044708252, lat: 4.60149270954445},
  {lng: -74.1831636428833, lat: 4.599867180966172},
  {lng: -74.18595314025879, lat: 4.600679945719189},
  {lng: -74.1862964630127, lat: 4.601236047384077},
  {lng: -74.19327020645142, lat: 4.602968515170624},
  {lng: -74.19741153717041, lat: 4.605364019236194},
  {lng: -74.20303344726562, lat: 4.609577520202364},
  {lng: -74.20494318008421, lat: 4.610604156247398},
  {lng: -74.2062735557556, lat: 4.612058554771687},
  {lng: -74.207603931427, lat: 4.612037166579663},
  {lng: -74.20869827270508, lat: 4.612058554771687},
  {lng: -74.21114444732666, lat: 4.613512950317755},
  {lng: -74.21245336532593, lat: 4.614218758847363},
  {lng: -74.21569347381592, lat: 4.6144326400814375},
  {lng: -74.21944856643677, lat: 4.614560968790947},
  {lng: -74.22659397125244, lat: 4.613085187231064},
  {lng: -74.2302632331848, lat: 4.609812791093705},
  {lng: -74.23856735229492, lat: 4.581729452824259},
  {lng: -74.24790143966675, lat: 4.579718874407927},
  {lng: -74.24841642379761, lat: 4.580724164322678},
  {lng: -74.2496609687805, lat: 4.579975544307735},
  {lng: -74.24875974655151, lat: 4.573708494601927},
  {lng: -74.24496173858643, lat: 4.569794234366253},
  {lng: -74.24513339996338, lat: 4.56093894384267},
  {lng: -74.24530506134033, lat: 4.559099425345496},
  {lng: -74.24260139465332, lat: 4.555677052821077},
  {lng: -74.23852443695068, lat: 4.551826864261898},
  {lng: -74.17865753173828, lat: 4.561880091019536},
  {lng: -74.17835712432861, lat: 4.567548337694689},
  {lng: -74.17846441268921, lat: 4.57064981220001},
  {lng: -74.17811036109924, lat: 4.574211143956999},
  {lng: -74.17541742324829, lat: 4.573922387987629},
  {lng: -74.17498826980591, lat: 4.578007739389147},
  {lng: -74.17917251586914, lat: 4.584852254897649},
  {lng: -74.17943000793457, lat: 4.588210321348973},
  {lng: -74.18286323547363, lat: 4.590969485148372},
  {lng: -74.18370008468628, lat: 4.59627389410228},
  {lng: -74.17733788490295, lat: 4.59681930488213},
  {lng: -74.17730569839476, lat: 4.598722892175795},
  {lng: -74.17870044708252, lat: 4.601578263577349},
  {lng: -74.2097282409668, lat: 4.593365029561696},
  {lng: -74.21470642089844, lat: 4.594220579187978},
  {lng: -74.21745300292967, lat: 4.590456153156141},
  {lng: -74.17870044708252, lat: 4.60149270954445}
  ]

export const Bosa = [
{lng:-74.1785717010498, lat:4.601342989962143},
{lng:-74.16157722473145, lat:4.607139253651771},
{lng:-74.16136264801025, lat:4.612571871186884},
{lng:-74.16252136230467, lat:4.615095671497546},
{lng:-74.16775703430176, lat:4.624164168015378},
{lng:-74.1692590713501, lat:4.6253191097142325},
{lng:-74.17058944702148, lat:4.628398945035127},
{lng:-74.156277179718, lat:4.640760926856299},
{lng:-74.1706109046936, lat:4.6563735010079705},
{lng:-74.19788360595703, lat:4.644011792719641},
{lng:-74.21693801879883, lat:4.633916949794763},
{lng:-74.21640157699585, lat:4.627393722479677},
{lng:-74.21620845794678, lat:4.623372817986419},
{lng:-74.21565055847168, lat:4.614454028201314},
{lng:-74.21286106109619, lat:4.6144326400814375},
{lng:-74.21187400817871, lat:4.614240146973664},
{lng:-74.21110153198242, lat:4.613855160601556},
{lng:-74.20886993408203, lat:4.612379377574662},
{lng:-74.20792579650879, lat:4.612058554771687},
{lng:-74.206702709198, lat:4.612336601209304},
{lng:-74.20586585998535, lat:4.612037166579663},
{lng:-74.2052435874939, lat:4.611288579453283},
{lng:-74.20466423034668, lat:4.610646932717078},
{lng:-74.20335531234741, lat:4.60996250889307},
{lng:-74.2020034790039, lat:4.609235307857741},
{lng:-74.19844150543213, lat:4.606583156600684},
{lng:-74.1953945159912, lat:4.604273210349362},
{lng:-74.191575050354, lat:4.602733242014956},
{lng:-74.18728351593018, lat:4.601706594607404},
{lng:-74.18612480163574, lat:4.60144993252414},
{lng:-74.18329238891602, lat:4.600294952004453},
{lng:-74.18084621429443, lat:4.600808276911184},
{lng:-74.1785717010498, lat:4.601342989962143}
]

export const Kenedy = [
 {lng:-74.16153430938719, lat:4.608080339978151},
 {lng:-74.15822982788086, lat:4.606112612602389},
 {lng:-74.15591239929199, lat:4.608422552878874},
 {lng:-74.15157794952393, lat:4.607738126912712},
 {lng:-74.14926052093506, lat:4.606369273003686},
 {lng:-74.14651393890381, lat:4.607567020318201},
 {lng:-74.1457414627075, lat:4.606903981875549},
 {lng:-74.14479732513428, lat:4.605214300468425},
 {lng:-74.14331674575806, lat:4.603054069026124},
 {lng:-74.14132118225098, lat:4.609128366466264},
 {lng:-74.14037704467773, lat:4.61556620954613},
 {lng:-74.13936853408813, lat:4.6187102512264735},
 {lng:-74.13848876953125, lat:4.623993065381306},
 {lng:-74.13784503936766, lat:4.630623262210004},
 {lng:-74.13702964782715, lat:4.636654548576402},
 {lng:-74.13466930389404, lat:4.641659193921242},
 {lng:-74.12939071655273, lat:4.6468776751993},
 {lng:-74.12707328796387, lat:4.648545870084027},
 {lng:-74.12657976150513, lat:4.649208869518525},
 {lng:-74.13005590438843, lat:4.653165466072503},
 {lng:-74.1358709335327, lat:4.659410427266898},
 {lng:-74.13655757904051, lat:4.660158963414316},
 {lng:-74.14265155792236, lat:4.654790872267579},
 {lng:-74.15213584899902, lat:4.659153786118591},
 {lng:-74.15745735168457, lat:4.659410427266898},
 {lng:-74.16548252105711, lat:4.654063717328538},
 {lng:-74.16664123535156, lat:4.652866048732446},
 {lng:-74.16215658187866, lat:4.647390966353604},
 {lng:-74.15786504745483, lat:4.642749945248647},
 {lng:-74.15624499320984, lat:4.640589828238123},
 {lng:-74.1620922088623, lat:4.635585175314568},
 {lng:-74.16698455810547, lat:4.63130766608762},
 {lng:-74.1701602935791, lat:4.62878392350806},
 {lng:-74.17067527770996, lat:4.628570046604514},
 {lng:-74.16993498802184, lat:4.62729747769204},
 {lng:-74.16929125785828, lat:4.6258324165314075},
 {lng:-74.16889429092407, lat:4.624955517160308},
 {lng:-74.16778922080994, lat:4.6240679227888},
 {lng:-74.16689872741699, lat:4.622645630694025},
 {lng:-74.16543960571289, lat:4.619886589769326},
 {lng:-74.16414141654968, lat:4.617929589116406},
 {lng:-74.16248917579651, lat:4.615149141746047},
 {lng:-74.1616415977478, lat:4.613705443622698},
 {lng:-74.16119098663329, lat:4.612101331153787},
 {lng:-74.1612124443054, lat:4.610625544482562},
 {lng:-74.16153430938719, lat:4.608080339978151}
]
   
export const Zona1 = [
    {lng:-74.12630081176758,lat:4.648545870084027},
    {lng:-74.13110733032227,lat:4.644097341619023},
    {lng:-74.13359642028807,lat:4.641701968514866},
    {lng:-74.1346263885498,lat:4.639819883943104},
    {lng:-74.13651466369629,lat:4.6362267994658835},
    {lng:-74.13702964782715,lat:4.631521542163573},
    {lng:-74.13763046264648,lat:4.627072906469984},
    {lng:-74.13797378540039,lat:4.622709794308677},
    {lng:-74.13926124572754,lat:4.618004447164209},
    {lng:-74.14054870605469,lat:4.612272436656439},
    {lng:-74.14243698120117,lat:4.6049148628383},
    {lng:-74.14544105529785,lat:4.595503901702505},
    {lng:-74.14372444152832,lat:4.595846120649539},
    {lng:-74.13891792297363,lat:4.596017230061449},
    {lng:-74.13256645202637,lat:4.594648353616158},
    {lng:-74.12467002868652,lat:4.594477243875684},
    {lng:-74.11145210266113,lat:4.597813876405987},
    {lng:-74.10604476928711,lat:4.600209397817348},
    {lng:-74.10072326660156,lat:4.603460449699286},
    {lng:-74.09720420837402,lat:4.607823680194504},
    {lng:-74.0837287902832,lat:4.6244208218889815},
    {lng:-74.10784721374512,lat:4.6273295592893735},
    {lng:-74.12269592285156,lat:4.640076532134748},
    {lng:-74.12381172180176,lat:4.642557459842802},
    {lng:-74.12398338317871,lat:4.645295025120784},
    {lng:-74.12630081176758,lat:4.648545870084027}
  ]

export const SurBajo = [
    {lng:-74.11499261856079,lat:4.576767163941714},
    {lng:-74.11413431167603,lat:4.57612548614238},
    {lng:-74.11052942276001,lat:4.572724584205},
    {lng:-74.10874843597412,lat:4.570029518372357},
    {lng:-74.10720348358154,lat:4.566799703907777},
    {lng:-74.10425305366516,lat:4.5651527068265105},
    {lng:-74.1031265258789,lat:4.5664574710592385},
    {lng:-74.09939289093016,lat:4.570478696714971},
    {lng:-74.09420013427734,lat:4.575826036305832},
    {lng:-74.08984422683714,lat:4.582371125596866},
    {lng:-74.08636808395386,lat:4.5872050419652926},
    {lng:-74.0833854675293,lat:4.591760871246145},
    {lng:-74.0804672241211,lat:4.59627389410228},
    {lng:-74.0773558616638,lat:4.60119327034836},
    {lng:-74.0708327293396,lat:4.6118232846240605},
    {lng:-74.07154083251953,lat:4.613448785871184},
    {lng:-74.07656192779541,lat:4.620656555758819},
    {lng:-74.07930850982666,lat:4.6244208218889815},
    {lng:-74.081711769104,lat:4.628056741772273},
    {lng:-74.08467292785645,lat:4.632505371279849},
    {lng:-74.08810615539551,lat:4.633703074443366},
    {lng:-74.09175395965576,lat:4.635585175314568},
    {lng:-74.09441471099854,lat:4.6399054333507},
    {lng:-74.09776210784912,lat:4.637937794353877},
    {lng:-74.10106658935547,lat:4.635285750511238},
    {lng:-74.10703182220459,lat:4.6275862120157},
    {lng:-74.10497188568114,lat:4.626046294262062},
    {lng:-74.09183979034424,lat:4.614197370720414},
    {lng:-74.08836364746094,lat:4.618368043283993},
    {lng:-74.09207582473755,lat:4.613833772463653},
    {lng:-74.0962815284729,lat:4.608764765614832},
    {lng:-74.09894227981567,lat:4.605577903135509},
    {lng:-74.10239696502686,lat:4.601963256598114},
    {lng:-74.10688161849976,lat:4.598562477712464},
    {lng:-74.10902738571167,lat:4.598220260069965},
    {lng:-74.11136627197266,lat:4.597086662955083},
    {lng:-74.11655902862549,lat:4.595161682591176},
    {lng:-74.12273883819579,lat:4.59379280450314},
    {lng:-74.12758827209473,lat:4.593578917064503},
    {lng:-74.13119316101074,lat:4.59366447204765},
    {lng:-74.13308143615723,lat:4.59409224680947},
    {lng:-74.1364073753357,lat:4.5947552971831085},
    {lng:-74.13902521133423,lat:4.595204459989081},
    {lng:-74.14194345474243,lat:4.595632233826912},
    {lng:-74.14533376693726,lat:4.595375569555019},
    {lng:-74.14531230926514,lat:4.593771415762166},
    {lng:-74.14531230926514,lat:4.589857265365192},
    {lng:-74.14503335952759,lat:4.588873377189082},
    {lng:-74.14331674575806,lat:4.584082250174407},
    {lng:-74.142587184906,lat:4.58138722711006},
    {lng:-74.1408920288086,lat:4.577366062702078},
    {lng:-74.13947582244873,lat:4.573195150215621},
    {lng:-74.13743734359741,lat:4.573900998651929},
    {lng:-74.13065671920776,lat:4.576724385439647},
    {lng:-74.12900447845459,lat:4.570799538215906},
    {lng:-74.12557125091553,lat:4.569173939798244},
    {lng:-74.12477731704712,lat:4.570756759357425},
    {lng:-74.12411212921143,lat:4.573152371500151},
    {lng:-74.12301778793335,lat:4.574863518125793},
    {lng:-74.12042140960692,lat:4.575740479186677},
    {lng:-74.11499261856079,lat:4.576767163941714}
  ]

export const SurAlto = [
  {lng:-74.10495042800903, lat:4.565109927631171},
  {lng:-74.10550832748413, lat:4.5629067956238565},
  {lng:-74.10407066345215, lat:4.558158274525738},
  {lng:-74.10256862640381, lat:4.547163830540114},
  {lng:-74.09591674804688, lat:4.534885790131652},
  {lng:-74.09797668457031, lat:4.515291830225104},
  {lng:-74.09514427185059, lat:4.505409086229953},
  {lng:-74.09166812896729, lat:4.496467440198526},
  {lng:-74.08351421356201, lat:4.493215905331433},
  {lng:-74.07750606536865, lat:4.494927241280203},
  {lng:-74.07660484313965, lat:4.498007635860927},
  {lng:-74.07866477966307, lat:4.507548219707322},
  {lng:-74.07651901245117, lat:4.535527504733999},
  {lng:-74.07742023468018, lat:4.548276116555395},
  {lng:-74.0683650970459, lat:4.554821457144433},
  {lng:-74.06441688537598, lat:4.564232953564656},
  {lng:-74.06647682189941, lat:4.573216539572402},
  {lng:-74.06381607055664, lat:4.583526135136049},
  {lng:-74.06252861022949, lat:4.591910592841579},
  {lng:-74.05982494354248, lat:4.599738849604564},
  {lng:-74.0591812133789, lat:4.60542818441274},
  {lng:-74.06663775444031, lat:4.609000036775223},
  {lng:-74.0688693523407, lat:4.6103795797392975},
  {lng:-74.07065033912659, lat:4.612176189816276},
  {lng:-74.07917976379395, lat:4.598284425890459},
  {lng:-74.08947944641113, lat:4.582627794544632},
  {lng:-74.0972900390625, lat:4.5726176373413026},
  {lng:-74.10377025604248, lat:4.565858563181445},
  {lng:-74.10495042800903, lat:4.565109927631171}
]

  export const chapineroCentro = [
    {lng:-74.0892219543457,lat:4.592081703197872},
    {lng:-74.08853530883788,lat:4.592595034020486},
    {lng:-74.07299995422363,lat:4.615010119091552},
    {lng:-74.06510353088379,lat:4.609106978186024},
    {lng:-74.05497550964354,lat:4.637510046020418},
    {lng:-74.0489673614502,lat:4.651112316040725},
    {lng:-74.06132698059082,lat:4.660436990923181},
    {lng:-74.06064033508301,lat:4.664029951910404},
    {lng:-74.0683650970459,lat:4.67352554599835},
    {lng:-74.07711982727051,lat:4.664543230551236},
    {lng:-74.07883644104004,lat:4.651540056124372},
    {lng:-74.0837287902832,lat:4.653165466072503},
    {lng:-74.08724784851074,lat:4.644696183624144},
    {lng:-74.09934997558594,lat:4.637082297427909},
    {lng:-74.10690307617188,lat:4.628013966352786},
    {lng:-74.10252571105957,lat:4.627158457420124},
    {lng:-74.08973693847656,lat:4.625447436453401},
    {lng:-74.08347129821777,lat:4.623821962705926},
    {lng:-74.10673141479492,lat:4.598327203100916},
    {lng:-74.1023540496826,lat:4.5952472373844175},
    {lng:-74.10012245178223,lat:4.594990572973916},
    {lng:-74.09394264221191,lat:4.593621694557355},
    {lng:-74.09196853637695,lat:4.592937254363656},
    {lng:-74.0892219543457,lat:4.592081703197872}
  ]

  export const fontibon = [
   {lng:-74.11885499954224,lat:4.67974893991828},
   {lng:-74.12222385406494,lat:4.683876452449536},
   {lng:-74.12630081176758,lat:4.6890732340968775},
   {lng:-74.14127826690674,lat:4.699958548487621},
   {lng:-74.14664268493652,lat:4.6943555198534765},
   {lng:-74.16595458984375,lat:4.708683785806117},
   {lng:-74.18788433074951,lat:4.6946549194684675},
   {lng:-74.18144702911377,lat:4.68648554106952},
   {lng:-74.1681432723999,lat:4.67322613733687},
   {lng:-74.15342330932617,lat:4.661891286869531},
   {lng:-74.14810180664062,lat:4.66112136586107},
   {lng:-74.1408061981201,lat:4.662490113737273},
   {lng:-74.13565635681152,lat:4.659495974295515},
   {lng:-74.12662267684937,lat:4.6492302565866686},
   {lng:-74.11563634872437,lat:4.639712947169055},
   {lng:-74.11301851272583,lat:4.63718923460033},
   {lng:-74.10977840423584,lat:4.6336602993653315},
   {lng:-74.10518646240234,lat:4.629853307056178},
   {lng:-74.10085201263428,lat:4.635713500191445},
   {lng:-74.0987491607666,lat:4.637424496322641},
   {lng:-74.0943717956543,lat:4.640033757442629},
   {lng:-74.10643100738525,lat:4.658811597775109},
   {lng:-74.11883354187012,lat:4.680283593173258},
   {lng:-74.11885499954224,lat:4.67974893991828}
  ]

 export const engativa = [
 {lng:-74.14200782775879,lat:4.719804027654071},
 {lng:-74.14595603942871,lat:4.717408913682986},
 {lng:-74.14767265319824,lat:4.7114638629507},
 {lng:-74.12188053131104,lat:4.691960318309203},
 {lng:-74.1193699836731,lat:4.688966304822779},
 {lng:-74.12394046783447,lat:4.68648554106952},
 {lng:-74.11110877990723,lat:4.666959912442769},
 {lng:-74.10269737243652,lat:4.654598390150962},
 {lng:-74.090895652771,lat:4.669825701423038},
 {lng:-74.08565998077393,lat:4.677760026224703},
 {lng:-74.0833854675293,lat:4.68088240433392},
 {lng:-74.07909393310547,lat:4.683961996493088},
 {lng:-74.08746242523193,lat:4.694740462192014},
 {lng:-74.09634590148926,lat:4.69944529582967},
 {lng:-74.10089492797852,lat:4.702738660481503},
 {lng:-74.10505771636963,lat:4.705048283545418},
 {lng:-74.11170959472656,lat:4.709282572591707},
 {lng:-74.1209363937378,lat:4.7215148182869315},
 {lng:-74.11954164505005,lat:4.719269404716318},
 {lng:-74.12617206573486,lat:4.714265314058064},
 {lng:-74.12939071655273,lat:4.711977106746532},
 {lng:-74.13235187530518,lat:4.717109523855579},
 {lng:-74.13960456848145,lat:4.721001581539997},
 {lng:-74.14200782775879,lat:4.719804027654071}
 ]
 
  export const funzaMosqueraMadrid = [
    {lng:-74.17350769042969,lat:4.695168175652408},
    {lng:-74.1738510131836,lat:4.697905535588258},
    {lng:-74.17213439941406,lat:4.695852516643575},
    {lng:-74.1525650024414,lat:4.7156981129306175},
    {lng:-74.15565490722656,lat:4.717066753869689},
    {lng:-74.19410705566405,lat:4.73759604380187},
    {lng:-74.20543670654297,lat:4.7468340255485915},
    {lng:-74.21367645263672,lat:4.7451232975121895},
    {lng:-74.23599243164062,lat:4.733490234499022},
    {lng:-74.24251556396484,lat:4.744781151396066},
    {lng:-74.2789077758789,lat:4.7488868935934345},
    {lng:-74.2892074584961,lat:4.741017532928716},
    {lng:-74.28749084472655,lat:4.724936386894007},
    {lng:-74.26654815673828,lat:4.721172660497829},
    {lng:-74.2510986328125,lat:4.708170539579654},
    {lng:-74.23324584960938,lat:4.690719942850837},
    {lng:-74.18964385986328,lat:4.68592950660633},
    {lng:-74.17350769042969,lat:4.695168175652408}
  ]

  export const subaBaja = [
    {lng:-74.14209365844727,lat:4.745978662060481},
    {lng:-74.10758972167969,lat:4.706117550887695},
    {lng:-74.09025192260742,lat:4.696365771946349},
    {lng:-74.08355712890625,lat:4.699274211526369},
    {lng:-74.08201217651367,lat:4.705433219977856},
    {lng:-74.08184051513672,lat:4.708170539579654},
    {lng:-74.07617568969727,lat:4.721856975907261},
    {lng:-74.0694808959961,lat:4.731266244286349},
    {lng:-74.06587600708008,lat:4.7461497348429225},
    {lng:-74.06656265258789,lat:4.760690766192597},
    {lng:-74.06896591186523,lat:4.762572524280281},
    {lng:-74.07291412353516,lat:4.759322211623155},
    {lng:-74.07772064208984,lat:4.758295793909994},
    {lng:-74.07960891723633,lat:4.759493281093201},
    {lng:-74.09093856811523,lat:4.75983541990569},
    {lng:-74.10209655761719,lat:4.763256798672008},
    {lng:-74.1108512878418,lat:4.767533498188535},
    {lng:-74.14209365844727,lat:4.745978662060481}
  ]

  export const subaMedia = [
    {lng:-74.08699035644531,lat:4.6943127484694145},
    {lng:-74.0863037109375,lat:4.6937994916562085},
    {lng:-74.05952453613281,lat:4.6643721377126495},
    {lng:-74.04733657836914,lat:4.657528390003915},
    {lng:-74.04081344604492,lat:4.670189270830019},
    {lng:-74.02364730834961,lat:4.721172660497829},
    {lng:-74.01987075805664,lat:4.759664350520704},
    {lng:-74.02725219726562,lat:4.782416204817067},
    {lng:-74.04029846191406,lat:4.7822451410590805},
    {lng:-74.04253005981445,lat:4.769073103498246},
    {lng:-74.05197143554688,lat:4.71330298479833},
    {lng:-74.05488967895508,lat:4.705262137145269},
    {lng:-74.0643310546875,lat:4.707828375218317},
    {lng:-74.06484603881835,lat:4.7050910542706355},
    {lng:-74.06913757324217,lat:4.69876095836449},
    {lng:-74.0811538696289,lat:4.700471800767583},
    {lng:-74.08287048339844,lat:4.6961946868874},
    {lng:-74.08699035644531,lat:4.6943127484694145}
  ]

  export const subaAlta = [
    {lng:-74.08012390136719,lat:4.701840471665839},
      {lng:-74.07583236694336,lat:4.699274211526369},
      {lng:-74.06810760498047,lat:4.70235372255946},
      {lng:-74.06587600708008,lat:4.7073151283607935},
      {lng:-74.06501770019531,lat:4.709881358861781},
      {lng:-74.05454635620117,lat:4.70748621068869},
      {lng:-74.04767990112305,lat:4.753334753391994},
      {lng:-74.04458999633789,lat:4.770441638653787},
      {lng:-74.04253005981445,lat:4.782758332204812},
      {lng:-74.06244277954102,lat:4.783613649926344},
      {lng:-74.06621932983398,lat:4.761888249207674},
      {lng:-74.0643310546875,lat:4.752821540264635},
      {lng:-74.0639877319336,lat:4.743070418271896},
      {lng:-74.06570434570312,lat:4.735029915843721},
      {lng:-74.06845092773438,lat:4.727844706932091},
      {lng:-74.07171249389648,lat:4.723738839801949},
      {lng:-74.07394409179688,lat:4.721685897118189},
      {lng:-74.07600402832031,lat:4.715527032623595},
      {lng:-74.07892227172852,lat:4.708683785806117},
      {lng:-74.08098220825194,lat:4.706117550887695},
      {lng:-74.08012390136719,lat:4.701840471665839}
  ]

  export const subaChia = [
   {lng:-74.11812543869017,lat:4.74523021813869},
   {lng:-74.11812543869017,lat:4.745465443458713},
   {lng:-74.11816835403442,lat:4.7453371387486385},
   {lng:-74.11128044128418,lat:4.743904401199457},
   {lng:-74.10750389099121,lat:4.742471660677105},
   {lng:-74.10842657089233,lat:4.739648939310726},
   {lng:-74.09985423088074,lat:4.73593875391791},
   {lng:-74.10058379173279,lat:4.730849245325962},
   {lng:-74.09733295440674,lat:4.729491323893932},
   {lng:-74.09205436706543,lat:4.72668993426038},
   {lng:-74.09164667129517,lat:4.729309554681257},
   {lng:-74.09010171890259,lat:4.73218577956773},
   {lng:-74.08929705619812,lat:4.73168324299556},
   {lng:-74.08717274665833,lat:4.730774399332131},
   {lng:-74.08692598342896,lat:4.731116552380744},
   {lng:-74.0780210494995,lat:4.730197015679156},
   {lng:-74.0747594833374,lat:4.733361927565625},
   {lng:-74.0787935256958,lat:4.739648939310726},
   {lng:-74.07488822937012,lat:4.7499560932802884},
   {lng:-74.07626152038574,lat:4.760519697020289},
   {lng:-74.06905174255371,lat:4.778139598052855},
   {lng:-74.05137062072752,lat:4.771767404484355},
   {lng:-74.04362440109253,lat:4.770270571908509},
   {lng:-74.04021263122559,lat:4.79075551117267},
   {lng:-74.0357494354248,lat:4.8186807543549675},
   {lng:-74.03132915496825,lat:4.844124655527842},
   {lng:-74.02961254119873,lat:4.8545585165842775},
   {lng:-74.02673721313477,lat:4.863153502233033},
   {lng:-74.02467727661133,lat:4.891032998148092},
   {lng:-74.06673431396484,lat:4.912241162744231},
   {lng:-74.07377243041992,lat:4.896164067425009},
   {lng:-74.09591674804688,lat:4.844680562025371},
   {lng:-74.14037704467773,lat:4.78044896902098},
   {lng:-74.15608406066895,lat:4.759236676872203},
   {lng:-74.1408920288086,lat:4.744823919669852},
   {lng:-74.12617206573486,lat:4.75177372894289},
   {lng:-74.122416973114,lat:4.747903228417578},
   {lng:-74.118812084198,lat:4.746705721092962},
   {lng:-74.11812543869017,lat:4.74523021813869}
  ]

  export const chico = [
    {lng:-74.07278537750244,lat:4.61556620954613},
    {lng:-74.0714979171753,lat:4.613170739869022},
    {lng:-74.07031774520874,lat:4.611780508225205},
    {lng:-74.06842947006226,lat:4.61002667365458},
    {lng:-74.06630516052246,lat:4.608743377323664},
    {lng:-74.06506061553955,lat:4.6088503187730785},
    {lng:-74.05991077423094,lat:4.614111818206166},
    {lng:-74.05514717102051,lat:4.6245919244197236},
    {lng:-74.05149936676025,lat:4.636141249612704},
    {lng:-74.05016899108887,lat:4.645038378827345},
    {lng:-74.04480457305907,lat:4.654619777055395},
    {lng:-74.03892517089844,lat:4.661249686087722},
    {lng:-74.01442050933838,lat:4.663003393503384},
    {lng:-74.0099573135376,lat:4.670232043689031},
    {lng:-74.01570796966553,lat:4.6749370422509156},
    {lng:-74.03326034545898,lat:4.680240820927889},
    {lng:-74.04102802276611,lat:4.6724562287629405},
    {lng:-74.04460072517395,lat:4.670028872585455},
    {lng:-74.04613494873047,lat:4.670820170236022},
    {lng:-74.04802322387695,lat:4.671836024019025},
    {lng:-74.04904246330261,lat:4.672381376495377},
    {lng:-74.05115604400635,lat:4.673472080175324},
    {lng:-74.05275464057922,lat:4.674327532854266},
    {lng:-74.05447125434875,lat:4.675215063904887},
    {lng:-74.05670285224915,lat:4.676348535659988},
    {lng:-74.058837890625,lat:4.677631709018192},
    {lng:-74.0605115890503,lat:4.665740879254103},
    {lng:-74.06454563140869,lat:4.670616999302822},
    {lng:-74.06694889068604,lat:4.673140591981517},
    {lng:-74.06905174255371,lat:4.6751081325125785},
    {lng:-74.07209873199461,lat:4.676861805285385},
    {lng:-74.07523155212402,lat:4.679171513944196},
    {lng:-74.07727003097534,lat:4.6815026010672405},
    {lng:-74.07917976379395,lat:4.684004768510946},
    {lng:-74.0813684463501,lat:4.68205863905044},
    {lng:-74.08467292785645,lat:4.679470920064299},
    {lng:-74.08604621887207,lat:4.677610322814816},
    {lng:-74.08909320831299,lat:4.672413456039598},
    {lng:-74.0837287902832,lat:4.668906083846599},
    {lng:-74.08557415008544,lat:4.666510795198522},
    {lng:-74.08840656280518,lat:4.659880935795469},
    {lng:-74.09072399139404,lat:4.654405907981675},
    {lng:-74.0929126739502,lat:4.651925021977513},
    {lng:-74.09926414489746,lat:4.647733160248821},
    {lng:-74.09454345703125,lat:4.639948208050605},
    {lng:-74.09265518188477,lat:4.636740098367434},
    {lng:-74.08982276916504,lat:4.634558575460685},
    {lng:-74.08561706542969,lat:4.633018672884082},
    {lng:-74.0842866897583,lat:4.63216317000334},
    {lng:-74.08265590667725,lat:4.629682205797085},
    {lng:-74.07806396484375,lat:4.623052000155313},
    {lng:-74.07278537750244,lat:4.61556620954613}
  ]

  export const norteArriba =[
    {lng:-74.06076908111572,lat:4.666339702839398},
    {lng:-74.05836582183838,lat:4.679085969314938},
    {lng:-74.0548038482666,lat:4.677118439957038},
    {lng:-74.04828071594238,lat:4.673782181892101},
    {lng:-74.04343128204346,lat:4.671344137107884},
    {lng:-74.03952598571776,lat:4.674765951947498},
    {lng:-74.03364658355713,lat:4.68297823936045},
    {lng:-74.0247631072998,lat:4.681096265338547},
    {lng:-74.02171611785889,lat:4.688196413566067},
    {lng:-74.01686668395996,lat:4.705262137145269},
    {lng:-74.01214599609375,lat:4.723653300644797},
    {lng:-74.00922775268555,lat:4.741787365647434},
    {lng:-74.01309013366699,lat:4.7653096177610665},
    {lng:-74.0178108215332,lat:4.775830219304177},
    {lng:-74.0215015411377,lat:4.825009746699143},
    {lng:-74.0238618850708,lat:4.850218221013997},
    {lng:-74.02950525283813,lat:4.852356301137812},
    {lng:-74.03074979782104,lat:4.844744705053362},
    {lng:-74.03130769729613,lat:4.841067161622188},
    {lng:-74.03201580047607,lat:4.836577110562308},
    {lng:-74.03274536132812,lat:4.832129792543784},
    {lng:-74.03358221054077,lat:4.827468629838583},
    {lng:-74.03461217880248,lat:4.823897900963493},
    {lng:-74.0347409248352,lat:4.821289332667878},
    {lng:-74.03510570526123,lat:4.818146208382516},
    {lng:-74.03547048568726,lat:4.815558999935717},
    {lng:-74.03635025024414,lat:4.809871383137204},
    {lng:-74.0369725227356,lat:4.80638609071712},
    {lng:-74.03763771057129,lat:4.802045486889388},
    {lng:-74.03813123703003,lat:4.799009186016439},
    {lng:-74.03886079788208,lat:4.795310012474084},
    {lng:-74.03948307037354,lat:4.7918246456599976},
    {lng:-74.04047012329102,lat:4.786029916716129},
    {lng:-74.04137134552002,lat:4.780641416250334},
    {lng:-74.04351711273193,lat:4.767576265049289},
    {lng:-74.04609203338623,lat:4.752821540264635},
    {lng:-74.04875278472899,lat:4.736013599353135},
    {lng:-74.05227184295654,lat:4.715869193195505},
    {lng:-74.05669212341309,lat:4.690035596827192},
    {lng:-74.05832290649414,lat:4.680369137656147},
    {lng:-74.06012535095215,lat:4.678016660567169},
    {lng:-74.06557559967041,lat:4.674979814820256},
    {lng:-74.06754970550537,lat:4.67399604506509},
    {lng:-74.06068325042725,lat:4.666168610438583},
    {lng:-74.06076908111572,lat:4.666339702839398}
  ]

  export const norteAbajo =[
    {lng:-74.08171713352203,lat:4.699375792836518},
    {lng:-74.08105731010437,lat:4.699183322973154},
    {lng:-74.07848238945007,lat:4.6970821901748945},
    {lng:-74.07574653625488,lat:4.695039861641819},
    {lng:-74.07154083251953,lat:4.689276399672586},
    {lng:-74.06918048858643,lat:4.689736195230971},
    {lng:-74.06540393829346,lat:4.689137391653476},
    {lng:-74.0571641921997,lat:4.686784944058787},
    {lng:-74.04870986938477,lat:4.737980962174189},
    {lng:-74.04381752014159,lat:4.7694152375428285},
    {lng:-74.06458854675293,lat:4.775402555719409},
    {lng:-74.07154083251953,lat:4.764112140704059},
    {lng:-74.06581163406372,lat:4.760498313370759},
    {lng:-74.06641244888306,lat:4.759439821888374},
    {lng:-74.0657901763916,lat:4.755515904918312},
    {lng:-74.07109022140502,lat:4.7565637105484795},
    {lng:-74.07355785369873,lat:4.741744597185563},
    {lng:-74.07527446746826,lat:4.740247699352165},
    {lng:-74.07617568969727,lat:4.736697900099713},
    {lng:-74.07424449920654,lat:4.733853770681074},
    {lng:-74.07505989074706,lat:4.731907780658006},
    {lng:-74.08265590667725,lat:4.7297693237739535},
    {lng:-74.08682942390442,lat:4.731383859332336},
    {lng:-74.08716201782227,lat:4.730667476469752},
    {lng:-74.0892595052719,lat:4.731688589131224},
    {lng:-74.09019827842712,lat:4.7298014006760125},
    {lng:-74.09085273742676,lat:4.728742862123809},
    {lng:-74.0917432308197,lat:4.727523936938574},
    {lng:-74.09202218055725,lat:4.7276415526201},
    {lng:-74.0928590297699,lat:4.725652775683364},
    {lng:-74.09311652183533,lat:4.725487044014338},
    {lng:-74.09121215343475,lat:4.7246049231737794},
    {lng:-74.09226894378662,lat:4.72281929330957},
    {lng:-74.09323453903198,lat:4.721600357707827},
    {lng:-74.09469366073608,lat:4.7199964918108455},
    {lng:-74.09271955490112,lat:4.717173678829466},
    {lng:-74.08924341201782,lat:4.714030078139873},
    {lng:-74.08304214477539,lat:4.710629840974362},
    {lng:-74.08482313156128,lat:4.706352789482565},
    {lng:-74.0838360786438,lat:4.700920896202436},
    {lng:-74.08171713352203,lat:4.699375792836518}
  ]

  export const norOccidente = [
    {lng:-74.12516355514526,lat:4.726219470768309},
    {lng:-74.12842512130737,lat:4.724401767912088},
    {lng:-74.13252353668213,lat:4.727160397433032},
    {lng:-74.13769483566284,lat:4.725727622230561},
    {lng:-74.13733005523682,lat:4.720830502539991},
    {lng:-74.13117170333862,lat:4.716296893673358},
    {lng:-74.12810325622559,lat:4.712319269066551},
    {lng:-74.12215948104858,lat:4.716510743813503},
    {lng:-74.11911249160765,lat:4.718756166308638},
    {lng:-74.11469221115112,lat:4.712618660958392},
    {lng:-74.11196708679199,lat:4.709859973646731},
    {lng:-74.10866260528564,lat:4.706652183942843},
    {lng:-74.10256862640381,lat:4.703765160566781},
    {lng:-74.08857822418213,lat:4.695039861641819},
    {lng:-74.07853603363037,lat:4.683234871789328},
    {lng:-74.0747594833374,lat:4.6787010183538795},
    {lng:-74.06750679016113,lat:4.673803568212329},
    {lng:-74.05853748321533,lat:4.679513692356714},
    {lng:-74.05735731124878,lat:4.687041575090347},
    {lng:-74.06583309173583,lat:4.689308478442292},
    {lng:-74.06984567642212,lat:4.689650651894188},
    {lng:-74.0723991394043,lat:4.6890732340968775},
    {lng:-74.07264590263367,lat:4.688902147250456},
    {lng:-74.07861113548279,lat:4.696985954933261},
    {lng:-74.0808641910553,lat:4.698835807807468},
    {lng:-74.08214092254639,lat:4.699167283815463},
    {lng:-74.082612991333,lat:4.697680986935583},
    {lng:-74.08591747283934,lat:4.702204024421228},
    {lng:-74.09093856811523,lat:4.714329469294665},
    {lng:-74.0929126739502,lat:4.717334066238236},
    {lng:-74.09480631351471,lat:4.720071338968506},
    {lng:-74.09162521362305,lat:4.7241665354294184},
    {lng:-74.0906810760498,lat:4.726486779609799},
    {lng:-74.09953236579895,lat:4.730218400267484},
    {lng:-74.09876525402069,lat:4.732132318247605},
    {lng:-74.10047650337219,lat:4.732763161561931},
    {lng:-74.10005807876585,lat:4.73607775257689},
    {lng:-74.10879135131836,lat:4.73954201782017},
    {lng:-74.10754680633545,lat:4.74221505012043},
    {lng:-74.10864114761353,lat:4.743177339216337},
    {lng:-74.10977840423584,lat:4.743284260144227},
    {lng:-74.11816835403442,lat:4.7453371387486385},
    {lng:-74.12282466888428,lat:4.748929661612737},
    {lng:-74.12784576416016,lat:4.751581273628998},
    {lng:-74.13134336471558,lat:4.748395061180856},
    {lng:-74.13419723510742,lat:4.740247699352165},
    {lng:-74.12516355514526,lat:4.726219470768309}
  ]

  export const sabana = [
    {lng:-74.18908596038818,lat:4.698675416134096},
    {lng:-74.19367790222168,lat:4.721856975907261},
    {lng:-74.21916961669922,lat:4.7461497348429225},
    {lng:-74.27684783935547,lat:4.749913325324661},
    {lng:-74.29676055908203,lat:4.729042246928916},
    {lng:-74.2844009399414,lat:4.717066753869689},
    {lng:-74.23187255859375,lat:4.694997090299714},
    {lng:-74.20196056365967,lat:4.691489831325786},
    {lng:-74.19681072235107,lat:4.690078368473319},
    {lng:-74.19200420379639,lat:4.69341454879858},
    {lng:-74.18908596038818,lat:4.698675416134096}
  ]