import React from 'react';

// Ant Desing
import { Button, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select } from 'antd';
import { BookOutlined, PaperClipOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import { useRequestForm } from './Hooks/useRequestForm';
import { CompanyTrasnport } from '../../Models/CompanyTransport';

// Ant Desing Controllers
const { Option } = Select;
const { TextArea } = Input;

export const RequestForm = () => {

    const {
        handleDateOriginChange,
        handleOriginSelectChange,
        addBook,
        provinces,
        provinceLoading,
        disabledCities,
        cities,
        citiesLoading,
        loadingNoteBook,
        originNoteBook,
        handleNoteBook,
        dateOrigin,
        contactOrigin,
        confirm,
        handleDateDestinationChange,
        handleDestinationSelectChange,
        loadingNoteBookDestination,
        destinationNoteBook,
        dateDestination,
        contactDestination,
        customers,
        loadingCustomers,
        handleSubmit,
        handleFailed,
        loading
    } = useRequestForm();


    return (
        <>
            <Row justify="end" className="mb-1">
                <Col>
                    <Button type="primary" icon={<PaperClipOutlined />}>Carga Masiva</Button>
                </Col>
            </Row>
            {/* <Divider orientation="left">
                Datos Cliente
            </Divider>
            <Form>
                <Row gutter={[16, 8]}>
                    <Col span={8}>
                        <Form.Item>
                            <Select placeholder="¿Dónde inicia el servicio?" onChange={(value) => handleNoteBook(value, 'origin')}>
                                {originNoteBook.map(({ id, contact }: any) =>
                                    <Option key={id} value={id}>{contact}</Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    {Object.keys(contactOrigin).length !== 0 &&
                        <Col span={2}>
                            <Popconfirm
                                title="¿Quiere eliminar el contacto?"
                                onConfirm={() => confirm('origin')}
                                okText="Si"
                                cancelText="No"
                            >
                                <DeleteOutlined />
                            </Popconfirm>
                        </Col>
                    }
                    <Col span={8}>
                        <Form.Item>
                            <Select loading={loadingCustomers} placeholder="Selecciona un cliente">
                                {customers.map(({ id, name }) =>
                                    <Option key={id} value={name}>{name}</Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Button type="primary" loading={loadingNoteBook} onClick={() => addBook('origin')} icon={<BookOutlined />}>
                            Agregar Libreta
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Form.Item>
                            <Input
                                placeholder="Razón Social"
                                name="business_name"
                                onChange={handleDateOriginChange}
                                value={dateOrigin.business_name}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item>
                            <Input
                                placeholder="Persona Contacto"
                                name="contact"
                                onChange={handleDateOriginChange}
                                value={dateOrigin.contact}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item>
                            <Input
                                placeholder="Dirección"
                                name="address"
                                onChange={handleDateOriginChange}
                                value={dateOrigin.address}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item>
                            <Input
                                placeholder="Interior, Oficina, Apartamento, Local, Etc..."
                                name="interior"
                                onChange={handleDateOriginChange}
                                value={dateOrigin.interior}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Row gutter={[6, 4]}>
                            <Col span={8}>
                                <Form.Item>
                                    <Select
                                        placeholder="Departamento"
                                        loading={provinceLoading}
                                        onChange={(value) => handleOriginSelectChange(value, 'province')}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={dateOrigin.province}
                                    >
                                        {provinces.map(({ id, name }) =>
                                            <Option key={id} value={name}>{name}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item>
                                    <Select
                                        placeholder="Ciudad"
                                        loading={citiesLoading}
                                        disabled={disabledCities}
                                        onChange={(value) => handleOriginSelectChange(value, 'city')}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={dateOrigin.city}
                                    >
                                        {cities.map(({ cityId, cityName } : any) =>
                                            <Option key={cityId} value={cityName}>{cityName}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item>
                                    <Input
                                        placeholder="Barrio"
                                        name="neighborhood"
                                        onChange={handleDateOriginChange}
                                        value={dateOrigin.neighborhood}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row gutter={[6, 4]}>
                            <Col span={8}>
                                <Input
                                    placeholder="Teléfono"
                                    name="phone"
                                    onChange={handleDateOriginChange}
                                    value={dateOrigin.phone}
                                />
                            </Col>
                            <Col span={8}>
                                <Input
                                    placeholder="Celular"
                                    name="cellphone"
                                    onChange={handleDateOriginChange}
                                    value={dateOrigin.cellphone}
                                />
                            </Col>
                            <Col span={8}>
                                <Input
                                    placeholder="Código Postal"
                                    name="code"
                                    onChange={handleDateOriginChange}
                                    value={dateOrigin.code}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form> */}
            <Divider orientation="left">
                Datos Del Servicio
            </Divider>
            <Form onFinish={handleSubmit} onFinishFailed={ handleFailed }>
                <Row gutter={[16, 8]}>
                    <Col span={8}>
                        <Form.Item>
                            <Select placeholder="¿A quién va dirigido el servicio?" onChange={(value) => handleNoteBook(value, 'destination')}>
                                {destinationNoteBook.map(({ id, contact }: any) =>
                                    <Option key={id} value={id}>{contact}</Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    {Object.keys(contactDestination).length !== 0 &&
                        <Col span={2}>
                            <Popconfirm
                                title="¿Quiere eliminar el contacto?"
                                onConfirm={() => confirm('destination')}
                                okText="Si"
                                cancelText="No"
                            >
                                <DeleteOutlined />
                            </Popconfirm>
                        </Col>
                    }
                    <Col span={12}>
                        <Button type="primary" onClick={() => addBook('destination')} loading={loadingNoteBookDestination} icon={<BookOutlined />}>
                            Agregar Libreta
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            // name="business_name"
                            // rules={[{ required: true, message: 'Campo Requerido' }]}
                        >
                            <Input
                                placeholder="Razón Social"
                                name="business_name"
                                value={dateDestination.business_name}
                                onChange={handleDateDestinationChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            // name="contact"
                            // rules={[{ required: true, message: 'Campo Requerido' }]}
                        >
                            <Input
                                placeholder="Persona Contacto"
                                name="contact"
                                value={dateDestination.contact}
                                onChange={handleDateDestinationChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            // name="address"
                            // rules={[{ required: true, message: 'Campo Requerido' }]}
                        >
                            <Input
                                placeholder="Dirección"
                                name="address"
                                value={dateDestination.address}
                                onChange={handleDateDestinationChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            // name="interior"
                            // rules={[{ required: true, message: 'Campo Requerido' }]}
                        >
                            <Input
                                placeholder="Interior, Oficina, Apartamento, Local, Etc..."
                                name="interior"
                                value={dateDestination.interior}
                                onChange={handleDateDestinationChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Row gutter={[6, 4]}>
                            <Col span={8}>
                                <Form.Item
                                    // name="province"
                                    // rules={[{ required: true, message: 'Campo Requerido' }]}
                                >
                                    <Select
                                        placeholder="Departamento"
                                        loading={provinceLoading}
                                        onChange={(value) => handleDestinationSelectChange(value, 'province')}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={dateDestination.province}
                                    >
                                        {provinces.map(({ id, name }) =>
                                            <Option key={id} value={name}>{name}</Option>
                                        )}
                                    </Select>

                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    // name="city"
                                    // rules={[{ required: true, message: 'Campo Requerido' }]}
                                >
                                    <Select
                                        placeholder="Ciudad"
                                        loading={citiesLoading}
                                        disabled={disabledCities}
                                        onChange={(value) => handleDestinationSelectChange(value, 'city')}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={dateDestination.city}
                                    >
                                        {cities.map(({ id, name } : CompanyTrasnport) =>
                                            <Option key={id} value={name}>{name}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item
                                    // name="neighborhood"
                                    // rules={[{ required: true, message: 'Campo Requerido' }]}
                                >
                                    <Input
                                        placeholder="Barrio"
                                        name="neighborhood"
                                        value={dateDestination.neighborhood}
                                        onChange={handleDateDestinationChange}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row gutter={[6, 4]}>
                            <Col span={8}>
                                <Form.Item
                                    // name="phone"
                                    // rules={[{ required: true, message: 'Campo Requerido' }]}
                                >
                                    <Input
                                        placeholder="Teléfono"
                                        name="phone"
                                        value={dateDestination.phone}
                                        onChange={handleDateDestinationChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    // name="cellphone"
                                    // rules={[{ required: true, message: 'Campo Requerido' }]}
                                >
                                    <Input
                                        name="cellphone"
                                        value={dateDestination.cellphone}
                                        onChange={handleDateDestinationChange}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Divider orientation="left">
                    Datos Adicionales
                </Divider>
                <Row gutter={[16, 8]}>
                    <Col span={24}>
                        <Form.Item
                            name="observations"
                        >
                            <TextArea rows={4} name="observations" onChange={handleDateDestinationChange} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="service_Description"
                            rules={[{ required: true, message: 'Campo Requerido' }]}
                        >
                            <Select placeholder="Seleccione un servicio" onChange={(value) => handleDestinationSelectChange(value, 'service_Description')}>
                                <Option value="AFILIACIÓN SEGURIDAD SOCIAL">AFILIACIÓN SEGURIDAD SOCIAL</Option>
                                <Option value="COBRO">COBRO</Option>
                                <Option value="CAMBIO">CAMBIO</Option>
                                <Option value="COMPRAS">COMPRAS</Option>
                                <Option value="CONSIGNACIÓN">CONSIGNACIÓN</Option>
                                <Option value="ENTREGA DE CAJAS">ENTREGA DE CAJAS</Option>
                                <Option value="ENTREGAR DOCUMENTOS">ENTREGAR DOCUMENTOS</Option>
                                <Option value="ENTREGAR FACTURAS">ENTREGAR FACTURAS</Option>
                                <Option value="ENTREGAR MERCANCIA">ENTREGAR MERCANCIA</Option>
                                <Option value="ENTREGAR MUESTRAS">ENTREGAR MUESTRAS</Option>
                                <Option value="ENTREGAR PAQUETE">ENTREGAR PAQUETE</Option>
                                <Option value="ENTREGAR PEDIDO">ENTREGAR PEDIDO</Option>
                                <Option value="FIRMA DOCUMENTOS">FIRMA DOCUMENTOS</Option>
                                <Option value="HISTORIAL VEHICULAR ">HISTORIAL VEHICULAR </Option>
                                <Option value="INVENTARIO E IMPRONTAS">INVENTARIO E IMPRONTAS</Option>
                                <Option value="LLEVAR RECIBOS">LLEVAR RECIBOS</Option>
                                <Option value="PAGO FACTURA">PAGO FACTURA</Option>
                                <Option value="RADICAR DOCUMENTOS">RADICAR DOCUMENTOS</Option>
                                <Option value="RADICAR INCAPACIDAD">RADICAR INCAPACIDAD</Option>
                                <Option value="RECOGER CAJAS">RECOGER CAJAS</Option>
                                <Option value="RECOGER DOCUMENTOS">RECOGER DOCUMENTOS</Option>
                                <Option value="RECOGER MERCANCIA">RECOGER MERCANCIA</Option>
                                <Option value="RECOGER PAQUETE">RECOGER PAQUETE</Option>
                                <Option value="RECOGER PODER">RECOGER PODER</Option>
                                <Option value="RECOGER MUESTRAS">RECOGER MUESTRAS</Option>
                                <Option value="RECOGER WEB">RECOGER WEB</Option>
                                <Option value="RUTA">RUTA</Option>
                                <Option value="TRAMITE CAMARA DE COMERCIO">TRAMITE CAMARA DE COMERCIO</Option>
                                <Option value="TRAMITE TRANSITO">TRAMITE TRANSITO</Option>
                                <Option value="TRAMITES BANCARIOS">TRAMITES BANCARIOS</Option>
                                <Option value="OTROS">OTROS</Option>

                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="collect_Value"
                        >
                            <Input placeholder="Valor a cobrar" name="collect_Value" onChange={handleDateDestinationChange} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item>
                            <Button type="primary" loading={loading} htmlType="submit" icon={<SaveOutlined />} block>Enviar</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>

    )
}




