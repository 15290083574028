import { useState } from 'react';
import Swal from 'sweetalert2';
import utils from '../utils';


// Apis
import billsAPI from '../Api/api-bills';
import citiesAPI from '../Api/api-cities';
import productsAPI from '../Api/api-products';
import usersAPI from '../Api/api-users';
import customerAPI from '../Api/api-customers';


// Models
import { BillGet, DispatchOrder } from '../Models/Bill';
import { GetFormCities } from '../Models/City';
import { Product } from '../Models/Product';
import { User } from '../Models/User';
import { Customer } from '../Models/Customer';
import BillNewsAPI from '../Api/api-billNews';
import { BillNews } from '../Models/BillNews';

export const useSheduledComponent = (selectedData: BillGet, setSelectedData: any, handleClose3 : any, handleClose2? : any, handleClose?: any, getHistoryServices? : any  ) => {

  const [bill, setBill] = useState<BillGet[]>([]);
  const [billNews, setBillNews] = useState<BillGet[]>([]);
  const [billDispatch, setBillDispatch] = useState<BillGet[]>([]);
  const [newsSolved, setNewsSolved] = useState<BillGet[]>([]);


  const [news, setNews] = useState<BillNews>({
    id              : 0,
    billId          : selectedData.id,
    newsBillingType : '',
    description     : '',
    solution        : '',
    userID          : localStorage.userID,
  });


  const [sellersList, setSellersList] = useState<User[]>([]);
  const [CitiesList, setCitiesList] = useState<GetFormCities[]>([]);
  const [validated, setValidated] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<number>(0);
  const [ProductsList, setProductsList] = useState<Product[]>([]);
  const [quantity, setQuantity] = useState<number>(1);
  const [toDisable, setToDisable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [update, setUpdate] = useState<string>('');
  const [customers, setCustomers] = useState<Customer[]>([]);

 


  


  const [key, setKey] = useState('general');


  const handleInputChange = (e: any) => {
    setSelectedData({
      ...selectedData,
      [e.target.name]: e.target.value,
    })

    setNews({
      ...news,
      [e.target.name]: e.target.value,
    })
  }



  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      return setValidated(true);
    }

    selectedData.bill_Products.map((x:any,i:number)=>{
      delete x.name;
      delete x.palomaName;
      delete x.selling_price;
      delete x. code;
      delete x.customerId;
      x.productId = x.id;
      delete x.id;
    });
    setValidated(false);
    
    billsAPI.UpdateBills(selectedData).then(resp => { 
      debugger
      Swal.fire(
        'Completado',
        'Pedido actualizado correctamente.',
        'success',
      ).then((result) => {
       if (update === 'Programado') {
          getBill(localStorage.customerId);
          handleClose3()
       } else {
         getBill(localStorage.customerId);
         handleClose3()
       }
        
      });
      
    })
    .catch(err => {
      console.log(err);
      utils.messageError();
      setLoading(false);
    });
  }


  const handleDispatch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      return setValidated(true);
    }
    setValidated(false);
    let dispatchOrder:DispatchOrder = {
      billId: selectedData.id,
      company_TransportId: selectedData.company_TransportId,
      packingQuantity: selectedData.packingQuantity,
      guia: selectedData.guia,
      packing: selectedData.packing,
      userID: localStorage.userID
    }
    billsAPI.DispatchBills(dispatchOrder).then(res=>{
      Swal.fire(
        'Completado',
        'Pedido creado correctamente.',
        'success',
      ).then((result) => {
        getBill(localStorage.customerId);
        handleClose2()
      });
      setLoading(true);
    })
    .catch(err => {
      console.log(err);
      utils.messageError();
      setLoading(false);
    });
  }

  const handleNews = ( e : React.FormEvent<HTMLFormElement> ) => {
    e.preventDefault();
    const form = e.currentTarget;
    setLoading(true);
    if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        setLoading(false);
        return setValidated(true);
    }
    setValidated(false);
    news.billId = selectedData.id;
    news.newsBillingType = 'Novedad'
    news.changeStatus = true
    BillNewsAPI.createBillNews(news).then(res=>{
        Swal.fire(
            'Completado',
            'Novedad creada correctamente.',
            'success',
          ).then((result) => {
            getBill(localStorage.customerId)
            setLoading(false);
            getHistoryServices();
          });
          setLoading(true);
    })
    .catch(err => {
      console.log(err);
      utils.messageError();
      setLoading(false);
    });
  }


  // const handleSolution = ( e : React.FormEvent<HTMLFormElement> ) => {
  //   e.preventDefault();
  //   const form = e.currentTarget;
  //   setLoading(true);
  //   if (form.checkValidity() === false) {
  //       e.preventDefault();
  //       e.stopPropagation();
  //       setLoading(false);
  //       return setValidated(true);
  //   }
  //   setValidated(false);
  //   news.billId = selectedData.id;
  //   BillNewsAPI.updateBillNews(news).then(res => {
  //       Swal.fire(
  //           'Completado',
  //           'Novedad solucionada correctamente.',
  //           'success',
  //       ).then((result) => {
  //       handleClose();
  //       });
  //       setLoading(true);
  //   })
  //   .catch(err => {
  //       console.log(err);
  //       utils.messageError();
  //       setLoading(false);
  //   });
  // }
  
  const getBill = (customerId: number) => {
    setLoading(true);
    
    billsAPI.getBills(customerId).then(resp => {
      let pendingData = resp.filter( x => x.statusId === 1 && x.billNews.length === 0);
      let dataWithNews = resp.filter( x => x.statusId === 4);
      let dataDispatch = resp.filter( x => x.statusId === 2 && x.billNews.length === 0);
      // let dataSolved = resp.filter( x => x.billNews.filter( s => s.solution !== ''));

      console.log(dataDispatch)
      setBill(pendingData);
      setBillNews(dataWithNews);
      setBillDispatch(dataDispatch);
      // setNewsSolved(dataSolved);
      
      setUpdate('Programado');
      setLoading(false);
    })
  }

  
  const getSellersList = (customerId: number) => {
    usersAPI.getSellers(customerId).then(res => {
      setSellersList(res);
    })
  }

  const getCities = () => {
    citiesAPI.getCities().then(res => {
      setCitiesList(res);
    })
  }

  const getProductsList = (customerId: number) => {
    productsAPI.getProductsByCustomerId(customerId).then(res => {
      setProductsList(res);
    });
  }

  const getCustomers = () => {
    customerAPI.getCustomers().then(res => {
        setCustomers(res);
    })
}


  const pushProduct = () => {
    let product: any = ProductsList.filter((x) => x.id === Number(selectedProduct));
    product[0].quantity = quantity;
    // let arrayTemp = { ...(selectedData as any) };
    let arrayTemp = { ...selectedData };
    let arrayProducts = arrayTemp.bill_Products;
    // product[0].productId = product[0].id;
    arrayProducts.push(product[0]);
    arrayTemp.bill_Products = arrayProducts;

    setSelectedData(arrayTemp);
  };

  const deleteProduct = (productID: any) => {
    let product: any = selectedData.bill_Products.filter((x) => x.id !== productID);
    setSelectedData({
      ...selectedData,
      bill_Products: product,
    });
  };




  return {
    getBill,
    bill,
    billNews,
    billDispatch,
    getSellersList,
    sellersList,
    getCities,
    CitiesList,
    getProductsList,
    ProductsList,
    quantity,
    setQuantity,
    handleInputChange,
    handleSubmit,
    validated,
    setValidated,
    setSelectedProduct,
    pushProduct,
    deleteProduct,
    toDisable,
    setToDisable,
    loading,
    handleDispatch,
    getCustomers,
    customers,
    key,
    setKey,
    setLoading,
    handleNews,
    news,
    // newsSolved
    // handleSolution
  };
}

