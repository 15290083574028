import React, { useState } from 'react';
import { useNewsCourier } from './Hooks/useNewsCourier';

import DataGrid, {
    Column,
    FilterRow,
    Grouping,
    Selection,
    GroupPanel,
    Pager,
    Paging,
    SearchPanel,
    Export,
    HeaderFilter,
    Sorting,
    ColumnFixing,
    Editing,
    Lookup
} from 'devextreme-react/data-grid';
import { Alert, message, Spin } from 'antd';
import { EditOutlined, SearchOutlined, UserOutlined, EnvironmentOutlined, PrinterOutlined, WarningOutlined } from '@ant-design/icons';
import billsAPI from '../../Api/api-bills';
import { DrawerNews } from './DrawerNews';
import { CreateNewsModal } from './CreateNewsModal';
import { useSheduledServices } from './Hooks/useSheduledServices';
import { DrawerEditCourier } from './DrawerEditCourier';

export const NewsCourier = () => {

    const { newsCouriers, newsCouriersLoading, customers, getNewsCourier } = useNewsCourier();
    const {messagers, statuses } = useSheduledServices();

    const detailCell = ({ data }: any) => {
        return (
            <ul>
                <li><b>Nombre: </b>{`${data.name}`}</li>
                <li><b>Cel: </b>{`${data.phone}`}</li>
                <li><b>Dir: </b>{`${data.address}`}</li>
                <li><b>Ciu: </b>{`${data.city}`}</li>
            </ul>
        )
    }

    const newsCell = ({ data }: any) => {
        return (
            <SearchOutlined onClick={() => showDrawerNews(data)} />
        )
    }

    const createNews = ({ data }: any) => {
        return (
            <WarningOutlined onClick={() => showModalNews(data)} />
        )
    }

    const modalCell = ({ data }: any) => {
        return <EditOutlined onClick={(e) => showModalEdit(data)} />
    }

    // Drawer News
    const [visibleDrawerNews, setVisibleDrawerNews] = useState(false);
    const [billNews, setBillNews] = useState<any>([]);
    const [billData, setBillData] = useState<any>([])
    const showDrawerNews = async (data: any) => {
        try {
            let payload = {
                id: data.id,
                guia: data.guia
            }
            let billNews = (await billsAPI.getBillsById(payload)).billNews;
            let billData = await billsAPI.getBillsById(payload);
            if (billNews.length !== 0) {
                setBillNews(billNews);
                setBillData(billData)
                setVisibleDrawerNews(true);
                // setClientBillNews(clientBillNews)
            } else {
                message.warn(`No hay novedades para esta guia.`)
            }
        } catch (error) {
            message.error(`No se pudo cargar las novedades. ${error}`);
        }

    }

    // Drawer Edit
    const [drawerEditVisible, setDrawerEditVisible] = useState(false);
    const [billSelected, setBillSelected] = useState({});
    const showModalEdit = (data: any) => {
        data.confirmed = data.confirmed === true ? 1 : data.confirmed === false ? 0 : data.confirmed;
        setBillSelected(data);
        setDrawerEditVisible(true);
    }


    // Create News Modal
    const [newsModal, setNewsModal] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModalNews = (data: any) => {
        setNewsModal(data);
        setIsModalVisible(true);
    }

    const handleUpdate = async({data} : any) => {
        try {
            let payload = {
                id: data.id,
                guia: data.guia
            }
            let bill = await billsAPI.getBillsById(payload);
            let billNews = bill.billNews.filter( bill => bill.newsBillingType === 'Novedad Paloma');
            let lastBillNews = billNews.pop();
            let comparacion = lastBillNews?.description === data.aditionalInformation;
            if(!comparacion){
                if(data.aditionalInformation !== ''){
                    let registerNews = {
                        billId : data.id,
                        newsBillingType : 'Novedad Paloma',
                        description: data.aditionalInformation,
                        changeStatus : false
                    };
                    await billsAPI.createBillsNews(registerNews);
                    getNewsCourier();
                    message.success('Se creo correctamente la novedad');
                } else {
                    message.warn('Escriba una novedad');
                    return;
                }
            }
        } catch (error) {
            message.error(`No se pudo actualizar. ${error}`)
        }
    }

    return (
        <React.Fragment>
            {newsCouriers.length !== 0
                ?
                (
                    <>
                        <Spin tip="Cargando..." spinning={newsCouriersLoading}>
                            <div id="data-grid-demo">
                                <DataGrid
                                    id="gridContainer"
                                    dataSource={newsCouriers}
                                    allowColumnReordering={true}
                                    onRowUpdated={handleUpdate}
                                    allowColumnResizing={true}
                                    columnAutoWidth={true}
                                    showBorders={true}
                                    rowAlternationEnabled={true}
                                  
                                >
                                    <SearchPanel visible={true} highlightCaseSensitive={true} />
                                    <Grouping autoExpandAll={true} />
                                    <FilterRow visible={true} />
                                    <GroupPanel visible={true} />
                                    <Grouping autoExpandAll={true} />
                                    <Export enabled={true} allowExportSelectedData={false} />
                                    <HeaderFilter visible={true} />
                                    <Sorting mode="multiple" />
                                    <ColumnFixing enabled={true} />
                                    <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                                    <Grouping autoExpandAll={false} />
                                    <Selection
                                        mode="multiple"
                                        selectAllMode={'allPages'}
                                        showCheckBoxesMode={'onClick'}
                                    />
                                    <Column caption="Guia" dataField="guia" dataType="string" allowEditing={false}/>
                                    <Column caption="Mensajero" dataField="zone" dataType="string" allowEditing={false}/>
                                    <Column caption="Descripción Servicio" dataField="service_Description" dataType="string" allowEditing={false} />
                                    <Column dataField="customerId" dataType="number" caption="Cliente" allowEditing={false}>
                                        <Lookup dataSource={customers} displayExpr="name" valueExpr="id" />
                                    </Column>
                                    <Column caption="Nombre" dataField="name" dataType="string"  allowEditing={false} />
                                    <Column cellRender={detailCell} caption="Detalle" />
                                    <Column dataField="aditionalInformation" dataType="string" caption="Información Adicional" />
                                    <Column cellRender={newsCell} caption="Novedades" alignment="center" />
                                    <Column cellRender={createNews} alignment="center" />
                                    <Column cellRender={modalCell} caption="Editar" alignment="center" />
                                    <Pager allowedPageSizes={[5, 10, 50, 100,200]} showPageSizeSelector={true} />
                                    <Paging defaultPageSize={200} />
                                    <Editing
                                        mode="cell"
                                        allowUpdating={true}
                                    />
                                </DataGrid>
                            </div>
                        </Spin>

                        <DrawerNews
                            visibleDrawerNews={visibleDrawerNews}
                            setVisibleDrawerNews={setVisibleDrawerNews}
                            billNews={billNews}
                            billData={billData}
                        />

                        <CreateNewsModal
                            isModalVisible={isModalVisible}
                            setIsModalVisible={setIsModalVisible}
                            newsModal={newsModal}
                            getNewsCourier={getNewsCourier}
                        />

                       <DrawerEditCourier 
                            drawerEditVisible={drawerEditVisible}
                            setDrawerEditVisible={setDrawerEditVisible}
                            billSelected={billSelected}
                            statuses={statuses}
                            messagers={messagers}
                            setBillSelected={setBillSelected}
                            getNewsCourier={getNewsCourier}
                       />

                    </>
                )
                :
                (
                    <Alert
                        message="No hay novedades de mensajero"
                        description="Seleccione una bodega"
                        type="warning"
                        showIcon
                    />
                )
            }
        </React.Fragment>
    )
}
