import { Button, Result, Spin, Tabs, Badge, Input, Row, Col, Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { IPendingServicesProps } from './IPendingServicesProps';
import { CloseOutlined, PrinterOutlined } from '@ant-design/icons';
import './PendingService.css'
import DataGrid, {
  Column,
  FilterRow,
  Grouping,
  Selection,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  Export,
  HeaderFilter,
  Sorting,
  ColumnFixing
} from 'devextreme-react/data-grid';
import { usePrintLabels } from '../../Hooks/usePrintLabels';
import { usePendingServices } from '../../Hooks/usePendingServices';
import { Modal } from "react-bootstrap";
import NewsModalComponent from '../SheduledComponent/components/NewsModalComponent/NewsModalComponent';
import OfficesModalComponent from '../SheduledComponent/components/OfficesModalComponent/OfficesModalComponent';
import { useSheduledComponent } from '../../Hooks/useSheduledComponent';
import { BillGet } from '../../Models/Bill';
import ModalBillNews from '../ModalBillNews/ModalBillNews';
import { useHistoryServices } from '../../Hooks/useHistoryServices';
import ModalDetailService from '../ModalDetailService/ModalDetailService';
import { ModalGuias } from '../ScheduledServicesNew/ModalGuias';
import { ModalGuia } from '../ScheduledServicesNew/ModalGuia';
// import { CloseOutlined } from '@material-ui/icons';
const { TabPane } = Tabs;




const PendingServices: React.SFC<IPendingServicesProps> = (props) => {

  // Modal Guia
  const [modalGuiaVisible, setModalGuiaVisible] = useState(false);
  const [guiaSelected, setGuiaSelected] = useState<any>({});

  const showModalGuia = (data: any) => {
    setGuiaSelected(data);
    setModalGuiaVisible(true);
  }

  // Custom Cells
  const guiaCell = ({ data }: any) => {
    if (data.observations !== '') {
      return (
        <Badge color="green">
          <a onClick={() => showModalGuia(data)}>{data.guia}</a>
        </Badge>
      )
    } else {
      return (
        <a onClick={() => showModalGuia(data)}>{data.guia}</a>
      )
    }
  }


  const {
    historyServices,
    getHistoryServices,
    getMovement,
    movementList,
    statusList,
    saveEditing,
    DetailService,
    detailServiceId,
    showDetailModal,
    setShowDetailModal
  } = useHistoryServices();

  const {
    pendingServices,
    getPendingServices,
    loading,
    Province,
    filters,
    setSelectedBillid,
    selectedData,
    setSelectedData,
    setNewsShow,
    selectedBillid,
    setPackOffShow,
    GetBillById,
    packOffShow,
    newsShow,
    handleSearch,
    resetFilter,
    formRef,
    select,
    setselect,
  } = usePendingServices();
  const { setArrayLabels, arrayLabels, printLabels, modalGuiasVisible, setModalGuiasVisible, loadingPrint } = usePrintLabels();
  const pageSizes = [10, 25, 50, 100];

  const handleClose = () => {
    setNewsShow(false);
    setValidated(false);
  };
  const handleClose2 = () => {
    setPackOffShow(false);
    setValidated(false);
  };

  console.log(pendingServices)

  const {
    CitiesList,
    handleInputChange,
    validated,
    setValidated,
    handleDispatch,
    handleNews,
    getCities,
    news
  } = useSheduledComponent(selectedData, setSelectedData, handleClose2, handleClose);

  useEffect(() => {
    getPendingServices();
    getCities();
  }, [filters.customerId, filters.warehouseId, filters.date]);

  const selectedRows = (data: any) => {
    setArrayLabels(data.selectedRowKeys)
  }

  const DetailCell = (row: any) => {
    if (row.data.totalSolvedBillNews > 0 && row.data.totalPendingBillNews == 0) {
      return <ul>
        <li><Badge status="success" /><b>Nombre: </b>{`${row.data.name}`}</li>
        <li><b>Cel: </b>{`${row.data.phone}`}</li>
        <li><b>Dir: </b>{`${row.data.address}`}</li>
      </ul>
    }
    else {
      return <ul>
        <li><b>Nombre: </b>{`${row.data.name}`}</li>
        <li><b>Cel: </b>{`${row.data.phone}`}</li>
        <li><b>Dir: </b>{`${row.data.address}`}</li>
        <li><b>Ciu: </b>{`${row.data.city}`}</li>
      </ul>
    }
  }

  const { Option } = Select;

  return (
    <div style={{ padding: 10, backgroundColor: 'white', marginTop: 15 }}>
      {arrayLabels.length > 0 &&
        <Button onClick={printLabels} type="primary" loading={loadingPrint}><PrinterOutlined />Imprimir</Button>
      }

      {filters.customerId != 0 && filters.warehouseId != 0 ?
        <>
          <div className="title">
            {Province}
          </div>

          {/* {filters.warehouseId === 1 &&
            ( */}
          <Form
            layout='vertical'
            ref={formRef}
            initialValues={{}}
            onFinish={handleSearch}
          >
            <Row>
              <Col className="form-item">
                <Form.Item
                  name="type"
                >
                  <Select placeholder="Filtrar..." onChange={(e: number) => setselect(e)} >
                    <Option value={1}>Nombre</Option>
                    <Option value={2}>Teléfono</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col className="form-item">
                <Form.Item
                  name="input"
                >
                  <Input
                    placeholder={`${select == 1 ? 'Nombre...' : select == 2 ? 'Teléfono...' : 'Filtrar...'}`}
                  // onChange={(e : any) => setSearch(e.target.value)}
                  />
                </Form.Item>
              </Col>

              <Col className="form-item">
                <Button type="primary" htmlType="submit">Filtrar</Button>
              </Col>

              <Col className="form-item">
                <Button onClick={resetFilter} danger>
                  <CloseOutlined />
                </Button>
              </Col>
            </Row>
          </Form>
          {/* )
          } */}
          <Tabs defaultActiveKey="1">
            <TabPane tab="Histórico" key="1">
              <Spin tip="Cargando..." spinning={loading || loadingPrint}>
                <DataGrid
                  dataSource={pendingServices.filter(x => x.totalPendingBillNews == 0 && x.status == 'Programado')}
                  onSelectionChanged={selectedRows}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  showBorders={true}
                  rowAlternationEnabled={true}
                >
                  <SearchPanel visible={true} highlightCaseSensitive={true} />
                  <Grouping autoExpandAll={true} />
                  <FilterRow visible={true} />
                  <GroupPanel visible={true} />
                  <Grouping autoExpandAll={true} />
                  <Export enabled={true} allowExportSelectedData={false} />
                  <HeaderFilter visible={true} />
                  <Sorting mode="multiple" />
                  <ColumnFixing enabled={true} />
                  <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                  <Grouping autoExpandAll={false} />
                  <Selection
                    mode="multiple"
                    selectAllMode={'allPages'}
                    showCheckBoxesMode={'onClick'}
                  />
                  {/* <Column dataField="status" groupIndex={0} /> */}
                  <Column dataField="id" dataType="string" caption="ID" alignment="center" visible={filters.warehouseId == 1} />
                  <Column dataField="guia" dataType="string" caption="Guia" alignment="center" />
                  <Column dataField="company_Transport" dataType="string" caption="Transportadora" alignment="center" visible={filters.warehouseId == 1} />
                  <Column caption="Detalle" dataType="string" /* width={300} */ cellRender={DetailCell} />
                  <Column dataField="name" caption="Nombre" dataType="string" visible={filters.warehouseId !== 1 ? false : true} />
                  <Column dataField="address" caption="Detalle" dataType="string" visible={filters.warehouseId !== 1 ? false : true} />
                  <Column dataField="phone" caption="Teléfono" dataType="string" visible={filters.warehouseId !== 1 ? false : true} />
                  <Column dataField="city" dataType="string" caption="Ciudad" width={150} visible={filters.warehouseId !== 1 ? false : true} />
                  <Column dataField="updatedBy" caption="Usuario" dataType="string" />
                  <Column dataField="seller" dataType="string" caption="Vendedor" visible={filters.warehouseId == 1} />
                  <Column dataField="created_at" dataType="date" caption="Fecha" format="dd-MM-yyyy HH:mm" visible={filters.warehouseId == 1} />
                  <Column dataField="observations" dataType="string" caption="Observaciones" />
                  <Column
                    dataField="service_Value"
                    caption="Valor servicio"
                    dataType="number"
                    format="currency"
                    alignment="right"
                    visible={filters.warehouseId != 1}
                  />
                  {/* <Column dataField="gapCodigo" alignment="center" caption="Detalle"
                    type="buttons" width={100}
                    buttons={[{
                      type: "danger",
                      hint: 'Ver Detalle',
                      icon: 'dragvertical',
                      visible: true,
                      onClick: (row: any) => { setSelectedBillid(row.row.data.id) }
                    }]} /> */}
                  <Column alignment="center" caption="Novedades" visible={localStorage.role == 1}
                    type="buttons" width={100}
                    buttons={[{
                      type: "danger",
                      hint: 'Registrar Novedades',
                      icon: 'warning',
                      onClick: (row: any) => { GetBillById(row.row.data.id, 'News') }
                    }]} />
                  <Column alignment="center" caption="Despachar" visible={localStorage.role == 1}
                    type="buttons" width={100}
                    buttons={[{
                      type: "danger",
                      hint: 'Despachar',
                      icon: 'car',
                      visible: true,
                      onClick: (row: any) => { GetBillById(row.row.data.id, 'Dispatch') }
                    }]} />



                  <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
                  <Paging defaultPageSize={20} />
                </DataGrid>
              </Spin>
            </TabPane>
            <TabPane tab={<Badge count={pendingServices.map((item: any) => item.totalPendingBillNews).reduce((prev: number, curr: number) => prev + curr, 0)}>Novedades</Badge>} key="2">
              <Spin tip="Cargando..." spinning={loading || loadingPrint}>
                <DataGrid
                  dataSource={pendingServices.filter(x => x.totalPendingBillNews > 0)}
                  // allowColumnReordering={true}
                  // showBorders={true}
                  onSelectionChanged={selectedRows}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  showBorders={true}
                  rowAlternationEnabled={true}
                  wordWrapEnabled={true}
                >
                  <SearchPanel visible={true} highlightCaseSensitive={true} />
                  <Grouping autoExpandAll={true} />
                  <FilterRow visible={true} />
                  <GroupPanel visible={true} />
                  <Grouping autoExpandAll={true} />
                  <Export enabled={true} allowExportSelectedData={false} />
                  <HeaderFilter visible={true} />
                  <Sorting mode="multiple" />
                  <ColumnFixing enabled={true} />
                  <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                  <Grouping autoExpandAll={false} />
                  <Selection
                    mode="multiple"
                    selectAllMode={'allPages'}
                    showCheckBoxesMode={'onClick'}
                  />
                  {/* <Column dataField="status" groupIndex={0} /> */}
                  <Column dataField="id" dataType="string" caption="ID" alignment="center" visible={filters.warehouseId == 1} />
                  <Column dataField="guia" dataType="string" caption="Guia" alignment="center" />
                  <Column dataField="company_Transport" dataType="string" caption="Transportadora" alignment="center" visible={filters.warehouseId == 1} />
                  <Column caption="Detalle" dataType="string" width={300} cellRender={DetailCell} />
                  <Column dataField="name" caption="Nombre" dataType="string" visible={filters.warehouseId !== 1 ? false : true} />
                  <Column dataField="address" caption="Detalle" dataType="string" visible={filters.warehouseId !== 1 ? false : true} />
                  <Column dataField="phone" caption="Teléfono" dataType="string" visible={filters.warehouseId !== 1 ? false : true} />
                  <Column dataField="city" dataType="string" caption="Ciudad" width={150} visible={filters.warehouseId !== 1 ? false : true} />
                  <Column dataField="updatedBy" caption="Usuario" dataType="string" />
                  <Column dataField="seller" dataType="string" caption="Vendedor" visible={filters.warehouseId == 1} />
                  <Column dataField="created_at" dataType="date" caption="Fecha" format="dd-MM-yyyy HH:mm" visible={filters.warehouseId == 1} />
                  <Column dataField="observations" dataType="string" caption="Observaciones" />
                  <Column
                    dataField="service_Value"
                    caption="Valor servicio"
                    dataType="number"
                    format="currency"
                    alignment="right"
                    visible={filters.warehouseId != 1}
                  />
                  {/* <Column dataField="gapCodigo" alignment="center" caption="Detalle"
                    type="buttons" width={100}
                    buttons={[{
                      type: "danger",
                      hint: 'Ver Detalle',
                      icon: 'dragvertical',
                      visible: true,
                      onClick: (row: any) => { setSelectedBillid(row.row.data.id) }
                    }]} /> */}
                  <Column alignment="center" caption="Novedades" visible={localStorage.role == 1}
                    type="buttons" width={100}
                    buttons={[{
                      type: "danger",
                      hint: 'Registrar Novedades',
                      icon: 'warning',
                      onClick: (row: any) => { GetBillById(row.row.data.id, 'News') }
                    }]} />
                  <Column alignment="center" caption="Despachar" visible={localStorage.role == 1}
                    type="buttons" width={100}
                    buttons={[{
                      type: "danger",
                      hint: 'Despachar',
                      icon: 'car',
                      visible: true,
                      onClick: (row: any) => { GetBillById(row.row.data.id, 'Dispatch') }
                    }]} />
                  <Column dataField="DetailService" alignment="center" caption="Detalle"
                    type="buttons" width={50}
                    buttons={[{
                      type: "danger",
                      hint: 'Ver detalle',
                      icon: 'info',
                      onClick: (row: any) => { DetailService(row.row.data.id) }
                    }]} />
                  <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
                  <Paging defaultPageSize={20} />
                </DataGrid>
              </Spin>
            </TabPane>
            <TabPane tab={<Badge count={pendingServices.filter(x => (x.statusId == 4 || x.statusId == 1 || x.statusId == 8)).map((item: any) => item.totalSolvedBillNews).reduce((prev: number, curr: number) => prev + curr, 0)}>Novedades Solucionadas</Badge>} key="3">
              <Spin tip="Cargando..." spinning={loading || loadingPrint}>
                <DataGrid
                  dataSource={pendingServices.filter(x => (x.statusId == 4 || x.statusId == 1 || x.statusId == 8)).filter(x => x.totalSolvedBillNews > 0)}
                  allowColumnReordering={true}
                  showBorders={true}
                  onSelectionChanged={selectedRows}
                  wordWrapEnabled={true}
                >
                  <SearchPanel visible={true} highlightCaseSensitive={true} />
                  <Grouping autoExpandAll={true} />
                  <FilterRow visible={true} />
                  <GroupPanel visible={true} />
                  <Grouping autoExpandAll={true} />
                  <Export enabled={true} allowExportSelectedData={false} />
                  <HeaderFilter visible={true} />
                  <Sorting mode="multiple" />
                  <ColumnFixing enabled={true} />
                  <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                  <Grouping autoExpandAll={false} />
                  <Selection
                    mode="multiple"
                    selectAllMode={'allPages'}
                    showCheckBoxesMode={'onClick'}
                  />
                  {/* <Column dataField="status" groupIndex={0} /> */}
                  <Column dataField="id" dataType="string" caption="ID" alignment="center" visible={filters.warehouseId == 1} />
                  <Column dataField="guia" dataType="string" caption="Guia" alignment="center" />
                  <Column dataField="company_Transport" dataType="string" caption="Transportadora" alignment="center" visible={filters.warehouseId == 1} />
                  <Column caption="Detalle" dataType="string" width={300} cellRender={DetailCell} />
                  <Column dataField="name" caption="Nombre" dataType="string" visible={filters.warehouseId !== 1 ? false : true} />
                  <Column dataField="address" caption="Detalle" dataType="string" visible={filters.warehouseId !== 1 ? false : true} />
                  <Column dataField="phone" caption="Teléfono" dataType="string " visible={filters.warehouseId !== 1 ? false : true} />
                  <Column dataField="city" dataType="string" caption="Ciudad" width={150} visible={filters.warehouseId !== 1 ? false : true} />
                  <Column dataField="updatedBy" caption="Usuario" dataType="string" />
                  <Column dataField="seller" dataType="string" caption="Vendedor" visible={filters.warehouseId == 1} />
                  <Column dataField="created_at" dataType="date" caption="Fecha" format="dd-MM-yyyy HH:mm" visible={filters.warehouseId == 1} />
                  <Column dataField="observations" dataType="string" caption="Observaciones" />
                  <Column
                    dataField="service_Value"
                    caption="Valor servicio"
                    dataType="number"
                    format="currency"
                    alignment="right"
                    visible={filters.warehouseId != 1}
                  />
                  {/* <Column dataField="gapCodigo" alignment="center" caption="Detalle"
                    type="buttons" width={100}
                    buttons={[{
                      type: "danger",
                      hint: 'Ver Detalle',
                      icon: 'dragvertical',
                      visible: true,
                      onClick: (row: any) => { setSelectedBillid(row.row.data.id) }
                    }]} /> */}
                  <Column alignment="center" caption="Novedades" visible={localStorage.role == 1}
                    type="buttons" width={100}
                    buttons={[{
                      type: "danger",
                      hint: 'Registrar Novedades',
                      icon: 'warning',
                      onClick: (row: any) => { GetBillById(row.row.data.id, 'News') }
                    }]} />
                  <Column alignment="center" caption="Despachar" visible={localStorage.role == 1}
                    type="buttons" width={100}
                    buttons={[{
                      type: "danger",
                      hint: 'Despachar',
                      icon: 'car',
                      visible: true,
                      onClick: (row: any) => { GetBillById(row.row.data.id, 'Dispatch') }
                    }]} />
                  <Column dataField="DetailService" alignment="center" caption="Detalle"
                    type="buttons" width={50}
                    buttons={[{
                      type: "danger",
                      hint: 'Ver detalle',
                      icon: 'info',
                      onClick: (row: any) => { DetailService(row.row.data.id) }
                    }]} />
                  <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
                  <Paging defaultPageSize={20} />
                </DataGrid>
              </Spin>
            </TabPane>
          </Tabs>
        </>
        :
        <Result status="warning" title="Selecciona un cliente y una bodega" />
      }

      <Modal show={newsShow} onHide={handleClose} >
        <Modal.Header closeButton>
          <Modal.Title>Añadir Novedad</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <NewsModalComponent
            news={news}
            handleClose={handleClose}
            selectedData={selectedData}
            handleInputChange={handleInputChange}
            loading={loading}
            handleNews={handleNews}
            validated={validated}
          />

        </Modal.Body>
      </Modal>

      <Modal show={packOffShow} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Despacho</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <OfficesModalComponent
            handleDispatch={handleDispatch}
            selectedData={selectedData}
            handleInputChange={handleInputChange}
            validated={validated}
            handleClose2={handleClose2}
            CitiesList={CitiesList}
          />

        </Modal.Body>

      </Modal>
      {detailServiceId != 0 &&
        <ModalDetailService idService={detailServiceId} showDetailModal={showDetailModal} setShowDetailModal={setShowDetailModal}/>
      }
      {selectedBillid != 0 && <ModalBillNews billId={selectedBillid} />}

      <ModalGuias
        setModalGuiasVisible={setModalGuiasVisible}
        modalGuiasVisible={modalGuiasVisible}
        selectedRowsKeys={arrayLabels}
      />
      <ModalGuia
        guiaSelected={guiaSelected}
        modalGuiaVisible={modalGuiaVisible}
        setModalGuiaVisible={setModalGuiaVisible}
      />
    </div>
  );
};

export default PendingServices;
