import { message } from "antd"
import moment from "moment";
import { useEffect, useState } from "react";
import billsAPI from "../../../Api/api-bills";
import usersAPI from "../../../Api/api-users";
import { BillStatus, getBillsStatus } from "../../../Models/Bill";
import { Statuses } from "../../../Models/Status";

export const useCourierRoutes = () => {

    
    const [routes, setRoutes] = useState<BillStatus[]>([]);
    const [routesLoading, setRoutesLoading] = useState(false);
    const getRoutes = async () => {
        const messager = await usersAPI.getUserById(localStorage.userID);
        var from: any = moment(new Date()).add(-60, "d");;
        var to: any = moment(new Date()).add(1, "d");;
        let payload: getBillsStatus = {
            warehouseId: messager.warehouseId,
            status: 'Enrutado',
            from,
            to
        }

        setRoutesLoading(true);
        try {
            const routes = await billsAPI.GetBillByDepartmentandStatus(payload);
            const routesMessager = routes.filter( x => x.zone == messager.name);
            setRoutes(routesMessager.sort((a : any, b : any ) => a.delivery_Order  - b.delivery_Order));
        } catch (error) {
            messager.error(`No se obtuvieron los servicios enrutados. ${error}`);
        }
        setRoutesLoading(false);
    }

    const [statuses, setStatuses] = useState<Statuses[]>([]);
    const getStatuses = async () => {
        try {
            const users: any = await usersAPI.getUsers();
            const messager = users.find(((m: any) => m.id == localStorage.userID));
            const resp: any = await billsAPI.getStatusList();
            switch (messager.warehouseId) {
                case 2:  
                    setStatuses(resp.filter((s : any) => s.id === 3 || s.id ===10 || s.id == 8));
                    break;
                case 3:
                    setStatuses(resp.filter((s : any) => s.id === 3 || s.id ===10 || s.id == 8));
                    break;
                case 5:
                    setStatuses(resp.filter((s : any) => s.id === 3 || s.id ===10 || s.id == 8));
                    break;
                case 4:
                    setStatuses(resp.filter((s : any) => s.id === 3 || s.id ===10 || s.id === 7 || s.id == 8));
                    break;
                case 6:  
                    setStatuses(resp.filter((s : any) => s.id === 3 || s.id ===10 || s.id == 8));
                    break;
                case 7:  
                    setStatuses(resp.filter((s : any) => s.id === 3 || s.id ===10 || s.id == 8));
                    break;
            }
        } catch (error) {
            message.error(`No se obtuvieron los estados. ${error}`)
        }
    }


    useEffect(() => {
        let mounted = true;
        if (mounted) {
            getRoutes();
            getStatuses();
        }
        return () => {
            mounted = false;
        }
    }, [])


    return {
        routes,
        routesLoading,
        statuses,
        getRoutes
    }
}


