import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import Swal from 'sweetalert2';

export const useAlertMessage = () => {

    const Confirm = (statusCode:number,message:string,title?:string,statusText?:string) => {
       if(!statusText)
       {
           switch(statusCode){
               case 403: statusText = 'UNAUTHORIZED';
               break;
               case 401: statusText = 'FORBIDDEN';
               break;
               case 400: statusText = 'BAD REQUEST';
               break;
               case 500: statusText = 'INTERNAL SERVER ERROR';
               break;
            }
        }

        
        Swal.fire({
            title: title ? title : statusText,
            text: message,
            icon: (statusCode == 403 || statusCode == 401 || statusCode == 400 || statusCode == 500 || statusCode == 404 || statusCode == 0) ? 'error' : 'success',
            confirmButtonText: 'Close'
        })
    }

    const ShowLoading = (title:string) => {
        Swal.fire({
            title: title,
            allowOutsideClick: false
        });
        Swal.showLoading();
    }

    const CloseSwal = () => {
        Swal.close();
    }
    return {
        Confirm,
        ShowLoading,
        CloseSwal
    }
}