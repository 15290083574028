import { message } from "antd";
import { useContext, useState } from "react"
import BillNewsAPI from "../Api/api-billNews";
import billsAPI from "../Api/api-bills";
import { BillGet, getBillsById } from "../Models/Bill";
import { BillNews } from "../Models/BillNews"
import filterContext from "./filterContex";

export const useBillNews = () => {

    const filters = useContext(filterContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [bill, setBill] = useState<BillGet>();
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [news, setNews] = useState<BillNews[]>([])

    const GetBillById = (billId: number) => {
        let el: getBillsById = {
            id: billId
        }
        billsAPI.getBillsById(el).then(res => {
            setBill(res);
            setModalVisible(true);
        })
    }

   /*  const updateBillNews = (index: number) => (e: any) => {
        let newArr = { ...news as any };
        newArr[index][e.target.name] = e.target.value;
        newArr[index]["userID"] = localStorage.userID
        setNews(newArr);
    }

    const saveData = (billNews: BillNews) => {
        setLoading(true)
        BillNewsAPI.updateBillNews(billNews).then(res => {
            message.success('Los datos fueron guardados.')
            setLoading(false);
        });
    } */

    return {
        GetBillById,
        bill,
        setModalVisible,
        modalVisible,
        loading
    }
}