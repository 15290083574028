import * as React from 'react';
import { IFormModalComponentProps } from './IFormModalComponentProps';
import './FormModalComponent.css';

// Models
import { Product } from '../../../../Models/Product';

// React Bootstrap
import { Button, Col, Form, Row, Spinner, Table } from 'react-bootstrap';

// Icons Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';

const FormModalComponent: React.SFC<IFormModalComponentProps> = (props) => {
  return (
    <Form noValidate validated={ props.validated } onSubmit={ props.handleSubmit }>

      {localStorage.role == '1' && 
         <Form.Group controlId="formBasicCheckbox">
          <FontAwesomeIcon onClick={ () => props.setToDisable(!props.toDisable) } icon={faEdit}/>
        </Form.Group>
      }
      <Row>
        <Col>
         <Form.Group controlId="validationSeller">
           <Form.Label>Vendedor</Form.Label>
           <Form.Control
            as="select"
            name="sellerId"
            value={ props.selectedData.sellerId }
            onChange={ props.handleInputChange }
            required
            custom
            disabled={ !props.toDisable }
          >
            <option value="">Seleccionar...</option>
            {props.sellersList.map(x=><option value={x.id}>{x.name}</option>)}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            Seleccione un vendedor
          </Form.Control.Feedback> 
         </Form.Group>
        </Col>

        <Col>
          <Form.Group controlId="validationFullName">
            <Form.Label>Nombre Completo</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={props.selectedData.name}
              onChange={props.handleInputChange}
              required
              disabled={ !props.toDisable }
            />
            <Form.Control.Feedback type="invalid">
              Este campo es requerido.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group controlId="validationIdentification">
            <Form.Label>Cédula</Form.Label>
            <Form.Control
              type="text"
              name="document"
              value={props.selectedData.document}
              onChange={props.handleInputChange}
              required
              disabled={ !props.toDisable }
            />
            <Form.Control.Feedback type="invalid">
              Este campo es requerido.
            </Form.Control.Feedback>
          </Form.Group>
        </Col> 
      </Row>

      <Row>
        <Col>
          <Form.Group  controlId="validationAddress">
            <Form.Label>Dirección</Form.Label>
            <Form.Control
              required
              type="text"
              name="address"
              value={props.selectedData.address}
              onChange={props.handleInputChange}
              disabled={ !props.toDisable }
            />
            <Form.Control.Feedback type="invalid">
              Este campo es requerido.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group controlId="validationEmail">
            <Form.Label>Correo</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={props.selectedData.email}
              onChange={props.handleInputChange}
              disabled={ !props.toDisable }
            />
            <Form.Text className="text-muted">Opcional</Form.Text>
            <Form.Control.Feedback type="invalid">
              No es un email válido.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group controlId="validationTelephone">
            <Form.Label>Teléfono</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              value={props.selectedData.phone}
              onChange={props.handleInputChange}
              required
              disabled={ !props.toDisable }
            />
            <Form.Control.Feedback type="invalid">
              Este campo es requerido.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>

        <Col>
          <Form.Group  controlId="validationDepartament">
            <Form.Label>Departamento</Form.Label>
            <Form.Control
              as="select"
              name="provinceId"
              value={props.selectedData.provinceId}
              onChange={props.handleInputChange}
              required
              custom
              disabled={ !props.toDisable }
            >
              <option value="">Seleccionar...</option>
              {props.CitiesList.map(x=> <option value={x.provinceId} key={x.provinceName}>{x.provinceName}</option>)}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Seleccione un departamento.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group controlId="validationCity">
            <Form.Label>Ciudad</Form.Label>
            <Form.Control
              as="select"
              name="cityId"
              value={props.selectedData.cityId}
              onChange={props.handleInputChange}
              required
              custom
              disabled={ !props.toDisable }
            >
              <option value="">Seleccionar...</option>
              {props.CitiesList.filter(x=>x.provinceId == props.selectedData.provinceId)
                  .map(x=> x.cities_CompanyTransports
                  .map(ct=><option value={ct.cityId} key={ct.cityName}>{ct.cityName}</option>))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Seleccione una ciudad.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

      </Row>

      <Row>
        <Col>
          <Form.Group controlId="validationCompany">
            <Form.Label>Empresa</Form.Label>
            <Form.Control
              as="select"
              name="company"
              value={props.selectedData.company}
              onChange={props.handleInputChange}
              required
              custom
              disabled={ !props.toDisable }
            >
              <option value={props.selectedData.company}>{props.selectedData.company}</option>
              
              {/* <option value={'CBD'}>CBD</option>
              <option value={'DLuchi'}>D'Luchi</option> */}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Seleccione una empresa.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group  controlId="validationTransport">
            <Form.Label>Transportadora</Form.Label>
            <Form.Control
              as="select"
              name="company_TransportId"
              value={props.selectedData.company_TransportId}
              onChange={props.handleInputChange}
              required
              custom
              disabled={ !props.toDisable }
            >
              <option value="">Seleccionar...</option>
              {props.CitiesList.filter(x=>x.provinceId == props.selectedData.provinceId)
                  .map(x=> x.cities_CompanyTransports.filter(city=>city.cityId == props.selectedData.cityId)
                  .map(ct=> ct.company_Transports.map(company => <option value={company.id} key={company.name}>{company.name}</option>)))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Seleccione una transportadora.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group controlId="validationProduct">
            <Form.Label>Producto</Form.Label>
            <Form.Control
              as="select"
              onChange={(e: any) => props.setSelectedProduct(e.target.value)}
              custom
              disabled={ !props.toDisable }
            >
              <option value="">Seleccionar...</option>
              {props.ProductsList.map(product => (
                  <option value={product.id}>{product.name}</option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Seleccione y agregue al menos un producto.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group controlId="validationQuantity">
            <Form.Label>Cantidad</Form.Label>
            <Form.Control
              required
              type="number"
              name="quantity"
              min={1}  
              disabled={ !props.toDisable }value={props.quantity}
              onChange={(e: any) => props.setQuantity(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Este campo es requerido.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col>
          <Button style={{marginTop:30}} variant="secondary" onClick={props.pushProduct} block disabled={ !props.toDisable }>
            Agregar 
            <FontAwesomeIcon className="ml-2" icon={faPlus} />
          </Button>
        </Col>
        
      </Row>
      

      <Row>
        
        <Col>
          {props.selectedData.bill_Products  && (
            <Form.Row className="mb-3">
              <Form.Group as={Col} md="7">
                <Form.Label>Carrito</Form.Label>
                <Table>
                  <thead className="table-head">
                    <tr>
                      <th>Producto</th>
                      <th>Cantidad</th>
                      <th>Eliminar</th>
                    </tr>
                  </thead>
                  <tbody className="tb-body">
                    {props.selectedData.bill_Products.map((product: any) => (
                        <tr key={product.productId}>
                        <td>{product.productName}</td>
                        <td>{product.quantity}</td>
                        <td>
                          <Button
                            onClick={(e) => props.deleteProduct(product.id)}
                            variant="danger"
                            disabled={ !props.toDisable }
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Form.Group>
            </Form.Row>
          )}
        </Col>
        
      </Row>

      <Row>
        <Col>
          <Form.Group controlId="validationtypeConsigned">
            <Form.Label>Tipo Valor Consignado</Form.Label>
            <Form.Control
              as="select"
              name="typeConsigned"
              value={props.selectedData.typeConsignedValue}
              onChange={props.handleInputChange}
              custom
              disabled={ !props.toDisable }
            >
              <option value="">Seleccionar...</option>
              <option value={'Consignación'}>Consignación</option>
              <option value={'Transferencia'}>Transferencia</option>
            </Form.Control>
            <Form.Text className="text-muted">Opcional</Form.Text>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group controlId="validationConsignedValue">
            <Form.Label>Valor Consignado</Form.Label>
            <Form.Control
              type="number"
              value={props.selectedData.consigned_Value}
              name="consigned_Value"
              onChange={props.handleInputChange}
              disabled={ !props.toDisable }
              // disabled={!isConsigned}
            />
            {/* <Form.Check
              type="checkbox"
              // checked={isConsigned}
              // onClick={() => setIsConsigned(!isConsigned) }
              label="¿Valor consignado?"
            />
            <Form.Text className="text-muted">Opcional</Form.Text> */}
          </Form.Group>
          <Form.Check
              type="checkbox"
              // checked={isConsigned}
              // onClick={() => setIsConsigned(!isConsigned) }
              label="¿Valor consignado?"
            />
            <Form.Text className="text-muted">Opcional</Form.Text>
        </Col>

        <Col>
          <Form.Group  controlId="validationAmountToBePaid">
            <Form.Label>Valor a pagar</Form.Label>
            <Form.Control
              type="number"
              name="collect_Value"
              value={props.selectedData.collect_Value}
              onChange={props.handleInputChange}
              disabled={ !props.toDisable }
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col lg={7}>
          <Form.Group  controlId="validationAccountNumber">
            <Form.Label>Número De Cuenta</Form.Label>
            <Form.Control
              type="text"
              name="number_account"
              value={props.selectedData.number_account}
              onChange={props.handleInputChange}
              disabled={ !props.toDisable }
            />
            <Form.Text className="text-muted">
              Esta información es indispensable para agilizar el proceso de tu
              pedido.
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col lg={7}>
          <Form.Group controlId="validationObservations">
            <Form.Label>Observaciones</Form.Label>
            <Form.Control
              as="textarea"
              name="observations"
              value={props.selectedData.observations}
              onChange={props.handleInputChange}
              rows={3}
              disabled={ !props.toDisable }
            />
          </Form.Group>
        </Col>
      </Row>

      {props.toDisable &&
        <Row>
          <Col>
            <Button
              type="submit"
              variant="primary"
              block
              disabled={ props.loading }
            >
              Guardar
              {props.loading &&

                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="ml-3"
                />
              }
            </Button>
          </Col>

          <Col>
            <Button
              type="button"
              onClick={ props.handleClose3 }
              variant="secondary"
              block
            >
              Cancelar
            </Button>
          </Col>
        </Row>
      }
    </Form>
  );
};

export default FormModalComponent;
