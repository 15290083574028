import axios from 'axios';
import { Auth, User } from '../Models/User';
import utils from '../utils';
import { Utils } from '../utils/utils';

const usersAPI = {
    login(data: Auth) {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].ulrBackend}/Login`, data)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                            utils.messageError();
                        } else {
                            console.log('Error', error.message);
                            utils.messageError();
                        }
                        console.log(error.config);
                    });
            });
        });
    },
    getSellers(customerId: number): Promise<User[]> {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.get(`${config.data[0].ulrBackend}/Users/GetAllSellers/${customerId}`)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                            utils.messageError();
                        } else {
                            console.log('Error', error.message);
                            utils.messageError();
                        }
                        console.log(error.config);
                    });
            });
        });
    },

    // Servicio para crear usuarios
    postUsers(data: User) {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].ulrBackend}/Users`, data)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            // debugger
                            console.log(error.request);
                            utils.messageError();
                        } else {
                            // debugger
                            console.log('Error', error.message);
                            utils.messageError();
                        }
                        console.log(error.config);
                    });
            });
        });
    },

    postSaveToken(email: string) {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].ulrBackend}/Users/postsavetoken?email=${email}`)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            // debugger
                            console.log(error.request);
                            utils.messageError();
                        } else {
                            // debugger
                            console.log('Error', error.message);
                            utils.messageError();
                        }
                        console.log(error.config);
                    });
            });
        });
    },

    resetPass(userRecover: any) {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].ulrBackend}/Users/resetPass`, userRecover)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            // debugger
                            console.log(error.request);
                            utils.messageError();
                        } else {
                            // debugger
                            console.log('Error', error.message);
                            utils.messageError();
                        }
                        console.log(error.config);
                    });
            });
        });
    },

    getRols() {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.get(`${config.data[0].ulrBackend}/Users/GetRols`)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            // debugger
                            console.log(error.request);
                            utils.messageError();
                        } else {
                            // debugger
                            console.log('Error', error.message);
                            utils.messageError();
                        }
                        console.log(error.config);
                    });
            });
        });
    },

    getUsers() {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.get(`${config.data[0].ulrBackend}/Users/GetUserList`)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            // debugger
                            console.log(error.request);
                            utils.messageError();
                        } else {
                            // debugger
                            console.log('Error', error.message);
                            utils.messageError();
                        }
                        console.log(error.config);
                    });
            });
        });
    },

    UpdateUser( user: any) {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].ulrBackend}/Users/UpdateUser`, user)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            // debugger
                            console.log(error.request);
                            utils.messageError();
                        } else {
                            // debugger
                            console.log('Error', error.message);
                            utils.messageError();
                        }
                        console.log(error.config);
                    });
            });
        });
    },

    getUserById( id: any): Promise<any> {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.get(`${config.data[0].ulrBackend}/Users/${id}`)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            // debugger
                            console.log(error.request);
                            utils.messageError();
                        } else {
                            // debugger
                            console.log('Error', error.message);
                            utils.messageError();
                        }
                        console.log(error.config);
                    });
            });
        });
    },
}

export default usersAPI;