import * as React from 'react';
import { IBodyComponentProps } from './IBodyComponentProps';


// React Router Dom
import { BrowserRouter, Redirect, Route } from 'react-router-dom';

// Componentes
import LoginComponent from '../LoginComponent/LoginComponent';
import HomeComponent from '../HomeComponent/HomeComponent';
import ManagementComponent from '../ManagementComponent/ManagementComponent';
import DepartamentComponent from '../DepartamentComponent/DepartamentComponent';
import OfficesComponent from '../OfficesComponent/OfficesComponent';
import InventoryComponent from '../InventoryComponent/InventoryComponent';
import { RecoverPassword } from '../RecoverPassword/RecoverPassword';
import SheduledComponent from '../SheduledComponent/SheduledComponent';
import HistoryServices from '../HistoryServices/HistoryServices';
import LayoutComponent from '../Layout/Layout';
import CreateUsers from '../CreateUsers/CreateUsers';
import { ScheduledServices } from '../ScheduledServices/ScheduledServices';
import { FormService } from '../FormService/FormService';
import AdminCenter from '../Administrator/AdminCenter/AdminCenter';
import { RoutingServices } from '../ScheduledServicesNew/RoutingServices';
import { ConfirmedGuide } from '../ServiceConfirmed/ConfirmedGuide';
import DetailBill from '../NewComponents/DetailBill/DetailBill';


const BodyComponent: React.SFC<IBodyComponentProps> = (props) => {
  return (
    <BrowserRouter>
      <Route path="/LoginComponent" component={LoginComponent} />
      <Route exact path="/HomeComponent" component={HomeComponent} />
      <Route exact path="/ManagementComponent" component={ManagementComponent} />
      <Route exact path="/DepartamentComponent" component={DepartamentComponent} />
      <Route exact path="/SheduledComponent" component={SheduledComponent} />
      <Route exact path="/SheduledService" component={ScheduledServices} />
      <Route exact path="/FormService" component={FormService} />
      <Route path="/RecoverPassword" component={RecoverPassword} />
      <Route exact path="/OfficesComponent" component={OfficesComponent} />
      <Route exact path="/InventoryComponent" component={InventoryComponent} />
      <Route exact path="/HistoryServices" component={HistoryServices} />
      <Route exact path="/CreateUser" component={CreateUsers} />
      <Route exact path="/RoutingService" component={RoutingServices} />
      <Route exact path="/AdminCenter" component={AdminCenter} />
      <Route exact path="/ConfirmedGuide/:guia/:id" component={ConfirmedGuide} />
      <Route exact path="/DetailBill" component={DetailBill} />
      <Route exact path="/" component={LayoutComponent} />
    </BrowserRouter>
  );
};

export default BodyComponent;
