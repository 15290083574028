import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import customerAPI from '../Api/api-customers'
import { Customer } from '../Models/Customer';

export const useCustomer = () => {

    const [customers, setCustomers] = useState<Customer[]>([]);

    const getCustomers = async() => {
        try {
            const customers = await customerAPI.getCustomers();
            setCustomers(customers);
        } catch (error) {
            message.error(`No se obtuvieron los clientes. ${error}`);
        }
    }

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            getCustomers();
        }
        return () => {
            mounted = false;
        }
    }, [])

    return {
        customers
    }
}
