import * as React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { IOfficesModalComponentProps } from './IOfficesModalComponentProps';
import './OfficesModalComponent.css';

const OfficesModalComponent: React.SFC<IOfficesModalComponentProps> = (props) => {
  return (
    <Form onSubmit={ props.handleDispatch } validated={ props.validated }>
      <Row>
        <Col>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Transportadora</Form.Label>
            <Form.Control 
              as="select"
              name="company_TransportId"
              value={props.selectedData.company_TransportId}
              onChange={props.handleInputChange}
              required
              custom
            >
              <option value="">Seleccionar...</option>
              <option value={1} key={"Paloma Mensajeria"}>{"Paloma Mensajeria"}</option>
              <option value={2} key={"Coordinadora"}>{"Coordinadora"}</option>
              <option value={3} key={"Envia"}>{"Envia"}</option>
              <option value={4} key={"Interapidisimo"}>{"Interapidisimo"}</option>
              <option value={5} key={"Servientrega"}>{"Servientrega"}</option>
              <option value={6} key={"Otro"}>{"Otro"}</option>
             {/*  {console.log(props.CitiesList)}
              {props.CitiesList.filter(x=>x.provinceId == props.selectedData.provinceId)
                  .map(x=> x.cities_CompanyTransports.filter(city=>city.cityId == props.selectedData.cityId)
                  .map(ct=> ct.company_Transports.map(company => <option value={company.id} key={company.name}>{company.name}</option>)))} */}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Seleccione una transportadora.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Numero de Guía</Form.Label>
            <Form.Control
              type="text"
              name="guia"
              value={props.selectedData.guia}
              onChange={props.handleInputChange}
              required
            />
          </Form.Group>
          <Form.Control.Feedback type="invalid">
            Digite un número de guía.
          </Form.Control.Feedback>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group controlId="exampleForm.ControlSelect2">
            <Form.Label>Empaque</Form.Label>
            <Form.Control 
              as="select"
              name="packing"
              value={ props.selectedData.packing }
              required
              custom
              onChange={props.handleInputChange}
            >
              <option value={'Paquete'}>Paquete</option>
              <option value={'Caja'}>Caja</option>
            </Form.Control>
          </Form.Group>
          <Form.Control.Feedback type="invalid">
            Seleccione una empaque.
          </Form.Control.Feedback>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group controlId="exampleForm.ControlInput2">
            <Form.Label>Cantidad</Form.Label>
            <Form.Control
              type="text"
              name="packingQuantity"
              value={props.selectedData.packingQuantity}
              onChange={props.handleInputChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              Digite una cantidad
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Button variant="primary" type="submit" block>
            Guardar
          </Button>
        </Col>
        <Col>
          <Button variant="secondary" onClick={props.handleClose2} block>
            Cancelar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default OfficesModalComponent;
