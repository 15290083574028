import React, { useEffect, useState } from 'react';

import DataGrid, {
    Column,
    FilterRow,
    Grouping,
    Selection,
    GroupPanel,
    Pager,
    Paging,
    SearchPanel,
    Export,
    HeaderFilter,
    Sorting,
    ColumnFixing,
    Editing,
    Lookup
} from 'devextreme-react/data-grid';
import billsAPI from "../../Api/api-bills";
import { BillStatus } from '../../Models/Bill';
import { Spin, Badge, message, Row, Col, Button, Popconfirm } from 'antd';
import { Customer } from '../../Models/Customer';
import { EditOutlined, EnvironmentOutlined, PrinterOutlined, SearchOutlined, UserOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { ModalGuia } from './ModalGuia';
import { Statuses } from '../../Models/Status';
import { useAlertMessage } from '../../Hooks/useAlertMessage'
import { useManageErrors } from "../../Hooks/useManageAPIErrors";

type PendingAnulations = {
    services: BillStatus[],
    servicesLoading: boolean,
    customers: Customer[]
    messagers: any,
    statuses: Statuses[],
    statusSelected: string,
    getServices: Function,
    selectedRows: any
    selectedRowKeys: any,
    setSelectedRowKeys: any
    handleUpdate: any
}

const PendingAnulations = ({ services, servicesLoading, customers, messagers, statuses, statusSelected, getServices, selectedRows, selectedRowKeys, setSelectedRowKeys, handleUpdate }: PendingAnulations) => {

    const { Confirm } = useAlertMessage();
    const { onError } = useManageErrors();

    // Modal Guia
    const [modalGuiaVisible, setModalGuiaVisible] = useState(false);
    const [guiaSelected, setGuiaSelected] = useState<BillStatus>({});

    const showModalGuia = (data: BillStatus) => {
        setGuiaSelected(data);
        setModalGuiaVisible(true);
    }

    // Custom Cells
    const guiaCell = ({ data }: any) => {
        if (data.observations !== '') {
            return (
                <Badge color="green">
                    <a onClick={() => showModalGuia(data)}>{data.guia}</a>
                </Badge>
            )
        } else {
            return (
                <a onClick={() => showModalGuia(data)}>{data.guia}</a>
            )
        }
    }

    const detailCell = ({ data }: any) => {
        return (
            <ul>
                <li><b>Nombre: </b>{`${data.name}`}</li>
                <li><b>Cel: </b>{`${data.phone}`}</li>
                <li><b>Dir: </b>{`${data.address}`}</li>
                <li><b>Ciu: </b>{`${data.city}`}</li>
            </ul>
        )
    }

    const onCancelOrder = (billId: number) => {
        billsAPI.cancelBill(billId, "Anulado").then(res => {
            Confirm(200, 'El pedido fue cancelado');
        })
            .catch(error => onError(error));
    }

    const cancelButton = ({ data }: any) => {
        if (localStorage.role == "1") {
            return (
                <Popconfirm title='Seguro de cancelar este pedido?' onConfirm={() => onCancelOrder(data.id)}>
                    <Button><MinusCircleOutlined style={{ color: 'red' }} /> Anular</Button>
                </Popconfirm>
            )
        }
    }

    return (
        <div>
            <Spin tip="Cargando..." spinning={servicesLoading}>
                <div id="data-grid-demo">
                    <DataGrid
                        id="gridContainer"
                        dataSource={services}
                        onRowUpdated={handleUpdate}
                        onSelectionChanged={selectedRows}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        showBorders={true}
                        rowAlternationEnabled={true}
                    >
                        <SearchPanel visible={true} highlightCaseSensitive={true} />
                        <Grouping autoExpandAll={true} />
                        <FilterRow visible={true} />
                        <GroupPanel visible={true} />
                        <Grouping autoExpandAll={true} />
                        <Export enabled={true} allowExportSelectedData={false} />
                        <HeaderFilter visible={true} />
                        <Sorting mode="multiple" />
                        <ColumnFixing enabled={true} />
                        <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                        <Grouping autoExpandAll={false} />

                        <Selection
                            mode="multiple"
                            selectAllMode={'allPages'}
                            showCheckBoxesMode={'onClick'}
                        />
                        <Column caption="Guias" allowEditing={false} cellRender={guiaCell} />
                        <Column dataField="service_Description" dataType="string" caption="Descripción" allowEditing={false} />
                        <Column dataField="customerId" dataType="number" caption="Cliente" allowEditing={false} >
                            <Lookup dataSource={customers} displayExpr="name" valueExpr="id" />
                        </Column>
                        <Column cellRender={detailCell} caption="Detalle" />
                        <Column dataField="aditionalInformation" dataType="string" caption="Información Adicional" />
                        <Column cellRender={cancelButton} caption="Novedades" alignment="center" />

                        <Pager allowedPageSizes={[5, 10, 50, 100, 200]} showPageSizeSelector={true} />
                        <Paging defaultPageSize={200} />
                        <Editing
                            mode="cell"
                            allowUpdating={true}
                        />
                    </DataGrid>
                </div>
            </Spin>

            <ModalGuia
                guiaSelected={guiaSelected}
                modalGuiaVisible={modalGuiaVisible}
                setModalGuiaVisible={setModalGuiaVisible}
            />

        </div>
    )
};

export default PendingAnulations;
