import React from 'react';

// Ant Desing
import {  Col, Row, Tabs } from 'antd';


// Componentes
import { SheduledInvoice } from './SheduledInvoice';
import { DeferredInovice } from './DeferredInovice';
import { useSheduledServices } from './Hooks/useSheduledServices';
import { NewsCourier } from './NewsCourier';


// Ant Desing Controlls
const { TabPane } = Tabs;

export const ScheduledServices = () => {

    
    return (
        <>
            <h3 className="title mt-2">Servicios Programados</h3>

            <Row>
                <Col span={24}>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Programados" key="1">
                            <SheduledInvoice />
                        </TabPane>
                        <TabPane tab="Programados Aplazados" key="2">
                            <DeferredInovice />
                        </TabPane>
                        <TabPane tab="Novedad Mensajero" key="3">
                            <NewsCourier />
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </>
    )
}
