import { useState } from "react";
import { Account } from "../../Models/Account";
import { Customer } from "../../Models/Customer";
import customerAPI from '../../Api/api-customers';
import utils from "../../utils";
import moment from "moment";
import AccountAPI from "../../Api/api-account";
import { notification } from "antd";

export const useAccount = () => {

    const [Account, setAccount] = useState<Account>({
        id:0,
        clientId:0,
        total:0,
        date:"",
        dueDate:"",
        observations:"",
        status:""
    });
    const [CustomerList, setCustomerList] = useState<Customer[]>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<Customer>();
    const [loading, setLoading] = useState<boolean>(false);
    const [AccountList, setAccountList] = useState<Account[]>([]);

    const getCustomerList = () => {
        customerAPI.getCustomersList().then(res=>{
            let sorted = utils.sortArray(res,"name");
            setCustomerList(sorted);
        })
    }

    const getAccounts = () => {
        AccountAPI.GetAccounts().then(res=>{
            res.map(x=>{
                x.date = moment(x.date).format("YYYY-MM-DD")
                x.dueDate = moment(x.dueDate).format("YYYY-MM-DD")  
            })
            setAccountList(res);
        })
    }

    const handleChange = (e:any,field:string) => {
        switch(field)
        {
            case 'observations': 
                const { name, value } = e.target;
                setAccount(prevState => ({
                    ...prevState,
                    [name]: value
                }));
            break;
            case 'date':
                let date = moment(e).format("YYYY-MM-DD");
                setAccount(prevState => ({
                    ...prevState,
                    [field]: date
                }));
            break;
            case 'dueDate':
                let dueDate = moment(e).format("YYYY-MM-DD");
                setAccount(prevState => ({
                    ...prevState,
                    [field]: dueDate
                }));
            break;
            case 'total':
                setAccount(prevState => ({
                    ...prevState,
                    [field]: e
                }));
            break;
        }        
    };

    const saveData = () => {
        setLoading(true);
        if(selectedCustomer)
        {
            Account.clientId = selectedCustomer.id;
            AccountAPI.postAccount(Account).then(res=>{
                notification.success({message:"La cuenta de cobro fue generada"});
                getAccounts();
                setLoading(false);
            });
        }
    }

    const handleSelectedCustomer = (idCustomer:number) => {
        let customer = CustomerList.filter(x=>x.id == idCustomer);
        if(customer.length > 0)
        {
            setSelectedCustomer(customer[0])
        }
    }

    const handleSelectedAccount = (data:Account) => {
        setAccount(data);
        handleSelectedCustomer(data.clientId);
    }

    const handleVolver = () => {
        setAccount({
            id:0,
            clientId:0,
            total:0,
            date:"",
            dueDate:"",
            observations:"",
            status:""
        })
    }

    function printDiv() {

        var divElements = document.getElementById('printDiv')?.innerHTML;
        //Get the HTML of whole page
        var oldPage = document.body.innerHTML;
        //Reset the page's HTML with div's HTML only
        document.body.innerHTML =
          "<html><head><title></title></head><body>" +
          divElements +
          "</body>";
        //Print Page
        window.print();
        //Restore orignal HTML
        document.body.innerHTML = oldPage;
      }

    return{
    printDiv,
    Account,
    CustomerList,
    handleSelectedCustomer,
    selectedCustomer,
    getCustomerList,
    handleChange,
    saveData,
    loading,
    getAccounts,
    AccountList,
    handleSelectedAccount,
    handleVolver
    }
}