import { Modal, Form, InputNumber, Row, Col, message } from 'antd'
import React, { useState } from 'react'
import billsAPI from '../../Api/api-bills'

type DrawerEditProps = {
    visibleDrawerEdit: boolean,
    setVisibleDrawerEdit: Function,
    billSelected: any,
    setBillSelected: any,
    getServices : any
}
export const DrawerEdit = ({ visibleDrawerEdit, setVisibleDrawerEdit, billSelected, setBillSelected, getServices }: DrawerEditProps) => {

    const onClose = () => {
        setVisibleDrawerEdit(false);
    }

    const handleInputChange = (value : any, name: string) => {
        setBillSelected({
            ...billSelected,
            [ name ] : value
        })
    }

    const [loading, setLoading] = useState(false);
    const handleUpdate = async() => {
        setLoading(true);
        try {
            await billsAPI.UpdateBillHeader(billSelected);
            getServices();
        } catch (error) {
           message.error(`No se pudo actualizar el pedido`); 
        }
        setLoading(false);
        setVisibleDrawerEdit(false);
    }

    return (
        <Modal
            title="Editar Valores"
            visible={visibleDrawerEdit}
            onCancel={onClose}
            width={500}
            onOk={ handleUpdate }
            confirmLoading={loading}
            okText="Actualizar"
            cancelText="Cancelar"
        >
            <Form layout="vertical">
                <Row>
                    <Col span={12}>
                        <Form.Item label="Valor a cobrar">
                            <InputNumber
                                defaultValue={billSelected.collect_Value}
                                formatter={(value : any) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value : any) => value.replace(/\$\s?|(,*)/g, '')}
                                onChange={ (value) => handleInputChange(value, 'collect_Value') }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Valor servicio">
                            <InputNumber
                                defaultValue={billSelected.service_Value}
                                formatter={(value : any) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value : any) => value.replace(/\$\s?|(,*)/g, '')}
                                onChange={ (value) => handleInputChange(value, 'service_Value') }
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}
