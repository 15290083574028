import React, { useState } from 'react';
import { Button, Col, message, Row } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import apiBills from '../../Api/api-bills';


const UploadExcel = () => {

    const handleInputChange = (e) => {
        try {
            const target = e.target
            const name = target.name
            let hojas = [];
            if (name === 'file') {
                let reader = new FileReader();
                reader.readAsArrayBuffer(target.files[0]);
                reader.onloadend = (e) => {
                    var data = new Uint8Array(e.target.result);
                    var workbook = XLSX.read(data, { type: 'array' });
                    workbook.SheetNames.forEach(function (sheetName) {
                        var XL_row_objet = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
                        hojas.push({
                            data: XL_row_objet,
                            sheetName
                        })
                    })

                    hojas[0].data.map((row, i) => {
                        let payload = {
                            id: row.id,
                        }
                        apiBills.getBillsById(payload).then(res => {
                            let payloadDispatch = {
                                ...res,
                                guia: row.guia.toString()
                            }
                            apiBills.UpdateBillHeader(payloadDispatch);
                            if ( hojas.data.length == i+1 ) {
                                message.success('Se ha cargado el archivo y se han actualizado las guias correctamente');
                            }
                        })
                    })
                }
            }
        } catch (error) {
            message.error(`No se ha podido actualizar las guias. ${error}`);
        }
    }

    return (
        <div>
            <Row>
                <Col span={1}></Col>
                <Col span={10} style={{ textAlign: 'center' }}>
                    <label for="file" class="custom-file-upload" type="primary" >
                        <UploadOutlined />Upload
                    </label>
                    <input
                        required
                        type="file"
                        name="file"
                        id="file"
                        onChange={handleInputChange}
                        placeholder="Archivo de excel"
                        onClick={e => (e.target.value = null)}
                    />
                </Col>
            </Row>
        </div>);
};

export default UploadExcel;
