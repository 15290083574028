import React, { useEffect, useState } from 'react';
import { useInventoryEntries } from '../../../../Hooks/useInventoryEntries';
import DataGrid, {
  Column,
  FilterRow,
  Grouping,
  Selection,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  Export,
  HeaderFilter,
  Sorting,
  ColumnFixing,
  Editing,
  Lookup
} from 'devextreme-react/data-grid';
import { Form, Input, Button, Modal, Select, Col, InputNumber, Table } from 'antd';
import './InventoryEntriesComponent.css';
import { IInventoryEntriesComponent } from './IInventoryEntriesComponentProps';
import { DeleteColumnOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
// import { SaveOutlined } from '@material-ui/icons';


const { Option } = Select;
const InventoryEntriesComponent: React.SFC<IInventoryEntriesComponent> = (props) => {


  const {
    values,
    getProductsList,
    ProductsListFiltered,
    loading,
    saveData,
    setModalVisible,
    modalVisible,
    onSearchSelect,
    onFinishFailed,
    filters,
    addProduct,
    arrayProducts,
    deleteProduct,
    ref,
    setArrayProducts
  } = useInventoryEntries();

  useEffect(() => {
    getProductsList();
  }, [filters.customerId, filters.warehouseId])

  const DeleteProduct = ({ data } : any) => {
    return (
      <DeleteOutlined  onClick={() => deleteProduct(data)}/>
    )
  }

  const onCancel = () => {
    setModalVisible(false);
    ref.current.resetFields();
    setArrayProducts([  ])
  }

  return (
    <>
      <Button onClick={() => setModalVisible(true)} type="primary">Registrar entrada</Button>
      <Modal
        visible={modalVisible}
        title="Entradas de inventario"
        onOk={() => setModalVisible(false)}
        onCancel={onCancel}
        footer={[]}
        width={1000}
      >
        <Form
          ref={ref}
          name="Entradas de inventario"
          onFinish={addProduct}
          initialValues={values}
          onFinishFailed={onFinishFailed}
        >
          <Col span={12}>
            <Form.Item
              label="Producto"
              name="productId"
              rules={[
                {
                  validator: (_, value) =>
                    value != 0 ? Promise.resolve() : Promise.reject('El Producto es obligatorio!'),
                },
              ]}>
              <Select
                style={{ width: '100%' }}
                showSearch
                defaultActiveFirstOption={false}
                showArrow={true}
                filterOption={false}
                onSearch={onSearchSelect}
                notFoundContent="No hay datos..."
                placeholder="Seleccione"
                allowClear
              >
                <Option value={0} key={0}> Selecciona un producto...</Option>
                {ProductsListFiltered.map(x =>
                  <Option key={x.id} value={x.id}>{x.palomaName}</Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Cantidad"
              name="quantity"
              rules={[
                {
                  validator: (_, value) =>
                    value != 0 ? Promise.resolve() : Promise.reject('Cantidad es obligatorio!'),
                },
              ]}>
              <InputNumber />
            </Form.Item>
          </Col>
          <Form.Item
            label="Observaciones"
            name="observations"
          >
            <Input placeholder="Remisión..." />
          </Form.Item>

          <Col span={12}>
            <Button type="primary" htmlType="submit">Agregar producto</Button>
          </Col>


          {arrayProducts.length !== 0 &&
            <DataGrid
              id="gridContainer"
              dataSource={arrayProducts}
              showBorders={true}
            >
              <Column dataField="productId" dataType="number" caption="Productos" allowEditing={false}>
                <Lookup dataSource={ProductsListFiltered} displayExpr="name" valueExpr="id" />
              </Column>
              <Column dataField="quantity" dataType="string" caption="Cantidad" allowEditing={false} />
              <Column dataField="observations" dataType="string" caption="Observaciones" allowEditing={false} />
              <Column caption="Eliminar" alignment="center" allowEditing={false} cellRender={DeleteProduct} />


              <Pager allowedPageSizes={[5, 10, 50, 100]} showPageSizeSelector={true} />
              <Paging defaultPageSize={5} />
            </DataGrid>
          }

          <Form.Item>
            <Button type="primary" onClick={ saveData } style={{ float: 'right' }} loading={loading}>
              <SaveOutlined /> Guardar
            </Button>
          </Form.Item>
        </Form>
      </Modal>

    </>
  );
};

export default InventoryEntriesComponent;


