import React, { useState } from 'react';
import { Spin, Badge, Popconfirm } from 'antd';
// DevExtreme
import DataGrid, {
    Column,
    FilterRow,
    Grouping,
    Selection,
    GroupPanel,
    Pager,
    Paging,
    SearchPanel,
    Export,
    HeaderFilter,
    Sorting,
    ColumnFixing,
    Editing,
    Lookup
} from 'devextreme-react/data-grid';
import { BillStatus } from '../../Models/Bill';
import { ModalGuia } from '../ScheduledServicesNew/ModalGuia';
import { Statuses } from '../../Models/Status';
import { Customer } from '../../Models/Customer';
import { DrawerEdit } from './DrawerEdit';
import { EditOutlined, WhatsAppOutlined } from '@ant-design/icons';
import moment from 'moment';

type ConfirmedServicesProps = {
    services: BillStatus[],
    loadingServices: boolean,
    messagers: any,
    statuses: Statuses[],
    customers: Customer[],
    confirmedSelect: number,
    statusSelected: string,
    getServices: Function,
    handleUpdate : any
}

export const ConfirmedServices = ({ services, loadingServices, messagers, statuses, customers, confirmedSelect, statusSelected, getServices, handleUpdate }: ConfirmedServicesProps) => {
    // Modal Guia
    const [modalGuiaVisible, setModalGuiaVisible] = useState(false);
    const [guiaSelected, setGuiaSelected] = useState<BillStatus>({});

    const showModalGuia = (data: BillStatus) => {
        setGuiaSelected(data);
        setModalGuiaVisible(true);
    }

    // Drawer Edit
    const [drawerEditVisible, setDrawerEditVisible] = useState(false);
    const [billSelected, setBillSelected] = useState<any>({});
    const showModalEdit = (data: any) => {
        setBillSelected(data);
        setDrawerEditVisible(true);
    }

    // Custom Cells
    const guiaCell = ({ data }: any) => {
        if (data.observations !== '') {
            return (
                <Badge color="green">
                    <a onClick={() => showModalGuia(data)}>{data.guia}</a>
                </Badge>
            )
        } else {
            return (
                <a onClick={() => showModalGuia(data)}>{data.guia}</a>
            )
        }
    }

    const detailCell = ({ data }: any) => {
        return (
            <ul>
                <li><b>Nombre: </b>{`${data.name}`}</li>
                <li><b>Cel: </b>{`${data.phone}`}</li>
                <li><b>Dir: </b>{`${data.address}`}</li>
                <li><b>Ciu: </b>{`${data.city}`}</li>
            </ul>
        )
    }

    const modalCell = ({ data }: any) => {
        return <EditOutlined onClick={(e) => showModalEdit(data)} />
    }


    const confirm = (data: BillStatus) => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        let message = `Cordial saludo, somos la empresa Paloma Mensajería, tenemos un pedido programado para ser entregado el día de mañana ${moment(tomorrow).format("DD-MM-YYYY")}, entre las 8am y las 6pm.     Productos: ${data.products} valor: $${data.collect_Value} Ingrese por favor a el siguiente link para confirmar su entrega y validar si la información en la guía está correcta, de lo contrario si tiene alguna novedad hacerlo saber por este medio.
        ${window.location}ConfirmedGuide/${data.guia}/${data.id}`;
        enviarWP(data, message);
    }

    const cancel = (data: BillStatus) => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        let message = `Cordial saludo, somos la empresa Paloma Mensajería, tenemos un pedido programado para ser entregado el día de mañana ${moment(tomorrow).format("DD-MM-YYYY")}, entre las 8am y las 3pm.     Productos: ${data.products} valor: $${data.collect_Value} Ingrese por favor a el siguiente link para confirmar su entrega y validar si la información en la guía está correcta, de lo contrario si tiene alguna novedad hacerlo saber por este medio.
        ${window.location}ConfirmedGuide/${data.guia}/${data.id}`;
        enviarWP(data, message);
    }

    const whatsappCell = ({ data }: any) => {
        return (
            <Popconfirm
                title="Enviar Mensaje"
                onConfirm={() => confirm(data)}
                onCancel={() => cancel(data)}
                okText="8am y las 6pm"
                cancelText="8am y las 3pm"
            >
                <WhatsAppOutlined style={{color: "green"}} />
            </Popconfirm>
        )
    }

    const enviarWP = (data: BillStatus, message: string) => {
        debugger
        if(data.warehouseId)
        {
            let url = `https://api.whatsapp.com/send?phone=${data.warehouseId <= 5 ? '+57' : '+593'}${data.phone}&text=%20${message}`;
            window.open(url, "_blank");
        }
    };

    return (
        <>
            <Spin tip="Cargando servicios..." spinning={loadingServices}>
                <div id="data-grid-demo">
                    <DataGrid
                        id="gridContainer"
                        dataSource={services}
                        onRowUpdated={handleUpdate}
                        // onSelectionChanged={selectedRows}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        showBorders={true}
                        rowAlternationEnabled={true}
                    >
                        <SearchPanel visible={true} highlightCaseSensitive={true} />
                        <Grouping autoExpandAll={true} />
                        <FilterRow visible={true} />
                        <GroupPanel visible={true} />
                        <Grouping autoExpandAll={true} />
                        <Export enabled={true} allowExportSelectedData={false} />
                        <HeaderFilter visible={true} />
                        <Sorting mode="multiple" />
                        <ColumnFixing enabled={true} />
                        <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                        <Grouping autoExpandAll={false} />

                        <Selection
                            mode="multiple"
                            selectAllMode={'allPages'}
                            showCheckBoxesMode={'onClick'}
                        />
                        <Column caption="Guias" allowEditing={false} cellRender={guiaCell} />
                        <Column dataField="customerId" dataType="number" caption="Cliente" allowEditing={false}>
                            <Lookup dataSource={customers} displayExpr="name" valueExpr="id" />
                        </Column>
                        <Column dataField="service_Description" dataType="string" caption="Descripción" allowEditing={false} />
                        <Column cellRender={detailCell} caption="Detalle" />
                        <Column dataField="aditionalInformation" dataType="string" caption="Información Adicional" />
                        <Column dataField="products" caption="Productos" dataType="string" allowEditing={false}/>
                        <Column dataField="zone" dataType="string" caption="Ruta / Mensajero" allowEditing={false}>
                            <Lookup dataSource={messagers} displayExpr="name" valueExpr="name" />
                        </Column>
                        <Column dataField="statusId" dataType="number" caption="Estado" allowEditing={false}>
                            <Lookup dataSource={statuses} displayExpr="name" valueExpr="id" />
                        </Column>
                        <Column cellRender={modalCell} caption="Confirmar" alignment="center" />
                        <Column cellRender={whatsappCell} caption="Whatsapp" alignment="center" />

                        <Pager allowedPageSizes={[5, 10, 50, 100,200]} showPageSizeSelector={true} />
                        <Paging defaultPageSize={200} />
                        <Editing
                            mode="cell"
                            allowUpdating={true}
                        />
                    </DataGrid>
                </div>
            </Spin>

            <ModalGuia
                guiaSelected={guiaSelected}
                modalGuiaVisible={modalGuiaVisible}
                setModalGuiaVisible={setModalGuiaVisible}
            />

            <DrawerEdit
                drawerEditVisible={drawerEditVisible}
                setDrawerEditVisible={setDrawerEditVisible}
                billSelected={billSelected}
                messagers={messagers}
                statuses={statuses}
                setBillSelected={setBillSelected}
                confirmedSelect={confirmedSelect}
                statusSelected={statusSelected}
                getServices={getServices}
            />
        </>
    )
}
