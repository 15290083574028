import { DeleteOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Input, Modal, Row, Select } from 'antd';
import * as React from 'react';
import { useEffect } from 'react';
import { useAddKit } from '../hooks/useAdd_Kit';
import { IModal_Add_KitProps } from './IModal_Add_KitProps';
import './Modal_Add_Kit.css';
const { Option } = Select;

const Modal_Add_Kit: React.SFC<IModal_Add_KitProps> = (props) => {

  const {
    isModalVisible,
    showModal,
    handleCancel,
    loading,
    saveKit,
    getProductList,
    onSearchProducts,
    productListFiltered,
    updateKitData,
    kit,
    addProduct,
    updateProductId,
    deleteProductFromKit,
    productList
  } = useAddKit(props.customerId);


  useEffect(() => {
    getProductList(props.customerId);
  }, []);

  return (
    <div>
      <Button type='primary' onClick={showModal}>Agregar Kit</Button>
      <Modal
        title="Crear KIT"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button type='primary' icon={<SaveOutlined />} onClick={saveKit} loading={loading}>Guardar KIT</Button>
        ]}
      >
        <Row gutter={[16, 16]}>
          <Col span={18}>
            <Select
              style={{ width: '100%' }}
              showSearch
              placeholder="Select kit"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
              }
              onChange={(e) => updateKitData(e)}
            >
              <Option value={0} key={0}> Selecciona un producto...</Option>
              {productList.map(x =>
                <Option key={x.id} value={x.id}>{x.palomaName}</Option>
              )}
            </Select>
          </Col>
        </Row>
        <br />
        <Row gutter={[16, 16]}>
          <Col span={14}>
            Productos:
          </Col>
          <Col span={10}>
            <Button type='primary' icon={<PlusOutlined />} onClick={addProduct}>Agregar Producto</Button>
          </Col>
        </Row>
        <br />
        {kit.product_List.map((x, i) =>
          <>
            <Row gutter={[16, 16]}>
              {console.log(kit.product_List)}
              <Col span={18}>
                <Select
                  style={{ width: '100%' }}
                  value={x}
                  showSearch
                  defaultActiveFirstOption={false}
                  showArrow={true}
                  filterOption={false}
                  onSearch={onSearchProducts}
                  notFoundContent="No hay datos..."
                  allowClear
                  onChange={(e) => updateProductId(e, i)}
                >
                  <Option value={0} key={0}> Selecciona un producto...</Option>
                  {productListFiltered.map(x =>
                    <Option key={x.id} value={x.id}>{x.palomaName}</Option>
                  )}
                </Select>
              </Col>
              <Col span={2}></Col>
              <Col span={4} className='deleteIcon' title='Eliminar Producto' onClick={() => deleteProductFromKit(i)}><DeleteOutlined /></Col>
            </Row>
            <br />
          </>
        )}

      </Modal>
    </div>
  );
};

export default Modal_Add_Kit;
