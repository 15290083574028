import React, { useContext, useState } from 'react';
import { Row, Col, Select, Card, Typography, Spin, Badge, DatePicker, Alert, Form } from 'antd';
import DataGrid, {
    Column,
    FilterRow,
    Grouping,
    Selection,
    GroupPanel,
    Pager,
    Paging,
    SearchPanel,
    Export,
    HeaderFilter,
    Sorting,
    ColumnFixing,
    Editing,
    Lookup
} from 'devextreme-react/data-grid';
import './Style.css';
import { BillStatus } from '../../Models/Bill';
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import { DrawerNews } from '../ScheduledServicesNew/DrawerNews';
import { ModalGuia } from '../ScheduledServicesNew/ModalGuia';
import moment from 'moment';
import filterContext from '../../Hooks/filterContex';
import { Statuses } from '../../Models/Status';
import { Customer } from '../../Models/Customer';
import { useMessager } from '../../Hooks/useMessager';
import { DrawerEdit } from './DrawerEdit';

const { Option } = Select;
const { Title, Paragraph } = Typography;
const { RangePicker } = DatePicker;

type LiquidationCouriersProps = {
    handleChangeMessager: any,
    messagersByWarehouse: any,
    services: BillStatus[];
    handleChangeDate: any,
    loadingServices: boolean,
    statuses: Statuses[],
    customers: Customer[],
    liquidationValue: number,
    getServices : any,
}

export const LiquidationCouriers = ({ getServices, handleChangeMessager, messagersByWarehouse, services, handleChangeDate, loadingServices, statuses, customers, liquidationValue }: LiquidationCouriersProps) => {

    const [filterDates, setFilterDates] = useState<any>([moment(new Date(), 'YYYY-MM-DD'), moment(new Date(), 'YYYY-MM-DD')]);
    const { warehouseId } = useContext(filterContext);

    // Modal Guia
    const [modalGuiaVisible, setModalGuiaVisible] = useState(false);
    const [guiaSelected, setGuiaSelected] = useState<BillStatus>({});

    const showModalGuia = (data: BillStatus) => {
        setGuiaSelected(data);
        setModalGuiaVisible(true);
    }

    // Drawer News
    const [visibleDrawerNews, setVisibleDrawerNews] = useState(false);
    const [billSelected, setBillSelected] = useState<any>({});
    const showDrawerNews = async (data: BillStatus) => {
        setBillSelected(data);
        setVisibleDrawerNews(true);
    }


    // Drawer Edit 
    const [visibleDrawerEdit, setVisibleDrawerEdit] = useState(false);
    const showDrawerEdit = (data : any) => {
        setBillSelected(data);
        setVisibleDrawerEdit(true);
    }

    // Custom Cells
    const guiaCell = ({ data }: any) => {
        if (data.totalPendingBillNews > 0) {
            return (
                <Badge color="red" text={<a onClick={() => showModalGuia(data)}>{data.guia}</a>}/>
            )
        } else {
            return (
                <a onClick={() => showModalGuia(data)}>{data.guia}</a>
            )
        }
    }

    const detailCell = ({ data }: any) => {
        return (
            <ul>
                <li><b>Nombre.: </b>{`${data.name}`}</li>
                <li><b>Cel: </b>{`${data.phone}`}</li>
                <li><b>Dir: </b>{`${data.address}`}</li>
                <li><b>Ciu: </b>{`${data.city}`}</li>
            </ul>
        )
    }

    const newsCell = ({ data }: any) => {
        if (data.totalPendingBillNews > 0) {
            return (
                <Badge color="red" text={<SearchOutlined onClick={() => showDrawerNews(data)} />}/>
            )
        } else {
            return (
                null
            )
        }
    }

    const editDrawer = ({ data } : any) => {
        return (
            <EditOutlined  onClick={() => showDrawerEdit(data)} />
        )
    }

    const onExporting = (e:any) => {  
        e.component.beginUpdate();  
        e.component.columnOption("Novedades", "visible", false);  
        e.component.columnOption("Editar", "visible", false);  
        e.component.columnOption("Novedad", "visible", true);  
        e.component.columnOption("Guia", "visible", true);
        e.component.columnOption("Guias", "visible", false);
        e.component.columnOption("Detalle", "visible", false);
    } 
    const onExported = (e:any) =>{  
        e.component.columnOption("Novedades", "visible", true);  
        e.component.columnOption("Editar", "visible", true); 
        e.component.columnOption("Novedad", "visible", false);  
        e.component.columnOption("Guia", "visible", false);
        e.component.columnOption("Guias", "visible", true);
        e.component.columnOption("Detalle", "visible", true);
        e.component.endUpdate();  
    }

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    })


    return (
        <React.Fragment>
            {warehouseId !== 0
                ?
                (
                    <>
                        <Form layout="horizontal">
                            <Row className="contenedor" gutter={[24, 24]} justify="space-between">
                                <Col span={8}>
                                    <RangePicker onChange={handleChangeDate} defaultValue={filterDates} />
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Mensajero">
                                        <Select defaultValue={"Todos"} onChange={handleChangeMessager}>
                                            <Option value={"Todos"}>Todos</Option>
                                            {messagersByWarehouse.map(({ id, name }: any) =>
                                                <Option key={id} value={name}>{name}</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Card style={{ width: 300 }} /* loading={loading} */>
                                        <Spin tip="Cargando Valor..." spinning={loadingServices}>
                                            <Title level={5} className="title-liquidation">Valor a liquidar</Title>
                                            <Title level={5} className="title-liquidation">{formatterPeso.format(liquidationValue)}</Title>
                                        </Spin>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                        <Row style={{ marginTop: '15px' }}>
                            <Col span={24}>
                                <Spin tip="Cargando servicios..." spinning={loadingServices}>
                                    {services.length !== 0
                                        ?
                                        (
                                            <div id="data-grid-demo">
                                                <DataGrid
                                                    id="gridContainer"
                                                    dataSource={services}
                                                    onExporting={onExporting}
                                                    onExported={onExported}
                                                    showBorders={true}
                                                    wordWrapEnabled={true}
                                                >
                                                    <SearchPanel visible={true} highlightCaseSensitive={true} />
                                                    <Grouping autoExpandAll={true} />
                                                    <FilterRow visible={true} />
                                                    <GroupPanel visible={true} />
                                                    <Grouping autoExpandAll={true} />
                                                    <Export enabled={true} allowExportSelectedData={false} />
                                                    <HeaderFilter visible={true} />
                                                    <Sorting mode="multiple" />
                                                    <ColumnFixing enabled={true} />
                                                    <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                                                    <Grouping autoExpandAll={false} />


                                                    <Column caption="Guias" allowEditing={false} cellRender={guiaCell} />
                                                    <Column caption="Guia" dataField="guia" visible={false} />
                                                    <Column dataField="customerId" dataType="number" caption="Cliente" allowEditing={false}>
                                                        <Lookup dataSource={customers} displayExpr="name" valueExpr="id" />
                                                    </Column>
                                                    <Column cellRender={detailCell} caption="Detalle" />
                                                    <Column dataField="statusId" dataType="number" caption="Estado" allowEditing={false}>
                                                        <Lookup dataSource={statuses} displayExpr="name" valueExpr="id" />
                                                    </Column>
                                                    <Column cellRender={newsCell} caption="Novedades" alignment="center" />
                                                    <Column dataField="products" caption="Productos" dataType="string" allowEditing={false} />
                                                    <Column dataField="collect_Value" caption="Valor a cobrar" dataType="number" allowEditing={false} />
                                                    <Column caption="Editar" cellRender={ editDrawer } alignment="center"/>
                                                    <Column caption="Novedad" dataField="bill_News" visible={false}/>

                                                    <Pager allowedPageSizes={[5, 10, 50, 100]} showPageSizeSelector={true} />
                                                    <Paging defaultPageSize={5} />
                                                    <Editing
                                                        mode="cell"
                                                        allowUpdating={true}
                                                    />
                                                </DataGrid>
                                            </div>
                                        )
                                        :
                                        (
                                            <Alert
                                                message="No hay servicios que mostrar"
                                                description="Seleccione una bodega diferente a Nacional y un mensajero"
                                                type="warning"
                                                showIcon
                                            />
                                        )
                                    }
                                </Spin>
                            </Col>
                        </Row>
                    </>
                )
                :
                (
                    <Alert
                        message="No hay servicios con esta bodega"
                        description="Seleccione una bodega diferente a Nacional"
                        type="warning"
                        showIcon
                    />
                )
            }

            <DrawerNews
                visibleDrawerNews={visibleDrawerNews}
                setVisibleDrawerNews={setVisibleDrawerNews}
                billSelected={billSelected}
            />

            <ModalGuia
                guiaSelected={guiaSelected}
                modalGuiaVisible={modalGuiaVisible}
                setModalGuiaVisible={setModalGuiaVisible}
            />

            <DrawerEdit 
                visibleDrawerEdit={visibleDrawerEdit}
                setVisibleDrawerEdit={setVisibleDrawerEdit}
                billSelected={billSelected}
                setBillSelected={setBillSelected}
                getServices={getServices}
            />

        </React.Fragment>
    )
}
