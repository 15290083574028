import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import NewsModalComponent from '../SheduledComponent/components/NewsModalComponent/NewsModalComponent';
import { IHistoryServicesProps } from './IHistoryServicesProps';
import './styles.css';
import { useHistoryServices } from '../../Hooks/useHistoryServices';
import { PrinterOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import DataGrid, {
  Column,
  FilterRow,
  Grouping,
  Selection,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  Export,
  HeaderFilter,
  Sorting,
  ColumnFixing,
  Lookup,
  Editing,
  Popup
} from 'devextreme-react/data-grid';
import { Badge, Button, Col, DatePicker, Pagination, Result, Row, Spin, Tabs } from 'antd';
import { } from 'react-bootstrap';
import { usePrintLabels } from '../../Hooks/usePrintLabels';
import ModalBillNews from '../ModalBillNews/ModalBillNews';
import { useServiceForm } from '../../Hooks/useServiceForm';
import { usePendingServices } from '../../Hooks/usePendingServices';
import { useSheduledComponent } from '../../Hooks/useSheduledComponent';
import ModalDetailService from '../ModalDetailService/ModalDetailService';
import { BillGet, BillStatus } from '../../Models/Bill';
import moment from 'moment';
import { ModalGuias } from '../ScheduledServicesNew/ModalGuias';
import { ModalGuia } from '../ScheduledServicesNew/ModalGuia';
import { useMessager } from '../../Hooks/useMessager';
import { useStatus } from '../../Hooks/useStatus';
import { DrawerNews } from '../ScheduledServicesNew/DrawerNews';
import { useAdminRouting } from '../ScheduledServicesNew/Hooks/useAdminRouting';
// import { UpdateOutlined } from '@material-ui/icons';
const { TabPane } = Tabs;

const HistoryServices: React.SFC<IHistoryServicesProps> = (props) => {

  // Modal Guia
  const [modalGuiaVisible, setModalGuiaVisible] = useState(false);
  const [guiaSelected, setGuiaSelected] = useState<any>({});

  const showModalGuia = (data: any) => {
    setGuiaSelected(data);
    setModalGuiaVisible(true);
  }

  // Custom Cells
  const guiaCell = ({ data }: any) => {
    if (data.totalPendingBillNews > 0) {
      return (
          <Badge color="red" text={<a onClick={() => window.open(`/DetailBill?id=${data.id}`)}>{data.guia}</a>}/>
      )
    } else {
        return (
            <a onClick={() => window.open(`/DetailBill?id=${data.id}`)}>{data.guia}</a>
        )
    }
  }

  const { RangePicker } = DatePicker;
  const {
    selectedData,
    GetBillById,
    setNewsShow,
    setPackOffShow,
    newsShow
  } = usePendingServices();

  const {
    historyServices,
    getHistoryServices,
    loading,
    Province,
    filters,
    selectedBillid,
    getMovement,
    movementList,
    statusList,
    saveEditing,
    DetailService,
    detailServiceId,
    dailyResume,
    filterDates,
    getDailyResume,
    setFilterDates,
    setFilterDatesMovement,
    filterDatesMovement,
    getStatuses,
    showDetailModal, 
    setShowDetailModal,
    PageSize,
    pageNumber,
    totalpages,
    handleChangePage,
    novedades,
    getNovedades
  } = useHistoryServices();

  const {
    handleUpdate,
  } = useAdminRouting();

  const { setArrayLabels, arrayLabels, printLabels, loadingPrint, modalGuiasVisible, setModalGuiasVisible } = usePrintLabels();

  const { messagersByWarehouse } = useMessager();
  const { statuses } = useStatus()
  const {
    getSellersList,
    getCities,
  } = useServiceForm();

  useEffect(() => {
    getHistoryServices(pageNumber);
    getNovedades();
    getDailyResume(filterDates);
    getMovement(filterDatesMovement);
    getCities();
    getSellersList();
    getStatuses();
  }, [filters.customerId, filters.warehouseId, filters.date]);

  const selectedRows = (data: any) => {
    debugger
    setArrayLabels(data.selectedRowKeys)
  }

  const handleClose = () => {
    setNewsShow(false);
    setValidated(false);
  };
  const handleClose2 = () => {
    setPackOffShow(false);
    setValidated(false);
  };

  const {
    handleInputChange,
    validated,
    setValidated,
    handleNews,
    news
  } = useSheduledComponent(selectedData, handleClose2, handleClose, handleClose, handleClose, getHistoryServices);

  // Drawer News
  const [visibleDrawerNews, setVisibleDrawerNews] = useState(false);
  const [billSelected, setBillSelected] = useState<any>({});
  const showDrawerNews = async (data: BillStatus) => {
    setBillSelected(data);
    setVisibleDrawerNews(true);
  }

  const DetailCell = (row: any) => {
    if (row.data.totalSolvedBillNews > 0 && row.data.totalPendingBillNews == 0) {
      return <ul>
        <li><Badge status="success" /><b>Nombre: </b>{`${row.data.name}`}</li>
        <li><b>Cel: </b>{`${row.data.phone}`}</li>
        <li><b>Dir: </b>{`${row.data.address}`}</li>
      </ul>
    }
    else {
      return <ul>
        <li><b>Nombre: </b>{`${row.data.name}`}</li>
        <li><b>Cel: </b>{`${row.data.phone}`}</li>
        <li><b>Dir: </b>{`${row.data.address}`}</li>
      </ul>
    }
  }
  const DetailCellMovement = (row: any) => {
    return <ul>
      <li><Badge status="success" /><b>Guia: </b>{`${row.data.guia}`}</li>
      <li><b>Nombre: </b>{`${row.data.name}`}</li>
      <li><b>Salida: </b>{`${row.data.movement_type}`}</li>
    </ul>
  }

  const newsCell = ({ data }: any) => {
    if (data.totalPendingBillNews > 0) {
      return (
          <Badge color="red" text={<SearchOutlined onClick={() => showDrawerNews(data)} />}/>
      )
  } else {
      return (
          null
      )
  }
  }

  const onExporting = (e:any) => {  
    e.component.beginUpdate();  
    e.component.columnOption("Novedades", "visible", false);  
    e.component.columnOption("Editar", "visible", false);  
    e.component.columnOption("Novedad", "visible", true);  
    e.component.columnOption("Guia", "visible", true);
    e.component.columnOption("Guias", "visible", false);
    e.component.columnOption("Detalle", "visible", false);
} 
const onExported = (e:any) =>{  
    e.component.columnOption("Novedades", "visible", true);  
    e.component.columnOption("Editar", "visible", true); 
    e.component.columnOption("Novedad", "visible", false);  
    e.component.columnOption("Guia", "visible", false);
    e.component.columnOption("Guias", "visible", true);
    e.component.columnOption("Detalle", "visible", true);
    e.component.endUpdate();  
}


  return (
    <div style={{ padding: 10, backgroundColor: 'white', marginTop: 15 }}>
      {arrayLabels.length > 0 &&
        <Button onClick={printLabels} type="primary" loading={loadingPrint}><PrinterOutlined />Imprimir</Button>
      }

      {filters.customerId != 0 && filters.warehouseId != 0 ?
        <>
          <div className="title">
            {Province}
          </div>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Histórico" key="1">
              <Spin tip="Cargando..." spinning={loading || loadingPrint}>
                <Pagination current={pageNumber} onChange={handleChangePage} pageSize={1} total={totalpages} showSizeChanger={false}/>
                <DataGrid
                  dataSource={historyServices}
                  onExporting={onExporting}
                  onExported={onExported}
                  onSelectionChanged={selectedRows}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  showBorders={true}
                  rowAlternationEnabled={true}
                // rowAlternationEnabled={true}
                >

                  <GroupPanel visible={true} />
                  <SearchPanel visible={true} highlightCaseSensitive={true} />
                  <Grouping autoExpandAll={true} />
                  <FilterRow visible={true} />
                  <GroupPanel visible={true} />
                  <SearchPanel visible={true} highlightCaseSensitive={true} />
                  <Grouping autoExpandAll={true} />
                  <Export enabled={true} allowExportSelectedData={false} />
                  <HeaderFilter visible={true} />
                  <Sorting mode="multiple" />
                  <GroupPanel visible={true} />
                  <ColumnFixing enabled={true} />
                  <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                  <Grouping autoExpandAll={false} />
                  <Selection
                    mode="multiple"
                    selectAllMode={'allPages'}
                    showCheckBoxesMode={'onClick'}
                  />
                  {/* <Column dataField="status" groupIndex={0} /> */}
                  <Column dataField="guia" dataType="string" caption="Guia" alignment="center" width={150} cellRender={guiaCell} />
                  <Column dataField="company_Transport" dataType="string" caption="Transportadora" alignment="center" visible={filters.warehouseId == 1} />
                  <Column dataField="created_at" caption="Fecha Solicitud" dataType="date" format="dd-MM-yyyy HH:mm"  />
                  <Column dataField="address" caption="Detalle" dataType="string" width={300} cellRender={DetailCell} />
                  <Column dataField="plate" caption="Placa" dataType="string" />
                  <Column dataField="name" caption="Nombre" dataType="string" />
                  <Column dataField="products" visible={false}/>
                  <Column dataField="statusId" dataType="string" caption="Estado">
                    <Lookup dataSource={statusList} displayExpr="name" valueExpr="id" />
                  </Column>
                  <Column dataField="createdBy" caption="Creado por" dataType="string" />
                  <Column dataField="delivered_at" dataType="date" caption="Fecha" format="dd-MM-yyyy HH:mm" />
                  <Column dataField="packing" dataType="string" caption="Emp." visible={filters.warehouseId == 1} width={80} />
                  <Column dataField="packingQuantity" dataType="number" caption="Cant" width={50} visible={filters.warehouseId == 1} />
                  <Column dataField="city" dataType="string" caption="Ciudad" />
                  <Column dataField="seller" dataType="string" caption="Vendedor" />
                  <Column cellRender={newsCell} caption="Novedades" alignment="center" />
                    <Column caption="Guia" dataField="guia" visible={false} />
                    <Column caption="Novedad" dataField="bill_News" visible={false}/>
                </DataGrid>
              </Spin>
            </TabPane>
            <TabPane tab={<Badge count={novedades.filter(x => x.statusId == 4 && x.totalPendingBillNews > 0).length}>Novedades</Badge>} key="2">
              <Spin tip="Cargando..." spinning={loading || loadingPrint}>
                <DataGrid
                  dataSource={novedades.filter(x =>x.statusId == 4 && x.totalPendingBillNews > 0)}
                  // allowColumnReordering={true}
                  // allowColumnResizing={true}
                  // showBorders={true} 
                  onExporting={onExporting}
                  onExported={onExported}
                  onSelectionChanged={selectedRows}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  showBorders={true}
                  rowAlternationEnabled={true}
                >

                  <GroupPanel visible={true} />
                  <SearchPanel visible={true} highlightCaseSensitive={true} />
                  <Grouping autoExpandAll={true} />
                  <FilterRow visible={true} />
                  <GroupPanel visible={true} />
                  <SearchPanel visible={true} highlightCaseSensitive={true} />
                  <Grouping autoExpandAll={true} />
                  <Export enabled={true} allowExportSelectedData={false} />
                  <HeaderFilter visible={true} />
                  <Sorting mode="multiple" />
                  <GroupPanel visible={true} />
                  <ColumnFixing enabled={true} />
                  <FilterRow visible={true} />
                  <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                  <Grouping autoExpandAll={false} />
                  <FilterRow visible={true} />
                  <Selection
                    mode="multiple"
                    selectAllMode={'allPages'}
                    showCheckBoxesMode={'onClick'}
                  />
                  {/* <Column dataField="status" groupIndex={0} /> */}
                  <Column dataField="guia" dataType="string" caption="Guia" alignment="center" width={150} cellRender={guiaCell} />
                  <Column dataField="company_Transport" dataType="string" caption="Transportadora" alignment="center" visible={filters.warehouseId == 1} />
                  <Column dataField="created_at" caption="Fecha Solicitud" dataType="date" format="dd-MM-yyyy HH:mm"  />
                  <Column dataField="address" caption="Detalle" dataType="string" width={300} cellRender={DetailCell} />
                  <Column dataField="plate" caption="Placa" dataType="string" />
                  <Column dataField="name" caption="Nombre" dataType="string" />
                  <Column dataField="statusId" dataType="string" caption="Estado">
                    <Lookup dataSource={statusList} displayExpr="name" valueExpr="id" />
                  </Column>
                  <Column dataField="createdBy" caption="Creado por" dataType="string" />
                  <Column dataField="delivered_at" dataType="date" caption="Fecha" format="dd-MM-yyyy HH:mm" />
                  <Column dataField="packing" dataType="string" caption="Emp." visible={filters.warehouseId == 1} width={80} />
                  <Column dataField="packingQuantity" dataType="number" caption="Cant" width={50} visible={filters.warehouseId == 1} />
                  <Column dataField="city" dataType="string" caption="Ciudad" />
                  <Column dataField="seller" dataType="string" caption="Vendedor" />
                  <Column cellRender={newsCell} caption="Novedades" alignment="center" />
                    <Column caption="Guia" dataField="guia" visible={false} />
                    <Column caption="Novedad" dataField="bill_News" visible={false}/>
                  <Editing
                    mode="cell"
                    allowUpdating={true}
                  />
                  <Pager allowedPageSizes={PageSize} showPageSizeSelector={true} />
                  <Paging defaultPageSize={20} />
                </DataGrid>
              </Spin>
            </TabPane>
            <TabPane tab={<Badge count={novedades.map((item: any) => item.totalSolvedBillNews).reduce((prev: number, curr: number) => prev + curr, 0)}>Novedades Solucionadas</Badge>} key="3">
              <Spin tip="Cargando..." spinning={loading || loadingPrint}>
                <DataGrid
                  dataSource={novedades.filter(x => x.totalSolvedBillNews > 0)}
                  // allowColumnReordering={true}
                  // allowColumnResizing={true}
                  // showBorders={true}
                  onSelectionChanged={selectedRows}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  showBorders={true}
                  rowAlternationEnabled={true}
                >
                  <GroupPanel visible={true} />
                  <SearchPanel visible={true} highlightCaseSensitive={true} />
                  <Grouping autoExpandAll={true} />
                  <FilterRow visible={true} />
                  <GroupPanel visible={true} />
                  <SearchPanel visible={true} highlightCaseSensitive={true} />
                  <Grouping autoExpandAll={true} />
                  <Export enabled={true} allowExportSelectedData={false} />
                  <HeaderFilter visible={true} />
                  <Sorting mode="multiple" />
                  <GroupPanel visible={true} />
                  <ColumnFixing enabled={true} />
                  <FilterRow visible={true} />
                  <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                  <Grouping autoExpandAll={false} />
                  <FilterRow visible={true} />
                  <Selection
                    mode="multiple"
                    selectAllMode={'allPages'}
                    showCheckBoxesMode={'onClick'}
                  />
                 <Column dataField="guia" dataType="string" caption="Guia" alignment="center" width={150} cellRender={guiaCell} />
                  <Column dataField="company_Transport" dataType="string" caption="Transportadora" alignment="center" visible={filters.warehouseId == 1} />
                  <Column dataField="created_at" caption="Fecha Solicitud" dataType="date" format="dd-MM-yyyy HH:mm"  />
                  <Column dataField="address" caption="Detalle" dataType="string" width={300} cellRender={DetailCell} />
                  <Column dataField="plate" caption="Placa" dataType="string" />
                  <Column dataField="name" caption="Nombre" dataType="string" />
                  <Column dataField="statusId" dataType="string" caption="Estado">
                    <Lookup dataSource={statusList} displayExpr="name" valueExpr="id" />
                  </Column>
                  <Column dataField="createdBy" caption="Creado por" dataType="string" />
                  <Column dataField="delivered_at" dataType="date" caption="Fecha" format="dd-MM-yyyy HH:mm" />
                  <Column dataField="packing" dataType="string" caption="Emp." visible={filters.warehouseId == 1} width={80} />
                  <Column dataField="packingQuantity" dataType="number" caption="Cant" width={50} visible={filters.warehouseId == 1} />
                  <Column dataField="city" dataType="string" caption="Ciudad" />
                  <Column dataField="seller" dataType="string" caption="Vendedor" />
                  <Column cellRender={newsCell} caption="Novedades" alignment="center" />
                    <Column caption="Guia" dataField="guia" visible={false} />
                    <Column caption="Novedad" dataField="bill_News" visible={false}/>
                  <Pager allowedPageSizes={PageSize} showPageSizeSelector={true} />
                  <Editing
                    mode="cell"
                    allowUpdating={true}
                  />
                  <Paging defaultPageSize={20} />
                </DataGrid>
              </Spin>
            </TabPane>
            <TabPane tab="Resumen Diario" key="4">
              <RangePicker defaultValue={filterDates} onChange={(e) => setFilterDates(e)} />
              <Button
                type="primary"
                className="btn"

                onClick={() => getDailyResume(filterDates)}
              >
                Actualizar <SaveOutlined />
              </Button>
              <Spin tip="Cargando..." spinning={loading || loadingPrint}>
                <DataGrid
                  dataSource={dailyResume.filter(x => x.status !== 'Programado' && x.status !== 'Enrutado' && x.status !== 'Novedad')}  /* || x.status == 'Cancelado' || x.status == 'Fallido' || x.status == 'Cobrado' || x.status == 'Despachado' */
                  onSelectionChanged={selectedRows}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  showBorders={true}
                  rowAlternationEnabled={true}
                >
                  <GroupPanel visible={true} />
                  <SearchPanel visible={true} highlightCaseSensitive={true} />
                  <Grouping autoExpandAll={true} />
                  <FilterRow visible={true} />
                  <GroupPanel visible={true} />
                  <SearchPanel visible={true} highlightCaseSensitive={true} />
                  <Grouping autoExpandAll={true} />
                  <Export enabled={true} allowExportSelectedData={false} />
                  <HeaderFilter visible={true} />
                  <Sorting mode="multiple" />
                  <GroupPanel visible={true} />
                  <ColumnFixing enabled={true} />
                  <FilterRow visible={true} />
                  <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                  <Grouping autoExpandAll={false} />
                  <FilterRow visible={true} />
                  <Selection
                    mode="multiple"
                    selectAllMode={'allPages'}
                    showCheckBoxesMode={'onClick'}
                  />
                  {/* <Column dataField="status" groupIndex={0} /> */}
                  <Column dataField="guia" dataType="string" caption="Guia" alignment="center" cellRender={guiaCell} />
                  <Column dataField="company_Transport" dataType="string" caption="Transportadora" alignment="center" visible={filters.warehouseId == 1} />
                  <Column dataField="address" caption="Detalle" dataType="string" width={300} cellRender={DetailCell} />
                  <Column dataField="document" caption="Cédula" dataType="string" />
                  <Column dataField="observations" caption="Observaciones" dataType="string" />
                  <Column dataField="name" caption="Nombre" dataType="string" />
                  <Column dataField="plate" caption="Placa" dataType="string" />
                  <Column dataField="status" dataType="string" caption="Estado" />
                  <Column dataField="products" dataType="string" caption="Productos" />
                  <Column dataField="delivered_at" dataType="date" caption="Fecha" format="yyyy-MM-dd HH:mm" visible={filters.warehouseId != 1} />
                  <Column
                    dataField="collect_Value"
                    caption="Valor a cobrar"
                    dataType="number"
                    format="currency"
                    alignment="right"
                    visible={filters.warehouseId != 1}
                  />

                  <Column
                    dataField="service_Value"
                    caption="Valor servicio"
                    dataType="number"
                    format="currency"
                    alignment="right"
                    visible={filters.warehouseId != 1}
                  />
                  <Column dataField="city" dataType="string" caption="Ciudad" />
                  <Column dataField="packing" dataType="string" caption="Empaque" visible={filters.warehouseId == 1} />
                  <Column dataField="packingQuantity" dataType="number" caption="Cantidad" visible={filters.warehouseId == 1} />
                  <Pager allowedPageSizes={PageSize} showPageSizeSelector={true} />
                  <Paging defaultPageSize={20} />
                </DataGrid>
              </Spin>
            </TabPane>
            <TabPane tab="Movimiento" key="5">
              <RangePicker defaultValue={filterDates} onChange={(e) => setFilterDatesMovement(e)} />
              <Button
                type="primary"
                className="btn"

                onClick={() => getMovement(filterDatesMovement)}
              >
                Actualizar <SaveOutlined />
              </Button>
              <Spin tip="Cargando..." spinning={loading || loadingPrint}>
                <DataGrid
                  dataSource={movementList}
                  // allowColumnReordering={true}
                  // showBorders={true}
                  // allowColumnResizing={true}
                  onSelectionChanged={selectedRows}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  showBorders={true}
                  rowAlternationEnabled={true}
                >
                  <GroupPanel visible={true} />
                  <SearchPanel visible={true} highlightCaseSensitive={true} />
                  <Grouping autoExpandAll={true} />
                  <Export enabled={true} allowExportSelectedData={false} />
                  <HeaderFilter visible={true} />
                  <Sorting mode="multiple" />
                  <ColumnFixing enabled={true} />
                  <FilterRow visible={true} />
                  <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                  <FilterRow visible={true} />
                  <Column dataField="guia" dataType="string" caption="Guia" alignment="center" cellRender={guiaCell} />
                  {/* <Column dataField="id" caption="Detalle" dataType="string" width={300} cellRender={DetailCellMovement} /> */}
                  <Column dataField="customerName" dataType="string" caption="Nombre" width={300} />
                  <Column dataField="delivered_at" caption="Fecha" dataType="date" format="dd-MM-yyyy HH:mm" />
                  <Column dataField="movement_type" dataType="string" caption="Tipo" />
                  <Column dataField="palomaName" dataType="string" caption="Producto" />
                  <Column dataField="quantity" caption="Cantidad" dataType="number" alignment="center" />
                  <Column dataField="observations" caption="Observaciones" />
                  <Paging defaultPageSize={10} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[5, 10, 20]}
                    showInfo={true} />
                </DataGrid>
              </Spin>
            </TabPane>
          </Tabs>
        </>
        :
        <Result status="warning" title="Selecciona un cliente y una bodega" />
      }
      {selectedBillid != 0 && <ModalBillNews billId={selectedBillid} />}
      <Modal show={newsShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Añadir Novedad</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <NewsModalComponent
            news={news}
            handleClose={handleClose}
            selectedData={selectedData}
            handleInputChange={handleInputChange}
            loading={loading}
            handleNews={handleNews}
            validated={validated}
          />

        </Modal.Body>
      </Modal>
      {detailServiceId != 0 &&
        <ModalDetailService idService={detailServiceId} getHistoryServices={getHistoryServices} showDetailModal={showDetailModal} setShowDetailModal={setShowDetailModal}/>
      }

      <ModalGuias
        setModalGuiasVisible={setModalGuiasVisible}
        modalGuiasVisible={modalGuiasVisible}
        selectedRowsKeys={arrayLabels}
      />
      <ModalGuia
        guiaSelected={guiaSelected}
        modalGuiaVisible={modalGuiaVisible}
        setModalGuiaVisible={setModalGuiaVisible}
      />

      <DrawerNews
        visibleDrawerNews={visibleDrawerNews}
        setVisibleDrawerNews={setVisibleDrawerNews}
        billSelected={billSelected}
      />


    </div>
  );
};

export default HistoryServices;
