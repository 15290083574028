import * as React from 'react';
import { IAdminHeaderProps } from './IAdminHeaderProps';
import './AdminHeader.css';
import { Row, Col, Menu, Badge } from 'antd';
import { DeploymentUnitOutlined, EnvironmentOutlined, UserOutlined } from '@ant-design/icons';
import Logo from '../../../Assets/Logo.png';
import Layout, { Header } from 'antd/lib/layout/layout';

const { SubMenu } = Menu;

const AdminHeader: React.SFC<IAdminHeaderProps> = (props) => {


  return (
    // <Layout className="layout">
    //   <Header>
    //     <img src={Logo} alt="Logo Paloma" className="logo" />
    //     <Menu theme="dark" mode="horizontal" className="menuHeader">
    //       <SubMenu className="color" key="SubMenu" icon={<DeploymentUnitOutlined />} title={
    //         <Badge className="menuHeader" count={props.numServiciosProgramados} overflowCount={999}>
    //           <div onClick={() => props.setEstadoSeleccionado("Programado")}>
    //             <p className="color">Servicios programados &nbsp;&nbsp;&nbsp;&nbsp; </p>
    //           </div>
    //         </Badge>
    //       }>
    //         <Menu.Item key="route:1" onClick={() => props.setDrawerMensajerosVisible(true)}>
    //           Enrutar
    //         </Menu.Item>
    //       </SubMenu>
    //       <Menu.Item className="color" key="Enrutados" icon={<EnvironmentOutlined />}>
    //         <Badge className="menuHeader" count={props.numServiciosEnrutados} overflowCount={999}>
    //           <p className="color">Servicios Enrutados &nbsp;&nbsp;&nbsp;&nbsp;</p>
    //         </Badge>
    //       </Menu.Item>
    //       <SubMenu icon={<UserOutlined />} title={localStorage.userName}>
    //       </SubMenu>
    //     </Menu>
    //   </Header>
    // </Layout>
    <Row className="adminHeader">
      <Col xs={24} sm={24} md={4} lg={4} xl={4}>
        <img src={Logo} style={{ width: '30%' }} />
      </Col>
      <Col xs={24} sm={24} md={16} lg={16} xl={16}>
        <Menu mode="horizontal" theme="dark" className="menuHeader">
            <SubMenu 
              className="menuHeader"
              title={
              <Badge className="menuHeader" count={props.numServiciosProgramados} overflowCount={999}>
                <div onClick={()=>props.setEstadoSeleccionado("Programado")}>
                  <p className="color">Servicios programados &nbsp;&nbsp;&nbsp;&nbsp;</p>
                </div>
              </Badge>
            }>

            </SubMenu>
            <SubMenu 
              className="menuHeader"
              title={
              <Badge className="menuHeader" count={props.numServiciosEnrutados} overflowCount={999}>
                <div onClick={()=>props.setEstadoSeleccionado("Enrutado")}>
                  <p className="color">Servicios Enrutados &nbsp;&nbsp;&nbsp;&nbsp;</p>
                </div>
              </Badge>
            }>
               <Menu.Item className="menuHeader" key="Enrutar" onClick={()=>props.setDrawerMensajerosVisible(true)}>
                Enrutdos
              </Menu.Item>
            </SubMenu>
        </Menu>
      </Col>
      <Col xs={24} sm={24} md={4} lg={4} xl={4} style={{ padding: 10 }} className="color">
        <UserOutlined  /><span className="color">&nbsp;{localStorage.userName} </span>
      </Col>
    </Row>
  );
};

export default AdminHeader;
