import React, { useRef, useState } from 'react';
import { Col, Drawer, Row, Form, Input, Select, Button, InputNumber, message } from 'antd';
import billsAPI from '../../Api/api-bills';
const { TextArea } = Input;
const { Option } = Select;

type DrawerEditCourierProps = {
    drawerEditVisible : any,
    setDrawerEditVisible : any,
    billSelected : any,
    messagers : any,
    statuses:any,
    setBillSelected : any,
    getNewsCourier: any,

}
export const DrawerEditCourier = ({drawerEditVisible, setDrawerEditVisible, billSelected, messagers, statuses, setBillSelected, getNewsCourier} : DrawerEditCourierProps) => {

    const handleSelectChange = (value: any, name: string ) => {
        setBillSelected({
            ...billSelected,
            [ name ] : value
        })
    }

    const [loading, setLoading] = useState(false);
    const handleUpdate = async() => {
        setLoading(true);
        try {
            if (billSelected.confirmed === 1) {
                billSelected.confirmed = true;
            }
            if (billSelected.confirmed === 0) {
                billSelected.confirmed = false;
            }
            if(billSelected.statusId === 1 || billSelected.statusId === 9){
               billSelected.zone = null;
            }
            await billsAPI.UpdateBillHeader(billSelected);
            message.success('Se actualizó correctamente el pedido');
            getNewsCourier();
        } catch (error) {
            message.error(`No se pudo actualizar el pedido. ${error}`);
        }
        setLoading(false);
        setDrawerEditVisible(false);
    }

    const newsFormClientRef = useRef<any>();
    const newsFormPalomaRef = useRef<any>();
    const [news, setNews] = useState({});
    const [loadingClient, setLoadingClient] = useState(false);
    const [loadingPaloma, setLoadingPaloma] = useState(false);
    const handleNewsChange = (event: any) => {
        let { name, value } = event.target;
        setNews({
            ...news,
            [name]: value
        })
    }

    const handleNewsClientSubmit = async ( newsBillingType: string ) => {
        if (Object.entries(news).length !== 0) {
            setLoadingClient(true);
            try {
                let registerNews: any = news;
                registerNews.billId = billSelected.id;
                registerNews.newsBillingType = newsBillingType;
                registerNews.changeStatus = false;
                await billsAPI.createBillsNews(registerNews);     
                message.success(`Novedad creada`);
            } catch (error) {
                message.error(`No se pudo crear la novedad. ${error}`);
            }
            newsFormClientRef.current.resetFields();
            setLoadingClient(false);
        } else {
            message.error('Escriba una novedad.');
        }
    }
    const handleNewsPalomaSubmit = async ( newsBillingType: string ) => {
        if (Object.entries(news).length !== 0) {
            setLoadingPaloma(true);
            try {
                let registerNews: any = news;
                registerNews.billId = billSelected.id;
                registerNews.newsBillingType = newsBillingType;
                registerNews.changeStatus = false;
                await billsAPI.createBillsNews(registerNews);          
                message.success(`Novedad creada`);
            } catch (error) {
                message.error(`No se pudo crear la novedad. ${error}`);
            }
            newsFormPalomaRef.current.resetFields();
            setLoadingPaloma(false);
        } else {
            message.error('Escriba una novedad.');
        }
    }

    return (
        <Drawer
            title="Editar"
            placement="right"
            closable={true}
            onClose={() => setDrawerEditVisible(false)}
            visible={drawerEditVisible}
            width={550}
        >
            <h5 style={{ textAlign: 'center', marginTop: '20px' }}>Información Adicional</h5>
            <Form ref={newsFormPalomaRef}>
                <Row>
                    <Col className="mt-3" span={24}>
                        <Form.Item name="description">
                            <div>Información Adicional:</div>
                            <TextArea
                                rows={4}
                                name="description"
                                onChange={handleNewsChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={11} >
                        <Button type="primary" loading={loadingPaloma} onClick={() => handleNewsPalomaSubmit('Novedad Paloma')}>
                            Registrar novedad
                        </Button>
                    </Col>
                </Row>
            </Form>
            <Form>
                <Row>
                    <Col span={24} className="mt-3">
                        <Form.Item>
                            <div>Mensajero:</div>
                            <Select placeholder="Seleccione un mensajero"  value={billSelected.zone} onChange={(value) => handleSelectChange(value, 'zone')}>
                                {messagers.map((m: any) =>
                                    <Option key={m.id} value={m.name}>{m.name}</Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8} className="mt-3">
                        <Form.Item>
                            <div>Valor a cobrar:</div>
                            <InputNumber
                                name="collect_Value"
                                value={billSelected.collect_Value}
                                formatter={(value: any) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                onChange={(value) => handleSelectChange(value, 'collect_Value')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8} className="mt-3">
                        <Form.Item>
                            <div>Valor adicional:</div>
                            <InputNumber
                                value={billSelected.aditional_Value}
                                formatter={(value: any) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                onChange={(value) => handleSelectChange(value, 'aditional_Value')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8} className="mt-3">
                        <Form.Item>
                            <div>Valor servicio:</div>
                            <InputNumber
                                value={billSelected.service_Value}
                                formatter={(value: any) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                onChange={(value) => handleSelectChange(value, 'service_Value')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} className="mt-3">
                        <Form.Item>
                            <div>Estado:</div>
                            <Select placeholder="Seleccione un estado"  value={billSelected.statusId} onChange={(value) => handleSelectChange(value, 'statusId')}>
                                {statuses.map((s: any) =>
                                    <Option key={s.id} value={s.id}>{s.name}</Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={11} className="mt-3 ml-3">
                        <Form.Item>
                            <div>Confirmar:</div>
                            <Select placeholder="Seleccione una opción"  value={billSelected.confirmed} onChange={(value) => handleSelectChange(value, 'confirmed')}>
                                <Option value={0}>Sin Confirmar</Option>
                                <Option value={1}>Confirmado</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Row justify="center">
                <Button type="primary" loading={loading} onClick={handleUpdate} className="mt-3" block>
                    Actualizar
                </Button>
            </Row>

            <h5 style={{ textAlign: 'center', marginTop: '20px' }}>Novedades</h5>
            <Form ref={newsFormClientRef}>
                <Row>
                    <Col className="mt-3" span={24}>
                        <Form.Item name="description">
                            <div>Registrar Novedad:</div>
                            <TextArea
                                rows={4}
                                name="description"
                                onChange={handleNewsChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Button type="primary" loading={loadingClient} onClick={() => handleNewsClientSubmit('Novedad Cliente')}>
                            Registrar novedad
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )
}
