import * as React from 'react';
import { IAccountProps } from './IAccountProps';
import firma from '../../Assets/FirmaJonathan.png'
import logo from '../../Assets/Logo.png'
import './Account.css';
import {
  Row,
  Col,
  Divider,
  Input,
  Button,
  Card,
  DatePicker,
  InputNumber,
  Select,
  Spin,
  message,
  Table,
  Typography
} from 'antd';
import DataGrid, {
  Column,
  FilterRow,
  Grouping,
  Selection,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  Export,
  HeaderFilter,
  Sorting,
  ColumnFixing,
  Lookup,
  Editing,
  Popup
} from 'devextreme-react/data-grid';
import moment from 'moment';
import { useAccount } from './useAccount';
import { useEffect } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { SaveOutlined } from '@ant-design/icons';
const { Option } = Select;
const { Title, Paragraph } = Typography;

const Account: React.SFC<IAccountProps> = (props) => {

  const {
    printDiv,
    Account,
    CustomerList,
    handleSelectedCustomer,
    selectedCustomer,
    getCustomerList,
    handleChange,
    saveData,
    loading,
    getAccounts,
    AccountList,
    handleSelectedAccount,
    handleVolver
  } = useAccount();

  useEffect(() => {
    getCustomerList();
    getAccounts();
  }, []);

  return (
    <div>
      <Title level={2} className="title">Cuentas de cobro</Title>
      {Account.id != 0 ?
        <>
          <Button type='link' onClick={handleVolver}>Volver</Button>
          <Row>
            <Col span={6}></Col>
            <Col span={12}>
              <Card id="printDiv">
                <div className='container'>
                  <div className='marginBottom'>
                    <Row>
                      <Col span={6} >
                        <img src={logo} width='100%' />
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row style={{justifyContent: 'center', marginBottom: 15}}>
                      <Col span={6} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        <Col span={24}>Cuenta N° {Account?.id}</Col>
                      </Col>
                    </Row>
                  </div>
                  <div className="marginBottom">
                    <Row>
                      <Col span={8} className={"negrilla"}>Cliente:</Col>
                      <Col span={8}>{selectedCustomer?.name}</Col>
                    </Row>
                    <Row >
                      <Col span={8} className={"negrilla"}>NIT / Cédula:</Col>
                      <Col span={8}>{selectedCustomer?.nit}</Col>
                    </Row>
                    <Row>
                      <Col span={8} className={"negrilla"}>Dirección:</Col>
                      <Col span={8}>{selectedCustomer?.address}</Col>
                    </Row>
                    <Row>
                      <Col span={8} className={"negrilla"}>Teléfono:</Col>
                      <Col span={8}>{selectedCustomer?.phone}</Col>
                    </Row>
                  </div>
                  <div className={"marginBottom"}>
                    <Row>
                      <Col span={24}>
                        Debe a: PALOMA MENSAJERÍA Y/O JONATHAN SUÁREZ JIMÉNEZ
                      </Col>
                    </Row>
                    <Row> 
                      <Col span={8} className={"negrilla"}>NIT:</Col>
                      <Col span={8}>1.128.386.008-0</Col>
                    </Row>
                    <Row>
                      <Col span={8} className={"negrilla"}>La suma de:</Col>
                      <Col span={8}>{Account?.total}</Col>
                    </Row>
                  </div>
                  <div className={"marginBottom"}>
                    <Row style={{textAlign: 'initial'}}>
                      <Col span={24}>
                        Por concepto de servicios de mensajería, según relación detallada abajo
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8} className={"negrilla"}>Fecha de facturación:</Col>
                      <Col span={8}>{moment(Account?.date).format("DD-MM-YYYY")}</Col>
                    </Row>
                    <Row>
                      <Col span={8} className={"negrilla"}>Fecha de vencimiento:	</Col>
                      <Col span={8}>{moment(Account?.dueDate).format("DD-MM-YYYY")}</Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col span={6} className={"negrilla"}>Observaciones:	</Col>
                    </Row>
                    <Row>
                      <Col span={18}>{Account?.observations}</Col>
                    </Row>
                  </div>
                  <br />
                  <div className={"marginBottom"}>
                    <Row>
                      <Col span={24}>
                        Favor realizar el pago por transferencia o consignación bancaria a:
                        BANCOLOMBIA / CUENTA DE AHORROS No. 27438361044 A NOMBRE DE
                        PALOMA MENSAJERÍA Y/O JONATHAN SUÁREZ JIMÉNEZ
                        y reportar el pago al correo contabilidad@palomamenajeria.com
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col span={24}>
                      <img src={firma} /><br />
                      JONATHAN SUÁREZ JIMÉNEZ<br />
                      <div className={"negrilla"}>Gerente</div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col span={6}></Col>
          </Row>
          <Row>
            <Col span={6}></Col>
            <Col span={12} style={{ padding: 10, textAlign: 'right' }}>
              <Button type="primary" onClick={printDiv}>Imprimir</Button>
            </Col>
            <Col span={6}></Col>
          </Row>
        </>
        :
        <>
          <Row gutter={[16, 16]}>
            <Col span={4}>
              Cliente:
              <Select
                style={{ width: '100%' }}
                onChange={(e: number) => handleSelectedCustomer(e)}>
                {CustomerList.map(x => <Option value={x.id}>{x.name}</Option>)}
              </Select>
            </Col>
            <Col span={4}>
              Fecha:
              <DatePicker
                name="date"
                style={{ width: '100%' }}
                onChange={(e) => handleChange(e, "date")}
              />
            </Col>
            <Col span={4}>
              Fecha vencimiento:
              <DatePicker
                name="dueDate"
                style={{ width: '100%' }}
                onChange={(e) => handleChange(e, "dueDate")}
              />
            </Col>
            <Col span={4}>
              Valor Total:
              <InputNumber
                style={{ width: '100%' }}
                name="total"
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                onChange={(e) => handleChange(e, "total")}
              />
            </Col>
            <Col span={6}>
              Observaciones:
              <TextArea
                name="observations"
                style={{ width: '100%' }}
                onChange={(e) => handleChange(e, "observations")}
              />
            </Col>
            <Col span={2}>
              <br />
              <Button type='primary' icon={<SaveOutlined />} loading={loading} onClick={saveData}>Guardar</Button>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col span={24}>
              <DataGrid
                dataSource={AccountList}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                showBorders={true}
                rowAlternationEnabled={true}
              // rowAlternationEnabled={true}
              >
               
                <GroupPanel visible={true} />
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <Grouping autoExpandAll={true} />
                <FilterRow visible={true} />
                <GroupPanel visible={true} />
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <Grouping autoExpandAll={true} />
                <Export enabled={true} allowExportSelectedData={false} />
                <HeaderFilter visible={true} />
                <Sorting mode="multiple" />
                <GroupPanel visible={true} />
                <ColumnFixing enabled={true} />
                <FilterRow visible={true} />
                <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                <Grouping autoExpandAll={false} />
                <FilterRow visible={true} />

               
              <Column dataField="id" caption="Nº Cuenta" />
              <Column dataField="clientId" dataType="string" caption="Cliente">
                  <Lookup dataSource={CustomerList} displayExpr="name" valueExpr="id" />
                </Column>
                <Column dataField="date" caption="Fecha" dataType="string" />
                <Column dataField="dueDate" caption="Fecha Vencimiento" dataType="string" />
                <Column dataField="total" caption="Valor" dataType="string" />
                <Column alignment="center" caption="Novedades" visible={localStorage.role == 1}
                    type="buttons" width={50}
                    buttons={[{
                      type: "print",
                      hint: 'Imprimir Cuenta',
                      icon: 'warning',
                      onClick: (row: any) => { handleSelectedAccount(row.row.data) }
                    }]} />
                <Paging defaultPageSize={20} />
              </DataGrid>
            </Col>
          </Row>
        </>
      }
    </div>
  );
};

export default Account;
