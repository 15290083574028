import moment from "moment";
import { useState } from "react";

// Api
import billsAPI from "../Api/api-bills";


// Models
import { BillGet, getBillsRequest } from "../Models/Bill";


export const usePrintLabels = () => {

    const [arrayLabels, setArrayLabels] = useState<BillGet[]>([]);
    const [loadingPrint, setLoadingPrint] = useState<boolean>(false);

    const [modalGuiasVisible, setModalGuiasVisible] = useState(false);
    const printLabels = () => {
        setModalGuiasVisible(true);
        // setLoadingPrint(true);
        // let arrayPrint: any = [];
        // let datos: any = {};
        // datos.report = "RotuloPaloma";
        // arrayLabels.map(x => {
        //     debugger
        //     let dir = x.address.split("-");
        //     let el = {
        //         "Guia": x.guia ? x.guia : "-",
        //         "Fecha": x.created_at ? moment(x.created_at).format("DD-MM-YYYY HH:mm") : "-",
        //         "DatosContacto": "Contacto (+ 57) 3007332187 contacto@palomamesajeria.com Bogotá",
        //         "Logo":  "LogoPaloma.png",
        //         "Cliente": x.customer ? x.customer : "-",
        //         "TipoServicio": x.customer ? x.customer : "-",
        //         "Empresa": x.customer ? x.customer : "-",
        //         "Contacto": x.name ? x.name : "-",
        //         "ValorCobrar": `$ ${x.collect_Value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        //         "Departamento": x.province ? x.province : "-",
        //         "Ciudad": x.city ? x.city : "-",
        //         "ObservacionesServicio": x.observations ? x.observations : '-',
        //         "Direccion": x.address ? `${x.phone ? x.phone : "-"} - ${x.address}` : "-",
        //         "ObservacionesEntrega":x.products ? x.products : "-",
        //         "Nombre": "",
        //         "Firma":"",
        //         "Cedula":"",
        //         "FechaEntrega":""
        //     }
        //     arrayPrint.push(el);
        // });
        // datos.data = arrayPrint
        // billsAPI.PrintLabels(datos).then((res: any) => {
        //     const pdfBlob = new Blob([res], { type: "application/pdf" })
        //     const blobUrl = window.URL.createObjectURL(pdfBlob);
        //     window.open(blobUrl);
        //     setLoadingPrint(false);
        // });
    }

    return {
        printLabels,
        arrayLabels,
        setArrayLabels,
        loadingPrint,
        modalGuiasVisible,
        setModalGuiasVisible
    }

}

