import * as React from 'react';
import { Link } from 'react-router-dom'; 
import  './DepartamentComponent.css';

// React Bootstrap
import { Col, Container, Row } from 'react-bootstrap';

// Icons Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faBuilding, faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';

// Components
import FooterComponent from '../FooterComponent/FooterComponent';
import HeaderComponent from '../HeaderComponent/HeaderComponent';

// React Props
import { IDepartamentComponentProps } from './IDepartamentComponentProps';

const DepartamentComponent: React.SFC<IDepartamentComponentProps> = (props) => {
  return (
     <React.Fragment>
  {/*  <HeaderComponent />

      <Link to="/HomeComponent" className="btn btn-outline-danger m-5">
        Regresar
        <FontAwesomeIcon className="ml-2" icon={faLongArrowAltLeft} />
      </Link>

      <Container>

        <Row>
          <Col lg={4}>
            <Link to="/historyServices?IdWarehouse=1" className="btn btn-secondary btn-lg btn-block">
              Antioquia
              <FontAwesomeIcon className="ml-2" icon={faBuilding} />
            </Link>
          </Col>
          <Col lg={4}>
            <Link to="/historyServices?IdWarehouse=2" className="btn btn-secondary btn-lg btn-block">
              Cundinamarca
              <FontAwesomeIcon className="ml-2" icon={faBuilding} />
            </Link>
          </Col>
          <Col lg={4}>
            <Link to="/historyServices?IdWarehouse=3" className="btn btn-secondary btn-lg btn-block">
              Valle
              <FontAwesomeIcon className="ml-2" icon={faBuilding} />
            </Link>
          </Col>
        </Row>
      </Container>
       <FooterComponent />  */}
    </React.Fragment> 
  );
};

export default DepartamentComponent;
