import { message } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react'
import billsAPI from '../../../Api/api-bills';
import filterContext from '../../../Hooks/filterContex';
import { BillStatus } from '../../../Models/Bill';

export const useAdminRouting = () => {

    const filters = useContext(filterContext);

    const [servicesRouting, setServicesRouting] = useState<BillStatus[]>([]);
    const [loadingRouting, setLoadingRouting] = useState(false);
    const [loadingBillProducts, setLoadingBillProducts] = useState(false);
    
    const getRoutingServices = async(messager : string) => {
            var from: any = moment(new Date()).add(-60, "d");;
            var to: any = moment(new Date()).add(1, "d");;
            let payload = {
                warehouseId: filters.warehouseId,
                from: from,
                to: to,
                status: "Enrutado"
            }
            setLoadingRouting(true);
            try {
                const services = await billsAPI.GetBillByDepartmentandStatus(payload);
                const servicesMessager = services.filter( s => s.zone == messager);
                const servicesSort = servicesMessager.sort((a : any, b : any ) => a.delivery_Order  - b.delivery_Order);
                debugger
                setServicesRouting(servicesSort);
            } catch (error) {
                message.error(`No se obtuvieron los servicios del mensajero. ${error}`);
            }
            setLoadingRouting(false);
    }


    const selectRef = useRef<any>();
    const [messagerSelected, setMessagerSelected] = useState("");
    const handleMessager = (value : string) => {
        setMessagerSelected(value);
    }

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const selectedRows = ({ selectedRowKeys }: any) => {
        setSelectedRowKeys(selectedRowKeys);
    }


    const handleUpdate = async ({ data }: any) => {
        setLoadingRouting(true);
        let payload = {
            id: data.id,
            // guia: data.guia
        }
        try {
            // debugger
            let bill = await billsAPI.getBillsById(payload);
            console.log(bill)
            let billNews = bill.billNews.filter(b => b.newsBillingType === 'Novedad Paloma');
            let lastBillNews = billNews.pop();
            let compare = lastBillNews?.description === data.aditionalInformation;
            if (data.aditionalInformation !== null) {
                if(!compare) {
                    let registerNews = {
                        id: 0,
                        billId: data.id,
                        newsBillingType: 'Novedad Paloma',
                        description: data.aditionalInformation,
                        solution: '',
                        userID: Number(localStorage.userID),
                        changeStatus: false,
                    };
                    
                    if (data.statusId == 1 || data.statusId == 9) {
                        data.zone = null;
                    }
                    
                    await billsAPI.createBillsNews(registerNews);
                    await billsAPI.UpdateBillHeader(data);

                    message.success('Se creo correctamente la novedad y se actualizó el servicio.');
                } else {
                    if (data.statusId == 1 || data.statusId == 9) {
                        data.zone = null;
                    }
                    await billsAPI.UpdateBillHeader(data);
                    message.success(`Se actualizó correctamente el servicio`);
                }
                } else {
                if (data.statusId == 1 || data.statusId == 9) {
                    data.zone = null;
                }
                await billsAPI.UpdateBillHeader(data);
                message.success('Se actualizó correctamente el servicio.');
            }
            
        } catch (error) {
            message.error(`No se pudo actualizar el servicio. ${error}`);
        }
        setLoadingRouting(false);
        getRoutingServices(messagerSelected);
    }

    const dispatchBills = async () => {
        setLoadingRouting(true);
        try {
            selectedRowKeys.map((x: any,i) => {
                let payload = {
                    billId: x.id,
                    guia: x.guia,
                    company_TransportId: x.company_TransportId,
                    packingQuantity: x.packingQuantity,
                    packing: x.packing === null ? "" : x.packing,
                    userID: x.userId
                }
                billsAPI.DispatchBills(payload);
                if(selectedRowKeys.length == i+1) {
                    message.success('Se han despachado las guias correctamente');
                }
            });
            
        } catch (error) {
            message.error(`No se pudieron despachar las guias. ${error}`);
        }
        setLoadingRouting(false);
        getRoutingServices(messagerSelected);
    }

    const [billData, setBillData] = useState<any>([]);
    const getBillData = () => {
        const array: any = []
        setLoadingBillProducts(true);
        try {
            selectedRowKeys.map((x: any) => {
                array.push(x.id)
                billsAPI.GetBillProductsById(array).then((res: any) => {
                    setBillData(res);
                })
            }) 
        } catch (error) {
            message.error(`Error al traer los datos.${error}`);
        }
        setLoadingBillProducts(false);
    }

    useEffect(() => {
        getBillData()
    }, [selectedRowKeys])
    
    
    useEffect(() => {
        let mounted = true;
        if (mounted) {
            getRoutingServices(messagerSelected)
        }
        return () => {
            mounted = false;
        }
    }, [messagerSelected])

    return {
        servicesRouting,
        loadingRouting, 
        filters,
        handleMessager,
        selectRef,
        messagerSelected,
        getRoutingServices,
        selectedRowKeys,
        selectedRows,
        handleUpdate,
        dispatchBills,
        billData,
        getBillData,
        loadingBillProducts
    }
}
