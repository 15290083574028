import React, {useEffect} from 'react';
import { IHeaderComponentProps } from './IHeaderComponentProps';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './Header.css';

// React-Bootstrap
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import utils from '../../utils';
import { faUser } from '@fortawesome/free-solid-svg-icons';



const logoEmpresa = require('../../Assets/Logo.png');



const HeaderComponent: React.SFC<IHeaderComponentProps> = (props) => {

  useEffect(() => {
    sessionValidate();
  }, []);

  const sessionValidate = () => {
    let isLogedIn = utils.comprobarCookie('token');
    if (!isLogedIn) {
      localStorage.token = "";
      window.location.href = "/LoginComponent";
    }
  }

  const logOut = () => {
    utils.delete_cookie("token");
  }

  

  return (

    <React.Fragment>
      <Navbar className="navbar" expand="lg">
        <Navbar.Brand href="#home">
          <img src={logoEmpresa.default} alt='LogoEmpresa' width='100' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <FontAwesomeIcon icon={faUser} className="userIcon" />
            
            <NavDropdown title={localStorage.userName}  id="basic-nav-dropdown">
              <NavDropdown.Item onClick={()=>logOut()}>
                Cerrar Sesión
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          {/* <Nav className="ml-auto" >
              <FontAwesomeIcon className="userIcon" icon={faUser} /><NavDropdown title={localStorage.userName} id="basic-nav-dropdown">
              <NavDropdown.Item className="mr-5" onClick={()=>logOut()}>
                Cerrar Sesión 
              </NavDropdown.Item>
            </NavDropdown>
          </Nav> */}
        </Navbar.Collapse>
      </Navbar>
    </React.Fragment>

  );
};

export default HeaderComponent;







