import { message } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import customerAPI from '../Api/api-customers';
import filterContext from './filterContex';

export const useMessager = () => {

    const filters = useContext(filterContext);

    const [messagers, setMessagers] = useState<any>([]);
    const getMessagers = async () => {
        try {
            const messagers = await customerAPI.getMessengers();
            setMessagers(messagers)
        } catch (error) {
            message.error(`No se obtuvieron los mensajeros. ${error}`);
        }
    }

    const [messagersByWarehouse, setMessagersByWarehouse] = useState<any>([]);
    const getMessagersByWarehouse = async( warehouseId : number) => {
        try {
            const messagers : any = await customerAPI.getMessengers();
            const messagersFilter = messagers.filter((m : any) => m.warehouseId == warehouseId)
            setMessagersByWarehouse(messagersFilter)
        } catch (error) {
            message.error(`No se obtuvieron los mensajeros. ${error}`);
        }
    }

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            getMessagers();
        }
        return () => {
            mounted = false;
        }
    }, [])

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            getMessagersByWarehouse(filters.warehouseId);
        }
        return () => {
            mounted = false;
        }
    }, [filters.warehouseId])

    return {
        messagers,
        messagersByWarehouse
    }
}
