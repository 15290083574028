import React, { useState, useEffect } from 'react';
import { IAdminCenterProps } from './IAdminCenterProps';
import './AdminCenter.css';
import AdminHeader from '../AdminHeader/AdminHeader';
import AdminFooter from '../AdminFooter/AdminFooter';
import DrawerMensajerosDisponibles from '../DrawerMensajerosDisponibles/DrawerMensajerosDisponibles';
import { Button, message } from 'antd';
import utils from '../../../utils';
// import servicesAPI from '../../../api/api-service';
// import { service } from '../../../models/service';
import GeoCoder from '../GeoCoder';
import Mapa from '../Mapa/Mapa';
import billsAPI from '../../../Api/api-bills';
import moment from 'moment';
import { BillGet, getBillsStatus } from '../../../Models/Bill';
import usersAPI from '../../../Api/api-users';
import customerAPI from '../../../Api/api-customers';
// import Usuarios from '../../Usuarios/Usuarios';

const AdminCenter: React.SFC<IAdminCenterProps> = (props) => {

  const [drawerMensajerosVisible, setDrawerMensajerosVisible] = useState(false);
  const [mensajeros, setMensajeros]: any = useState([]);
  const [mensajerosFiltrados, setMensajerosFiltrados] = useState([]);
  const [bodegaSeleccionada, setBodegaSeleccionada] = useState(2);
  const [arrayCoordinates, setArrayCoordinates] = useState([]);
  const [gettingCoordinates, setGettingCoordinates] = useState(false);
  const [listadoServicios, setListadoServicios] = useState<any>([]);
  // const [serviciosProgramados, setServiciosProgramados] = useState<any>([]);
  // const [serviciosEnrutados, setServiciosEnrutados] = useState<any>([]);
  const [numServiciosProgramados, setNumServiciosProgramados] = useState(0);
  const [numServiciosEnrutados, setNumServiciosEnrutados] = useState(0);
  const [estadoSeleccionado, setEstadoSeleccionado] = useState("Programado");
  const [mensajeroSeleccionado, setMensajeroSeleccionado] = useState("Todos");
  const [servicioBuscar, setServicioBuscar] = useState<string>("");
  const [zonaSeleccionada, setzonaSeleccionada] = useState("Todas");
  const [centerMap, setCenterMap] = useState();
  const [centrarMapa, setCentrarMapa] = useState(true);

  const key = 'updatable';

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      obtenerUsuarios();
      obtenerServicios(estadoSeleccionado);
      calcularNumServicios();
      // obtenerServiciosProgramados();
      // obtenerServiciosEnrutados();
    }
    return () => {
      mounted = false;
    }
  }, [estadoSeleccionado, bodegaSeleccionada, mensajeroSeleccionado, servicioBuscar])

  // useEffect(() => {
  //   if (arrayCoordinates.length === 0) {
  //     // obtenerUsuarios();
  //     // obtenerServiciosProgramados();
  //     // obtenerServiciosEnrutados();
  //     obtenerServicios(estadoSeleccionado);
  //     calcularNumServicios();
  //     // obtenerServiciosProgramados();
  //   }
  //   else {
  //     filtrarDatosMapa();
  //   }
  // }, [arrayCoordinates, bodegaSeleccionada, mensajeroSeleccionado, estadoSeleccionado, servicioBuscar]);

  const searchGuia = (value: any) => {
    if (value !== '') {
      let serviciosFiltrados = listadoServicios.filter((x: any) => x.guia == value);
      setListadoServicios(serviciosFiltrados)
    }
  }

  const obtenerServicios = async (estado: any) => {
    message.loading({ content: `Calculando servicios ${estado}`, key });
    var from: any = moment(new Date()).add(-60, "d");;
    var to: any = moment(new Date()).add(1, "d");;
    let payload: getBillsStatus = {
      warehouseId: bodegaSeleccionada,
      status: estado,
      from,
      to
    }
    // debugger
    // let servicios = [...serviciosProgramados, ...serviciosEnrutados];
    switch (estado) {
      case 'Programado':
        try {
          const serviciosProgramados = await billsAPI.GetBillByDepartmentandStatus(payload);
          // if(mensajeroSeleccionado !== 'Todos'){
          //   let serviciosMensajeros = serviciosProgramados.filter((s: any) => s.zone == mensajeroSeleccionado)
          //   setListadoServicios(serviciosMensajeros);
          // }
          console.log(serviciosProgramados)
          setListadoServicios(serviciosProgramados);
          message.success({ content: `Servicios ${estado} calulados!`, key, duration: 3 });
        } catch (error) {
          message.error(`No se obtuvieron los servicios ${estado}. ${error}`);
        }
        setGettingCoordinates(true);
        break;
      case 'Enrutado':
       try {
        const serviciosEnrutados = await billsAPI.GetBillByDepartmentandStatus(payload);
        if(mensajeroSeleccionado !== 'Todos'){
          let serviciosMensajeros = serviciosEnrutados.filter((s: any) => s.zone == mensajeroSeleccionado);
          console.log(serviciosMensajeros)
          setListadoServicios(serviciosMensajeros);
          return;
        }
        console.log(serviciosEnrutados)
        setListadoServicios(serviciosEnrutados);
       } catch (error) {
         message.error(`No se obtuvieron los servicios ${estado}. ${error}`)
       }
        break;
    }
    setGettingCoordinates(true);

    // setListadoServicios(servicios);
  }



  const quitarFiltro = () => {
    obtenerServicios(estadoSeleccionado);
  }

  const obtenerUsuarios = async () => {
    try {
      const messagers: any = await customerAPI.getMessengers();
      setMensajeros(messagers);
      setMensajerosFiltrados(messagers.filter((x: any) => x.warehouseId === bodegaSeleccionada))
    } catch (error) {
      message.error(`No se obtuvieron los mensajeros. ${error}`);
    }
  };

  // const obtenerServiciosProgramados = async() => {
  //   try {
  //     var from: any = moment(new Date()).add(-60, "d");;
  //     var to: any = moment(new Date()).add(1, "d");;
  //     let payload: getBillsStatus = {
  //       warehouseId: bodegaSeleccionada,
  //       status: 'Programado',
  //       from,
  //       to
  //     }
  //     const services = await billsAPI.GetBillByDepartmentandStatus(payload);
  //     setServiciosProgramados(services);
  //     // obtenerServiciosEnrutados(services)
  //   } catch (error) {
  //     message.error(`No se pudo traer los servicios programados. ${error}`)
  //   }
  //   // servicesAPI.getServicesByState("Programado").then(json => {
  //   //   obtenerServiciosEnrutados(json.filter((x: any) => x.novedades_entrega.substring(0,2) != "No").reverse()); 
  //   // });
  // }

  const calcularNumServicios = async () => {
    try {
      let numProgramados = 0;
      let numEnrutados = 0;
      var from: any = moment(new Date()).add(-60, "d");;
      var to: any = moment(new Date()).add(1, "d");;
      let payloadProgramado: getBillsStatus = {
        warehouseId: bodegaSeleccionada,
        status: "Programado",
        from,
        to
      }
      let payloadEnrutado: getBillsStatus = {
        warehouseId: bodegaSeleccionada,
        status: "Enrutado",
        from,
        to
      }
      const programados = await billsAPI.GetBillByDepartmentandStatus(payloadProgramado);
      const enrutados = await billsAPI.GetBillByDepartmentandStatus(payloadEnrutado);
      numProgramados = programados.filter((x: any) => x.warehouseId === bodegaSeleccionada && x.status === 'Programado').length;
      numEnrutados = enrutados.filter((x: any) => x.warehouseId === bodegaSeleccionada && x.status === 'Enrutado').length;
      setNumServiciosProgramados(numProgramados);
      setNumServiciosEnrutados(numEnrutados);
    } catch (error) {
      message.error(`No se pudieron calcular los servicios. ${error}`);
    }
    // let numProgramados = 0;
    // let numEnrutados = 0;
    // numProgramados = serviciosProgramados.filter((x : any )=>x.warehouseId === bodegaSeleccionada && x.status === 'Programado').length;
    // numEnrutados = serviciosEnrutados.filter((x : any )=>x.warehouseId === bodegaSeleccionada && x.status === 'Enrutado').length;
    // setNumServiciosProgramados(numProgramados);
    // setNumServiciosEnrutados(numEnrutados);
  }

  const filtrarDatosMapa = () => {
    debugger
    setListadoServicios([]);
    let arrayTemp = arrayCoordinates.filter((x: any) => x.province === bodegaSeleccionada && x.status === estadoSeleccionado);
    if (mensajeroSeleccionado !== "Todos") {
      arrayTemp = arrayTemp.filter((x: any) => x.zona === mensajeroSeleccionado);
    }
    if (servicioBuscar !== "") {
      arrayTemp = arrayCoordinates.filter((x: any) => x.guia === servicioBuscar);
    }
    setListadoServicios(arrayTemp);
  }

  return (
    <div className="adminCenter">
      <AdminHeader
        setDrawerMensajerosVisible={setDrawerMensajerosVisible}
        numServiciosProgramados={numServiciosProgramados}
        numServiciosEnrutados={numServiciosEnrutados}
        setEstadoSeleccionado={setEstadoSeleccionado}
      />
      <div className="contenedorMapa">
        <Mapa
          listadoServicios={listadoServicios}
          setListadoServicios={setListadoServicios}
          bodegaSeleccionada={bodegaSeleccionada}
          mensajerosFiltrados={mensajerosFiltrados}
          mensajeroSeleccionado={mensajeroSeleccionado}
          setMensajeroSeleccionado={setMensajeroSeleccionado}
          centerMap={centerMap}
          setCentrarMapa={setCentrarMapa}
          centrarMapa={centrarMapa}
          setCenterMap={setCenterMap}
          zonaSeleccionada={zonaSeleccionada}
          users={mensajerosFiltrados}
          obtenerServicios={obtenerServicios}
          estadoSeleccionado={estadoSeleccionado}

        />
      </div>
      <AdminFooter
        bodegaSeleccionada={bodegaSeleccionada}
        setBodegaSeleccionada={setBodegaSeleccionada}
        mensajeros={mensajeros}
        setMensajeros={setMensajeros}
        setMensajerosFiltrados={setMensajerosFiltrados}
        mensajerosFiltrados={mensajerosFiltrados}
        setMensajeroSeleccionado={setMensajeroSeleccionado}
        estadoSeleccionado={estadoSeleccionado}
        setServicioBuscar={setServicioBuscar}
        setzonaSeleccionada={setzonaSeleccionada}
        zonaSeleccionada={zonaSeleccionada}
        setCenterMap={setCenterMap}
        setCentrarMapa={setCentrarMapa}
        centrarMapa={centrarMapa}
        searchGuia={searchGuia}
        quitarFiltro={quitarFiltro}
      />
      <DrawerMensajerosDisponibles
        drawerMensajerosVisible={drawerMensajerosVisible}
        setDrawerMensajerosVisible={setDrawerMensajerosVisible}
        mensajeros={mensajerosFiltrados}
        bodegaSeleccionada={bodegaSeleccionada}
        listadoServicios={listadoServicios}
        numServiciosProgramados={numServiciosProgramados}
      />
      {listadoServicios.length > 0 && gettingCoordinates ?
        <GeoCoder
          listadoServicios={listadoServicios}
          setGettingCoordinates={setGettingCoordinates}
          setArrayCoordinates={setArrayCoordinates}
        />
        : null}
    </div>
  );
};

export default AdminCenter;
