import React, { useState } from 'react';

// Ant Desing
import { Alert, Badge, Button, Col, message, Row, Spin } from 'antd';
import { EditOutlined, SearchOutlined, UserOutlined, EnvironmentOutlined, PrinterOutlined } from '@ant-design/icons';

// DevExtreme
import DataGrid, {
    Column,
    FilterRow,
    Grouping,
    Selection,
    GroupPanel,
    Pager,
    Paging,
    SearchPanel,
    Export,
    HeaderFilter,
    Sorting,
    ColumnFixing,
    Editing,
    Lookup
} from 'devextreme-react/data-grid';

// Custom Hooks
import { useSheduledServices } from './Hooks/useSheduledServices';

// Components
import { ModalGuia } from './ModalGuia';
import billsAPI from '../../Api/api-bills';
import { DrawerNews } from './DrawerNews';
import { DrawerEdit } from './DrawerEdit';
import { DrawerRouting } from './DrawerRouting';
import { ModalGuias } from './ModalGuias';


export const SheduledInvoice = () => {

    const {
        sheduledInvoice,
        customers,
        messagers,
        selectedRows,
        handleUpdate,
        statuses,
        getSheduledInvoice,
        selectedRowKeys,
        setSelectedRowKeys,
        getDeferredInovice,
        billLoading,
        setBillLoading
    } = useSheduledServices();


    // Modal Guia
    const [modalGuiaVisible, setModalGuiaVisible] = useState(false);
    const [guiaSelected, setGuiaSelected] = useState({});
    const [billNewsGuia, setBillNewsGuia] = useState<any>([]);

    const showModalGuia = async (data: any) => {
        try {
            let payload = {
                id: data.id,
                guia: data.guia
            }
            let bill = await billsAPI.getBillsById(payload);
            let billNews = bill.billNews.filter((bill: any) => bill.newsBillingType === 'Novedad Cliente');
            setGuiaSelected(data);
            setBillNewsGuia(billNews);
            setModalGuiaVisible(true);
        } catch (error) {
            message.error(`No se pudo cargar las novedades. ${error}`);
        }
    }

    // Drawer News
    const [visibleDrawerNews, setVisibleDrawerNews] = useState(false);
    const [billNews, setBillNews] = useState<any>([]);
    const [billData, setBillData] = useState<any>([])
    const showDrawerNews = async (data: any) => {
        try {
            let payload = {
                id: data.id,
                guia: data.guia
            }
            let billNews = (await billsAPI.getBillsById(payload)).billNews;
            let billData = await billsAPI.getBillsById(payload);
            if (billNews.length !== 0) {
                setBillNews(billNews);
                setBillData(billData)
                setVisibleDrawerNews(true);
                // setClientBillNews(clientBillNews)
            } else {
                message.warn(`No hay novedades para esta guia.`)
            }
        } catch (error) {
            message.error(`No se pudo cargar las novedades. ${error}`);
        }

    }

    // Drawer Edit
    const [drawerEditVisible, setDrawerEditVisible] = useState(false);
    const [billSelected, setBillSelected] = useState({});
    const showModalEdit = (data: any) => {
        data.confirmed = data.confirmed === true ? 1 : data.confirmed === false ? 0 : data.confirmed;
        setBillSelected(data);
        setDrawerEditVisible(true);
    }

    // Drawer Routing
    const [drawerRoutingVisible, setDrawerRoutingVisible] = useState(false);
    const routingService = () => {
        if (selectedRowKeys.length !== 0) {
            setDrawerRoutingVisible(true);
        } else {
            message.warn('Seleccione servicios para enrutar');
        }
    }

    // Modal Guias
    const [modalGuiasVisible, setModalGuiasVisible] = useState(false);
    const [guiasSelected, setGuiasSelected] = useState([]);

    const showModalGuias = () => {
        setModalGuiasVisible(true);
    }

    // Cellrender Devextreme
    const modalCell = ({ data }: any) => {
        return <EditOutlined onClick={(e) => showModalEdit(data)} />
    }

    const detailCell = ({ data }: any) => {
        return (
            <ul>
                <li><b>Nombre: </b>{`${data.name}`}</li>
                <li><b>Cel: </b>{`${data.phone}`}</li>
                <li><b>Dir: </b>{`${data.address}`}</li>
                <li><b>Ciu: </b>{`${data.city}`}</li>
            </ul>
        )
    }

    const guiaCell = ({ data }: any) => {
        if (data.totalPendingBillNews > 0) {
            return (
                <Badge color="red" text={<a onClick={() => showModalGuia(data)}>{data.guia}</a>}/>
            )
          } else {
              return (
                  <a onClick={() => showModalGuia(data)}>{data.guia}</a>
              )
          }
    }

    const newsCell = ({ data }: any) => {
        return (
            <SearchOutlined onClick={() => showDrawerNews(data)} />
        )
    }

    return (
        <>
            <Row gutter={[16, 8]} className="mb-2">
                <Col span={8}>
                    <Button
                        type="primary"
                        title="Enrutar Servicios Seleccionados"
                        icon={<UserOutlined />}
                        onClick={routingService}
                    >
                        Enrutar Servicios Seleccionados
                    </Button>
                </Col>
                <Col span={8}>
                    <Button

                        type="primary"
                        title="Ver Mapa"
                        icon={<EnvironmentOutlined />}
                        onClick={()=>window.location.href='/AdminCenter'}
                    >
                        Ver Mapa
                    </Button>
                </Col>
                <Col span={8}>
                    <Button
                        type="primary"
                        title="Imprimir"
                        icon={<PrinterOutlined />}
                        onClick={showModalGuias}
                    >
                        Imprimir
                    </Button>
                </Col>
            </Row>
            {sheduledInvoice.length !== 0
                ?
                (
                    <>
                        <Spin tip="Cargando..." spinning={billLoading}>
                            <div id="data-grid-demo">
                                <DataGrid
                                    id="gridContainer"
                                    dataSource={sheduledInvoice}
                                    onRowUpdated={handleUpdate}
                                    onSelectionChanged={selectedRows}
                                    // wordWrapEnabled={true}
                                    allowColumnReordering={true}
                                    allowColumnResizing={true}
                                    columnAutoWidth={true}
                                    showBorders={true}
                                    rowAlternationEnabled={true}
                                >
                                    <SearchPanel visible={true} highlightCaseSensitive={true} />
                                    <Grouping autoExpandAll={true} />
                                    <FilterRow visible={true} />
                                    <GroupPanel visible={true} />
                                    <Grouping autoExpandAll={true} />
                                    <Export enabled={true} allowExportSelectedData={false} />
                                    <HeaderFilter visible={true} />
                                    <Sorting mode="multiple" />
                                    <ColumnFixing enabled={true} />
                                    <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                                    <Grouping autoExpandAll={false} />

                                    <Selection
                                        mode="multiple"
                                        selectAllMode={'allPages'}
                                        showCheckBoxesMode={'onClick'}
                                    />
                                    <Column caption="Guias" allowEditing={false} cellRender={guiaCell} />
                                    <Column dataField="service_Description" dataType="string" caption="Descripción" allowEditing={false} />
                                    <Column dataField="customerId" dataType="number" caption="Cliente" allowEditing={false}>
                                        <Lookup dataSource={customers} displayExpr="name" valueExpr="id" />
                                    </Column>
                                    <Column cellRender={detailCell} caption="Detalle" />
                                    <Column dataField="aditionalInformation" dataType="string" caption="Información Adicional" />
                                    <Column dataField="service_Value" caption="Valor Servicio" dataType="number" format="currency" />
                                    <Column dataField="zone" dataType="string" caption="Ruta / Mensajero" >
                                        <Lookup dataSource={messagers} displayExpr="name" valueExpr="name" />
                                    </Column>
                                    <Column cellRender={newsCell} caption="Novedades" alignment="center" />
                                    <Column cellRender={modalCell} caption="Editar" alignment="center" />

                                    <Pager allowedPageSizes={[5, 10, 50, 100,200]} showPageSizeSelector={true} />
                                    <Paging defaultPageSize={200} />
                                    <Editing
                                        mode="cell"
                                        allowUpdating={true}
                                    />
                                </DataGrid>
                            </div>
                        </Spin>

                        <DrawerNews
                            visibleDrawerNews={visibleDrawerNews}
                            setVisibleDrawerNews={setVisibleDrawerNews}
                            billNews={billNews}
                            billData={billData}
                        />

                        <ModalGuia
                            guiaSelected={guiaSelected}
                            modalGuiaVisible={modalGuiaVisible}
                            setModalGuiaVisible={setModalGuiaVisible}
                            billNewsGuia={billNewsGuia}
                        />
                        <DrawerEdit
                            billSelected={billSelected}
                            drawerEditVisible={drawerEditVisible}
                            setDrawerEditVisible={setDrawerEditVisible}
                            messagers={messagers}
                            statuses={statuses}
                            setBillSelected={setBillSelected}
                            getSheduledInvoice={getSheduledInvoice}
                            getDeferredInovice={getDeferredInovice}
                            billLoading={billLoading}
                            setBillLoading={setBillLoading}
                        />

                        <DrawerRouting
                            drawerRoutingVisible={drawerRoutingVisible}
                            setDrawerRoutingVisible={setDrawerRoutingVisible}
                            selectedRowKeys={selectedRowKeys}
                            messagers={messagers}
                            setSelectedRowKeys={setSelectedRowKeys}
                            getSheduledInvoice={getSheduledInvoice}
                        />

                        <ModalGuias
                            modalGuiasVisible={modalGuiasVisible}
                            setModalGuiasVisible={setModalGuiasVisible}
                            selectedRowsKeys={selectedRowKeys}
                        />
                    </>
                )
                :
                (
                    <Alert
                        message="No hay servicios programados"
                        description="Seleccione una bodega"
                        type="warning"
                        showIcon
                    />
                )
            }
        </>
    )
}
