import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Alert, Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import useLoginForm from '../../Hooks/useLoginForm';


import ModalComponent from '../ModalComponent/ModalComponent';

import { ILoginComponentProps } from './ILoginComponentProps';
import './LoginComponent.css';
import { RecoverModal } from './RecoverModal';

const img = require('../../Assets/Login.jpg');
const logo = require('../../Assets/Logo.png');

const LoginComponent: React.SFC<ILoginComponentProps> = (props) => {

  // Custom Hook useLoginForm
  const { handleChange, handleSubmit, values, validated, credentials, loading } = useLoginForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleModal = () => {
    setIsModalVisible(true);
  }


  return (
    <>
      <Container style={{ marginTop: '100px' }}>
        <Row className="row-content" noGutters>
          <Col lg={5}>
            <img src={img.default} alt="" className="img-fluid" />
          </Col>
          <Col lg={7} className="px-5 pt-5">
            <img src={logo.default} className="py-3" alt="" width="200" />
            <h4>Iniciar sesión en su cuenta</h4>
            <Form noValidate validated={validated} onSubmit={handleSubmit} >
              <Form.Row>
                <Form.Group as={Col} md="12" controlId="validationEmail">
                  <Form.Label className="mt-3">Correo electrónico</Form.Label>
                  <Form.Control
                    className="my-3 p-4"
                    required
                    type="email"
                    placeholder="example@example.com"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    autoComplete="off"
                  />
                  <Form.Control.Feedback type="invalid">
                    Correo no válido
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="12" controlId="validationPassword">
                  <Form.Label className="mt-3">Contraseña</Form.Label>
                  <Form.Control
                    className="my-3 p-4"
                    required
                    type="password"
                    name="password"
                    onChange={handleChange}
                    value={values.password}
                    autoComplete="off"
                  />
                  <Form.Control.Feedback type="invalid">
                    Contraseña no válida
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Row className="mb-3">
                <Button
                  variant="secondary"
                  block
                  type="submit"
                  disabled={loading}
                >
                  Iniciar Sesión
                  {loading &&
                    <Spinner
                      className="ml-3"
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />

                  }
                </Button>
              </Form.Row>
              <div className="recover-password" onClick={handleModal}>¿Olvidaste la contraseña?</div>

              {isModalVisible &&
                <RecoverModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
              }

              {/* <ModalComponent /> */}

              {!credentials &&
                <Alert className="mt-5" variant='danger'>
                  El usuario o contraseña son incorrectos.
                </Alert>
              }
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LoginComponent;
