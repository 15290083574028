import React, { useState } from 'react';
import { Drawer, Form, Row, Col, Select, InputNumber, Button, Divider, Input, message } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { BillStatus } from '../../Models/Bill';
import { Statuses } from '../../Models/Status';
import billsAPI from '../../Api/api-bills';


type DrawerEditProps = {
    drawerEditVisible: boolean,
    setDrawerEditVisible: Function,
    setBillSelected: Function,
    billSelected: BillStatus,
    messagers: any,
    statuses: Statuses[],
    statusSelected: string,
    getServices: Function
}

// Ant Controls
const { TextArea } = Input;
const { Option } = Select;

export const DrawerEdit = ({ drawerEditVisible, setDrawerEditVisible, billSelected, messagers, statuses, setBillSelected, statusSelected, getServices }: DrawerEditProps) => {

    const onClose = () => {
        setDrawerEditVisible(false);
    }

    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const handleUpdate = async() => {
        setLoadingUpdate(true);
        if(billSelected.statusId == 8 && billSelected.zone == null) {
            message.warn(`No se puede enrutar si no se selecciona un mensajero!`);
            setLoadingUpdate(false);
            return;
        }
        if(billSelected.confirmed == 1) {
            billSelected.confirmed = true;
        } else {
            billSelected.confirmed = false;
        }

        if (billSelected.statusId == 1 || billSelected.statusId == 9) {
            if(billSelected.zone !== null){
                billSelected.zone = null
            }
        }

        try {
            await billsAPI.UpdateBillHeader(billSelected);
            message.success(`Se actualizó correctamente el servicio.`);
            getServices(statusSelected);
        } catch (error) {
            message.error(`No se pudo actualizar el servicio. ${error}`);
        }
        setLoadingUpdate(false);
        setDrawerEditVisible(false);
    }

    const handleBillChange = (value: any, type: string) => {
        setBillSelected({
            ...billSelected,
            [type]: value
        })
    }


    const [news, setNews] = useState<News>({
        id: 0,
        billId: billSelected.id,
        newsBillingType: '',
        description: '',
        solution: '',
        changeStatus: false,
        userID: Number(localStorage.userID)
    });

    const [loadingNews, setLoadingNews] = useState(false);
    const handleSubmitNews = async() => {
        debugger
        setLoadingNews(true);
        try {
            news.billId = billSelected.id;
            news.changeStatus = false;
            await billsAPI.createBillsNews(news);
            message.success(`Se creo correctamente la novedad.`);
            getServices(statusSelected);
            setNews({
                ...news,
                description : ""
            })
        } catch (error) {
            message.error(`No se pudo crear la novedad. ${error}`);
        }
        setDrawerEditVisible(false);
        setLoadingNews(false);
    }

    const handleNewsChange = (value: any, type: string) => {
        if(type == 'description') {
            setNews({
                ...news,
                [value.target.name] : value.target.value
            })
            return;
        }
        setNews({
            ...news,
            [type] : value
        })
    }



    return (
        <Drawer
            title="Editar - Crear Novedad"
            placement="right"
            visible={drawerEditVisible}
            closable={true}
            onClose={onClose}
            width={550}
        >
            <Divider orientation="left">Actualizar Guia</Divider>
            <Form layout="vertical">
                <Row gutter={[8, 16]}>
                    <Col span={24}>
                        <Form.Item label="Mensajero">
                            <Select placeholder="Seleccione Mensajero" value={billSelected.zone !== null ? billSelected.zone : ''} onChange={(value) => handleBillChange(value, 'zone')}>
                                {messagers.map(({ id, name }: any) =>
                                    <Option key={id} value={name}>{name}</Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Valor a cobrar">
                            <InputNumber
                                value={billSelected.collect_Value}
                                onChange={(value) => handleBillChange(value, 'collect_Value')}
                                formatter={(value: any) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                min={0}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Valor adicional">
                            <InputNumber
                                value={billSelected.aditional_Value}
                                onChange={(value) => handleBillChange(value, 'aditional_Value')}
                                formatter={(value: any) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                min={0}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Valor servicio">
                            <InputNumber
                                value={billSelected.service_Value}
                                onChange={(value) => handleBillChange(value, 'service_Value')}
                                formatter={(value: any) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                min={0}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Estado">
                            <Select placeholder="Seleccione un estado" value={billSelected.statusId} onChange={(value) => handleBillChange(value, 'statusId')}>
                                {statuses.map(({ id, name }) =>
                                    <Option key={id} value={id}>{name}</Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Confirmar" >
                            <Select placeholder="Seleccione un estado" value={billSelected.confirmed == true ? 1 : 0} onChange={(value) => handleBillChange(value, 'confirmed')}>
                                <Option value={0}>Sin Confirmar</Option>
                                <Option value={1}>Confirmado</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Button
                            type="primary"
                            loading={loadingUpdate}
                            icon={<SaveOutlined />}
                            block
                            onClick={handleUpdate}
                        >
                            Actualizar
                        </Button>
                    </Col>
                </Row>
            </Form>

            <Divider orientation="left">Novedades</Divider>
            <Form layout="vertical">
                <Row>
                    <Col span={12}>
                        <Form.Item label="Tipo Novedad" >
                            <Select placeholder="Seleccione un tipo" onChange={(value) => handleNewsChange(value, 'newsBillingType')}>
                                <Option value="Novedad Paloma">Novedad Paloma</Option>
                                <Option value="Novedad Cliente">Novedad Cliente</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Novedad">
                            <TextArea rows={4} placeholder="Digite la novedad..." name="description" value={news.description} onChange={(value) => handleNewsChange(value, 'description')} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>

                        <Button
                            type="primary"
                            loading={loadingNews}
                            onClick={handleSubmitNews}
                            icon={<SaveOutlined />}
                            block
                        >
                            Crear Novedad
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )
}


type News = {
    id?: number,
    billId?: number,
    newsBillingType?: string,
    description?: string,
    solution?: string,
    created_at?: string,
    update_at?: string,
    userID?: number,
    changeStatus?: boolean
}