import React from 'react';

// Custom Hooks
import { useCreateUsers } from '../Hooks/useCreateUsers';

// Props
import { IFormCustomerProps } from './IFormCustomerProps';

// Ant Desing
import { Button, Col, Form, Input, InputNumber, Row, Select, Spin } from 'antd';

// Devextreme
import DataGrid, { Column, Editing, Export, FilterRow, Grouping, GroupPanel, Lookup, Pager, Paging, SearchPanel } from 'devextreme-react/data-grid';


// Style
import './FormCustomer.css';


const FormCustomer: React.SFC<IFormCustomerProps> = (props) => {

  // Ant Desing
  const { Option } = Select;

  // Paginas
  const pageSizes = [5, 10, 20];

  const {
    onFinish,
    setButton,
    onFinishFailed,
    provinces,
    customers,
    loading,
    handleUpdate,
    personType,
    inventories,
    formRef
  } = useCreateUsers()

  console.log(customers)

  return (
    <React.Fragment>
      <Form
        // ref={formRef}
        layout="vertical"
        initialValues={{
          antioquiaValueService: 0,
          valleValueService: 0,
          cundinamarcaValueService: 0,
          atlanticoValueService : 0
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row
          gutter={[16, 16]}
          className="rowForm"
        >
          <Col span={12}>
            <Form.Item
              label="Nombre"
              name="name"
              rules={[{ required: true, message: 'Por favor digite su nombre.' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Por favor digite su email.' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Nit"
              name="nit"
              rules={[{ required: true, message: 'Por favor digite su nit.' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Bodega"
              name="provinceId"
              rules={[{ required: true, message: 'Por favor seleccione una provincia.' }]}
            >
              <Select placeholder="Seleccionar">
                {provinces.map(pro => (
                  <Option
                    key={pro.id}
                    value={pro.id}
                  >
                    {pro.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Dirección"
              name="address"
              rules={[{ required: true, message: 'Por favor digite su dirección.' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Celular"
              name="phone"
              rules={[{ required: true, message: 'Por favor digite su celular.' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Tipo de persona"
              name="personType"
              rules={[{ required: true, message: 'Por favor seleccione una tipo de persona.' }]}
            >
              <Select placeholder="Seleccionar...">
                <Option value={"Persona Juridica"}>Persona Juridica</Option>
                <Option value={"Persona Natural"}>Persona Natural</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Inventario"
              name="inventory"
              rules={[{ required: true, message: 'Seleccione una opción.' }]}
            >
              <Select placeholder="Seleccionar...">
                <Option value={1}>Si</Option>
                <Option value={0}>No</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label="Valor de servicio Antioquía"
              name="antioquiaValueService"
              rules={[{ type: 'number', min: 0, message: 'Digite el precio.' }]}
            >
              <InputNumber
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Valor de servicio Valle"
              name="valleValueService"
              rules={[{ type: 'number', min: 0, message: 'Digite el precio.' }]}
            >
              <InputNumber
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Valor de servicio Cundinamarca"
              name="cundinamarcaValueService"
              rules={[{ type: 'number', min: 0, message: 'Digite el precio.' }]}
            >
              <InputNumber
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Valor de servicio Atlantico"
              name="atlanticoValueService"
              rules={[{ type: 'number', min: 0, message: 'Digite el precio.' }]}
            >
              <InputNumber
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              className="btn"
              htmlType="submit"
              block
              onClick={() => setButton('formCustomer')}
            >
              Crear
            </Button>
          </Col>
        </Row>
      </Form>

      <Row>
        <Col>
          <Spin tip="Cargando..." spinning={loading}>
            <DataGrid
              dataSource={customers}
              allowColumnReordering={true}
              showBorders={true}
              rowAlternationEnabled={true}
              onRowUpdated={handleUpdate}
            >

              <GroupPanel visible={true} />
              <SearchPanel visible={true} highlightCaseSensitive={true} />
              <Grouping autoExpandAll={true} />
              <FilterRow visible={true} />


              <Column dataField="name" caption="Nombre" dataType="string" />
              <Column dataField="nit" caption="NIT" dataType="string" />
              <Column dataField="provinceId" caption="Provincia" dataType="string">
                <Lookup dataSource={provinces} valueExpr="id" displayExpr="name" />
              </Column>
              <Column dataField="address" caption="Dirección" dataType="string" width={150} />
              <Column dataField="phone" caption="Celular" dataType="string" />
              <Column dataField="personType" caption="Tipo de persona" dataType="string" width={150}>
                <Lookup dataSource={personType} valueExpr="personType" displayExpr="personType" />
              </Column>
              <Column dataField="inventory" caption="Inventario" dataType="string">
                <Lookup dataSource={inventories} valueExpr="inventory" displayExpr="name" />
              </Column>
              <Column dataField="antioquiaValueService" caption="Antioquia" dataType="number" format="currency" />
              <Column dataField="valleValueService" caption="Valle" dataType="number" format="currency" />
              <Column dataField="cundinamarcaValueService" caption="Cundinamarca" dataType="number" format="currency" />
              <Column dataField="atlanticoValueService" caption="Atlantico" dataType="number" format="currency" />

              <Paging defaultPageSize={5} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={pageSizes}
                showInfo={true} />
              <Editing
                mode="form"
                allowUpdating={true}
                useIcons={true}
              />
            </DataGrid>
          </Spin>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default FormCustomer;
