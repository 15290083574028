import { message } from 'antd';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import billsAPI from '../../../Api/api-bills';
import filterContext from '../../../Hooks/filterContex';
import { BillStatus } from '../../../Models/Bill';


export const useConfirmed = () => {

    const filters = useContext(filterContext);
    const [statusSelected, setStatusSelected] = useState('Programado');
    const handleStatus = (value: string) => {
        setStatusSelected(value)
    }


    const [confirmedSelect, setConfirmedSelect] = useState(0);
    const handleConfirmed = (confirmed: string) => {
        if (confirmed == "Sin Confirmar") {
            setConfirmedSelect(0);
        }
        if (confirmed == "Confirmado") {
            setConfirmedSelect(1);
        }
    }


    const [services, setServices] = useState<BillStatus[]>([]);
    const [loadingServices, setLoadingServices] = useState(false);
    const getServices = async (confirmed: number, statusSelected: string) => {
        var from: any = "";
        var to: any = "";
        if (filters.date) {
            from = moment(filters.date[0]);
            to = moment(filters.date[1]);
        }
        else {
            from = moment(new Date()).add(-120, "d");
            to = moment(new Date()).add(1, "d");
        }
        let payload = {
            warehouseId: filters.warehouseId,
            from: from,
            to: to,
            status: statusSelected
        }
        setLoadingServices(true);
        try {
            const services = await billsAPI.GetBillByDepartmentandStatus(payload);
            const servicesFilter = services.filter(x => x.confirmed == confirmed);
            setServices(servicesFilter);
        } catch (error) {
            message.error(`No se pudo obtener los servicios ${statusSelected}. ${error}`)
        }
        setLoadingServices(false);
    }

    const handleUpdate = async ({ data }: any) => {
        let payload = {
            id: data.id,
            guia: data.guia
        }
        try {
            let bill = await billsAPI.getBillsById(payload);
            let billNews = bill.billNews.filter(b => b.newsBillingType === 'Novedad Paloma');
            let lastBillNews = billNews.pop();
            let compare = lastBillNews?.description === data.aditionalInformation;
            if (data.aditionalInformation !== '') {
                if (!compare) {
                    let payload = {
                        id: 0,
                        billId: data.id,
                        newsBillingType: "Novedad Paloma",
                        description: data.aditionalInformation,
                        solution: "",
                        userID: Number(localStorage.userID),
                        changeStatus: false
                    }
                    await billsAPI.createBillsNews(payload);
                    message.success('Se creo correctamente los servicios')
                }
            } else {
                message.warn(`Debe escribir una novedad para poder actualizar.`)
            }
            getServices(confirmedSelect, statusSelected);
        } catch (error) {
            message.error(`No se pudo actualizar el servicio`);
        }
    }



    useEffect(() => {
        let mounted = true;
        if (mounted) {
            getServices(confirmedSelect, statusSelected);
        }
        return () => {
            mounted = false;
        }
    }, [filters.warehouseId, confirmedSelect, statusSelected])

    return {
        handleConfirmed,
        handleStatus,
        services,
        loadingServices,
        confirmedSelect,
        statusSelected,
        getServices,
        handleUpdate
    }
}
