import { Button, Col, Input, Modal, Row } from 'antd';
import moment from 'moment';
import React,{useEffect} from 'react';
import { useBillNews } from '../../Hooks/useBillNews';
//import NewsDispatchedModalComponent from '../OfficesComponent/components/NewsDispatchedModalComponent/NewsDispatchedModalComponent';
import { IModalBillNewsProps } from './IModalBillNewsProps';
import './styles.css';
const { TextArea } = Input;

const ModalBillNews: React.SFC<IModalBillNewsProps> = (props) => {

  const {GetBillById,modalVisible,setModalVisible,bill,loading} = useBillNews();

  useEffect(() => {
    GetBillById(props.billId)
  }, [props.billId]);

  const handleClose = () => {}

  return (
      <Modal
        visible={modalVisible}
        title={`Novedades Guia: ${bill?.guia ? bill.guia : "-"}`}
        onOk={()=>setModalVisible(false)}
        onCancel={()=>setModalVisible(false)}
        footer={[]}
        width={800}
      >
        <Row gutter={[16,16]}>
          <Col span={12}><b>Cliente: </b>{bill?.name}</Col>
          <Col span={12}><b>Ciudad: </b>{bill?.city}</Col>
          <Col span={12}><b>Dirección: </b>{bill?.address}</Col>
          <Col span={12}><b>Teléfono: </b>{bill?.phone}</Col>
        </Row>
        {/* {bill &&
        <NewsDispatchedModalComponent 
                handleClose={ handleClose }
                selectedData={bill}
              />
        } */}
       {/*  {bill?.billNews.map((x,i)=>
          <>
          <hr/>
          <Row gutter={[16,16]}>
            <Col span={24}><b>Fecha: {moment(x.created_at).format('YYYY-MM-DD HH:mm')}</b></Col>
            <Col span={12} style={{borderRight:'1px solid gray'}}>
              <b style={{textDecoration:'underline'}}>Novedad:</b><br/>
              {x.description}
            </Col>
            <Col span={12}>
              <b style={{textDecoration:'underline'}}>Solución:</b><br/>
              {x.solution != "" ? x.solution : 
                <>
                  <TextArea rows={2} name="solution" onChange={updateBillNews(i)}/>
                  <Button 
                    type="primary" 
                    style={{float:'right',marginTop:10}} 
                    loading={loading}
                    onClick={()=>saveData(x)}>
                      <SaveOutlined/>Guardar</Button>
                </>
              }
            </Col>
          </Row>
          </>
        )} */}
        
      </Modal>
  );
};

export default ModalBillNews;
