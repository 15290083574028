import { message } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react"
import billsAPI from "../../../Api/api-bills";
import customerAPI from "../../../Api/api-customers";
import filterContext from "../../../Hooks/filterContex";
import { BillStatus, SettleCourier } from "../../../Models/Bill";
import { Customer } from "../../../Models/Customer";
import { Statuses } from "../../../Models/Status";

export const useLiquidationAdmin = () => {

    const { warehouseId, customerId, date} = useContext(filterContext);
    const [payloadMessager, setPayloadMessager] = useState<SettleCourier>({
        warehouseId,
        from: moment(new Date(), "YYYY-MM-DD"),
        to : moment(new Date(), "YYYY-MM-DD"),
        
    })

    const [payloadCustomer, setPayloadCustomer] = useState<PayloadCustomer>({
        warehouseId,
        customerId,
        from: moment(new Date(), "YYYY-MM-DD"),
        to : moment(new Date(), "YYYY-MM-DD"),
        isClosed: true
    })


    const [tabSelect, setTabSelect] = useState("Mensajeros");
    const handleChangeTab = (tabSelect:string) => {
        setTabSelect(tabSelect)
    }

    const handleChangeMessager = (messager:string) => {
        if(messager == "Todos"){
            delete payloadMessager.zone;
            setPayloadMessager({
                warehouseId,
                ...payloadMessager
            });
            return;
        }
        setPayloadMessager({
            ...payloadMessager,
            zone: messager
        });
    }

    const handleChangeDate = ( dates : any) => {
        if(dates !== null) {
            setPayloadMessager({
                ...payloadMessager,
                from: moment(dates[0]).format("YYYY-MM-DD"),
                to: moment(dates[1]).format("YYYY-MM-DD")
            })
        }
    }

    const [services, setServices] = useState<BillStatus[]>([]);
    const [liquidationValue, setLiquidationValue] = useState(0);
    const [valorRecaudo, setValorRecaudo] = useState(0);
    const [valorDomicilio, setValorDomicilio] = useState(0);
    const [loadingServices, setLoadingServices] = useState(false);
    const getServices = async() => {
        setLoadingServices(true);
        switch (tabSelect) {
            case "Mensajeros":
                try {
                    let liquidationValue = 0;
                    payloadMessager.warehouseId = warehouseId;
                    const services = await billsAPI.GetSettleCourierInvoice(payloadMessager);  
                    services.forEach( (s : any) => {
                        liquidationValue += s.collect_Value;
                    }) 
                    setLiquidationValue(liquidationValue);
                    const servicesFilter = services.filter( s =>  s.statusId === 3 || s.statusId === 5 || s.statusId === 6 ||  s.statusId === 7)
                    console.log(servicesFilter)
                    setServices(servicesFilter);
                } catch (error) {
                    message.error(`No se obtuvo la liquidación de mensajeros. ${error}`);
                }
                break;
            case "Clientes":
                //payloadCustomer.warehouseId = warehouseId;
                //payloadCustomer.customerId = customerId;
                let payloadCustomer: PayloadCustomer = {
                    warehouseId,
                    customerId,
                    isClosed:true,
                    from: date[0] == null ?   moment(new Date(), "YYYY-MM-DD") : date[0],
                    to: date[1] == null ?  moment(new Date(), "YYYY-MM-DD") : date[1],
                }
                let valorRecaudo = 0;
                let valorDomicilio = 0;
                const services = await billsAPI.GetBillsByDepartmentandDate(payloadCustomer);
                services.forEach( (s : any) => {
                    valorRecaudo += s.collect_Value;
                    valorDomicilio += s.service_Value;
                }) 
                const servicesFilter = services.filter( s =>  s.statusId === 3 || s.statusId === 5 || s.statusId === 6 ||  s.statusId === 7)
                setServices(servicesFilter);
                setValorRecaudo(valorRecaudo);
                setValorDomicilio(valorDomicilio);
                break;
            }
        setLoadingServices(false);
    }

    const handleUpdate = async({ data } : any) => {
        try {
            console.log(data)
            // await billsAPI.UpdateBillHeader(data);
            // message.success(`Se actualizó correctamente el servicio.`);
            // getServices();
        } catch (error) {
            message.error(`No se pudo actualizar el servicio. ${error}`)
        }
    }


    // Controladores Globales
    const [statuses, setStatuses] = useState<Statuses[]>([]);
    const [customers, setCustomers] = useState<Customer[]>([]);
    const getControllers = async() => {
        try {
            const statuses = await billsAPI.getStatusList();
            const statusesFilter = statuses.filter(s => s.id === 3 || s.id === 5 || s.id === 6 ||  s.id === 7 );
            const customers = await customerAPI.getCustomers();
            setStatuses(statusesFilter);
            setCustomers(customers);
        } catch (error) {
            message.error(`No se cargaron los controles generales. ${error}`);
        }
    }
    useEffect(() => {
        let mounted = true;
        if( mounted ) {
            if( warehouseId !== 0) {
                getControllers();
                getServices();
            }
        }
        return () => {
            mounted = false;
        }
    }, [ warehouseId, customerId, payloadMessager, tabSelect, date])

    return {
        handleChangeTab,
        statuses,
        customers,
        services,
        handleChangeMessager,
        handleChangeDate,
        loadingServices,
        liquidationValue,
        valorRecaudo,
        valorDomicilio,
        handleUpdate,
        getServices
    }
}


export type PayloadCustomer = {
    customerId: number
    warehouseId: any,
    from?: any,
    to?: any
    isClosed?: boolean
}