import axios from 'axios';
import { Liquidation } from '../Models/Liquidation';
import utils from '../utils';
import { Utils } from '../utils/utils';

const LiquidationAPI = {
    GetLiquidations(startDate:string,endDate:string,warehouseId:number): Promise<Liquidation[]>  {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.get(`${config.data[0].ulrBackend}/Liquidation/GetLiquidationByDate?startDate=${startDate}&endDate=${endDate}&warehouseId=${warehouseId}`)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                            utils.messageError();
                        } else {
                            console.log('Error', error.message);
                            utils.messageError();
                        }
                        console.log(error.config);
                    });
            });
        });
    },
    postLiquidation(data: Liquidation) {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].ulrBackend}/Liquidation/PostLiquidation`,data)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                            utils.messageError();
                        } else {
                            console.log('Error', error.message);
                            utils.messageError();
                        }
                        console.log(error.config);
                    });
            });
        });
    },
    DeleteLiquidation(startDate:string,endDate:string,warehouseId:number): Promise<Liquidation[]>  {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.get(`${config.data[0].ulrBackend}/Liquidation/DeleteLiquidation?startDate=${startDate}&endDate=${endDate}&warehouseId=${warehouseId}`)
                    .then(response => resolve(response.data))
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            reject(error.response.data)
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                            utils.messageError();
                        } else {
                            console.log('Error', error.message);
                            utils.messageError();
                        }
                        console.log(error.config);
                    });
            });
        });
    }
}

export default LiquidationAPI;