import React from 'react';
import { Row, Col, Typography, Tabs } from 'antd';
import { LiquidationCouriers } from './LiquidationCouriers';
import './Style.css';
import { LiquidationCustomers } from './LiquidationCustomers';
import { useLiquidationAdmin } from './Hooks/useLiquidationAdmin';
import { useMessager } from '../../Hooks/useMessager';
import CurrierLiquidation from '../2.0/Liquidation/CurrierLiquidation';

const { TabPane } = Tabs;
const { Title, Paragraph } = Typography;

export const LiquidationAdmin = () => {

    const {
        handleChangeTab,
        statuses,
        customers,
        services,
        handleChangeMessager,
        handleChangeDate,
        loadingServices,
        liquidationValue,
        valorRecaudo,
        valorDomicilio,
        handleUpdate,
        getServices
    } = useLiquidationAdmin();
    const { messagersByWarehouse } = useMessager();

    return (
        <React.Fragment>
            <Row justify="center">
                <Col>
                    <Title className="title" level={1}>Liquidación</Title>
                </Col>
            </Row>

            <Tabs defaultActiveKey="1" onChange={handleChangeTab}>
                <TabPane tab="Mensajeros" key="Mensajeros">

                    <LiquidationCouriers 
                        services={services}
                        handleChangeMessager={handleChangeMessager}
                        messagersByWarehouse={messagersByWarehouse}
                        handleChangeDate={handleChangeDate}
                        loadingServices={loadingServices}
                        statuses={statuses}
                        customers={customers}
                        liquidationValue={liquidationValue}
                        getServices={getServices}
                    />
                </TabPane>
                <TabPane tab="Clientes" key="Clientes">
                    <LiquidationCustomers 
                        services={services}
                        loadingServices={loadingServices}
                        statuses={statuses}
                        valorRecaudo={valorRecaudo}
                        valorDomicilio={valorDomicilio}
                        handleUpdate={handleUpdate}
                        handleChangeDate={handleChangeDate}
                        getServices={getServices}
                    />
                </TabPane>
                <TabPane tab="Liquidación Diaria" key="2">
                    <CurrierLiquidation />
                </TabPane>
            </Tabs>
        </React.Fragment>
    )
}
